import { AssetType } from "@enzymefinance/environment";
import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { useGlobals } from "components/providers/GlobalsProvider";
import { formatUnits } from "viem";
import type { IntegrationHandler } from "./types";

export const idleLend: IntegrationHandler<Integrations.IdleV4.LendArgs> = {
  Description({ args: { idleToken: idleTokenAddress, depositAmount, minIncomingIdleTokenAmount } }) {
    const { environment } = useGlobals();
    const idleToken = environment.getAsset(idleTokenAddress);
    const underlyingToken = idleToken.type === AssetType.IDLE ? environment.getAsset(idleToken.underlying) : undefined;

    return (
      <>
        Lending on Idle (converting{" "}
        <BigIntDisplay
          value={depositAmount}
          decimals={underlyingToken?.decimals}
          numberFormat={{ currency: underlyingToken?.symbol }}
        />{" "}
        into{" "}
        <BigIntDisplay
          value={minIncomingIdleTokenAmount}
          decimals={idleToken.decimals}
          numberFormat={{ currency: idleToken.symbol }}
        />
      </>
    );
  },
  Label() {
    return <>Lend on Idle</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.IdleV4.lendDecode(encodedCallArgs),
};

export const idleRedeem: IntegrationHandler<Integrations.IdleV4.RedeemArgs> = {
  Description({ args: { idleToken: idleTokenAddress, outgoingIdleTokenAmount, minIncomingUnderlyingAmount } }) {
    const { environment } = useGlobals();

    const idleToken = environment.getAsset(idleTokenAddress);
    const underlyingToken = idleToken.type === AssetType.IDLE ? environment.getAsset(idleToken.underlying) : undefined;

    const idleTokenAmount = formatUnits(outgoingIdleTokenAmount, idleToken.decimals);
    const underlyingTokenAmount = formatUnits(minIncomingUnderlyingAmount, underlyingToken?.decimals ?? 18);

    return (
      <>
        Redeeming on Idle (converting {idleTokenAmount} {idleToken.symbol} into {underlyingTokenAmount}{" "}
        {underlyingToken?.symbol})
      </>
    );
  },
  Label() {
    return <>Redeem on Idle</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.IdleV4.redeemDecode(encodedCallArgs),
};

export const idleClaimRewards: IntegrationHandler<Integrations.IdleV4.ClaimRewardsArgs> = {
  Description() {
    return <>Claim rewards on Idle</>;
  },
  Label() {
    return <>Claim rewards on Idle</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.IdleV4.claimRewardsDecode(encodedCallArgs),
};
