import type { Address } from "@enzymefinance/environment";
import { toAddress } from "@enzymefinance/environment";
import { BigIntDisplay, useFormatBigInt } from "@enzymefinance/ethereum-ui";
import { Form, FormErrorMessage, NumberInput, SubmitButton, TokenPicker, useForm } from "@enzymefinance/hook-form";
import { Depositor, Utils } from "@enzymefinance/sdk";
import { Button, ExpandableButton, Modal } from "@enzymefinance/ui";
import { asset, bigIntInput, bigint, numberInput, percentage, refine, safeParse } from "@enzymefinance/validation";
import { useSigner } from "components/connection/Connection";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useNetwork } from "components/providers/NetworkProvider";
import { useCurrencySlug } from "components/settings/Settings";
import { useVaultBalancesQuery } from "queries/backend";
import { useVaultAllowedAssetsForRedemptionQuery, useVaultMinAssetBalancesPostRedemptionQuery } from "queries/core";
import type { ReactNode } from "react";
import { useMemo } from "react";
import { useToggle } from "react-use";
import { client as backendClient } from "utils/backend";
import { useSharesBalance } from "utils/hooks/useSharesBalance";
import { useSpecificAssetsRedemptionExpectedAmounts } from "utils/hooks/useSpecificAssetsRedemptionExpectedAmounts";
import { useTokenBalance } from "utils/hooks/useTokenBalance";
import { isAddressEqual } from "viem";
import { z } from "zod";

import type { StartSdkFunction } from "components/transactions/TransactionModal";
import { maxUint256 } from "viem";
import { PolicyTokenAmountsList } from "../config/policies/PolicyTokenAmountsList";

const schemaBase = z.object({
  maxWithdrawalAmount: bigint(),
  payoutAsset: asset(),
  redeemQuantity: bigIntInput({
    decimals: 18,
  }).transform(
    refine((value) => value > 0n, {
      message: "The share quantity cannot be zero.",
    }),
  ),
  withdrawalLimit: numberInput(
    percentage(
      z
        .number()
        .min(1, "The withdrawal limit cannot be lower than 1%.")
        .max(100, "The withdrawal limit can not be higher than 100%."),
    ),
  ),
  redeemValue: bigint(),
  shareBalance: bigint(),
});

const schema = schemaBase.transform(
  refine((value) => value.redeemQuantity <= value.shareBalance, {
    message: "The desired number of shares to redeem exceeds your actual share balance.",
    path: ["redeemQuantity"],
  }),
);

interface VaultRedeemSharesSpecifiedAssetsFormProps {
  children?: ReactNode;
  comptrollerProxy: string;
  close: () => void;
  exitFeeWarning: ReactNode;
  startTransaction: StartSdkFunction;
  vaultProxy: Address;
}

export function VaultRedeemSharesSpecifiedAssetsForm({
  children,
  comptrollerProxy,
  close,
  exitFeeWarning,
  startTransaction,
  vaultProxy,
}: VaultRedeemSharesSpecifiedAssetsFormProps) {
  const [signerAddress] = useSigner();
  const { client, deployment } = useNetwork();
  const { environment } = useGlobals();
  const [isAdvancedOpen, toggleAdvanced] = useToggle(false);

  const currency = useCurrencySlug();
  const { data: minAssetBalancesData } = useVaultMinAssetBalancesPostRedemptionQuery({
    variables: { comptroller: comptrollerProxy.toLowerCase() },
  });

  const minAssetBalances = useMemo(() => {
    const policySettings = minAssetBalancesData?.minAssetBalancesPostRedemptionPolicies[0];

    if (!policySettings?.enabled) {
      return undefined;
    }

    return policySettings.assetBalances;
  }, [minAssetBalancesData]);

  const { data: vaultBalancesData } = useVaultBalancesQuery({
    client: backendClient,
    skip: !vaultProxy,
    variables: { currency, network: deployment, vault: vaultProxy },
  });

  const vaultHoldings = useMemo(
    () =>
      vaultBalancesData?.vaultBalances
        .map((vaultBalance) => ({
          asset: environment.getAsset(vaultBalance.asset),
          balance: vaultBalance.balance,
        }))
        .filter((value) => value.balance > 0) ?? [],
    [environment, vaultBalancesData?.vaultBalances],
  );

  const { data: allowedAssetsData } = useVaultAllowedAssetsForRedemptionQuery({
    variables: { comptroller: comptrollerProxy.toLowerCase() },
  });

  const allowedAssets = useMemo(() => {
    const policySettings = allowedAssetsData?.allowedAssetsForRedemptionPolicies[0];

    if (!policySettings?.enabled) {
      return vaultHoldings;
    }

    const allowedAssetsFromPolicy = policySettings.addressLists.flatMap((addressList) => addressList.items);

    return vaultHoldings.filter((holding) =>
      allowedAssetsFromPolicy.some((address) => isAddressEqual(toAddress(address), holding.asset.id)),
    );
  }, [allowedAssetsData, vaultHoldings]);

  const form = useForm({
    defaultValues: {
      withdrawalLimit: "95",
      maxWithdrawalAmount: 0n,
      payoutAsset: allowedAssets[0]?.asset,
      redeemQuantity: "",
      redeemValue: 0n,
      shareBalance: 0n,
    },
    mode: "onChange",
    onSubmit: (values, helpers) => {
      if (!signerAddress) {
        return helpers.setError("form", {
          message: "Wallet not connected. You must connect your wallet to perform this action.",
        });
      }

      const withdrawalLimit = BigInt(Math.floor(values.withdrawalLimit * 100));
      const safeRedeemValue =
        typeof values.redeemValue === "bigint" ? (values.redeemValue * 100n) / withdrawalLimit : undefined;

      const redeemAmountOkay = typeof safeRedeemValue === "bigint" && safeRedeemValue <= values.maxWithdrawalAmount;
      if (!redeemAmountOkay) {
        const redemptionCap = (values.withdrawalLimit * 100).toFixed(0);
        return helpers.setError("form", {
          message: `The vault does not have a sufficient balance of this specific asset to allow withdrawals. Redemption in a single asset is capped at ${redemptionCap}% of the vault's balance (which can be changed in the advanced settings below). Please also note that in-kind redemptions are still available.`,
        });
      }

      // If the user wants to redeem all shares, we have to pass MaxInt256 to the method.
      // This will make sure that any shares that are allocated as part of the redemption (e.g. management fees)
      // are also included in the redemption.
      const redeemQuantity =
        typeof values.redeemQuantity === "bigint" && values.redeemQuantity < values.shareBalance
          ? values.redeemQuantity
          : maxUint256;

      try {
        const fn = Depositor.redeemSharesForSpecificAssets({
          comptrollerProxy: toAddress(comptrollerProxy),
          recipient: signerAddress,
          sharesQuantity: redeemQuantity,
          payoutAssets: [values.payoutAsset.id],
          payoutPercentages: [10000n],
        });

        startTransaction(fn, signerAddress);
      } catch (error) {
        helpers.setError("form", { message: `Error submitting the transaction: ${error.message}` });
      }
    },
    schema,
  });

  const { setValue, trigger, watch } = form;

  // Shares Balance Query
  const sharesBalanceQuery = useSharesBalance(
    client,
    {
      account: signerAddress,
      vault: vaultProxy,
    },
    {
      queryKey: ["sharesbalance", signerAddress ?? "", vaultProxy],
      onSuccess(data) {
        setValue("shareBalance", data);
      },
    },
  );

  const [payoutAsset, shareBalance, redeemQuantity, redeemValue] = watch([
    "payoutAsset",
    "shareBalance",
    "redeemQuantity",
    "redeemValue",
  ]);

  const parsedPayoutAsset = safeParse(asset(), payoutAsset);
  const parsedShareBalance = safeParse(bigint(), shareBalance);
  const parsedRedeemValue = safeParse(bigint(), redeemValue);
  const parsedRedeemQuantity = safeParse(schemaBase.shape.redeemQuantity, redeemQuantity);

  const specificAssetsRedemptionQuery = useSpecificAssetsRedemptionExpectedAmounts(
    client,
    {
      comptrollerProxy: toAddress(comptrollerProxy),
      payoutAssets: parsedPayoutAsset ? [parsedPayoutAsset.id] : undefined,
      payoutPercentages: [1],
      redeemQuantity: typeof parsedRedeemQuantity === "bigint" ? parsedRedeemQuantity : undefined,
      recipient: signerAddress,
    },
    {
      queryKey: [
        "specific-assets-redemption-expected-amounts",
        comptrollerProxy,
        payoutAsset?.id ?? "",
        parsedRedeemQuantity ? parsedRedeemQuantity.toString() : "",
      ],
      cacheTime: 0,
      onSettled(data, error) {
        if (!error && data?.[0] !== undefined) {
          setValue("redeemValue", data[0]);
          trigger();
        } else {
          setValue("redeemValue", 0n);
          form.setError("payoutAsset", {
            message: "The vault does not have a sufficient balance of this asset to allow this redemption.",
          });
        }
      },
    },
  );

  const payoutAssetBalanceQuery = useTokenBalance(
    client,
    { account: vaultProxy, token: payoutAsset?.id },
    {
      onSuccess: (vaultBalance) => {
        if (payoutAsset?.decimals === undefined) {
          setValue("maxWithdrawalAmount", 0n);

          return;
        }

        // Max withdrawal amount for each asset = vault holding - minAssetBalance post withdrawal
        const minAssetBalance =
          safeParse(
            bigIntInput({ decimals: payoutAsset.decimals }),
            minAssetBalances?.find((assetBalance) =>
              Utils.Address.safeSameAddress(assetBalance.asset.id, payoutAsset.id),
            )?.amount ?? "0",
          ) ?? 0n;
        const maxWithdrawalAmount = vaultBalance > minAssetBalance ? vaultBalance - minAssetBalance : 0n;
        setValue("maxWithdrawalAmount", maxWithdrawalAmount);
      },
    },
  );

  return (
    <Form form={form}>
      <Modal.Body className="space-y-6">
        <div className="space-y-1">
          <h3 className="text-base-content text-sm font-medium">Your Current Balance:</h3>
          <div className="text-base-content text-lg font-bold">
            <BigIntDisplay
              loading={sharesBalanceQuery.isLoading || sharesBalanceQuery.isFetching}
              numberFormat={{ currency: "shares", maximumFractionDigits: 18 }}
              value={parsedShareBalance}
            />
          </div>
        </div>
        <NumberInput
          appearance="extended"
          wrapperclassname="rounded-lg"
          numberFormat={{ maximumFractionDigits: 18 }}
          balance={useFormatBigInt(parsedShareBalance).value}
          description="The number of shares you would like to redeem."
          name="redeemQuantity"
          label="Quantity of shares to redeem"
        />
        <div className="space-y-2">
          <TokenPicker
            label="Asset to redeem shares in"
            name="payoutAsset"
            kind="modal"
            options={allowedAssets.map((allowedAsset) => allowedAsset.asset)}
          >
            <TokenPicker.Button
              name="redemptionAsset"
              className="bg-base-300 group flex w-full !px-4 [&>span]:!justify-between"
              appearance="quaternary"
            >
              <span className="text-heading-content text-sm">Select a token</span>
            </TokenPicker.Button>
          </TokenPicker>
          {typeof parsedRedeemValue === "bigint" && payoutAsset && parsedRedeemValue > 0n && form.formState.isValid ? (
            <div className="text-sm">
              You will receive about{" "}
              <BigIntDisplay
                loading={specificAssetsRedemptionQuery.isLoading || specificAssetsRedemptionQuery.isFetching}
                numberFormat={{ currency: `${payoutAsset.symbol} tokens`, maximumFractionDigits: 4 }}
                value={parsedRedeemValue}
                decimals={payoutAsset.decimals}
              />
            </div>
          ) : null}
        </div>
        {exitFeeWarning}
        {minAssetBalances ? (
          <div className="space-y-4">
            <p>Minimum asset balances remaining in vault after redemption:</p>
            <PolicyTokenAmountsList assetBalances={minAssetBalances} />{" "}
          </div>
        ) : null}
        {children}

        <ExpandableButton isOpen={isAdvancedOpen} handleExpand={toggleAdvanced} label="Advanced Settings">
          <NumberInput
            label="Asset redeem limit"
            name="withdrawalLimit"
            description="Percentage of a vault's asset value that can be redeemed by a single redemption. Please note that a higher limit increases the likelihood of transaction reverts in case of share price fluctuation."
            numberFormat={{ style: "percent", maximumFractionDigits: 2 }}
          />
        </ExpandableButton>

        <FormErrorMessage />
      </Modal.Body>
      <Modal.Actions>
        <SubmitButton
          disabled={specificAssetsRedemptionQuery.isLoading || payoutAssetBalanceQuery.isLoading}
          loading={specificAssetsRedemptionQuery.isLoading || payoutAssetBalanceQuery.isLoading}
        >
          Continue
        </SubmitButton>
        <Button appearance="secondary" onClick={close}>
          Cancel
        </Button>
      </Modal.Actions>
    </Form>
  );
}
