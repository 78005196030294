import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { useGlobals } from "components/providers/GlobalsProvider";
import { parseEther } from "viem";
import { getDefaultExtensionSummary } from "..";
import type { CreateExternalPositionHandler, ExternalPositionHandler } from "./types";
import { decodeCallOnExternalPositionArgs } from "./utils";

const claimFeeType = ["Execution Layer Rewards", "Consensus Layer Rewards", "All Rewards"];

export const kilnStake: ExternalPositionHandler<Integrations.Kiln.StakeArgs> = {
  Description({ args: { validatorAmount } }) {
    const { environment } = useGlobals();
    const { weth } = environment.namedTokens;

    return (
      <div>
        Staking{" "}
        <BigIntDisplay
          value={validatorAmount * parseEther("32")}
          decimals={weth.decimals}
          numberFormat={{ currency: weth.symbol }}
        />
      </div>
    );
  },
  Label() {
    return <>Stake WETH</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.Kiln.stakeDecode(encodedCallArgs),
};

export const kilnUnstake: ExternalPositionHandler<Integrations.Kiln.UnstakeArgs> = {
  Description({ args: { publicKeys } }) {
    // Each validator has length 96 (48 bytes). Also remove initial 0x
    const numberOfValidators = (publicKeys.length - 2) / 96;

    return <>Unstaking {numberOfValidators} validators.</>;
  },
  Label() {
    return <>Unstake WETH from Kiln.</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.Kiln.unstakeDecode(encodedCallArgs),
};

export const kilnClaimFees: ExternalPositionHandler<Integrations.Kiln.ClaimFeesArgs> = {
  Description({ args: { claimFeeType: claimType, publicKeys } }) {
    return (
      <>
        Claiming {claimFeeType[claimType]} for {publicKeys.length} validators.
      </>
    );
  },
  Label() {
    return <>Claim Kiln Rewards</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.Kiln.claimFeesDecode(encodedCallArgs),
};

export const kilnSweepEth: ExternalPositionHandler = {
  Description() {
    return <>Sweep WETH</>;
  },
  Label() {
    return <>Sweep ETH from Kiln position into the vault.</>;
  },
  decodeExternalPositionArgs: () => null,
};

export const createKilnExternalPosition: CreateExternalPositionHandler = {
  Description({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize Kiln external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = kilnStake.decodeExternalPositionArgs(actionArgs);

    return <kilnStake.Description args={args} />;
  },
  Label({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize Kiln external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = kilnStake.decodeExternalPositionArgs(actionArgs);

    return <kilnStake.Label args={args} />;
  },
  Summary({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize Kiln external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = kilnStake.decodeExternalPositionArgs(actionArgs);

    const Summary = getDefaultExtensionSummary(kilnStake.Label, kilnStake.Description);

    return <Summary args={args} />;
  },
};
