import { toAddress } from "@enzymefinance/environment";
import { AddressCreatable, useFormContext } from "@enzymefinance/hook-form";
import { Utils } from "@enzymefinance/sdk";
import { Policies } from "@enzymefinance/sdk/Configuration";
import { Constants } from "@enzymefinance/sdk/Utils";
import { Alert, Badge, Button, ErrorMessage, RadioGroup } from "@enzymefinance/ui";
import { address } from "@enzymefinance/validation";
import { useSigner } from "components/connection/Connection.js";
import type { ReactNode } from "react";
import { useCallback, useMemo } from "react";
import { useUpdateEffect } from "react-use";
import { isAddress, isAddressEqual } from "viem";
import { z } from "zod";
import { InlineLink } from "../../../routing/Link";
import { VaultConfigFieldName } from "../VaultConfig";
import type { AllowedSharesTransferRecipientsPolicySettings } from "../VaultConfigSettingsTypes";
import type {
  VaultConfig,
  VaultConfigDisplayProps,
  VaultConfigDisplaySubgraphProps,
  VaultConfigFormFieldsProps,
} from "../VaultConfigTypes";
import { VaultConfigContext, VaultConfigPolicyListOption, VaultConfigType } from "../VaultConfigTypes";
import { PolicyAddressList } from "./PolicyAddressList";

export const allowedSharesTransferRecipientsPolicySchema = z
  .object({
    listId: z.string().optional(),
    items: z.array(address({ caseInsensitive: true, allowZeroAddress: false })),
    isDisallowAll: z.boolean().optional(),
  })
  .refine(
    (value) => {
      return value.isDisallowAll || value.items.length > 0;
    },
    {
      message: 'Please specify some addresses or choose "Disallow All"',
      path: ["items"],
    },
  )
  .optional();

interface AllowedSharesTransferRecipientsPolicyFormFieldsValues {
  [VaultConfigFieldName.ALLOWED_SHARES_TRANSFER_RECIPIENTS_POLICY]?: Partial<AllowedSharesTransferRecipientsPolicySettings>;
}

function allowedSharesTransferRecipientsPolicyFormFields({
  listOptions,
}: VaultConfigFormFieldsProps<VaultConfigType.ALLOWED_SHARES_TRANSFER_RECIPIENTS_POLICY>) {
  const { getFieldState, setValue, trigger, watch } =
    useFormContext<AllowedSharesTransferRecipientsPolicyFormFieldsValues>();
  const [signerAddress] = useSigner();
  const { error } = getFieldState(VaultConfigFieldName.ALLOWED_SHARES_TRANSFER_RECIPIENTS_POLICY);

  const [value] = watch([VaultConfigFieldName.ALLOWED_SHARES_TRANSFER_RECIPIENTS_POLICY]) as [
    AllowedSharesTransferRecipientsPolicyFormFieldsValues[VaultConfigFieldName.ALLOWED_SHARES_TRANSFER_RECIPIENTS_POLICY],
  ];
  const isDisallowAll = !!value?.isDisallowAll;

  useUpdateEffect(() => {
    trigger();
  }, [value]);

  const includesSigner = useMemo(
    () =>
      !!signerAddress &&
      (value?.items?.some((item) => item && isAddressEqual(signerAddress, toAddress(item))) ||
        listOptions?.options.items.some(
          (recipientAddress) => recipientAddress && isAddressEqual(signerAddress, toAddress(recipientAddress)),
        )),
    [listOptions?.options, signerAddress, value?.items],
  );

  const addOwnerAddress = useCallback(() => {
    if (signerAddress && !includesSigner) {
      const newValue: AllowedSharesTransferRecipientsPolicySettings = {
        items: [...(value?.items ?? []), signerAddress],
        listId: undefined,
        isDisallowAll: false,
      };
      setValue(VaultConfigFieldName.ALLOWED_SHARES_TRANSFER_RECIPIENTS_POLICY, newValue);
      // Setting nested prop directly so that the underlying form component subscribed to .items rerenders
      setValue(`${VaultConfigFieldName.ALLOWED_SHARES_TRANSFER_RECIPIENTS_POLICY}.items`, newValue.items as never, {
        shouldValidate: true,
      });
      trigger();
    }
  }, [includesSigner, setValue, signerAddress, trigger, value?.items]);

  const handleSelectListOption = useCallback(
    ({ value: newValue }: ListSelectOption) => {
      const newFieldValue: AllowedSharesTransferRecipientsPolicySettings = {
        items: newValue === VaultConfigPolicyListOption.DISALLOW_ALL ? [] : value?.items ?? [],
        listId: undefined,
        isDisallowAll: newValue === VaultConfigPolicyListOption.DISALLOW_ALL,
      };

      setValue(VaultConfigFieldName.ALLOWED_SHARES_TRANSFER_RECIPIENTS_POLICY, newFieldValue, { shouldValidate: true });
      trigger();
    },
    [setValue, trigger, value?.items],
  );

  const addressesInput = useMemo(
    () => (
      <AddressCreatable
        isDisabled={isDisallowAll}
        isExpandable={true}
        isMulti={true}
        label={allowedSharesTransferRecipientsPolicy.label}
        labelHidden={true}
        name={`${VaultConfigFieldName.ALLOWED_SHARES_TRANSFER_RECIPIENTS_POLICY}.items`}
        cornerHint={
          <Button
            disabled={includesSigner || isDisallowAll}
            size="xs"
            appearance="quaternary"
            onClick={addOwnerAddress}
          >
            Add Owner Wallet
          </Button>
        }
      />
    ),
    [addOwnerAddress, includesSigner, isDisallowAll],
  );

  const listSelectOptions: ListSelectOption[] = useMemo(
    () => [
      {
        value: VaultConfigPolicyListOption.CUSTOM_LIST,
        label: allowedSharesTransferRecipientsPolicy.label,
        description: isDisallowAll ? <></> : addressesInput,
      },
      {
        value: VaultConfigPolicyListOption.DISALLOW_ALL,
        label: "Disallow all transfers",
        description: <p className="text-sm">This setting can be changed later</p>,
      },
    ],
    [addressesInput, isDisallowAll],
  );

  const selectedListOption = useMemo(
    () =>
      isDisallowAll
        ? listSelectOptions.find((option) => option.value === VaultConfigPolicyListOption.DISALLOW_ALL)
        : listSelectOptions.find((option) => option.value === VaultConfigPolicyListOption.CUSTOM_LIST),
    [isDisallowAll, listSelectOptions],
  );

  if (listOptions?.action === "remove") {
    return (
      <AddressCreatable
        label={allowedSharesTransferRecipientsPolicy.label}
        labelHidden={true}
        isExpandable={true}
        isMulti={true}
        name={`${VaultConfigFieldName.ALLOWED_SHARES_TRANSFER_RECIPIENTS_POLICY}.items`}
        isValidNewOption={(inputValue) =>
          isAddress(inputValue) && listOptions.options.items.includes(toAddress(inputValue))
        }
        options={listOptions.options.items.map((option) => ({ label: option, value: option }))}
      />
    );
  }

  if (listOptions?.action === "add") {
    return (
      <>
        {addressesInput}
        {listOptions.options.items.some((option) =>
          value?.items?.some((recipientAddress) => Utils.Address.safeSameAddress(option, recipientAddress)),
        ) ? (
          <Alert appearance="info">
            One of the selected addresses is already contained in the list, and will therefore not get added.
          </Alert>
        ) : null}
      </>
    );
  }

  return (
    <div className="space-y-4">
      <RadioGroup<ListSelectOption>
        id={VaultConfigFieldName.ALLOWED_SHARES_TRANSFER_RECIPIENTS_POLICY}
        value={selectedListOption}
        onChange={handleSelectListOption}
        label={allowedSharesTransferRecipientsPolicy.label}
        labelHidden={true}
        optionRenderer={(option) => (
          <div className="space-y-2">
            <p className="text-heading-content text-sm font-medium">{option.label}</p>
            {option.description}
          </div>
        )}
        options={listSelectOptions}
      />
      {typeof error?.message === "string" ? <ErrorMessage>{error.message}</ErrorMessage> : null}
    </div>
  );
}

function allowedSharesTransferRecipientsPolicyDisplay({
  settings,
}: VaultConfigDisplayProps<VaultConfigType.ALLOWED_SHARES_TRANSFER_RECIPIENTS_POLICY>) {
  return <PolicyAddressList addresses={settings.items} />;
}

function allowedSharesTransferRecipientsPolicyDisplaySubgraph({
  settings,
}: VaultConfigDisplaySubgraphProps<VaultConfigType.ALLOWED_SHARES_TRANSFER_RECIPIENTS_POLICY>) {
  const addresses = settings.addressLists.flatMap((list) => list.items);

  return <PolicyAddressList addresses={addresses} columns={3} iconSize={12} />;
}

export const allowedSharesTransferRecipientsPolicy: VaultConfig<VaultConfigType.ALLOWED_SHARES_TRANSFER_RECIPIENTS_POLICY> =
  {
    address: (contracts) => contracts.AllowedSharesTransferRecipientsPolicy,
    disableable: true,
    display: allowedSharesTransferRecipientsPolicyDisplay,
    displaySubgraph: allowedSharesTransferRecipientsPolicyDisplaySubgraph,
    editable: true,
    encode: (settings, encodeArgs) => {
      const unique = [...new Set(settings.items.map((item) => toAddress(item)))];

      if (
        encodeArgs?.context === VaultConfigContext.RECONFIGURATION ||
        encodeArgs?.context === VaultConfigContext.MIGRATION
      ) {
        const previousSettingsSet = new Set(encodeArgs.previousSettings?.items.map((item) => item.toLowerCase()) ?? []);

        if (
          encodeArgs.previousSettings?.listId &&
          previousSettingsSet.size === unique.length &&
          unique.every((item) => previousSettingsSet.has(item))
        ) {
          // New and old values contain the same items => reuse the list ID

          return Policies.AllowedSharesTransferRecipients.encodeSettings({
            existingListIds: [BigInt(encodeArgs.previousSettings.listId)],
            newListsArgs: [],
          });
        }
      }

      const args = Policies.AllowedSharesTransferRecipients.encodeSettings({
        existingListIds: [],
        newListsArgs: [
          {
            initialItems: unique,
            updateType: Constants.AddressListUpdateType.AddAndRemove,
          },
        ],
      });

      return args;
    },
    fetch: async () => undefined,
    formFields: allowedSharesTransferRecipientsPolicyFormFields,
    label: "Restrict Wallets Permitted To Receive A Share Transfer",
    managerDescription: (
      <div className="space-y-4">
        <p>
          If enabled, restricts the potential recipients of shares transferred outside of the normal asset deposit and
          share minting process.
        </p>
        <p>
          <InlineLink to="https://docs.enzyme.finance/managers/setup/share-transfers" appearance="link">
            This policy
          </InlineLink>{" "}
          acts in concert with but not as a replacement for the policy which restricts wallets able to receive minted
          shares.
        </p>
        <p>
          In general, if you enable this policy to restrict who can receive shares that are already minted, you should
          also restrict who can mint new shares to the same list of wallets.
        </p>
        <Badge appearance="success">Editable Setting</Badge>
      </div>
    ),

    type: VaultConfigType.ALLOWED_SHARES_TRANSFER_RECIPIENTS_POLICY,
    validationSchema: allowedSharesTransferRecipientsPolicySchema,
  };

interface ListSelectOption {
  description: ReactNode;
  label: string;
  value: string;
}
