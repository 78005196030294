import classNames from "classnames";
import { forwardRef, useCallback, useMemo, useState } from "react";
import type { NumericFormatProps } from "react-number-format";
import { NumericFormat } from "react-number-format";

import type { NumberFormatOptions } from "../../hooks/useNumberFormat.js";
import { createNumberFormat } from "../../hooks/useNumberFormat.js";
import { validateSupportedIsoCurrency } from "../../utils/currency.js";
import { fromPercentage, parseFloatValue, toPercentage } from "../../utils/number.js";
import type { FieldGroupProps } from "./FieldGroup.js";
import { FieldGroup } from "./FieldGroup.js";
import type { BaseInputProps } from "./Input.js";
import { BaseInput } from "./Input.js";

export type { NumberFormatValues as NumberInputValues } from "react-number-format";

export interface BaseNumberInputProps
  extends Omit<BaseInputProps, "defaultValue" | "ref" | "type" | "appearance">,
    Omit<NumericFormatProps, "customInput" | "decimalScale" | "defaultValue" | "id"> {
  appearance?: "default" | "extended";
  balance?: string;
  // If type is extended you can't use customInput
  customInput?: NumericFormatProps["customInput"] | null;
  numberFormat?: Omit<NumberFormatOptions, "value">;
  onMaxClick?: () => void;
  showMaxButton?: boolean;
  value?: number | string;
  isError?: boolean;
  wrapperclassname?: string;
}

export const BaseNumberInput = forwardRef<HTMLInputElement, BaseNumberInputProps>(function BaseNumberInput(
  {
    appearance = "default",
    allowedDecimalSeparators = [",", "."],
    allowNegative = false,
    autoComplete = "off",
    autoCorrect = "off",
    customInput = BaseInput,
    inputMode = "decimal",
    valueIsNumericString: valueIsNumericStringBase,
    onValueChange,
    numberFormat,
    spellCheck = false,
    thousandSeparator,
    value: valueBase,
    isError,
    ...props
  },
  ref,
) {
  const { format, formatToParts } = createNumberFormat(numberFormat);

  const [focus, setFocus] = useState(false);

  const onBlur = useCallback<NonNullable<NumberInputProps["onBlur"]>>(
    (event) => {
      setFocus(false);

      if (props.onBlur) {
        props.onBlur(event);
      }
    },
    [props],
  );

  const onFocus = useCallback<NonNullable<NumberInputProps["onFocus"]>>(
    (event) => {
      setFocus(true);
      props.onFocus?.(event);
    },
    [props],
  );

  const inputWrapperClasses = useMemo(
    () =>
      appearance === "extended"
        ? classNames(
            "h-12 flex items-center bg-gray-50 dark:bg-gray-800 transition flex-1",
            {
              "border-red-300 dark:border-red-700 border": !focus && isError,
              "border-red-500 dark:border-red-500 border-2 ring-0": focus && isError,
              "border-transparent dark:border-transparent": !(focus || isError),
              "border-primary dark:border-primary border-2": focus && !isError,
            },
            props.wrapperclassname,
          )
        : "",
    [appearance, focus, isError, props.wrapperclassname],
  );

  const inputClasses = useMemo(
    () =>
      appearance === "extended"
        ? classNames(
            "block w-full bg-transparent border-none focus:ring-0 text-xl font-semibold py-0 px-3",
            {
              "text-heading-content placeholder-gray-400 dark:placeholder-gray-500": !isError,
              "text-red-900 dark:text-red-300 placeholder-red-500 dark:placeholder-red-500": isError,
            },
            props.className,
          )
        : props.className,
    [appearance, isError, props.className],
  );

  const isPercentage = numberFormat?.style === "percent";
  const decimalScale = numberFormat?.maximumFractionDigits;
  const value = isPercentage ? toPercentage(valueBase, decimalScale) : valueBase;
  const valueIsNumericString = valueIsNumericStringBase ?? typeof value === "string";
  const numberFormatProps = useMemo<Pick<NumericFormatProps, "prefix" | "suffix"> | undefined>(() => {
    if (!valueIsNumericString && typeof value === "string") {
      return undefined;
    }

    const isSupportedIsoCurrency = validateSupportedIsoCurrency(numberFormat?.currency);

    if (numberFormat?.currency !== undefined && !isSupportedIsoCurrency) {
      return { suffix: ` ${numberFormat.currency}` };
    }

    const [first, second, ...remainingParts] = formatToParts(Number(value ?? 0)) as (
      | Intl.NumberFormatPart
      | undefined
    )[];

    const last = remainingParts[remainingParts.length - 1];
    const secondToLast = remainingParts[remainingParts.length - 2];

    const prefix =
      first && (first.type === "currency" || first.type === "percentSign" || first.type === "unit")
        ? second?.type === "literal"
          ? `${first.value}${second.value}`
          : first.value
        : undefined;

    const suffix =
      last && (last.type === "currency" || last.type === "percentSign" || last.type === "unit")
        ? secondToLast?.type === "literal"
          ? `${secondToLast.value}${last.value}`
          : last.type === "unit"
            ? ` ${last.value}`
            : last.value
        : undefined;

    return { prefix, suffix };
  }, [formatToParts, valueIsNumericString, numberFormat?.currency, value]);
  const placeholder = useMemo<NumberInputProps["placeholder"]>(() => format(0), [format]);

  const handleValueChange = useCallback<NonNullable<NumberInputProps["onValueChange"]>>(
    (values, sourceInfo) => {
      onValueChange?.(
        {
          ...values,
          floatValue: isPercentage ? fromPercentage(values.floatValue, decimalScale) : values.floatValue,
        },
        sourceInfo,
      );
    },
    [isPercentage, decimalScale, onValueChange],
  );

  return (
    <div className={inputWrapperClasses}>
      <NumericFormat
        allowedDecimalSeparators={allowedDecimalSeparators}
        allowNegative={allowNegative}
        autoComplete={autoComplete}
        autoCorrect={autoCorrect}
        customInput={appearance === "extended" ? undefined : customInput ?? undefined}
        decimalScale={decimalScale}
        inputMode={inputMode}
        valueIsNumericString={valueIsNumericString}
        onValueChange={handleValueChange}
        placeholder={placeholder}
        spellCheck={spellCheck}
        thousandSeparator={thousandSeparator ?? numberFormat?.useGrouping}
        getInputRef={ref}
        value={value}
        {...numberFormatProps}
        {...props}
        onFocus={onFocus}
        onBlur={onBlur}
        className={inputClasses}
      />
    </div>
  );
});

export interface NumberInputProps extends Omit<BaseNumberInputProps, "id">, Omit<FieldGroupProps, "appearance"> {}

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(function NumberInput(
  { balance, cornerHint, error, description, label, labelHidden = false, showMaxButton = true, onMaxClick, ...props },
  ref,
) {
  const value = parseFloatValue(props.value);

  return (
    <FieldGroup
      cornerHint={
        showMaxButton && onMaxClick && typeof balance !== "undefined" ? (
          <span className="inline-flex max-w-full items-center space-x-2">
            <span className="inline-flex truncate">{cornerHint}</span>
            <NumberInputMaxButton balance={`${balance}`} onClick={onMaxClick} value={value} />
          </span>
        ) : (
          cornerHint
        )
      }
      error={error}
      description={description}
      label={label}
      labelHidden={labelHidden}
      id={props.id}
    >
      <BaseNumberInput {...props} ref={ref} isError={!!error} />
    </FieldGroup>
  );
});

export interface NumberInputMaxButtonProps {
  balance?: string;
  onClick?: () => void;
  value?: number;
}

export function NumberInputMaxButton({ balance, onClick, value }: NumberInputMaxButtonProps) {
  // Hide button if balance is undefined or 0
  if (balance === undefined || balance === "0" || balance === "") {
    return null;
  }

  const disabled = value === Number(balance);

  const classes = classNames(
    "flex-none bg-primary dark:bg-primary-focus text-white dark:text-gray-900 disabled:opacity-50 disabled:cursor-default text-xs font-medium rounded-lg px-1.5 py-0.5 focus:outline-none focus:ring-2 focus:ring-primary dark:focus:ring-primary focus:ring-offset-2 dark:focus:ring-offset-gray-900 transition",
    { "hover:bg-primary-focus dark:hover:bg-primary-lighter": !disabled },
  );

  return (
    <>
      <button className={classes} disabled={disabled} onClick={onClick} type="button">
        Max
      </button>
    </>
  );
}
