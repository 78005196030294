import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { BigIntInput } from "@enzymefinance/hook-form";
import { Policies } from "@enzymefinance/sdk/Configuration";
import { Constants } from "@enzymefinance/sdk/Utils";
import { Badge, StackedList } from "@enzymefinance/ui";
import { bigint } from "@enzymefinance/validation";
import { parseUnits } from "viem";
import { z } from "zod";
import { InlineLink } from "../../../routing/Link";
import { VaultConfigFieldName } from "../VaultConfig";
import type { CumulativeSlippageTolerancePolicySettings } from "../VaultConfigSettingsTypes";
import type { VaultConfig, VaultConfigDisplayProps, VaultConfigDisplaySubgraphProps } from "../VaultConfigTypes";
import { VaultConfigType } from "../VaultConfigTypes";

export const cumulativeSlippageTolerancePolicySchema = z.object({
  tolerance: bigint()
    // Check if value is at least 0
    .refine((value?: CumulativeSlippageTolerancePolicySettings["tolerance"]) => value && value >= parseUnits("0", 18), {
      message: "The slippage cannot be 0%.",
    })
    // Check if value is 100% at maximum
    .refine((value?: CumulativeSlippageTolerancePolicySettings["tolerance"]) => value && value <= parseUnits("1", 18), {
      message: "The slippage must be less than or equal to 100%.",
    }),
});

function cumulativeSlippageTolerancePolicyFormFields() {
  return (
    <BigIntInput
      label="Cumulative Slippage Tolerance"
      name={`${VaultConfigFieldName.CUMULATIVE_SLIPPAGE_TOLERANCE_POLICY}.tolerance`}
      numberFormat={{ style: "percent" }}
      decimals={16}
    />
  );
}

function cumulativeSlippageTolerancePolicyDisplay({
  settings,
}: VaultConfigDisplayProps<VaultConfigType.CUMULATIVE_SLIPPAGE_TOLERANCE_POLICY>) {
  return <BigIntDisplay value={settings.tolerance} decimals={18} numberFormat={{ style: "percent" }} />;
}

function cumulativeSlippageTolerancePolicyDisplaySubgraph({
  settings,
}: VaultConfigDisplaySubgraphProps<VaultConfigType.CUMULATIVE_SLIPPAGE_TOLERANCE_POLICY>) {
  const { cumulativeSlippage, lastSlippageTimestamp, tolerance } = settings;
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const secondsSinceLastTimestamp = BigInt(currentTimestamp - lastSlippageTimestamp);
  let currentCumulativeSlippage: bigint;

  if (secondsSinceLastTimestamp >= Constants.ONE_WEEK_IN_SECONDS) {
    // If one week has elapsed, current slippage is always 0
    currentCumulativeSlippage = 0n;
  } else {
    const rateOfDecay = parseUnits(cumulativeSlippage, 18) / Constants.ONE_WEEK_IN_SECONDS;
    currentCumulativeSlippage = parseUnits(cumulativeSlippage, 18) - rateOfDecay * secondsSinceLastTimestamp;
  }

  return (
    <StackedList.ItemDataBoxList>
      <StackedList.ItemDataBox label="Tolerance">
        <BigIntDisplay numberFormat={{ style: "percent" }} value={parseUnits(tolerance, 18)} />
      </StackedList.ItemDataBox>
      <StackedList.ItemDataBox label="Current cumulative slippage">
        <BigIntDisplay numberFormat={{ style: "percent" }} value={currentCumulativeSlippage} />
      </StackedList.ItemDataBox>
    </StackedList.ItemDataBoxList>
  );
}

export const cumulativeSlippageTolerancePolicy: VaultConfig<VaultConfigType.CUMULATIVE_SLIPPAGE_TOLERANCE_POLICY> = {
  address: (contracts) => contracts.CumulativeSlippageTolerancePolicy,
  disableable: false,
  display: cumulativeSlippageTolerancePolicyDisplay,
  displaySubgraph: cumulativeSlippageTolerancePolicyDisplaySubgraph,
  editable: false,
  encode: (settings) => Policies.CumulativeSlippageTolerance.encodeSettings({ slippageTolerance: settings.tolerance }),
  fetch: async ({ comptroller, client, vaultConfigAddress }) => {
    const policyInfo = await Policies.CumulativeSlippageTolerance.getInfo(client, {
      cumulativeSlippageTolerancePolicy: vaultConfigAddress,
      comptrollerProxy: comptroller,
    });

    return policyInfo;
  },
  formFields: cumulativeSlippageTolerancePolicyFormFields,
  label: "Set Cumulative Slippage Tolerance",
  managerDescription: (
    <div className="space-y-4">
      <p>
        Restricts value loss (i.e. slippage) that can occur via adapter actions over a &ldquo;tolerance period&rdquo; (7
        days). Vaults define their own tolerance amount (e.g. 5%, 10%, etc). When an adapter action results in slippage,
        that slippage amount is added to a cumulative slippage total. If the cumulative slippage total is greater than
        the tolerance amount, interactions with all adapters will be temporarily disabled. The cumulative slippage total
        then diminishes over the &ldquo;tolerance period&rdquo; duration at a constant rate based on the fund&rsquo;s
        chosen tolerance.
      </p>

      <p>
        Implementing{" "}
        <InlineLink to="https://docs.enzyme.finance/managers/setup/advanced-settings" appearance="link">
          this policy
        </InlineLink>{" "}
        will assure your depositors that you cannot manipulate the share price of your vault by systematically
        interacting with adapters in a way that benefits you but not the vault.
      </p>
      <Badge appearance="warning">Semi-permanent Setting</Badge>
    </div>
  ),
  publicDescription: (
    <p>
      Caps the amount of slippage a manager can accrue over a 7-day period.{" "}
      <InlineLink to="https://docs.enzyme.finance/managers/setup/advanced-settings" appearance="link">
        This policy
      </InlineLink>{" "}
      prevents managers from making trades that may potentially benefit themselves but not the vault.
    </p>
  ),
  type: VaultConfigType.CUMULATIVE_SLIPPAGE_TOLERANCE_POLICY,
  validationSchema: cumulativeSlippageTolerancePolicySchema,
  usePricelessAssetBypass: true,
};
