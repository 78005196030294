import { toAddress } from "@enzymefinance/environment";
import { TokenSelect, useFormContext } from "@enzymefinance/hook-form";
import { Policies } from "@enzymefinance/sdk/Configuration";
import { Constants } from "@enzymefinance/sdk/Utils";
import { Switch, Tooltip } from "@enzymefinance/ui";
import { asset } from "@enzymefinance/validation";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useMemo, useState } from "react";
import { useUpdateEffect } from "react-use";
import { isAddressEqual } from "viem";
import { z } from "zod";
import { InlineLink } from "../../../routing/Link";
import { VaultConfigFieldName } from "../VaultConfig";
import type { AllowedAdapterIncomingAssetsPolicySettings } from "../VaultConfigSettingsTypes";
import type {
  VaultConfig,
  VaultConfigDisplayProps,
  VaultConfigDisplaySubgraphProps,
  VaultConfigFormFieldsProps,
} from "../VaultConfigTypes";
import { VaultConfigType } from "../VaultConfigTypes";
import { PolicyTokenList } from "./PolicyTokenList";

export const allowedAdapterIncomingAssetsPolicySchema = z.array(asset()).optional();

interface AllowedAdapterIncomingAssetsPolicyFormFieldsValues {
  [VaultConfigFieldName.ALLOWED_ADAPTER_INCOMING_ASSETS_POLICY]?: Partial<AllowedAdapterIncomingAssetsPolicySettings>;
}

function allowedAdapterIncomingAssetsPolicyFormFields({
  listOptions,
}: VaultConfigFormFieldsProps<VaultConfigType.ALLOWED_ADAPTER_INCOMING_ASSETS_POLICY>) {
  const { getAssets } = useGlobals();
  const {
    formState: { isValid },
    setValue,
    trigger,
    watch,
  } = useFormContext<AllowedAdapterIncomingAssetsPolicyFormFieldsValues>();
  const [value] = watch([VaultConfigFieldName.ALLOWED_ADAPTER_INCOMING_ASSETS_POLICY]) as [
    AllowedAdapterIncomingAssetsPolicyFormFieldsValues[VaultConfigFieldName.ALLOWED_ADAPTER_INCOMING_ASSETS_POLICY],
  ];
  const [emptyList, toggleEmptyList] = useState(value?.length === 0);

  const defaultOptions = useMemo(
    () => getAssets({ registered: listOptions?.action === "add" || undefined }),
    [getAssets, listOptions?.action],
  );

  // If we add items to the list, we should not display the provided options
  // If we remove items from the list, we should only display the provided options
  const options = useMemo(
    () =>
      listOptions?.action === "add"
        ? defaultOptions.filter(
            (defaultOption) => !listOptions.options.find((option) => isAddressEqual(defaultOption.id, option.id)),
          )
        : listOptions?.action === "remove"
          ? listOptions.options
          : defaultOptions,
    [defaultOptions, listOptions?.action, listOptions?.options],
  );

  useUpdateEffect(() => {
    if (!isValid) {
      trigger();
    }
  }, [emptyList]);

  useUpdateEffect(() => {
    setValue(VaultConfigFieldName.ALLOWED_ADAPTER_INCOMING_ASSETS_POLICY, emptyList ? [] : undefined);
  }, [emptyList]);

  return (
    <div className="space-y-4">
      <TokenSelect
        isDisabled={emptyList}
        isExpandable={true}
        isMulti={true}
        label={allowedAdapterIncomingAssetsPolicy.label}
        name={VaultConfigFieldName.ALLOWED_ADAPTER_INCOMING_ASSETS_POLICY}
        options={options}
      />
      {listOptions?.action === "add" ? null : (
        <div className="border-base-200 flex items-center space-x-3 border-t">
          <Switch
            id="disallow-all-assets"
            label={<Tooltip label="Disallow all assets">This setting can be changed later.</Tooltip>}
            checked={emptyList}
            onChange={() => toggleEmptyList(!emptyList)}
          />
        </div>
      )}
    </div>
  );
}

function allowedAdapterIncomingAssetsPolicyDisplay({
  settings,
}: VaultConfigDisplayProps<VaultConfigType.ALLOWED_ADAPTER_INCOMING_ASSETS_POLICY>) {
  return <PolicyTokenList addresses={settings.map((setting) => setting.id)} />;
}

function allowedAdapterIncomingAssetsPolicyDisplaySubgraph({
  settings,
}: VaultConfigDisplaySubgraphProps<VaultConfigType.ALLOWED_ADAPTER_INCOMING_ASSETS_POLICY>) {
  const addresses = settings.addressLists.flatMap((list) => list.items);

  return <PolicyTokenList addresses={addresses} />;
}

export const allowedAdapterIncomingAssetsPolicy: VaultConfig<VaultConfigType.ALLOWED_ADAPTER_INCOMING_ASSETS_POLICY> = {
  address: (contracts) => contracts.AllowedAdapterIncomingAssetsPolicy,
  disableable: false,
  display: allowedAdapterIncomingAssetsPolicyDisplay,
  displaySubgraph: allowedAdapterIncomingAssetsPolicyDisplaySubgraph,
  editable: false,
  encode: (settings) => {
    const unique = settings
      .map((setting) => toAddress(setting.id))
      .filter((id, index, array) => array.indexOf(id) === index);

    const args = Policies.AllowedAdapterIncomingAssets.encodeSettings({
      existingListIds: [],
      newListsArgs: [
        {
          initialItems: unique,
          updateType: Constants.AddressListUpdateType.AddAndRemove,
        },
      ],
    });

    return args;
  },
  fetch: async () => undefined,
  formInitialValues: [],
  formFields: allowedAdapterIncomingAssetsPolicyFormFields,
  label: "Limit Allowed Incoming Assets",
  managerDescription: (
    <div className="space-y-4">
      <p>Restricts the assets that the vault can receive via an interaction with a third-party protocol.</p>
      <p>
        Assets can enter a vault in various ways.
        <InlineLink to="https://docs.enzyme.finance/managers/setup/advanced-settings" appearance="link">
          This policy
        </InlineLink>{" "}
        only limits the assets that can enter the vault via an interaction with a third-party protocol. It does not, for
        example, limit the assets that the vault can receive via an airdrop or other DeFi-native event.
      </p>
    </div>
  ),
  type: VaultConfigType.ALLOWED_ADAPTER_INCOMING_ASSETS_POLICY,
  validationSchema: allowedAdapterIncomingAssetsPolicySchema,
};
