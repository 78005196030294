import { toAddress } from "@enzymefinance/environment";
import type { Adapter } from "@enzymefinance/utils";
import { isTruthy } from "@enzymefinance/utils";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useAddressListsByIdsQuery } from "queries/core";
import { useMemo } from "react";
import { useAdapterOptions } from "utils/hooks/useAdapterOptions";
import { isAddressEqual } from "viem";

export interface AdapterListData {
  description: string;
  id: string;
  items: Adapter[];
}

interface KnownAdapterListMap {
  noSlippageAdapters: AdapterListData;
  adapters: AdapterListData;
}

export function useKnownAdapterLists(params?: { skip?: boolean }): KnownAdapterListMap | undefined {
  const allAdapters = useAdapterOptions();
  const { environment } = useGlobals();

  const addressListsByIdsQuery = useAddressListsByIdsQuery({
    variables: {
      ids: [environment.knownAddressLists.noSlippageAdapters, environment.knownAddressLists.adapters].filter(isTruthy),
    },
    skip: !!params?.skip,
  });

  const data = useMemo(() => {
    if (!addressListsByIdsQuery.data) {
      return undefined;
    }

    const noSlippageAdaptersList = addressListsByIdsQuery.data.addressLists.find(
      (list) => list.id === environment.knownAddressLists.noSlippageAdapters,
    );
    const adaptersList = addressListsByIdsQuery.data.addressLists.find(
      (list) => list.id === environment.knownAddressLists.adapters,
    );

    const noSlippageAdapters =
      noSlippageAdaptersList?.items
        .map((item) => allAdapters.find((adapter) => isAddressEqual(adapter.id, toAddress(item))))
        .filter(isTruthy) ?? [];
    const adapters =
      adaptersList?.items
        .map((item) => allAdapters.find((adapter) => isAddressEqual(adapter.id, toAddress(item))))
        .filter(isTruthy) ?? [];

    return {
      noSlippageAdapters: {
        id: environment.knownAddressLists.noSlippageAdapters,
        items: noSlippageAdapters,
        description: noSlippageAdaptersList?.description ?? "No-slippage adapters",
      },
      adapters: {
        id: environment.knownAddressLists.adapters,
        items: adapters,
        description: adaptersList?.description ?? "Adapters",
      },
    };
  }, [
    allAdapters,
    environment.knownAddressLists.adapters,
    environment.knownAddressLists.noSlippageAdapters,
    addressListsByIdsQuery.data,
  ]);

  return data;
}
