import { MultiTokenBigIntInput, useFormContext } from "@enzymefinance/hook-form";
import { Badge } from "@enzymefinance/ui";
import { asset, bigint } from "@enzymefinance/validation";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useMemo } from "react";
import { useUpdateEffect } from "react-use";
import { z } from "zod";

import { Policies } from "@enzymefinance/sdk/Configuration";
import { formatUnits } from "viem";
import { InlineLink } from "../../../routing/Link";
import { VaultConfigFieldName } from "../VaultConfig";
import type { MinAssetBalancesPostRedemptionPolicySettings } from "../VaultConfigSettingsTypes";
import type { VaultConfig, VaultConfigDisplayProps, VaultConfigDisplaySubgraphProps } from "../VaultConfigTypes";
import { VaultConfigType } from "../VaultConfigTypes";
import { PolicyTokenAmountsList } from "./PolicyTokenAmountsList";
export const minAssetBalancesPostRedemptionPolicySchema = z
  .array(
    z.object({
      value: bigint(),
      token: asset(),
    }),
  )
  .min(1, "Should have at least one asset selected")
  .superRefine((values, context) => {
    return values.every((value, index) => {
      if (value.value === 0n) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Invalid value. Every asset needs a non-zero value.",
          path: [`${index}.value]`],
        });

        return false;
      }

      return true;
    });
  });

interface MinAssetBalancesPostRedemptionPolicyFormFieldsValues {
  [VaultConfigFieldName.MIN_ASSET_BALANCES_POST_REDEMPTION_POLICY]: MinAssetBalancesPostRedemptionPolicySettings;
}

function minAssetBalancesPostRedemptionPolicyFormFields() {
  const { getAssets } = useGlobals();
  const { trigger, watch } = useFormContext<MinAssetBalancesPostRedemptionPolicyFormFieldsValues>();
  const [value] = watch([VaultConfigFieldName.MIN_ASSET_BALANCES_POST_REDEMPTION_POLICY]) as [
    MinAssetBalancesPostRedemptionPolicyFormFieldsValues[VaultConfigFieldName.MIN_ASSET_BALANCES_POST_REDEMPTION_POLICY],
  ];

  useUpdateEffect(() => {
    trigger();
  }, [value]);

  const assets = useMemo(() => getAssets({ registered: true }), [getAssets]);

  return (
    <MultiTokenBigIntInput
      label={minAssetBalancesPostRedemptionPolicy.label}
      name={VaultConfigFieldName.MIN_ASSET_BALANCES_POST_REDEMPTION_POLICY}
      tokens={assets}
    />
  );
}

function minAssetBalancesPostRedemptionPolicyDisplay({
  settings,
}: VaultConfigDisplayProps<VaultConfigType.MIN_ASSET_BALANCES_POST_REDEMPTION_POLICY>) {
  const assetBalances = settings.map(({ token, value }) => ({
    amount: formatUnits(value, token.decimals),
    asset: token,
  }));

  return <PolicyTokenAmountsList assetBalances={assetBalances} />;
}

function minAssetBalancesPostRedemptionPolicyDisplaySubgraph({
  settings,
}: VaultConfigDisplaySubgraphProps<VaultConfigType.MIN_ASSET_BALANCES_POST_REDEMPTION_POLICY>) {
  return <PolicyTokenAmountsList assetBalances={settings.assetBalances} />;
}

export const minAssetBalancesPostRedemptionPolicy: VaultConfig<VaultConfigType.MIN_ASSET_BALANCES_POST_REDEMPTION_POLICY> =
  {
    address: (contracts) => contracts.MinAssetBalancesPostRedemptionPolicy,
    disableable: true,
    display: minAssetBalancesPostRedemptionPolicyDisplay,
    displaySubgraph: minAssetBalancesPostRedemptionPolicyDisplaySubgraph,
    editable: false,
    encode: (settings) =>
      Policies.MinAssetBalancesPostRedemption.encodeSettings({
        minAssetBalances: settings.map((setting) => ({
          asset: setting.token.id,
          balance: setting.value,
        })),
      }),
    fetch: async () => undefined,
    formFields: minAssetBalancesPostRedemptionPolicyFormFields,
    label: "Specific Asset Redemption Threshold",
    managerDescription: (
      <div className="space-y-4">
        <p>
          Restricts the value of a specific-asset redemption by setting a minimum balance of that asset that the vault
          must maintain post-withdrawal. Specific-asset withdrawals that would reduce the vault&apos;s balance below
          this level will be rejected. Note that the depositor can still redeem their shares by withdrawing their pro
          rata share of the vault&apos;s holdings.
        </p>
        <p>
          If you have enabled specific-asset redemption, you can protect your vault&apos;s balances of those assets from
          total depletion by configuring{" "}
          <InlineLink to="https://docs.enzyme.finance/managers/setup/redemptions" appearance="link">
            this policy
          </InlineLink>
          .
        </p>
        <Badge appearance="warning">Semi-permanent Setting</Badge>
      </div>
    ),
    publicDescription: (
      <p>
        <InlineLink to="https://docs.enzyme.finance/managers/setup/redemptions" appearance="link">
          This policy
        </InlineLink>{" "}
        sets a minimum balance the vault must maintain in the assets it makes available for single-asset withdrawal.
        Withdrawals that would reduce the vault&apos;s balance below this level will be rejected. Note that the you can
        still redeem shares by withdrawing your pro rata portion of the vault&apos;s holdings.
      </p>
    ),
    type: VaultConfigType.MIN_ASSET_BALANCES_POST_REDEMPTION_POLICY,
    validationSchema: minAssetBalancesPostRedemptionPolicySchema,
  };
