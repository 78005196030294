import { TokenLabel } from "@enzymefinance/ethereum-ui";
import { useGlobals } from "components/providers/GlobalsProvider";

interface PolicyTokenListProps {
  addresses: string[];
}

export function PolicyTokenList({ addresses }: PolicyTokenListProps) {
  const { getAssetsByAddresses } = useGlobals();
  const tokens = getAssetsByAddresses(addresses);

  if (addresses.length === 0) {
    return <p className="text-sm">No assets allowed.</p>;
  }

  return (
    <div className="space-y-2">
      {tokens.map((token) => (
        <TokenLabel key={token.id} asset={token} />
      ))}
    </div>
  );
}
