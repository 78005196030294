import classNames from "classnames";
import type { ComponentPropsWithoutRef } from "react";

type ProgressBarSize = "lg" | "md" | "sm";
const sizes: Record<ProgressBarSize, string> = {
  lg: "h-2.5",
  md: "h-2",
  sm: "h-1.5",
};

export interface ProgressBarProps extends ComponentPropsWithoutRef<"div"> {
  size?: ProgressBarSize;
  state?: "error" | "neutral" | "success" | "warning";
  progress?: number;
  rounded?: "all" | "indicator";
  shimmer?: boolean;
}

export function ProgressBar({
  className,
  state = "neutral",
  progress = 0,
  rounded = "all",
  shimmer = true,
  size = "md",
  ...props
}: ProgressBarProps) {
  const classes = classNames(
    "bg-gray-100 dark:bg-gray-700",
    { "rounded-full": rounded === "all" },
    sizes[size],
    className,
  );
  const indicatorClasses = classNames("h-full overflow-hidden max-w-full transition-all", {
    "rounded-l-full": rounded === "all",
    "rounded-r-full": rounded,
  });
  const gradientWrapperClasses = classNames("flex items-center h-full", { shimmer });
  const gradientClasses = classNames("flex-none h-full w-full bg-gradient-to-r", {
    "from-green-400 dark:from-green-500 via-green-500 dark:via-green-600 to-green-400 dark:to-green-500":
      state === "success",
    "from-red-400 dark:from-red-500 via-red-500 dark:via-red-600 to-red-400 dark:to-red-500": state === "error",
    "from-teal-500 dark:from-teal-500 via-primary-light dark:via-primary-light to-teal-500 dark:to-teal-500":
      state === "neutral",
    "from-yellow-400 dark:from-yellow-500 via-yellow-500 dark:via-yellow-600 to-yellow-400 dark:to-yellow-500":
      state === "warning",
  });

  return (
    <div className={classes} {...props}>
      <div className={indicatorClasses} style={{ width: `${progress * 100}%` }}>
        <div className={gradientWrapperClasses}>
          <div className={gradientClasses} />
          <div className={gradientClasses} />
        </div>
      </div>
    </div>
  );
}
