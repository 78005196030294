import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XMarkIcon,
} from "@enzymefinance/icons/solid";
import classNames from "classnames";
import type { ComponentPropsWithoutRef, ComponentType, ReactElement, ReactFragment } from "react";
import { createElement } from "react";

import type { ButtonProps } from "../elements/Button.js";
import { Button } from "../elements/Button.js";

export type AlertAppearance = "error" | "info" | "success" | "warning";

const icons: Record<AlertAppearance, ComponentType<ComponentPropsWithoutRef<"svg">>> = {
  error: ExclamationCircleIcon,
  info: InformationCircleIcon,
  success: CheckCircleIcon,
  warning: ExclamationTriangleIcon,
};

export interface AlertProps {
  className?: string;
  children?: ReactElement | ReactFragment | string | null;
  dismiss?: ButtonProps["onClick"];
  appearance?: AlertAppearance;
  title?: ReactElement | string;
}

export function Alert({ children = null, className, dismiss, appearance = "info", title }: AlertProps) {
  const isPrimitive = typeof children === "string" || typeof children === "number";
  const Icon = icons[appearance];
  const wrapperClasses = classNames(
    "rounded-lg p-4 bg-base-300 border shadow-lg overflow-auto",
    {
      "border-info": appearance === "info",
      "border-success": appearance === "success",
      "border-error": appearance === "error",
      "border-warning": appearance === "warning",
    },
    className,
  );
  const iconClasses = classNames("h-5 w-5", {
    "text-info": appearance === "info",
    "text-success": appearance === "success",
    "text-error": appearance === "error",
    "text-warning": appearance === "warning",
  });

  return (
    <div className={wrapperClasses}>
      <div className="flex space-x-3">
        <div className="flex-none">
          <Icon className={iconClasses} />
        </div>
        {title !== undefined || children !== null ? (
          <div className="w-full space-y-2">
            {title === undefined ? null : <h3 className="title-sm">{title}</h3>}
            {children === null
              ? null
              : createElement(isPrimitive ? "p" : "div", { className: "text-sm text-base-content" }, children)}
          </div>
        ) : null}
        {dismiss ? (
          <div className="flex-none">
            <Button className="-mr-1 -mt-1" icon={XMarkIcon} size="xs" onClick={dismiss}>
              Close
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
}
