import { Depositor, Utils } from "@enzymefinance/sdk";
import { address, bigint } from "@enzymefinance/validation";
import { z } from "zod";
import { createUseValidatedClientQuery } from "./useValidatedQuery";

export const useSpecificAssetsRedemptionExpectedAmounts = createUseValidatedClientQuery(
  ["specific-assets-redemption-expected-amounts"],
  {
    paramsSchema: z.object({
      comptrollerProxy: address({ allowZeroAddress: false }),
      payoutAssets: z.array(address({ allowZeroAddress: false })),
      payoutPercentages: z.array(z.number()),
      redeemQuantity: bigint().refine((value) => value > 0n),
      recipient: address({ allowZeroAddress: false }),
    }),
    responseSchema: z.array(bigint()),
    queryFn: async (params, client) => {
      const payoutAmounts = await Depositor.getSpecificAssetsRedemptionExpectedAmounts(client, {
        comptrollerProxy: params.comptrollerProxy,
        recipient: params.recipient,
        sharesQuantity: params.redeemQuantity,
        payoutAssets: params.payoutAssets,
        payoutPercentages: params.payoutPercentages.map((percentage) => Utils.Conversion.toBps(percentage)),
      });

      return Object.values(payoutAmounts);
    },
  },
);
