import type { Network } from '../types/graphql';
import type { Currency } from '../types/graphql';
import type { Deployment } from '../types/graphql';
import type { Address } from '@enzymefinance/environment';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
type Bytes = ArrayLike<number>;
type BytesLike = Bytes | string;
type CurrencyOrAddress = Currency | Address;
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Address: { input: Address; output: Address; }
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: { input: string; output: string; }
  BytesLike: { input: BytesLike; output: BytesLike; }
  CurrencyOrAddress: { input: CurrencyOrAddress; output: CurrencyOrAddress; }
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: { input: Date; output: Date; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: Date; output: Date; }
  Upload: { input: File; output: File; }
};

export type AaveV2RewardsData = {
  __typename: 'AaveV2RewardsData';
  globalRewards?: Maybe<Array<Reward>>;
  individualRewards?: Maybe<Array<IndividualReward>>;
};

export type Account = {
  __typename: 'Account';
  accountProfile?: Maybe<AccountProfile>;
  accountRoles: Array<AccountRole>;
  apiTokens: Array<ApiToken>;
  auth?: Maybe<AccountAuth>;
  email?: Maybe<Scalars['String']['output']>;
  features: Array<Feature>;
  id: Scalars['ID']['output'];
  walletClaims: Array<WalletClaim>;
  whiteLabels: Array<WhiteLabel>;
};

export type AccountAuth = {
  __typename: 'AccountAuth';
  authenticator: Scalars['Boolean']['output'];
  backupCodes: Scalars['Boolean']['output'];
  google?: Maybe<AccountAuthGoogle>;
  id: Scalars['ID']['output'];
  password: Scalars['Boolean']['output'];
  wallets: Array<AccountAuthWallet>;
};

export type AccountAuthGoogle = {
  __typename: 'AccountAuthGoogle';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type AccountAuthWallet = {
  __typename: 'AccountAuthWallet';
  address: Scalars['Address']['output'];
  id: Scalars['ID']['output'];
};

export type AccountProfile = {
  __typename: 'AccountProfile';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  telegram?: Maybe<Scalars['String']['output']>;
  twitter?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export enum AccountRole {
  ADMIN = 'ADMIN',
  MODERATOR = 'MODERATOR'
}

export type ActiveIntegrations = {
  __typename: 'ActiveIntegrations';
  activeIntegrations: Array<Scalars['String']['output']>;
};

export type AddLiquidityForPendleV2Data = {
  __typename: 'AddLiquidityForPendleV2Data';
  amountMinOut: Scalars['BigInt']['output'];
  guessOut: GuessOutPendleV2;
  priceImpact: Scalars['Float']['output'];
};

export type ApiToken = {
  __typename: 'ApiToken';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ArbitraryLoanPortfolioAsset = {
  __typename: 'ArbitraryLoanPortfolioAsset';
  amount: Scalars['Float']['output'];
  assetAddress: Scalars['Address']['output'];
  currencyValue?: Maybe<Scalars['Float']['output']>;
};

export enum ArbitraryLoanStatus {
  CLOSED = 'CLOSED',
  EMPTY = 'EMPTY',
  INACTIVE = 'INACTIVE',
  NO_DEBT = 'NO_DEBT',
  OPENED = 'OPENED'
}

export type ArbitraryLoansEnabledForVault = {
  __typename: 'ArbitraryLoansEnabledForVault';
  isFeatureEnabled: Scalars['Boolean']['output'];
};

export type ArgValidationError = UserError & {
  __typename: 'ArgValidationError';
  issues: Array<ArgValidationIssue>;
  message: Scalars['String']['output'];
};

export type ArgValidationIssue = {
  __typename: 'ArgValidationIssue';
  message: Scalars['String']['output'];
  path: Array<Scalars['String']['output']>;
};

export type AssetApys = {
  __typename: 'AssetApys';
  baseApy?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Address']['output'];
  protocol?: Maybe<Protocol>;
  rewardApys: Array<RewardApy>;
  totalApy?: Maybe<Scalars['Float']['output']>;
};

export type AssetBalance = {
  __typename: 'AssetBalance';
  address: Scalars['Address']['output'];
  balance: Scalars['BigInt']['output'];
};

export type AssetBalanceCurrency = {
  __typename: 'AssetBalanceCurrency';
  address: Scalars['Address']['output'];
  amount: Scalars['BigInt']['output'];
  currencyValue?: Maybe<Scalars['Float']['output']>;
};

export type AssetBalanceObligatoryCurrency = {
  __typename: 'AssetBalanceObligatoryCurrency';
  address: Scalars['Address']['output'];
  amount: Scalars['BigInt']['output'];
  currencyValue: Scalars['Float']['output'];
};

export type AssetPrice = {
  __typename: 'AssetPrice';
  change24h?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Address']['output'];
  price?: Maybe<Scalars['Float']['output']>;
  price24h?: Maybe<Scalars['Float']['output']>;
};

export type AuthenticatorCredentialsInput = {
  code: Scalars['String']['input'];
};

export type BackupCodesCredentialsInput = {
  code: Scalars['String']['input'];
};

export type BalancerDepositInfo = {
  __typename: 'BalancerDepositInfo';
  bptAmount: Scalars['BigInt']['output'];
  priceImpact: Scalars['Float']['output'];
};

export type BalancerWithdrawInfoExactBptIn = {
  __typename: 'BalancerWithdrawInfoExactBptIn';
  amounts: Array<Scalars['BigInt']['output']>;
  priceImpact: Scalars['Float']['output'];
};

export type BorrowerArbitraryLoanPositionsData = {
  __typename: 'BorrowerArbitraryLoanPositionsData';
  arbitraryLoansBasic: Array<SingleBorrowerArbitraryLoanBasicData>;
};

export type ChangePasswordInput = {
  /** Current password */
  current: Scalars['String']['input'];
  /** New password */
  password: Scalars['String']['input'];
  /** The token that is requested by the `requestAuthToken` mutation */
  token: Scalars['String']['input'];
};

export type ChangePasswordPayload = {
  __typename: 'ChangePasswordPayload';
  account?: Maybe<Account>;
  errors?: Maybe<Array<UserError>>;
};

export type CheckTokenStatusInput = {
  scope: CheckTokenStatusScope;
  token: Scalars['String']['input'];
};

export type CheckTokenStatusPayload = {
  __typename: 'CheckTokenStatusPayload';
  errors?: Maybe<Array<UserError>>;
  token?: Maybe<Scalars['String']['output']>;
};

export enum CheckTokenStatusScope {
  ACCOUNT_UPDATE_EMAIL = 'ACCOUNT_UPDATE_EMAIL',
  AUTH_CONNECT_PASSWORD = 'AUTH_CONNECT_PASSWORD',
  AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD',
  AUTH_SIGN_UP_PASSWORD = 'AUTH_SIGN_UP_PASSWORD'
}

export enum ColorScheme {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
  SYSTEM = 'SYSTEM'
}

export type CompoundV2AssetBalance = {
  __typename: 'CompoundV2AssetBalance';
  amount: Scalars['BigInt']['output'];
  assetAddress: Scalars['Address']['output'];
  currencyValue?: Maybe<Scalars['Float']['output']>;
};

export type CompoundV2RewardsData = {
  __typename: 'CompoundV2RewardsData';
  globalRewards?: Maybe<Array<Reward>>;
};

export type CompoundV3RewardsData = {
  __typename: 'CompoundV3RewardsData';
  cTokensToClaim: Array<Scalars['Address']['output']>;
  globalRewards?: Maybe<Array<Reward>>;
};

export type ConnectAuthMethodCompleteInput = {
  method: PrimaryAuthMethod;
  token: Scalars['String']['input'];
};

export type ConnectAuthMethodCompletePayload = {
  __typename: 'ConnectAuthMethodCompletePayload';
  account?: Maybe<Account>;
  errors?: Maybe<Array<UserError>>;
};

export type ConnectAuthMethodCredentialsInput = {
  google?: InputMaybe<GoogleConnectInput>;
  password?: InputMaybe<PasswordConnectInput>;
  wallet?: InputMaybe<WalletConnectInput>;
};

export type ConnectAuthMethodInput = {
  credentials: ConnectAuthMethodCredentialsInput;
  method: PrimaryAuthMethod;
  reCaptchaToken: Scalars['String']['input'];
  /** The token that is requested by the `requestAuthToken` mutation */
  token: Scalars['String']['input'];
};

export type ConnectAuthMethodPayload = {
  __typename: 'ConnectAuthMethodPayload';
  account?: Maybe<Account>;
  address?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<UserError>>;
  method?: Maybe<PrimaryAuthMethod>;
  name?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export enum ConvexVoteStatus {
  ONGOING = 'ONGOING',
  STOPPED = 'STOPPED'
}

export type ConvexVotingInfo = {
  __typename: 'ConvexVotingInfo';
  claimableRewards: Array<ConvexVotingReward>;
  delegate?: Maybe<Scalars['Address']['output']>;
  hasAnyCvxCrvStakingRewards: Scalars['Boolean']['output'];
  lockedBalance: Scalars['Float']['output'];
  nextEpochStart: Scalars['Int']['output'];
  positions: Array<ConvexVotingPosition>;
  unlockedBalance: Scalars['Float']['output'];
  voteState: ConvexVotingVoteState;
  votiumClaims: Array<VotiumClaim>;
};

export type ConvexVotingLockedAsset = {
  __typename: 'ConvexVotingLockedAsset';
  amount: Scalars['Float']['output'];
  assetAddress: Scalars['Address']['output'];
  currencyValue?: Maybe<Scalars['Float']['output']>;
};

export enum ConvexVotingPosStatus {
  LOCKED = 'LOCKED',
  UNLOCKED = 'UNLOCKED',
  UNLOCKED_WITH_BOUNTY = 'UNLOCKED_WITH_BOUNTY'
}

export type ConvexVotingPosition = {
  __typename: 'ConvexVotingPosition';
  amount: Scalars['Float']['output'];
  epochsAmountInsideLocker: Scalars['Float']['output'];
  status: ConvexVotingPosStatus;
  unlockTime: Scalars['Int']['output'];
};

export type ConvexVotingReward = {
  __typename: 'ConvexVotingReward';
  amount: Scalars['Float']['output'];
  assetAddress: Scalars['Address']['output'];
  currencyValue?: Maybe<Scalars['Float']['output']>;
};

export type ConvexVotingVoteState = {
  __typename: 'ConvexVotingVoteState';
  currentEndTime?: Maybe<Scalars['Int']['output']>;
  nextEndTime?: Maybe<Scalars['Int']['output']>;
  nextStartTime?: Maybe<Scalars['Int']['output']>;
  status: ConvexVoteStatus;
};

export type CreateApiTokenInput = {
  name: Scalars['String']['input'];
  reCaptchaToken: Scalars['String']['input'];
  /** The token that is requested by the `requestAuthToken` mutation */
  token: Scalars['String']['input'];
};

export type CreateApiTokenPayload = {
  __typename: 'CreateApiTokenPayload';
  account?: Maybe<Account>;
  errors?: Maybe<Array<UserError>>;
  token?: Maybe<Scalars['String']['output']>;
};

export type CreateAuthenticatorInput = {
  secret: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type CreateAuthenticatorPayload = {
  __typename: 'CreateAuthenticatorPayload';
  account?: Maybe<Account>;
  errors?: Maybe<Array<UserError>>;
};

export type CreateUserRepresentativeApplicationInput = {
  electionId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  experience: Scalars['String']['input'];
  miscExperience: Scalars['String']['input'];
  motivation: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateUserRepresentativeApplicationPayload = {
  __typename: 'CreateUserRepresentativeApplicationPayload';
  errors?: Maybe<Array<UserError>>;
  userRepresentativeApplication?: Maybe<UserRepresentativeApplication>;
};

export type CreateUserRepresentativeElectionInput = {
  applyingEndDate: Scalars['DateTime']['input'];
  applyingStartDate: Scalars['DateTime']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  votingEndDate: Scalars['DateTime']['input'];
  votingStartDate: Scalars['DateTime']['input'];
};

export type CreateUserRepresentativeElectionPayload = {
  __typename: 'CreateUserRepresentativeElectionPayload';
  errors?: Maybe<Array<UserError>>;
  userRepresentativeElection?: Maybe<UserRepresentativeElection>;
};

export type CreateWalletClaimInput = {
  address: Scalars['Address']['input'];
  claimSignature: Scalars['String']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  network: Network;
  timestamp: Scalars['Int']['input'];
};

export type CreateWalletClaimMessageInput = {
  address: Scalars['Address']['input'];
  message: Scalars['String']['input'];
  timestamp: Scalars['Int']['input'];
};

export type CreateWalletClaimMessagePayload = {
  __typename: 'CreateWalletClaimMessagePayload';
  errors?: Maybe<Array<UserError>>;
  walletClaimMessage?: Maybe<WalletClaimMessage>;
};

export type CreateWalletClaimPayload = {
  __typename: 'CreateWalletClaimPayload';
  errors?: Maybe<Array<UserError>>;
  walletClaim?: Maybe<WalletClaim>;
};

export type CreateWhiteLabelInput = {
  name: Scalars['String']['input'];
  subdomain: Scalars['String']['input'];
  vaultAddresses: Array<Scalars['Address']['input']>;
};

export type CreateWhiteLabelPayload = {
  __typename: 'CreateWhiteLabelPayload';
  account?: Maybe<Account>;
  errors?: Maybe<Array<UserError>>;
  whiteLabel?: Maybe<WhiteLabel>;
};

export type CumulativeSlippagePolicy = {
  __typename: 'CumulativeSlippagePolicy';
  currentCumulativeSlippage: Scalars['BigInt']['output'];
  enabled: Scalars['Boolean']['output'];
  tolerance: Scalars['BigInt']['output'];
};

export { Currency };

export type DelegateVoteAsset = {
  __typename: 'DelegateVoteAsset';
  assetBalance: DelegateVoteAssetBalance;
  delegatee?: Maybe<Scalars['Address']['output']>;
  protocol: Protocol;
};

export type DelegateVoteAssetBalance = {
  __typename: 'DelegateVoteAssetBalance';
  amount: Scalars['BigInt']['output'];
  assetAddress: Scalars['Address']['output'];
  currencyValue?: Maybe<Scalars['Float']['output']>;
};

export type DeleteAccountInput = {
  /** The token that is requested by the `requestAuthToken` mutation */
  token: Scalars['String']['input'];
};

export type DeleteAccountPayload = {
  __typename: 'DeleteAccountPayload';
  account?: Maybe<Account>;
  errors?: Maybe<Array<UserError>>;
};

export type DeleteApiTokenInput = {
  id: Scalars['ID']['input'];
  reCaptchaToken: Scalars['String']['input'];
  /** The token that is requested by the `requestAuthToken` mutation */
  token: Scalars['String']['input'];
};

export type DeleteApiTokenPayload = {
  __typename: 'DeleteApiTokenPayload';
  account?: Maybe<Account>;
  errors?: Maybe<Array<UserError>>;
};

export type DeleteAuthenticatorInput = {
  /** The token that is requested by the `requestAuthToken` mutation */
  token: Scalars['String']['input'];
};

export type DeleteAuthenticatorPayload = {
  __typename: 'DeleteAuthenticatorPayload';
  account?: Maybe<Account>;
  errors?: Maybe<Array<UserError>>;
};

export type DeleteBackupCodesInput = {
  /** The token that is requested by the `requestAuthToken` mutation */
  token: Scalars['String']['input'];
};

export type DeleteBackupCodesPayload = {
  __typename: 'DeleteBackupCodesPayload';
  account?: Maybe<Account>;
  errors?: Maybe<Array<UserError>>;
};

export type DeleteUserRepresentativeApplicationInput = {
  id: Scalars['ID']['input'];
};

export type DeleteUserRepresentativeApplicationPayload = {
  __typename: 'DeleteUserRepresentativeApplicationPayload';
  errors?: Maybe<Array<UserError>>;
  userRepresentativeApplication?: Maybe<UserRepresentativeApplication>;
};

export type DeleteUserRepresentativeElectionInput = {
  id: Scalars['ID']['input'];
};

export type DeleteUserRepresentativeElectionPayload = {
  __typename: 'DeleteUserRepresentativeElectionPayload';
  errors?: Maybe<Array<UserError>>;
  userRepresentativeElection?: Maybe<UserRepresentativeElection>;
};

export type DeleteWalletClaimInput = {
  id: Scalars['ID']['input'];
};

export type DeleteWalletClaimPayload = {
  __typename: 'DeleteWalletClaimPayload';
  errors?: Maybe<Array<UserError>>;
  walletClaim?: Maybe<WalletClaim>;
};

export type DeleteWhiteLabelCoverInput = {
  id: Scalars['String']['input'];
};

export type DeleteWhiteLabelCoverPayload = {
  __typename: 'DeleteWhiteLabelCoverPayload';
  account?: Maybe<Account>;
  errors?: Maybe<Array<UserError>>;
  whiteLabel?: Maybe<WhiteLabel>;
};

export type DeleteWhiteLabelInput = {
  id: Scalars['String']['input'];
};

export type DeleteWhiteLabelLogoInput = {
  id: Scalars['String']['input'];
};

export type DeleteWhiteLabelLogoPayload = {
  __typename: 'DeleteWhiteLabelLogoPayload';
  account?: Maybe<Account>;
  errors?: Maybe<Array<UserError>>;
  whiteLabel?: Maybe<WhiteLabel>;
};

export type DeleteWhiteLabelPayload = {
  __typename: 'DeleteWhiteLabelPayload';
  account?: Maybe<Account>;
  errors?: Maybe<Array<UserError>>;
};

export { Deployment };

export type DisconnectAuthMethodCredentialsInput = {
  wallet?: InputMaybe<WalletDisconnectInput>;
};

export type DisconnectAuthMethodInput = {
  credentials?: InputMaybe<DisconnectAuthMethodCredentialsInput>;
  method: PrimaryAuthMethod;
  /** The token that is requested by the `requestAuthToken` mutation */
  token: Scalars['String']['input'];
};

export type DisconnectAuthMethodPayload = {
  __typename: 'DisconnectAuthMethodPayload';
  account?: Maybe<Account>;
  errors?: Maybe<Array<UserError>>;
};

export type DivaStakingDepositorActivity = {
  __typename: 'DivaStakingDepositorActivity';
  createdAt: Scalars['Int']['output'];
  trancheAmounts: Array<DivaStakingTrancheAmount>;
  type: DivaStakingDepositorActivityType;
  vaultName: Scalars['String']['output'];
};

export enum DivaStakingDepositorActivityType {
  DEPOSIT = 'DEPOSIT',
  REDEEM = 'REDEEM'
}

export type DivaStakingDepositorInfo = {
  __typename: 'DivaStakingDepositorInfo';
  activities: Array<DivaStakingDepositorActivity>;
  nektarInfo: DivaStakingNektarInfo;
  totalEthInTranches: AssetBalanceCurrency;
  tranches: Array<DivaStakingDepositorTranche>;
};

export type DivaStakingDepositorTranche = {
  __typename: 'DivaStakingDepositorTranche';
  accruedRewards: Scalars['BigInt']['output'];
  asset: AssetBalanceCurrency;
  maxRewards: Scalars['BigInt']['output'];
  trancheId: Scalars['Int']['output'];
};

export type DivaStakingGeneralInfo = {
  __typename: 'DivaStakingGeneralInfo';
  stakers: Scalars['Int']['output'];
  tvl: Scalars['Float']['output'];
  tvlEth: Scalars['Float']['output'];
  tvlstEth: Scalars['Float']['output'];
};

export type DivaStakingNektarInfo = {
  __typename: 'DivaStakingNektarInfo';
  accruedDrops: Scalars['Float']['output'];
  dropsPerEthPerDay: Scalars['Float']['output'];
  epoch: Scalars['Int']['output'];
  maxDrops: Scalars['Float']['output'];
};

export type DivaStakingTrancheAmount = {
  __typename: 'DivaStakingTrancheAmount';
  asset: AssetBalanceCurrency;
  trancheId: Scalars['Int']['output'];
};

export type EModeCategoriesAaveV3Data = {
  __typename: 'EModeCategoriesAaveV3Data';
  assets: Array<Scalars['Address']['output']>;
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
};

export type EModePositionLiquidationInfoData = {
  __typename: 'EModePositionLiquidationInfoData';
  healthFactor?: Maybe<Scalars['Float']['output']>;
  maxLoanToValueRatio?: Maybe<Scalars['Float']['output']>;
};

export enum Erc4626Protocol {
  ANGLE = 'ANGLE',
  MORPHO = 'MORPHO',
  SPARK = 'SPARK'
}

export type ErrorResponse = {
  __typename: 'ErrorResponse';
  error: Scalars['String']['output'];
};

export enum Exchange {
  CURVE = 'CURVE',
  ONE_INCH_V5 = 'ONE_INCH_V5',
  PARASWAP = 'PARASWAP',
  PMM_2 = 'PMM_2',
  PMM_2_KYC = 'PMM_2_KYC',
  UNISWAP_V2 = 'UNISWAP_V2',
  UNISWAP_V3 = 'UNISWAP_V3'
}

export type ExternalPositionAaveV2DebtPortfolioData = {
  __typename: 'ExternalPositionAaveV2DebtPortfolioData';
  borrowedAssets: Array<AssetBalanceCurrency>;
  collateralAssets: Array<AssetBalanceCurrency>;
  netValue?: Maybe<Scalars['Float']['output']>;
  valueChange24h?: Maybe<Scalars['Float']['output']>;
};

export type ExternalPositionAaveV3DebtPortfolioData = {
  __typename: 'ExternalPositionAaveV3DebtPortfolioData';
  borrowedAssets: Array<AssetBalanceCurrency>;
  collateralAssets: Array<AssetBalanceCurrency>;
  netValue?: Maybe<Scalars['Float']['output']>;
  valueChange24h?: Maybe<Scalars['Float']['output']>;
};

export type ExternalPositionCompoundV2DebtPortfolioData = {
  __typename: 'ExternalPositionCompoundV2DebtPortfolioData';
  borrowedAssets: Array<CompoundV2AssetBalance>;
  collateralAssets: Array<CompoundV2AssetBalance>;
  netValue?: Maybe<Scalars['Float']['output']>;
  valueChange24h?: Maybe<Scalars['Float']['output']>;
};

export type ExternalPositionConvexVotingPortfolioData = {
  __typename: 'ExternalPositionConvexVotingPortfolioData';
  lockedAsset: ConvexVotingLockedAsset;
  valueChange24h?: Maybe<Scalars['Float']['output']>;
};

export type ExternalPositionData = {
  __typename: 'ExternalPositionData';
  address: Scalars['Address']['output'];
  type: ExternalPositionType;
  value: Scalars['Float']['output'];
};

export type ExternalPositionKilnPortfolioData = {
  __typename: 'ExternalPositionKilnPortfolioData';
  netValue?: Maybe<Scalars['Float']['output']>;
  stakedEthAmount: Scalars['Float']['output'];
  stakedEthCurrencyValue?: Maybe<Scalars['Float']['output']>;
  valueChange24h?: Maybe<Scalars['Float']['output']>;
};

export type ExternalPositionLiquityPortfolioData = {
  __typename: 'ExternalPositionLiquityPortfolioData';
  borrowedAsset?: Maybe<LiquityPortfolioAsset>;
  collateralAsset?: Maybe<LiquityPortfolioAsset>;
  netValue?: Maybe<Scalars['Float']['output']>;
  valueChange24h?: Maybe<Scalars['Float']['output']>;
};

export type ExternalPositionMaplePortfolioData = {
  __typename: 'ExternalPositionMaplePortfolioData';
  lentAssets: Array<MaplePortfolioAsset>;
  netValue?: Maybe<Scalars['Float']['output']>;
  valueChange24h?: Maybe<Scalars['Float']['output']>;
};

export type ExternalPositionPendleV2PortfolioData = {
  __typename: 'ExternalPositionPendleV2PortfolioData';
  assetBalances: Array<AssetBalanceCurrency>;
  netValue?: Maybe<Scalars['Float']['output']>;
  valueChange24h?: Maybe<Scalars['Float']['output']>;
};

export type ExternalPositionStakeWisePortfolioData = {
  __typename: 'ExternalPositionStakeWisePortfolioData';
  netValue?: Maybe<Scalars['Float']['output']>;
  stakedEthAmount: Scalars['BigInt']['output'];
  stakedEthCurrencyValue?: Maybe<Scalars['Float']['output']>;
  valueChange24h?: Maybe<Scalars['Float']['output']>;
};

export type ExternalPositionTheGraphDelegationPortfolioPositionData = {
  __typename: 'ExternalPositionTheGraphDelegationPortfolioPositionData';
  asset: TheGraphDelegationPortfolioAsset;
  defaultDisplayName?: Maybe<Scalars['String']['output']>;
  indexer: Scalars['Address']['output'];
};

export enum ExternalPositionType {
  AAVE_DEBT = 'AAVE_DEBT',
  AAVE_V3_DEBT = 'AAVE_V3_DEBT',
  ARBITRARY_LOAN = 'ARBITRARY_LOAN',
  COMPOUND_DEBT = 'COMPOUND_DEBT',
  CONVEX_VOTING = 'CONVEX_VOTING',
  KILN_STAKING = 'KILN_STAKING',
  LIDO_WITHDRAWALS = 'LIDO_WITHDRAWALS',
  LIQUITY_DEBT = 'LIQUITY_DEBT',
  MAPLE_LIQUIDITY = 'MAPLE_LIQUIDITY',
  NOTIONAL_V2 = 'NOTIONAL_V2',
  PENDLE_V2 = 'PENDLE_V2',
  STAKEWISE_V3 = 'STAKEWISE_V3',
  THEGRAPH_DELEGATION = 'THEGRAPH_DELEGATION',
  UNISWAP_V3_LIQUIDITY = 'UNISWAP_V3_LIQUIDITY',
  UNKNOWN = 'UNKNOWN'
}

export type ExternalPositionsArbitraryLoanPositionData = {
  __typename: 'ExternalPositionsArbitraryLoanPositionData';
  amountToRepay: Scalars['BigInt']['output'];
  asset: ArbitraryLoanPortfolioAsset;
  borrowableAmount: Scalars['BigInt']['output'];
  currentInterestRate: Scalars['Float']['output'];
  valueChange24h?: Maybe<Scalars['Float']['output']>;
};

export type ExternalPositionsArbitraryLoanPositionsData = {
  __typename: 'ExternalPositionsArbitraryLoanPositionsData';
  arbitraryLoansBasic: Array<SingleArbitraryLoanBasicData>;
};

export enum Feature {
  API_TOKEN = 'API_TOKEN',
  ARBITRARY_LOAN = 'ARBITRARY_LOAN',
  WHITE_LABEL = 'WHITE_LABEL'
}

export type ForgotPasswordInput = {
  email: Scalars['String']['input'];
  reCaptchaToken: Scalars['String']['input'];
};

export type ForgotPasswordPayload = {
  __typename: 'ForgotPasswordPayload';
  errors?: Maybe<Array<UserError>>;
  success: Scalars['Boolean']['output'];
};

export type GenerateBackupCodesInput = {
  /** The token that is requested by the `requestAuthToken` mutation */
  token: Scalars['String']['input'];
};

export type GenerateBackupCodesPayload = {
  __typename: 'GenerateBackupCodesPayload';
  account?: Maybe<Account>;
  codes?: Maybe<Array<Scalars['String']['output']>>;
  errors?: Maybe<Array<UserError>>;
};

export type GenericUserError = UserError & {
  __typename: 'GenericUserError';
  message: Scalars['String']['output'];
};

export type GoogleConnectInput = {
  idToken: Scalars['String']['input'];
};

export type GoogleSignInOrSignUpInput = {
  idToken: Scalars['String']['input'];
};

export type GuessOutPendleV2 = {
  __typename: 'GuessOutPendleV2';
  eps: Scalars['BigInt']['output'];
  guessMax: Scalars['BigInt']['output'];
  guessMin: Scalars['BigInt']['output'];
  guessOffchain: Scalars['BigInt']['output'];
  maxIteration: Scalars['BigInt']['output'];
};

export type IndividualReward = {
  __typename: 'IndividualReward';
  address: Scalars['Address']['output'];
  rewards: Array<Reward>;
};

export type InitializeAuthenticatorPayload = {
  __typename: 'InitializeAuthenticatorPayload';
  errors?: Maybe<Array<UserError>>;
  issuer?: Maybe<Scalars['String']['output']>;
  qrcode?: Maybe<Scalars['String']['output']>;
  secret?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type KilnStakingPositionData = {
  __typename: 'KilnStakingPositionData';
  active: Scalars['Boolean']['output'];
  apy: Scalars['Float']['output'];
  consensusLayerRewards: Scalars['BigInt']['output'];
  consensusLayerRewardsCurrencyValue?: Maybe<Scalars['Float']['output']>;
  executionLayerRewards: Scalars['BigInt']['output'];
  executionLayerRewardsCurrencyValue?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Address']['output'];
  kilnStakingContractAddress: Scalars['Address']['output'];
  positionValuePaused: Scalars['Boolean']['output'];
  stakedEth: Scalars['BigInt']['output'];
  stakedEthCurrencyValue?: Maybe<Scalars['Float']['output']>;
  totalRewards: Scalars['BigInt']['output'];
  totalRewardsCurrencyValue?: Maybe<Scalars['Float']['output']>;
  validators: Array<KilnValidator>;
};

export type KilnValidator = {
  __typename: 'KilnValidator';
  consensusLayerRewards: Scalars['BigInt']['output'];
  executionLayerRewards: Scalars['BigInt']['output'];
  publicKey: Scalars['BytesLike']['output'];
  state: KilnValidatorState;
  unstakeSignalled: Scalars['Boolean']['output'];
};

export type LpHoldingPendleV2 = {
  __typename: 'LPHoldingPendleV2';
  apy: Scalars['Float']['output'];
  asset: Scalars['Address']['output'];
  balance: Scalars['BigInt']['output'];
  currencyValue?: Maybe<Scalars['Float']['output']>;
  expiry: Scalars['Int']['output'];
  icon: Scalars['String']['output'];
  marketName: Scalars['String']['output'];
  syToken: Scalars['Address']['output'];
  tvl: Scalars['Float']['output'];
  underlying: Scalars['Address']['output'];
};

export type LidoPositionWithdrawalRequest = {
  __typename: 'LidoPositionWithdrawalRequest';
  amount: Scalars['Float']['output'];
  claimed: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
};

export type LidoUnclaimedWithdrawalRequest = {
  __typename: 'LidoUnclaimedWithdrawalRequest';
  amount: Scalars['Float']['output'];
  claimable: Scalars['Boolean']['output'];
  hint: Scalars['BigInt']['output'];
  id: Scalars['String']['output'];
  lidoWithdrawalsPosition: Scalars['String']['output'];
  requestedTimestamp: Scalars['Float']['output'];
};

export type LidoWithdrawalsPortfolioData = {
  __typename: 'LidoWithdrawalsPortfolioData';
  amount: Scalars['Float']['output'];
  claimed: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  valueChange24h?: Maybe<Scalars['Float']['output']>;
};

export type LidoWithdrawalsPositionData = {
  __typename: 'LidoWithdrawalsPositionData';
  active: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  withdrawalRequests: Array<LidoPositionWithdrawalRequest>;
};

export type LiquityAsset = {
  __typename: 'LiquityAsset';
  decimals: Scalars['Int']['output'];
  id: Scalars['Address']['output'];
  name: Scalars['String']['output'];
  symbol: Scalars['String']['output'];
};

export type LiquityAssetBalance = {
  __typename: 'LiquityAssetBalance';
  amount?: Maybe<Scalars['Float']['output']>;
  asset?: Maybe<LiquityAsset>;
  currencyValue?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

export type LiquityDebtPositionData = {
  __typename: 'LiquityDebtPositionData';
  active: Scalars['Boolean']['output'];
  borrowedAssetBalance: LiquityAssetBalance;
  collateralAssetBalance: LiquityAssetBalance;
  currentCollateralRatio: Scalars['Float']['output'];
  id: Scalars['Address']['output'];
  maxSafeDecreaseInCollateral: Scalars['Float']['output'];
  maxSafeIncreaseInBorrow: Scalars['Float']['output'];
  safeCollateralRatio: Scalars['Float']['output'];
  troveStatus: TroveStatus;
};

export type LiquityHintsData = {
  __typename: 'LiquityHintsData';
  lowerHint: Scalars['Address']['output'];
  upperHint: Scalars['Address']['output'];
};

export type LiquityPortfolioAsset = {
  __typename: 'LiquityPortfolioAsset';
  amount?: Maybe<Scalars['Float']['output']>;
  assetAddress: Scalars['Address']['output'];
  currencyValue?: Maybe<Scalars['Float']['output']>;
};

export type MapleAsset = {
  __typename: 'MapleAsset';
  address: Scalars['Address']['output'];
  balance: Scalars['BigInt']['output'];
};

export type MaplePortfolioAsset = {
  __typename: 'MaplePortfolioAsset';
  amount: Scalars['Float']['output'];
  assetAddress: Scalars['Address']['output'];
  currencyValue?: Maybe<Scalars['Float']['output']>;
};

export type MapleSharesToExitAssets = {
  __typename: 'MapleSharesToExitAssets';
  assets: Scalars['BigInt']['output'];
};

export enum MapleStatus {
  NOTHING_REQUESTED_TO_WITHDRAW = 'NOTHING_REQUESTED_TO_WITHDRAW',
  REDEEM_WINDOW_OPENED = 'REDEEM_WINDOW_OPENED',
  REQUESTED_WITHDRAWAL = 'REQUESTED_WITHDRAWAL',
  STALE_LOCKED_SHARES = 'STALE_LOCKED_SHARES'
}

export type MarketInfoToRegisterPendleV2 = {
  __typename: 'MarketInfoToRegisterPendleV2';
  expiry: Scalars['Int']['output'];
  fixedApy: Scalars['Float']['output'];
  market: Scalars['Address']['output'];
  marketName: Scalars['String']['output'];
  swapApy: Scalars['Float']['output'];
  tvl: Scalars['Float']['output'];
};

export type MarketInfoToUpdatePendleV2 = {
  __typename: 'MarketInfoToUpdatePendleV2';
  duration: Scalars['Int']['output'];
  expiry: Scalars['Int']['output'];
  fixedApy: Scalars['Float']['output'];
  market: Scalars['Address']['output'];
  marketName: Scalars['String']['output'];
  swapApy: Scalars['Float']['output'];
  tvl: Scalars['Float']['output'];
};

export type MarketRewardsPendleV2 = {
  __typename: 'MarketRewardsPendleV2';
  assetBalances: Array<AssetBalanceCurrency>;
  markets: Array<Scalars['Address']['output']>;
};

export type MultiFactorAuthInput = {
  authenticator?: InputMaybe<AuthenticatorCredentialsInput>;
  backupCodes?: InputMaybe<BackupCodesCredentialsInput>;
};

export enum MultiFactorAuthMethod {
  AUTHENTICATOR = 'AUTHENTICATOR',
  BACKUP_CODES = 'BACKUP_CODES'
}

export type Mutation = {
  __typename: 'Mutation';
  changePassword: ChangePasswordPayload;
  checkTokenStatus: CheckTokenStatusPayload;
  connectAuthMethod: ConnectAuthMethodPayload;
  connectAuthMethodComplete: ConnectAuthMethodCompletePayload;
  createApiToken: CreateApiTokenPayload;
  createAuthenticator: CreateAuthenticatorPayload;
  createUserRepresentativeApplication: CreateUserRepresentativeApplicationPayload;
  createUserRepresentativeElection: CreateUserRepresentativeElectionPayload;
  createWalletClaim: CreateWalletClaimPayload;
  createWalletClaimMessage: CreateWalletClaimMessagePayload;
  createWhiteLabel: CreateWhiteLabelPayload;
  deleteAccount: DeleteAccountPayload;
  deleteApiToken: DeleteApiTokenPayload;
  deleteAuthenticator: DeleteAuthenticatorPayload;
  deleteBackupCodes: DeleteBackupCodesPayload;
  deleteUserRepresentativeApplication: DeleteUserRepresentativeApplicationPayload;
  deleteUserRepresentativeElection: DeleteUserRepresentativeElectionPayload;
  deleteWalletClaim: DeleteWalletClaimPayload;
  deleteWhiteLabel: DeleteWhiteLabelPayload;
  deleteWhiteLabelCover: DeleteWhiteLabelCoverPayload;
  deleteWhiteLabelLogo: DeleteWhiteLabelLogoPayload;
  disconnectAuthMethod: DisconnectAuthMethodPayload;
  forgotPassword: ForgotPasswordPayload;
  generateBackupCodes: GenerateBackupCodesPayload;
  initializeAuthenticator: InitializeAuthenticatorPayload;
  requestAuthToken: RequestAuthTokenPayload;
  resetPassword: ResetPasswordPayload;
  revokeBackupCodes: RevokeBackupCodesPayload;
  signIn: SignInPayload;
  signInComplete: SignInCompletePayload;
  signOut?: Maybe<SignOutPayload>;
  signUp: SignUpPayload;
  signUpComplete: SignUpCompletePayload;
  submitDepositorSignature: SubmitDepositorSignaturePayload;
  updateAccountProfile: UpdateAccountProfilePayload;
  updateApiToken: UpdateApiTokenPayload;
  updateEmail: UpdateEmailPayload;
  updateEmailComplete: UpdateEmailCompletePayload;
  updateUserRepresentativeApplication: UpdateUserRepresentativeApplicationPayload;
  updateUserRepresentativeApplicationStatus: UpdateUserRepresentativeApplicationStatusPayload;
  updateUserRepresentativeElection: UpdateUserRepresentativeElectionPayload;
  updateVaultProfile: UpdateVaultProfilePayload;
  updateWalletClaim: UpdateWalletClaimPayload;
  updateWhiteLabel: UpdateWhiteLabelPayload;
  updateWhiteLabelCover: UpdateWhiteLabelCoverPayload;
  updateWhiteLabelLogo: UpdateWhiteLabelLogoPayload;
  updateWhiteLabelTheme: UpdateWhiteLabelThemePayload;
  voteUserRepresentative: VoteUserRepresentativePayload;
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationCheckTokenStatusArgs = {
  input: CheckTokenStatusInput;
};


export type MutationConnectAuthMethodArgs = {
  input: ConnectAuthMethodInput;
};


export type MutationConnectAuthMethodCompleteArgs = {
  input: ConnectAuthMethodCompleteInput;
};


export type MutationCreateApiTokenArgs = {
  input: CreateApiTokenInput;
};


export type MutationCreateAuthenticatorArgs = {
  input: CreateAuthenticatorInput;
};


export type MutationCreateUserRepresentativeApplicationArgs = {
  input: CreateUserRepresentativeApplicationInput;
};


export type MutationCreateUserRepresentativeElectionArgs = {
  input: CreateUserRepresentativeElectionInput;
};


export type MutationCreateWalletClaimArgs = {
  input: CreateWalletClaimInput;
};


export type MutationCreateWalletClaimMessageArgs = {
  input: CreateWalletClaimMessageInput;
};


export type MutationCreateWhiteLabelArgs = {
  input: CreateWhiteLabelInput;
};


export type MutationDeleteAccountArgs = {
  input: DeleteAccountInput;
};


export type MutationDeleteApiTokenArgs = {
  input: DeleteApiTokenInput;
};


export type MutationDeleteAuthenticatorArgs = {
  input: DeleteAuthenticatorInput;
};


export type MutationDeleteBackupCodesArgs = {
  input: DeleteBackupCodesInput;
};


export type MutationDeleteUserRepresentativeApplicationArgs = {
  input: DeleteUserRepresentativeApplicationInput;
};


export type MutationDeleteUserRepresentativeElectionArgs = {
  input: DeleteUserRepresentativeElectionInput;
};


export type MutationDeleteWalletClaimArgs = {
  input: DeleteWalletClaimInput;
};


export type MutationDeleteWhiteLabelArgs = {
  input: DeleteWhiteLabelInput;
};


export type MutationDeleteWhiteLabelCoverArgs = {
  input: DeleteWhiteLabelCoverInput;
};


export type MutationDeleteWhiteLabelLogoArgs = {
  input: DeleteWhiteLabelLogoInput;
};


export type MutationDisconnectAuthMethodArgs = {
  input: DisconnectAuthMethodInput;
};


export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


export type MutationGenerateBackupCodesArgs = {
  input: GenerateBackupCodesInput;
};


export type MutationRequestAuthTokenArgs = {
  input: RequestAuthTokenInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationRevokeBackupCodesArgs = {
  input: RevokeBackupCodesInput;
};


export type MutationSignInArgs = {
  input: SignInInput;
};


export type MutationSignInCompleteArgs = {
  input: SignInCompleteInput;
};


export type MutationSignUpArgs = {
  input: SignUpInput;
};


export type MutationSignUpCompleteArgs = {
  input: SignUpCompleteInput;
};


export type MutationSubmitDepositorSignatureArgs = {
  input: SubmitDepositorSignatureInput;
};


export type MutationUpdateAccountProfileArgs = {
  input: UpdateAccountProfileInput;
};


export type MutationUpdateApiTokenArgs = {
  input: UpdateApiTokenInput;
};


export type MutationUpdateEmailArgs = {
  input: UpdateEmailInput;
};


export type MutationUpdateEmailCompleteArgs = {
  input: UpdateEmailCompleteInput;
};


export type MutationUpdateUserRepresentativeApplicationArgs = {
  input: UpdateUserRepresentativeApplicationInput;
};


export type MutationUpdateUserRepresentativeApplicationStatusArgs = {
  input: UpdateUserRepresentativeApplicationStatusInput;
};


export type MutationUpdateUserRepresentativeElectionArgs = {
  input: UpdateUserRepresentativeElectionInput;
};


export type MutationUpdateVaultProfileArgs = {
  input: UpdateVaultProfileInput;
};


export type MutationUpdateWalletClaimArgs = {
  input: UpdateWalletClaimInput;
};


export type MutationUpdateWhiteLabelArgs = {
  input: UpdateWhiteLabelInput;
};


export type MutationUpdateWhiteLabelCoverArgs = {
  input: UpdateWhiteLabelCoverInput;
};


export type MutationUpdateWhiteLabelLogoArgs = {
  input: UpdateWhiteLabelLogoInput;
};


export type MutationUpdateWhiteLabelThemeArgs = {
  input: UpdateWhiteLabelThemeInput;
};


export type MutationVoteUserRepresentativeArgs = {
  input: VoteUserRepresentativeInput;
};

export { Network };

export type NetworkAssetBalance = {
  __typename: 'NetworkAssetBalance';
  asset: Scalars['Address']['output'];
  balance: Scalars['Float']['output'];
  balanceChange24h?: Maybe<Scalars['Float']['output']>;
  change24h?: Maybe<Scalars['Float']['output']>;
  numberOfVaults: Scalars['Int']['output'];
  price?: Maybe<Scalars['Float']['output']>;
  priceChange24h?: Maybe<Scalars['Float']['output']>;
  valid: Scalars['Boolean']['output'];
  value?: Maybe<Scalars['Float']['output']>;
};

export type NetworkLatestStats = {
  __typename: 'NetworkLatestStats';
  deposits: Scalars['Int']['output'];
  gav: Scalars['Float']['output'];
  vaults: Scalars['Int']['output'];
};

export type PtHoldingPendleV2 = {
  __typename: 'PTHoldingPendleV2';
  apy: Scalars['Float']['output'];
  asset: Scalars['Address']['output'];
  balance: Scalars['BigInt']['output'];
  currencyValue?: Maybe<Scalars['Float']['output']>;
  expiry: Scalars['Int']['output'];
  icon: Scalars['String']['output'];
  market: Scalars['Address']['output'];
  marketName: Scalars['String']['output'];
  syToken: Scalars['Address']['output'];
  underlying: Scalars['Address']['output'];
};

/** PageInfo cursor, as defined in https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
export type PageInfo = {
  __typename: 'PageInfo';
  /** The cursor corresponding to the last nodes in edges. Null if the connection is empty. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** Used to indicate whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Used to indicate whether more edges exist prior to the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** The cursor corresponding to the first nodes in edges. Null if the connection is empty. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PasswordConnectCompleteInput = {
  token: Scalars['String']['input'];
};

export type PasswordConnectInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type PasswordSignInInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type PasswordSignUpCompleteInput = {
  token: Scalars['String']['input'];
};

export type PasswordSignUpInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export enum PrimaryAuthMethod {
  GOOGLE = 'GOOGLE',
  PASSWORD = 'PASSWORD',
  WALLET = 'WALLET'
}

export enum Protocol {
  AAVE = 'AAVE',
  AAVE_V3 = 'AAVE_V3',
  AURA = 'AURA',
  BALANCER = 'BALANCER',
  COMPOUND_V2 = 'COMPOUND_V2',
  COMPOUND_V3 = 'COMPOUND_V3',
  CONVEX = 'CONVEX',
  CURVE = 'CURVE',
  IDLE = 'IDLE',
  KILN = 'KILN',
  LIQUITY = 'LIQUITY',
  MAPLE = 'MAPLE',
  MORPHO = 'MORPHO',
  ONE_INCH_V5 = 'ONE_INCH_V5',
  SPARK = 'SPARK',
  THE_GRAPH = 'THE_GRAPH',
  UNISWAP = 'UNISWAP',
  UNISWAP_V2 = 'UNISWAP_V2',
  YEARN_VAULT_V2 = 'YEARN_VAULT_V2'
}

export type Query = {
  __typename: 'Query';
  activeProtocolIntegrations?: Maybe<ActiveIntegrations>;
  addLiquidityForPendleV2: AddLiquidityForPendleV2Data;
  allBalancerPools: Array<SingleBalancerPoolData>;
  arbitraryLoansEnabledForVault: ArbitraryLoansEnabledForVault;
  assetApys: Array<AssetApys>;
  assetPrices: Array<AssetPrice>;
  availableToRegisterPendleV2Markets: Array<MarketInfoToRegisterPendleV2>;
  availableToUnregisterPendleV2Markets: Array<MarketInfoToUpdatePendleV2>;
  availableToUpdatePendleV2Markets: Array<MarketInfoToUpdatePendleV2>;
  balancerDepositInfo?: Maybe<BalancerDepositInfo>;
  balancerPoolPositions: Array<SingleBalancerPoolPositionData>;
  balancerWithdrawInfoExactBptIn?: Maybe<BalancerWithdrawInfoExactBptIn>;
  borrowerArbitraryLoanPositions: BorrowerArbitraryLoanPositionsData;
  borrowingForAaveV2Assets?: Maybe<Array<SingleAaveV2AssetBorrowingData>>;
  borrowingForAaveV3Assets?: Maybe<Array<SingleAaveV3AssetBorrowingData>>;
  borrowingForCompoundV2Assets?: Maybe<Array<SingleCompoundV2AssetBorrowingData>>;
  currentUserRepresentativeApplicationForAccount?: Maybe<UserRepresentativeApplication>;
  currentUserRepresentativeApplications?: Maybe<Array<UserRepresentativeApplication>>;
  curvePools: Array<SinglePoolData>;
  delegateVotesAssets?: Maybe<Array<DelegateVoteAsset>>;
  divaStakingDepositorInfo?: Maybe<DivaStakingDepositorInfo>;
  divaStakingGeneralInfo?: Maybe<DivaStakingGeneralInfo>;
  eModeCategories: Array<EModeCategoriesAaveV3Data>;
  eModePositionLiquidationInfo?: Maybe<EModePositionLiquidationInfoData>;
  erc20TokenDepositTransaction: TokenSwapTransaction;
  externalPositionConvexVotingInfo?: Maybe<ConvexVotingInfo>;
  externalPositionConvexVotingPortfolio?: Maybe<ExternalPositionConvexVotingPortfolioData>;
  externalPositions?: Maybe<Array<ExternalPositionData>>;
  externalPositionsAaveV2DebtPortfolio?: Maybe<ExternalPositionAaveV2DebtPortfolioData>;
  externalPositionsAaveV2DebtPositions: Array<SingleAaveV2DebtPositionData>;
  externalPositionsAaveV3DebtPortfolio?: Maybe<ExternalPositionAaveV3DebtPortfolioData>;
  externalPositionsAaveV3DebtPositions: Array<SingleAaveV3DebtPositionData>;
  externalPositionsArbitraryLoanPortfolio?: Maybe<Array<ExternalPositionsArbitraryLoanPositionData>>;
  externalPositionsArbitraryLoanPositions: ExternalPositionsArbitraryLoanPositionsData;
  externalPositionsCompoundV2DebtPortfolio?: Maybe<ExternalPositionCompoundV2DebtPortfolioData>;
  externalPositionsCompoundV2DebtPositions: Array<SingleCompoundV2DebtPositionData>;
  externalPositionsKilnPortfolio?: Maybe<ExternalPositionKilnPortfolioData>;
  externalPositionsKilnPositions?: Maybe<Array<KilnStakingPositionData>>;
  externalPositionsLidoWithdrawalsPortfolio?: Maybe<Array<LidoWithdrawalsPortfolioData>>;
  externalPositionsLidoWithdrawalsPositions?: Maybe<Array<LidoWithdrawalsPositionData>>;
  externalPositionsLiquityPortfolio?: Maybe<ExternalPositionLiquityPortfolioData>;
  externalPositionsLiquityPositions?: Maybe<Array<LiquityDebtPositionData>>;
  externalPositionsMaplePortfolio?: Maybe<ExternalPositionMaplePortfolioData>;
  externalPositionsMaplePositions?: Maybe<Array<SingleMaplePositionData>>;
  externalPositionsPendleV2Portfolio?: Maybe<ExternalPositionPendleV2PortfolioData>;
  externalPositionsPendleV2Positions: Array<SinglePendleV2PositionData>;
  externalPositionsStakeWisePortfolio?: Maybe<ExternalPositionStakeWisePortfolioData>;
  externalPositionsStakeWisePositions?: Maybe<Array<StakeWiseVaultData>>;
  externalPositionsTheGraphDelegationPortfolio?: Maybe<Array<ExternalPositionTheGraphDelegationPortfolioPositionData>>;
  externalPositionsTheGraphDelegationPositions?: Maybe<Array<Array<SingleTheGraphDelegationPositionData>>>;
  isDepositorSignatureRequired: Scalars['Boolean']['output'];
  lendingDataForAaveV2Assets: Array<SingleAaveV2AssetLendingData>;
  lendingDataForAaveV3Assets: Array<SingleAaveV3AssetLendingData>;
  lendingDataForAllMapleAssets?: Maybe<Array<SingleMapleAssetLendingData>>;
  lendingDataForCompoundV2Assets: Array<SingleCompoundV2AssetLendingData>;
  lendingDataForCompoundV3Assets: Array<SingleCompoundV3AssetLendingData>;
  lendingDataForCompoundV3AssetsPosition: Array<SingleCompoundAssetV3PositionLendingData>;
  lendingDataForERC4626Assets?: Maybe<Array<SingleErc4626AssetLendingData>>;
  lendingDataForERC4626AssetsPosition?: Maybe<Array<SingleErc4626AssetPositionLendingData>>;
  lendingDataForIdleAssets: Array<SingleIdleAssetLendingData>;
  lendingDataForYearnAssets: Array<SingleYearnAssetLendingData>;
  lidoUnclaimedWithdrawalRequests?: Maybe<Array<LidoUnclaimedWithdrawalRequest>>;
  liquityHints?: Maybe<LiquityHintsData>;
  mapleConvertSharesToExitAssets?: Maybe<MapleSharesToExitAssets>;
  me?: Maybe<Account>;
  nativeTokenDepositTransaction: TokenSwapTransaction;
  networkAssetBalances: Array<NetworkAssetBalance>;
  networkLatestStats: NetworkLatestStats;
  referencePrice: ReferencePrice;
  removeLiquidityForPendleV2: RemoveLiquidityForPendleV2Data;
  rewardsForAaveV2?: Maybe<AaveV2RewardsData>;
  rewardsForBalancer?: Maybe<RewardsData>;
  rewardsForCompoundV2?: Maybe<CompoundV2RewardsData>;
  rewardsForCompoundV3?: Maybe<CompoundV3RewardsData>;
  rewardsForCurve?: Maybe<RewardsData>;
  rewardsForIdle?: Maybe<RewardsData>;
  rewardsForWallet?: Maybe<Array<RewardsData>>;
  sellPtForTokenPendleV2: SellPtForTokenPendleV2Data;
  stakingDataForAllStakeWiseAssets?: Maybe<Array<StakeWiseVaultData>>;
  swapExactTokenForPtPendleV2: SwapExactTokenForPtPendleV2Data;
  swellStakingTransaction: TokenSwapTransaction;
  theGraphDelegationIndexerInfo?: Maybe<TheGraphDelegationIndexerInfoData>;
  theGraphDelegationInfo?: Maybe<TheGraphDelegationInfoData>;
  theGraphDelegationSharesToTokens?: Maybe<TheGraphDelegationSharesToTokensData>;
  theGraphDelegationTokensToShares?: Maybe<TheGraphDelegationTokensToSharesData>;
  threeOneThirdAssetsToTrade: Array<ThreeOneThirdAssetToTrade>;
  threeOneThirdRebalance: ThreeOneThirdRebalanceResponse;
  tokenPriceInNativeCurrency: TokenPriceInNativeCurrency;
  tokenSwapAndReferencePrices: TokenSwapAndReferencePrices;
  tokenSwapPrice: TokenSwapPrice;
  tokenSwapPrices: Array<TokenSwapPrices>;
  tokenSwapTransaction: TokenSwapTransaction;
  uniswapV2Pools?: Maybe<Array<SinglePoolData>>;
  uniswapV3FeeTierDistribution: Array<UniswapV3FeeTierDistribution>;
  uniswapV3NftDetails?: Maybe<UniswapV3Nft>;
  uniswapV3PoolDetails: UniswapV3PoolDetails;
  uniswapV3UtilsTokenAmounts: UniswapV3TokenAmounts;
  uniswapV3UtilsTokenAmountsFromAmount0?: Maybe<UniswapV3TokenAmounts>;
  uniswapV3UtilsTokenAmountsFromAmount1?: Maybe<UniswapV3TokenAmounts>;
  uniswapV3VaultLiquidityPositions: Array<UniswapV3VaultLiquidityPosition>;
  userRepresentativeElection: UserRepresentativeElection;
  userRepresentativeElections: Array<UserRepresentativeElection>;
  vault?: Maybe<Vault>;
  vaultBalances: Array<VaultBalance>;
  vaultDepositorMetrics?: Maybe<VaultDepositorMetrics>;
  vaultList: QueryVaultListConnection;
  vaultListForDepositor: QueryVaultListForDepositorConnection;
  vaultListForManager: QueryVaultListForManagerConnection;
  vaultMonthlyReturns: Array<VaultMonthlyReturn>;
  vaultPerformanceLatest?: Maybe<VaultPerformanceLatest>;
  vaultProfile?: Maybe<VaultProfile>;
  vaultRiskMetrics?: Maybe<VaultRiskMetrics>;
  vaultStrategies: Array<VaultStrategy>;
  vaultUntrackedBalances: Array<VaultBalance>;
  whiteLabelById?: Maybe<WhiteLabel>;
  whiteLabelBySubdomain?: Maybe<WhiteLabel>;
};


export type QueryActiveProtocolIntegrationsArgs = {
  network: Deployment;
  vault: Scalars['Address']['input'];
};


export type QueryAddLiquidityForPendleV2Args = {
  amountTokenIn: Scalars['BigInt']['input'];
  market: Scalars['Address']['input'];
  network: Deployment;
  slippage: Scalars['Float']['input'];
  tokenInAddr: Scalars['Address']['input'];
  vaultId: Scalars['Address']['input'];
};


export type QueryAllBalancerPoolsArgs = {
  currency: Currency;
  network: Deployment;
  vaultId: Scalars['Address']['input'];
};


export type QueryArbitraryLoansEnabledForVaultArgs = {
  comptroller: Scalars['Address']['input'];
  network: Deployment;
  ownerId: Scalars['Address']['input'];
};


export type QueryAssetApysArgs = {
  addresses: Array<Scalars['Address']['input']>;
  network: Deployment;
};


export type QueryAssetPricesArgs = {
  currency: Scalars['CurrencyOrAddress']['input'];
  network: Deployment;
};


export type QueryAvailableToRegisterPendleV2MarketsArgs = {
  currency: Currency;
  network: Deployment;
  vaultId: Scalars['Address']['input'];
};


export type QueryAvailableToUnregisterPendleV2MarketsArgs = {
  currency: Currency;
  network: Deployment;
  vaultId: Scalars['Address']['input'];
};


export type QueryAvailableToUpdatePendleV2MarketsArgs = {
  currency: Currency;
  network: Deployment;
  vaultId: Scalars['Address']['input'];
};


export type QueryBalancerDepositInfoArgs = {
  amounts: Array<Scalars['BigInt']['input']>;
  network: Deployment;
  poolId: Scalars['String']['input'];
};


export type QueryBalancerPoolPositionsArgs = {
  currency: Currency;
  network: Deployment;
  vaultId: Scalars['Address']['input'];
};


export type QueryBalancerWithdrawInfoExactBptInArgs = {
  bptAmountIn: Scalars['BigInt']['input'];
  network: Deployment;
  poolId: Scalars['String']['input'];
  tokenIndex?: InputMaybe<Scalars['BigInt']['input']>;
};


export type QueryBorrowerArbitraryLoanPositionsArgs = {
  borrower: Scalars['Address']['input'];
  network: Deployment;
};


export type QueryBorrowingForAaveV2AssetsArgs = {
  currency: Currency;
  network: Deployment;
};


export type QueryBorrowingForAaveV3AssetsArgs = {
  currency: Currency;
  network: Deployment;
};


export type QueryBorrowingForCompoundV2AssetsArgs = {
  currency: Currency;
  network: Deployment;
};


export type QueryCurvePoolsArgs = {
  currency: Currency;
  network: Deployment;
};


export type QueryDelegateVotesAssetsArgs = {
  currency: Currency;
  network: Deployment;
  vaultId: Scalars['Address']['input'];
};


export type QueryDivaStakingDepositorInfoArgs = {
  currency: Currency;
  depositor: Scalars['Address']['input'];
  network: Deployment;
};


export type QueryDivaStakingGeneralInfoArgs = {
  network: Deployment;
};


export type QueryEModeCategoriesArgs = {
  network: Deployment;
};


export type QueryEModePositionLiquidationInfoArgs = {
  eModeCategory: Scalars['Int']['input'];
  externalPosition: Scalars['Address']['input'];
  network: Deployment;
};


export type QueryErc20TokenDepositTransactionArgs = {
  comptrollerProxy: Scalars['Address']['input'];
  expectedDenominationAmount: Scalars['BigInt']['input'];
  incoming: Scalars['Address']['input'];
  maxSlippage: Scalars['Float']['input'];
  network: Deployment;
  originAddress?: InputMaybe<Scalars['Address']['input']>;
  outgoing: Scalars['Address']['input'];
  quantity: Scalars['BigInt']['input'];
  userAddress: Scalars['Address']['input'];
};


export type QueryExternalPositionConvexVotingInfoArgs = {
  address: Scalars['Address']['input'];
  currency: Currency;
  network: Deployment;
};


export type QueryExternalPositionConvexVotingPortfolioArgs = {
  address: Scalars['Address']['input'];
  currency: Scalars['CurrencyOrAddress']['input'];
  network: Deployment;
};


export type QueryExternalPositionsArgs = {
  currency: Currency;
  network: Deployment;
  vaultId: Scalars['Address']['input'];
};


export type QueryExternalPositionsAaveV2DebtPortfolioArgs = {
  currency: Scalars['CurrencyOrAddress']['input'];
  network: Deployment;
  vaultId: Scalars['Address']['input'];
};


export type QueryExternalPositionsAaveV2DebtPositionsArgs = {
  currency: Currency;
  network: Deployment;
  vaultId: Scalars['Address']['input'];
};


export type QueryExternalPositionsAaveV3DebtPortfolioArgs = {
  currency: Scalars['CurrencyOrAddress']['input'];
  network: Deployment;
  vaultId: Scalars['Address']['input'];
};


export type QueryExternalPositionsAaveV3DebtPositionsArgs = {
  currency: Currency;
  network: Deployment;
  vaultId: Scalars['Address']['input'];
};


export type QueryExternalPositionsArbitraryLoanPortfolioArgs = {
  address: Scalars['Address']['input'];
  currency: Scalars['CurrencyOrAddress']['input'];
  network: Deployment;
};


export type QueryExternalPositionsArbitraryLoanPositionsArgs = {
  address: Scalars['Address']['input'];
  network: Deployment;
};


export type QueryExternalPositionsCompoundV2DebtPortfolioArgs = {
  currency: Scalars['CurrencyOrAddress']['input'];
  network: Deployment;
  vaultId: Scalars['Address']['input'];
};


export type QueryExternalPositionsCompoundV2DebtPositionsArgs = {
  currency: Currency;
  network: Deployment;
  vaultId: Scalars['Address']['input'];
};


export type QueryExternalPositionsKilnPortfolioArgs = {
  currency: Scalars['CurrencyOrAddress']['input'];
  network: Deployment;
  vaultId: Scalars['Address']['input'];
};


export type QueryExternalPositionsKilnPositionsArgs = {
  currency: Currency;
  network: Deployment;
  vaultId: Scalars['Address']['input'];
};


export type QueryExternalPositionsLidoWithdrawalsPortfolioArgs = {
  currency: Scalars['CurrencyOrAddress']['input'];
  network: Deployment;
  vaultId: Scalars['Address']['input'];
};


export type QueryExternalPositionsLidoWithdrawalsPositionsArgs = {
  network: Deployment;
  vaultId: Scalars['Address']['input'];
};


export type QueryExternalPositionsLiquityPortfolioArgs = {
  currency: Scalars['CurrencyOrAddress']['input'];
  network: Deployment;
  vaultId: Scalars['Address']['input'];
};


export type QueryExternalPositionsLiquityPositionsArgs = {
  currency: Currency;
  network: Deployment;
  vaultId: Scalars['Address']['input'];
};


export type QueryExternalPositionsMaplePortfolioArgs = {
  currency: Scalars['CurrencyOrAddress']['input'];
  network: Deployment;
  vaultId: Scalars['Address']['input'];
};


export type QueryExternalPositionsMaplePositionsArgs = {
  network: Deployment;
  vaultId: Scalars['Address']['input'];
};


export type QueryExternalPositionsPendleV2PortfolioArgs = {
  currency: Scalars['CurrencyOrAddress']['input'];
  network: Deployment;
  vaultId: Scalars['Address']['input'];
};


export type QueryExternalPositionsPendleV2PositionsArgs = {
  currency: Currency;
  network: Deployment;
  vaultId: Scalars['Address']['input'];
};


export type QueryExternalPositionsStakeWisePortfolioArgs = {
  currency: Scalars['CurrencyOrAddress']['input'];
  network: Deployment;
  vaultId: Scalars['Address']['input'];
};


export type QueryExternalPositionsStakeWisePositionsArgs = {
  currency: Currency;
  network: Deployment;
  vaultId: Scalars['Address']['input'];
};


export type QueryExternalPositionsTheGraphDelegationPortfolioArgs = {
  address: Scalars['Address']['input'];
  currency: Scalars['CurrencyOrAddress']['input'];
  network: Deployment;
};


export type QueryExternalPositionsTheGraphDelegationPositionsArgs = {
  address: Scalars['Address']['input'];
  network: Deployment;
};


export type QueryIsDepositorSignatureRequiredArgs = {
  vaultAddress: Scalars['Address']['input'];
  walletAddress: Scalars['Address']['input'];
};


export type QueryLendingDataForAaveV2AssetsArgs = {
  currency: Currency;
  network: Deployment;
};


export type QueryLendingDataForAaveV3AssetsArgs = {
  currency: Currency;
  network: Deployment;
};


export type QueryLendingDataForAllMapleAssetsArgs = {
  currency: Currency;
  network: Deployment;
};


export type QueryLendingDataForCompoundV2AssetsArgs = {
  currency: Currency;
  network: Deployment;
};


export type QueryLendingDataForCompoundV3AssetsArgs = {
  currency: Currency;
  network: Deployment;
  vaultId: Scalars['Address']['input'];
};


export type QueryLendingDataForCompoundV3AssetsPositionArgs = {
  currency: Currency;
  network: Deployment;
  vaultId: Scalars['Address']['input'];
};


export type QueryLendingDataForErc4626AssetsArgs = {
  currency: Currency;
  network: Deployment;
  protocol: Erc4626Protocol;
  vaultId: Scalars['Address']['input'];
};


export type QueryLendingDataForErc4626AssetsPositionArgs = {
  currency: Currency;
  network: Deployment;
  protocol: Erc4626Protocol;
  vaultId: Scalars['Address']['input'];
};


export type QueryLendingDataForIdleAssetsArgs = {
  currency: Currency;
  network: Deployment;
};


export type QueryLendingDataForYearnAssetsArgs = {
  currency: Currency;
  network: Deployment;
};


export type QueryLidoUnclaimedWithdrawalRequestsArgs = {
  currency: Currency;
  network: Deployment;
  vaultId: Scalars['Address']['input'];
};


export type QueryLiquityHintsArgs = {
  actionType: Scalars['String']['input'];
  collateralAmount: Scalars['BigInt']['input'];
  lusdAmount: Scalars['BigInt']['input'];
  network: Deployment;
};


export type QueryMapleConvertSharesToExitAssetsArgs = {
  network: Deployment;
  pool: Scalars['Address']['input'];
  shares: Scalars['BigInt']['input'];
};


export type QueryNativeTokenDepositTransactionArgs = {
  comptrollerProxy: Scalars['Address']['input'];
  expectedDenominationAmount: Scalars['BigInt']['input'];
  incoming: Scalars['Address']['input'];
  maxSlippage: Scalars['Float']['input'];
  network: Deployment;
  originAddress?: InputMaybe<Scalars['Address']['input']>;
  quantity: Scalars['BigInt']['input'];
  userAddress: Scalars['Address']['input'];
};


export type QueryNetworkAssetBalancesArgs = {
  currency: Currency;
  network: Deployment;
};


export type QueryNetworkLatestStatsArgs = {
  currency: Currency;
  network: Deployment;
};


export type QueryReferencePriceArgs = {
  incoming: Scalars['Address']['input'];
  network: Deployment;
  outgoing: Scalars['Address']['input'];
};


export type QueryRemoveLiquidityForPendleV2Args = {
  amountLpToRemove: Scalars['BigInt']['input'];
  market: Scalars['Address']['input'];
  network: Deployment;
  slippage: Scalars['Float']['input'];
  tokenOutAddr: Scalars['Address']['input'];
  vaultId: Scalars['Address']['input'];
};


export type QueryRewardsForAaveV2Args = {
  address: Scalars['Address']['input'];
  currency: Currency;
  network: Deployment;
};


export type QueryRewardsForBalancerArgs = {
  address: Scalars['Address']['input'];
  currency: Currency;
  network: Deployment;
};


export type QueryRewardsForCompoundV2Args = {
  address: Scalars['Address']['input'];
  currency: Currency;
  network: Deployment;
};


export type QueryRewardsForCompoundV3Args = {
  address: Scalars['Address']['input'];
  currency: Currency;
  network: Deployment;
};


export type QueryRewardsForCurveArgs = {
  address: Scalars['Address']['input'];
  currency: Currency;
  network: Deployment;
};


export type QueryRewardsForIdleArgs = {
  address: Scalars['Address']['input'];
  currency: Currency;
  network: Deployment;
};


export type QueryRewardsForWalletArgs = {
  address: Scalars['Address']['input'];
  currency: Currency;
  network: Deployment;
};


export type QuerySellPtForTokenPendleV2Args = {
  amountPtIn: Scalars['BigInt']['input'];
  hasExpired: Scalars['Boolean']['input'];
  market: Scalars['Address']['input'];
  network: Deployment;
  slippage: Scalars['Float']['input'];
  tokenOutAddr: Scalars['Address']['input'];
  vaultId: Scalars['Address']['input'];
};


export type QueryStakingDataForAllStakeWiseAssetsArgs = {
  currency: Currency;
  network: Deployment;
  vaultId: Scalars['Address']['input'];
};


export type QuerySwapExactTokenForPtPendleV2Args = {
  amountTokenIn: Scalars['BigInt']['input'];
  market: Scalars['Address']['input'];
  network: Deployment;
  slippage: Scalars['Float']['input'];
  tokenInAddr: Scalars['Address']['input'];
  vaultId: Scalars['Address']['input'];
};


export type QuerySwellStakingTransactionArgs = {
  comptrollerProxy: Scalars['Address']['input'];
  expectedIncomingAmount: Scalars['BigInt']['input'];
  maxSlippage: Scalars['Float']['input'];
  network: Deployment;
  originAddress?: InputMaybe<Scalars['Address']['input']>;
  quantity: Scalars['BigInt']['input'];
  userAddress: Scalars['Address']['input'];
  vaultProxy: Scalars['Address']['input'];
};


export type QueryTheGraphDelegationIndexerInfoArgs = {
  indexer: Scalars['Address']['input'];
  network: Deployment;
};


export type QueryTheGraphDelegationInfoArgs = {
  network: Deployment;
};


export type QueryTheGraphDelegationSharesToTokensArgs = {
  indexer: Scalars['Address']['input'];
  network: Deployment;
  shares: Scalars['BigInt']['input'];
};


export type QueryTheGraphDelegationTokensToSharesArgs = {
  indexer: Scalars['Address']['input'];
  network: Deployment;
  tokens: Scalars['BigInt']['input'];
};


export type QueryThreeOneThirdAssetsToTradeArgs = {
  currency: Currency;
  network: Deployment;
  vaultId: Scalars['Address']['input'];
};


export type QueryThreeOneThirdRebalanceArgs = {
  currency: Currency;
  maxPriceImpact: Scalars['Float']['input'];
  maxSlippage: Scalars['Float']['input'];
  network: Deployment;
  signer: Scalars['Address']['input'];
  tokensIn: Array<Scalars['Address']['input']>;
  tokensInAmounts: Array<Scalars['BigInt']['input']>;
  tokensOut: Array<Scalars['Address']['input']>;
  tokensOutAllocations: Array<Scalars['Float']['input']>;
  vaultId: Scalars['Address']['input'];
};


export type QueryTokenPriceInNativeCurrencyArgs = {
  network: Deployment;
  quantity: Scalars['BigInt']['input'];
  token: Scalars['Address']['input'];
};


export type QueryTokenSwapAndReferencePricesArgs = {
  comptroller: Scalars['Address']['input'];
  currency: Scalars['CurrencyOrAddress']['input'];
  incoming: Scalars['Address']['input'];
  network: Deployment;
  outgoing: Scalars['Address']['input'];
  quantity: Scalars['BigInt']['input'];
};


export type QueryTokenSwapPriceArgs = {
  exchange: Exchange;
  incoming: Scalars['Address']['input'];
  network: Deployment;
  outgoing: Scalars['Address']['input'];
  quantity: Scalars['BigInt']['input'];
};


export type QueryTokenSwapPricesArgs = {
  incoming: Scalars['Address']['input'];
  network: Deployment;
  outgoing: Scalars['Address']['input'];
  quantity: Scalars['BigInt']['input'];
};


export type QueryTokenSwapTransactionArgs = {
  comptrollerProxy: Scalars['Address']['input'];
  exchange: Exchange;
  expectedIncomingAmount: Scalars['BigInt']['input'];
  incoming: Scalars['Address']['input'];
  maxSlippage: Scalars['Float']['input'];
  network: Deployment;
  originAddress?: InputMaybe<Scalars['Address']['input']>;
  outgoing: Scalars['Address']['input'];
  quantity: Scalars['BigInt']['input'];
  userAddress: Scalars['Address']['input'];
  vaultProxy: Scalars['Address']['input'];
};


export type QueryUniswapV2PoolsArgs = {
  currency: Currency;
  network: Deployment;
};


export type QueryUniswapV3FeeTierDistributionArgs = {
  deployment: Deployment;
  token0: Scalars['Address']['input'];
  token1: Scalars['Address']['input'];
};


export type QueryUniswapV3NftDetailsArgs = {
  deployment: Deployment;
  nftId: Scalars['String']['input'];
};


export type QueryUniswapV3PoolDetailsArgs = {
  deployment: Deployment;
  fetchCurrentTickOnChain: Scalars['Boolean']['input'];
  poolAddress: Scalars['Address']['input'];
};


export type QueryUniswapV3UtilsTokenAmountsArgs = {
  deployment: Deployment;
  feeTier: Scalars['Int']['input'];
  pool: Scalars['Address']['input'];
  positionLiquidity: Scalars['BigInt']['input'];
  tickLower: Scalars['Int']['input'];
  tickUpper: Scalars['Int']['input'];
  token0: Scalars['Address']['input'];
  token1: Scalars['Address']['input'];
};


export type QueryUniswapV3UtilsTokenAmountsFromAmount0Args = {
  amount0: Scalars['BigInt']['input'];
  asset0: Scalars['Address']['input'];
  asset1: Scalars['Address']['input'];
  deployment: Deployment;
  feeTier: Scalars['Int']['input'];
  pool: Scalars['Address']['input'];
  tickLower: Scalars['Int']['input'];
  tickUpper: Scalars['Int']['input'];
};


export type QueryUniswapV3UtilsTokenAmountsFromAmount1Args = {
  amount1: Scalars['BigInt']['input'];
  asset0: Scalars['Address']['input'];
  asset1: Scalars['Address']['input'];
  deployment: Deployment;
  feeTier: Scalars['Int']['input'];
  pool: Scalars['Address']['input'];
  tickLower: Scalars['Int']['input'];
  tickUpper: Scalars['Int']['input'];
};


export type QueryUniswapV3VaultLiquidityPositionsArgs = {
  deployment: Deployment;
  vaultId: Scalars['Address']['input'];
};


export type QueryUserRepresentativeElectionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryVaultArgs = {
  address: Scalars['Address']['input'];
};


export type QueryVaultBalancesArgs = {
  currency: Scalars['CurrencyOrAddress']['input'];
  network: Deployment;
  vault: Scalars['Address']['input'];
};


export type QueryVaultDepositorMetricsArgs = {
  currency: Scalars['CurrencyOrAddress']['input'];
  depositor: Scalars['Address']['input'];
  network: Deployment;
  vault: Scalars['Address']['input'];
};


export type QueryVaultListArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  currency: Currency;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  network: Deployment;
  orderBy?: InputMaybe<VaultListOrderBy>;
  vaultAddresses?: InputMaybe<Array<Scalars['Address']['input']>>;
};


export type QueryVaultListForDepositorArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  currency: Currency;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  network: Deployment;
};


export type QueryVaultListForManagerArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  currency: Currency;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  network: Deployment;
};


export type QueryVaultMonthlyReturnsArgs = {
  currency: Scalars['CurrencyOrAddress']['input'];
  network: Deployment;
  vault: Scalars['Address']['input'];
};


export type QueryVaultPerformanceLatestArgs = {
  currency: Scalars['CurrencyOrAddress']['input'];
  network: Deployment;
  vault: Scalars['Address']['input'];
};


export type QueryVaultProfileArgs = {
  vaultAddress: Scalars['Address']['input'];
};


export type QueryVaultRiskMetricsArgs = {
  currency: Scalars['CurrencyOrAddress']['input'];
  network: Deployment;
  vault: Scalars['Address']['input'];
};


export type QueryVaultUntrackedBalancesArgs = {
  currency: Scalars['CurrencyOrAddress']['input'];
  network: Deployment;
  vault: Scalars['Address']['input'];
};


export type QueryWhiteLabelByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryWhiteLabelBySubdomainArgs = {
  subdomain: Scalars['String']['input'];
};

export type QueryVaultListConnection = {
  __typename: 'QueryVaultListConnection';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
  edges: Array<QueryVaultListEdge>;
  /** Flattened list of VaultListItem type */
  nodes: Array<VaultListItem>;
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
  pageInfo: PageInfo;
};

export type QueryVaultListEdge = {
  __typename: 'QueryVaultListEdge';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor */
  cursor: Scalars['String']['output'];
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Node */
  node: VaultListItem;
};

export type QueryVaultListForDepositorConnection = {
  __typename: 'QueryVaultListForDepositorConnection';
  aggregated: VaultListForDepositorAggregated;
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
  edges: Array<QueryVaultListForDepositorEdge>;
  /** Flattened list of VaultListItem type */
  nodes: Array<VaultListItem>;
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
  pageInfo: PageInfo;
};

export type QueryVaultListForDepositorEdge = {
  __typename: 'QueryVaultListForDepositorEdge';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor */
  cursor: Scalars['String']['output'];
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Node */
  node: VaultListItem;
};

export type QueryVaultListForManagerConnection = {
  __typename: 'QueryVaultListForManagerConnection';
  aggregated: VaultListForManagerAggregated;
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
  edges: Array<QueryVaultListForManagerEdge>;
  /** Flattened list of VaultListItem type */
  nodes: Array<VaultListItem>;
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
  pageInfo: PageInfo;
};

export type QueryVaultListForManagerEdge = {
  __typename: 'QueryVaultListForManagerEdge';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor */
  cursor: Scalars['String']['output'];
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Node */
  node: VaultListItem;
};

export type RateLimitError = UserError & {
  __typename: 'RateLimitError';
  message: Scalars['String']['output'];
};

export type ReferencePrice = {
  __typename: 'ReferencePrice';
  price?: Maybe<Scalars['Float']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type RemoveLiquidityForPendleV2Data = {
  __typename: 'RemoveLiquidityForPendleV2Data';
  minIncomingAmount: Scalars['BigInt']['output'];
  minSyOut: Scalars['BigInt']['output'];
  priceImpact: Scalars['Float']['output'];
};

export type RequestAuthTokenInput = {
  credentials?: InputMaybe<MultiFactorAuthInput>;
  method?: InputMaybe<MultiFactorAuthMethod>;
  scope: RequestAuthTokenScope;
};

export type RequestAuthTokenPayload = {
  __typename: 'RequestAuthTokenPayload';
  account?: Maybe<Account>;
  errors?: Maybe<Array<UserError>>;
  /** Token valid for 5 minutes */
  token?: Maybe<Scalars['String']['output']>;
};

export enum RequestAuthTokenScope {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  CONNECT_GOOGLE_AUTH = 'CONNECT_GOOGLE_AUTH',
  CONNECT_PASSWORD_AUTH = 'CONNECT_PASSWORD_AUTH',
  CONNECT_WALLET_AUTH = 'CONNECT_WALLET_AUTH',
  CREATE_API_TOKEN = 'CREATE_API_TOKEN',
  DELETE_ACCOUNT = 'DELETE_ACCOUNT',
  DELETE_API_TOKEN = 'DELETE_API_TOKEN',
  DELETE_AUTHENTICATOR = 'DELETE_AUTHENTICATOR',
  DELETE_BACKUP_CODES = 'DELETE_BACKUP_CODES',
  DISCONNECT_GOOGLE_AUTH = 'DISCONNECT_GOOGLE_AUTH',
  DISCONNECT_PASSWORD_AUTH = 'DISCONNECT_PASSWORD_AUTH',
  DISCONNECT_WALLET_AUTH = 'DISCONNECT_WALLET_AUTH',
  GENERATE_BACKUP_CODES = 'GENERATE_BACKUP_CODES',
  RESET_PASSWORD = 'RESET_PASSWORD',
  REVOKE_BACKUP_CODES = 'REVOKE_BACKUP_CODES',
  SIGN_IN = 'SIGN_IN',
  UPDATE_API_TOKEN = 'UPDATE_API_TOKEN',
  UPDATE_EMAIL = 'UPDATE_EMAIL'
}

export type ResetPasswordInput = {
  code: Scalars['String']['input'];
  password: Scalars['String']['input'];
  reCaptchaToken: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type ResetPasswordPayload = {
  __typename: 'ResetPasswordPayload';
  errors?: Maybe<Array<UserError>>;
};

export type RevokeBackupCodesInput = {
  /** The token that is requested by the `requestAuthToken` mutation */
  token: Scalars['String']['input'];
};

export type RevokeBackupCodesPayload = {
  __typename: 'RevokeBackupCodesPayload';
  account?: Maybe<Account>;
  errors?: Maybe<Array<UserError>>;
};

export type Reward = {
  __typename: 'Reward';
  assetAddress: Scalars['Address']['output'];
  claimable: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
};

export type RewardApy = {
  __typename: 'RewardApy';
  apy: Scalars['Float']['output'];
  assetAddress: Scalars['Address']['output'];
};

export type RewardsApr = {
  __typename: 'RewardsApr';
  apr: Scalars['Float']['output'];
  assetAddress: Scalars['Address']['output'];
};

export type RewardsData = {
  __typename: 'RewardsData';
  globalRewards?: Maybe<Array<Reward>>;
  individualRewards?: Maybe<Array<IndividualReward>>;
  protocol: Scalars['String']['output'];
};

export type SellPtForTokenPendleV2Data = {
  __typename: 'SellPtForTokenPendleV2Data';
  amountMinOut: Scalars['BigInt']['output'];
  priceImpact: Scalars['Float']['output'];
};

export type SignInCompleteInput = {
  credentials: MultiFactorAuthInput;
  method: MultiFactorAuthMethod;
  reCaptchaToken: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type SignInCompletePayload = {
  __typename: 'SignInCompletePayload';
  account?: Maybe<Account>;
  errors?: Maybe<Array<UserError>>;
};

export type SignInCredentialsInput = {
  google?: InputMaybe<GoogleSignInOrSignUpInput>;
  password?: InputMaybe<PasswordSignInInput>;
  wallet?: InputMaybe<WalletSignInInput>;
};

export type SignInInput = {
  credentials: SignInCredentialsInput;
  method: PrimaryAuthMethod;
  reCaptchaToken: Scalars['String']['input'];
};

export type SignInPayload = {
  __typename: 'SignInPayload';
  account?: Maybe<Account>;
  errors?: Maybe<Array<UserError>>;
  mfa?: Maybe<Array<MultiFactorAuthMethod>>;
  token?: Maybe<Scalars['String']['output']>;
};

export type SignOutPayload = {
  __typename: 'SignOutPayload';
  errors?: Maybe<Array<UserError>>;
};

export type SignUpCompleteInput = {
  method: PrimaryAuthMethod;
  token: Scalars['String']['input'];
};

export type SignUpCompletePayload = {
  __typename: 'SignUpCompletePayload';
  account?: Maybe<Account>;
  errors?: Maybe<Array<UserError>>;
};

export type SignUpCredentialsInput = {
  password?: InputMaybe<PasswordSignUpInput>;
  wallet?: InputMaybe<WalletSignUpInput>;
};

export type SignUpInput = {
  credentials: SignUpCredentialsInput;
  method: PrimaryAuthMethod;
  reCaptchaToken: Scalars['String']['input'];
};

export type SignUpPayload = {
  __typename: 'SignUpPayload';
  address?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<UserError>>;
  method?: Maybe<PrimaryAuthMethod>;
  name?: Maybe<Scalars['String']['output']>;
};

export type SingleAaveV2AssetBorrowingData = {
  __typename: 'SingleAaveV2AssetBorrowingData';
  borrowingEnabled: Scalars['Boolean']['output'];
  collateralFactor: Scalars['Float']['output'];
  derivativeAddress: Scalars['Address']['output'];
  isFrozen: Scalars['Boolean']['output'];
  reserveSize?: Maybe<Scalars['Float']['output']>;
  totalApy: Scalars['Float']['output'];
  underlyingAssetAddress: Scalars['Address']['output'];
  usageAsCollateralEnabled: Scalars['Boolean']['output'];
};

export type SingleAaveV2AssetLendingData = {
  __typename: 'SingleAaveV2AssetLendingData';
  assetAddress: Scalars['Address']['output'];
  isFrozen: Scalars['Boolean']['output'];
  reserveSize?: Maybe<Scalars['Float']['output']>;
  totalApy: Scalars['Float']['output'];
  underlyingAddress: Scalars['Address']['output'];
};

export type SingleAaveV2DebtPositionData = {
  __typename: 'SingleAaveV2DebtPositionData';
  active: Scalars['Boolean']['output'];
  borrowedAssets: Array<AssetBalanceCurrency>;
  claimableRewardValue: Scalars['Float']['output'];
  collateralAssets: Array<AssetBalanceCurrency>;
  currentBorrowValue: Scalars['Float']['output'];
  currentLoanToValueRatio?: Maybe<Scalars['Float']['output']>;
  externalPositionAddress: Scalars['Address']['output'];
  maxDecreaseInCollateral: Scalars['Float']['output'];
  maxIncreaseInBorrow: Scalars['Float']['output'];
  maxLoanToValueRatio?: Maybe<Scalars['Float']['output']>;
  maxSafeDecreaseInCollateral: Scalars['Float']['output'];
  maxSafeIncreaseInBorrow: Scalars['Float']['output'];
  rewardToken?: Maybe<Scalars['Address']['output']>;
  safeLoanToValueRatio: Scalars['Float']['output'];
  tokensWithRewardsToClaim: Array<Scalars['Address']['output']>;
  weightedApy?: Maybe<Scalars['Float']['output']>;
};

export type SingleAaveV3AssetBorrowingData = {
  __typename: 'SingleAaveV3AssetBorrowingData';
  borrowingEnabled: Scalars['Boolean']['output'];
  collateralFactor: Scalars['Float']['output'];
  derivativeAddress: Scalars['Address']['output'];
  eModeCategoryId?: Maybe<Scalars['Int']['output']>;
  isFrozen: Scalars['Boolean']['output'];
  reserveSize?: Maybe<Scalars['Float']['output']>;
  totalApy: Scalars['Float']['output'];
  underlyingAssetAddress: Scalars['Address']['output'];
  usageAsCollateralEnabled: Scalars['Boolean']['output'];
};

export type SingleAaveV3AssetLendingData = {
  __typename: 'SingleAaveV3AssetLendingData';
  assetAddress: Scalars['Address']['output'];
  isFrozen: Scalars['Boolean']['output'];
  isSupplyCapReached: Scalars['Boolean']['output'];
  reserveSize?: Maybe<Scalars['Float']['output']>;
  totalApy: Scalars['Float']['output'];
  underlyingAddress: Scalars['Address']['output'];
};

export type SingleAaveV3DebtPositionData = {
  __typename: 'SingleAaveV3DebtPositionData';
  active: Scalars['Boolean']['output'];
  borrowedAssets: Array<AssetBalanceCurrency>;
  collateralAssets: Array<AssetBalanceCurrency>;
  currentBorrowValue: Scalars['Float']['output'];
  currentLoanToValueRatio?: Maybe<Scalars['Float']['output']>;
  eModeCategoryId: Scalars['Int']['output'];
  eModeCategoryLabel: Scalars['String']['output'];
  externalPositionAddress: Scalars['Address']['output'];
  healthFactor?: Maybe<Scalars['Float']['output']>;
  maxDecreaseInCollateral: Scalars['Float']['output'];
  maxIncreaseInBorrow: Scalars['Float']['output'];
  maxLoanToValueRatio?: Maybe<Scalars['Float']['output']>;
  maxSafeDecreaseInCollateral: Scalars['Float']['output'];
  maxSafeIncreaseInBorrow: Scalars['Float']['output'];
  safeLoanToValueRatio: Scalars['Float']['output'];
  weightedApy?: Maybe<Scalars['Float']['output']>;
};

export type SingleArbitraryLoanBasicData = {
  __typename: 'SingleArbitraryLoanBasicData';
  amountToRepay: Scalars['BigInt']['output'];
  borrowableAmount: Scalars['BigInt']['output'];
  borrowedAmount: Scalars['BigInt']['output'];
  borrower: Scalars['Address']['output'];
  description: Scalars['String']['output'];
  externalPosition: Scalars['Address']['output'];
  loanAsset: Scalars['Address']['output'];
  status: ArbitraryLoanStatus;
  totalRepaid: Scalars['BigInt']['output'];
};

export type SingleBalancerPoolData = {
  __typename: 'SingleBalancerPoolData';
  depositDisabled: Scalars['Boolean']['output'];
  gaugeAddress?: Maybe<Scalars['Address']['output']>;
  poolAddress: Scalars['Address']['output'];
  poolId: Scalars['String']['output'];
  poolSize?: Maybe<Scalars['Float']['output']>;
};

export type SingleBalancerPoolPositionData = {
  __typename: 'SingleBalancerPoolPositionData';
  assetBalance: AssetBalanceCurrency;
  depositDisabled: Scalars['Boolean']['output'];
  gaugeAddress?: Maybe<Scalars['Address']['output']>;
  isGauge: Scalars['Boolean']['output'];
  poolAddress: Scalars['Address']['output'];
  poolId: Scalars['String']['output'];
};

export type SingleBorrowerArbitraryLoanBasicData = {
  __typename: 'SingleBorrowerArbitraryLoanBasicData';
  amountToRepay: Scalars['BigInt']['output'];
  borrowableAmount: Scalars['BigInt']['output'];
  borrowedAmount: Scalars['BigInt']['output'];
  description: Scalars['String']['output'];
  externalPosition: Scalars['Address']['output'];
  loanAsset: Scalars['Address']['output'];
  status: ArbitraryLoanStatus;
  totalRepaid: Scalars['BigInt']['output'];
  vaultAddress: Scalars['Address']['output'];
  vaultName: Scalars['String']['output'];
};

export type SingleCompoundAssetV3PositionLendingData = {
  __typename: 'SingleCompoundAssetV3PositionLendingData';
  asset: AssetBalanceCurrency;
  baseApy: Scalars['Float']['output'];
  isLendDisabled: Scalars['Boolean']['output'];
  rewardApys: Array<RewardApy>;
  totalApy: Scalars['Float']['output'];
};

export type SingleCompoundV2AssetBorrowingData = {
  __typename: 'SingleCompoundV2AssetBorrowingData';
  apyCurrent: Scalars['Float']['output'];
  collateralFactor: Scalars['Float']['output'];
  derivativeAddress: Scalars['Address']['output'];
  rewardApy: Scalars['Float']['output'];
  rewardAssetAddress: Scalars['Address']['output'];
  totalApy: Scalars['Float']['output'];
  totalSupply?: Maybe<Scalars['Float']['output']>;
  underlyingAssetAddress: Scalars['Address']['output'];
  usageAsCollateralEnabled: Scalars['Boolean']['output'];
};

export type SingleCompoundV2AssetLendingData = {
  __typename: 'SingleCompoundV2AssetLendingData';
  assetAddress: Scalars['Address']['output'];
  baseApy: Scalars['Float']['output'];
  isMintPaused: Scalars['Boolean']['output'];
  rewardApys: Array<RewardApy>;
  totalApy: Scalars['Float']['output'];
  totalSupply?: Maybe<Scalars['Float']['output']>;
  underlyingAddress: Scalars['Address']['output'];
};

export type SingleCompoundV2DebtPositionData = {
  __typename: 'SingleCompoundV2DebtPositionData';
  active: Scalars['Boolean']['output'];
  borrowedAssets: Array<CompoundV2AssetBalance>;
  claimableRewardValue: Scalars['Float']['output'];
  collateralAssets: Array<CompoundV2AssetBalance>;
  currentBorrowValue: Scalars['Float']['output'];
  currentLoanToValueRatio?: Maybe<Scalars['Float']['output']>;
  externalPositionAddress: Scalars['Address']['output'];
  maxDecreaseInCollateral: Scalars['Float']['output'];
  maxIncreaseInBorrow: Scalars['Float']['output'];
  maxLoanToValueRatio?: Maybe<Scalars['Float']['output']>;
  maxSafeDecreaseInCollateral: Scalars['Float']['output'];
  maxSafeIncreaseInBorrow: Scalars['Float']['output'];
  rewardToken?: Maybe<Scalars['Address']['output']>;
  safeLoanToValueRatio: Scalars['Float']['output'];
  weightedApy?: Maybe<Scalars['Float']['output']>;
};

export type SingleCompoundV3AssetLendingData = {
  __typename: 'SingleCompoundV3AssetLendingData';
  assetAddress: Scalars['Address']['output'];
  baseApy: Scalars['Float']['output'];
  rewardApys: Array<RewardApy>;
  totalApy: Scalars['Float']['output'];
  totalSupply: Scalars['Float']['output'];
  underlyingAsset: AssetBalanceCurrency;
};

export type SingleErc4626AssetLendingData = {
  __typename: 'SingleERC4626AssetLendingData';
  assetAddress: Scalars['Address']['output'];
  isPaused: Scalars['Boolean']['output'];
  totalApy: Scalars['Float']['output'];
  underlyingAsset: AssetBalanceCurrency;
};

export type SingleErc4626AssetPositionLendingData = {
  __typename: 'SingleERC4626AssetPositionLendingData';
  asset: AssetBalanceCurrency;
  isPaused: Scalars['Boolean']['output'];
  totalApy: Scalars['Float']['output'];
  underlyingAddress: Scalars['Address']['output'];
};

export type SingleIdleAssetLendingData = {
  __typename: 'SingleIdleAssetLendingData';
  assetAddress: Scalars['Address']['output'];
  baseApy: Scalars['Float']['output'];
  rewardApys: Array<RewardApy>;
  totalApy: Scalars['Float']['output'];
  tvl?: Maybe<Scalars['Float']['output']>;
  underlyingAddress: Scalars['Address']['output'];
};

export type SingleMapleAssetLendingData = {
  __typename: 'SingleMapleAssetLendingData';
  liquidityAssetAddress: Scalars['Address']['output'];
  maxDeposit: Scalars['BigInt']['output'];
  poolAddress: Scalars['Address']['output'];
  poolName: Scalars['String']['output'];
  poolSize?: Maybe<Scalars['Float']['output']>;
  totalApy: Scalars['Float']['output'];
  totalAssets: Scalars['BigInt']['output'];
  totalShares: Scalars['BigInt']['output'];
  totalUnrealizedLosses: Scalars['BigInt']['output'];
};

export type SingleMaplePoolPositionData = {
  __typename: 'SingleMaplePoolPositionData';
  exitWithdrawalWindowEnd: Scalars['Int']['output'];
  exitWithdrawalWindowStart: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  liquidityAsset: MapleAsset;
  lockedShares: Scalars['BigInt']['output'];
  maxDeposit: Scalars['BigInt']['output'];
  newWithdrawalRequestWindowEnd: Scalars['Int']['output'];
  newWithdrawalRequestWindowStart: Scalars['Int']['output'];
  pool: Scalars['Address']['output'];
  poolName: Scalars['String']['output'];
  redeemableShares: Scalars['BigInt']['output'];
  redeemableWithdrawalAmount: Scalars['BigInt']['output'];
  requestedWithdrawalAmount: Scalars['BigInt']['output'];
  shares: Scalars['BigInt']['output'];
  status: MapleStatus;
  totalAssets: Scalars['BigInt']['output'];
  totalShares: Scalars['BigInt']['output'];
  totalUnrealizedLosses: Scalars['BigInt']['output'];
  unrealizedLosses: Scalars['BigInt']['output'];
};

export type SingleMaplePositionData = {
  __typename: 'SingleMaplePositionData';
  active: Scalars['Boolean']['output'];
  claimableRewards: Scalars['BigInt']['output'];
  externalPositionAddress: Scalars['Address']['output'];
  externalPositionIndex: Scalars['Int']['output'];
  pools: Array<SingleMaplePoolPositionData>;
};

export type SinglePendleV2PositionData = {
  __typename: 'SinglePendleV2PositionData';
  active: Scalars['Boolean']['output'];
  externalPositionAddress: Scalars['Address']['output'];
  externalPositionIndex: Scalars['Int']['output'];
  lpHoldings: Array<LpHoldingPendleV2>;
  ptHoldings: Array<PtHoldingPendleV2>;
  rewards: MarketRewardsPendleV2;
};

export type SinglePoolData = {
  __typename: 'SinglePoolData';
  address: Scalars['Address']['output'];
  baseApy: Scalars['Float']['output'];
  poolSize: Scalars['Float']['output'];
  protocol: Protocol;
  rewardApys: Array<RewardApy>;
  totalApy: Scalars['Float']['output'];
};

export type SingleTheGraphDelegationPositionData = {
  __typename: 'SingleTheGraphDelegationPositionData';
  active: Scalars['Boolean']['output'];
  defaultDisplayName?: Maybe<Scalars['String']['output']>;
  delegatedAmount: Scalars['BigInt']['output'];
  externalPosition: Scalars['Address']['output'];
  externalPositionIndex: Scalars['Int']['output'];
  indexer: Scalars['Address']['output'];
  shares: Scalars['BigInt']['output'];
  unbondingAmount: Scalars['BigInt']['output'];
  unbondingEndEstimatedTime: Scalars['Int']['output'];
  unlockedAmount: Scalars['BigInt']['output'];
};

export type SingleYearnAssetLendingData = {
  __typename: 'SingleYearnAssetLendingData';
  apy?: Maybe<Scalars['Float']['output']>;
  assetAddress: Scalars['Address']['output'];
  tvl?: Maybe<Scalars['Float']['output']>;
  underlyingAddress: Scalars['Address']['output'];
};

export type StakeWiseExitRequest = {
  __typename: 'StakeWiseExitRequest';
  claimable: Scalars['Boolean']['output'];
  exitQueueIndex: Scalars['BigInt']['output'];
  positionTicket: Scalars['BigInt']['output'];
  shares: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
};

export type StakeWiseVaultData = {
  __typename: 'StakeWiseVaultData';
  externalPositionHolding?: Maybe<StakeWiseVaultHoldingData>;
  fallbackExternalPosition?: Maybe<Scalars['Address']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  stakeWiseVaultAddress: Scalars['Address']['output'];
  totalApy: Scalars['Float']['output'];
  totalValueLocked?: Maybe<Scalars['BigInt']['output']>;
  totalValueLockedCurrencyValue?: Maybe<Scalars['Float']['output']>;
  underlyingAddress: Scalars['Address']['output'];
};

export type StakeWiseVaultHoldingData = {
  __typename: 'StakeWiseVaultHoldingData';
  active: Scalars['Boolean']['output'];
  claimableShares: Scalars['Float']['output'];
  exitRequests: Array<StakeWiseExitRequest>;
  externalPositionAddress: Scalars['Address']['output'];
  shares: Scalars['BigInt']['output'];
  sharesCurrencyValue?: Maybe<Scalars['Float']['output']>;
  stakedEthAmount: Scalars['BigInt']['output'];
  unclaimableShares: Scalars['Float']['output'];
};

export type SubmitDepositorSignatureInput = {
  network: Network;
  signature: Scalars['String']['input'];
  vaultAddress: Scalars['Address']['input'];
  walletAddress: Scalars['Address']['input'];
};

export type SubmitDepositorSignaturePayload = {
  __typename: 'SubmitDepositorSignaturePayload';
  errors?: Maybe<Array<UserError>>;
  vaultDepositorSignature: VaultDepositorSignature;
};

export type SwapExactTokenForPtPendleV2Data = {
  __typename: 'SwapExactTokenForPtPendleV2Data';
  amountMinOut: Scalars['BigInt']['output'];
  guessOut: GuessOutPendleV2;
  priceImpact: Scalars['Float']['output'];
};

export type TheGraphDelegationIndexerInfoData = {
  __typename: 'TheGraphDelegationIndexerInfoData';
  availableCapacity?: Maybe<Scalars['BigInt']['output']>;
  defaultDisplayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Address']['output']>;
};

export type TheGraphDelegationInfoData = {
  __typename: 'TheGraphDelegationInfoData';
  delegationTaxPercentage: Scalars['Int']['output'];
};

export type TheGraphDelegationPortfolioAsset = {
  __typename: 'TheGraphDelegationPortfolioAsset';
  address: Scalars['Address']['output'];
  amount: Scalars['BigInt']['output'];
  currencyValue?: Maybe<Scalars['Float']['output']>;
};

export type TheGraphDelegationSharesToTokensData = {
  __typename: 'TheGraphDelegationSharesToTokensData';
  tokens: Scalars['BigInt']['output'];
};

export type TheGraphDelegationTokensToSharesData = {
  __typename: 'TheGraphDelegationTokensToSharesData';
  shares: Scalars['BigInt']['output'];
};

export type ThreeOneThirdAssetToTrade = {
  __typename: 'ThreeOneThirdAssetToTrade';
  allocation?: Maybe<Scalars['Float']['output']>;
  assetBalance: AssetBalanceObligatoryCurrency;
  isTracked: Scalars['Boolean']['output'];
  price: Scalars['Float']['output'];
};

export type ThreeOneThirdRebalanceData = {
  __typename: 'ThreeOneThirdRebalanceData';
  estimatedReceiveValue: Scalars['Float']['output'];
  expirationTimestamp: Scalars['Int']['output'];
  minReceivedValue: Scalars['Float']['output'];
  sellValue: Scalars['Float']['output'];
  trades: Array<ThreeOneThirdTradeData>;
};

export type ThreeOneThirdRebalanceResponse = ErrorResponse | ThreeOneThirdRebalanceData;

export type ThreeOneThirdTradeData = {
  __typename: 'ThreeOneThirdTradeData';
  data: Scalars['BytesLike']['output'];
  exchangeName: Scalars['String']['output'];
  fromAssetBalance: AssetBalanceObligatoryCurrency;
  minToReceiveBeforeFees: Scalars['BigInt']['output'];
  signature: Scalars['BytesLike']['output'];
  toAssetBalance: AssetBalanceObligatoryCurrency;
};

export type TokenPriceInNativeCurrency = {
  __typename: 'TokenPriceInNativeCurrency';
  amount?: Maybe<Scalars['BigInt']['output']>;
  bestRoute?: Maybe<Scalars['String']['output']>;
  exchange: Exchange;
  fastTransactionRequired?: Maybe<Scalars['Boolean']['output']>;
  fromUsd?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  toUsd?: Maybe<Scalars['Float']['output']>;
};

export type TokenSwapAndReferencePrices = {
  __typename: 'TokenSwapAndReferencePrices';
  currentCumulativeSlippagePolicy: CumulativeSlippagePolicy;
  outgoingValueInCurrency: Scalars['Float']['output'];
  referencePrice: ReferencePrice;
  tokenSwapPrices: Array<TokenSwapPrices>;
};

export type TokenSwapPrice = {
  __typename: 'TokenSwapPrice';
  amount?: Maybe<Scalars['BigInt']['output']>;
  bestRoute?: Maybe<Scalars['String']['output']>;
  exchange: Exchange;
  fastTransactionRequired?: Maybe<Scalars['Boolean']['output']>;
  fromUsd?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  toUsd?: Maybe<Scalars['Float']['output']>;
};

export type TokenSwapPrices = {
  __typename: 'TokenSwapPrices';
  amount?: Maybe<Scalars['BigInt']['output']>;
  bestRoute?: Maybe<Scalars['String']['output']>;
  exchange: Exchange;
  fastTransactionRequired?: Maybe<Scalars['Boolean']['output']>;
  fromUsd?: Maybe<Scalars['Float']['output']>;
  incomingValueInCurrency?: Maybe<Scalars['Float']['output']>;
  isBadTrade?: Maybe<Scalars['Boolean']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  slippage?: Maybe<Scalars['Float']['output']>;
  status: Scalars['String']['output'];
  toUsd?: Maybe<Scalars['Float']['output']>;
};

export type TokenSwapTransaction = {
  __typename: 'TokenSwapTransaction';
  reason?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  transaction?: Maybe<Transaction>;
};

export type Transaction = {
  __typename: 'Transaction';
  data: Scalars['BytesLike']['output'];
  to: Scalars['Address']['output'];
  value?: Maybe<Scalars['BigInt']['output']>;
};

export enum TroveStatus {
  ACTIVE = 'ACTIVE',
  CLOSED_BY_LIQUIDATION = 'CLOSED_BY_LIQUIDATION',
  CLOSED_BY_OWNER = 'CLOSED_BY_OWNER',
  CLOSED_BY_REDEMPTION = 'CLOSED_BY_REDEMPTION',
  NON_EXISTENT = 'NON_EXISTENT'
}

export type UniswapV3FeeTierDistribution = {
  __typename: 'UniswapV3FeeTierDistribution';
  feeTier: Scalars['Float']['output'];
  id: Scalars['Address']['output'];
  percentage?: Maybe<Scalars['Float']['output']>;
};

export type UniswapV3Nft = {
  __typename: 'UniswapV3Nft';
  amount0: Scalars['BigInt']['output'];
  amount1: Scalars['BigInt']['output'];
  externalPositionId?: Maybe<Scalars['Address']['output']>;
  feeTier: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  liquidity: Scalars['BigInt']['output'];
  nftTokenUri: Scalars['String']['output'];
  pendingFees0?: Maybe<Scalars['BigInt']['output']>;
  pendingFees1?: Maybe<Scalars['BigInt']['output']>;
  poolAddress: Scalars['Address']['output'];
  poolCurrentTick?: Maybe<Scalars['Int']['output']>;
  poolLiquidity?: Maybe<Scalars['BigInt']['output']>;
  tickLower: Scalars['Int']['output'];
  tickUpper: Scalars['Int']['output'];
  token0: Scalars['Address']['output'];
  token1: Scalars['Address']['output'];
};

export type UniswapV3PoolDetails = {
  __typename: 'UniswapV3PoolDetails';
  currentTick: Scalars['Int']['output'];
  ticks: Array<UniswapV3Tick>;
};

export type UniswapV3Tick = {
  __typename: 'UniswapV3Tick';
  liquidityNet: Scalars['BigInt']['output'];
  tickIdx: Scalars['Int']['output'];
};

export type UniswapV3TokenAmounts = {
  __typename: 'UniswapV3TokenAmounts';
  amount0: Scalars['BigInt']['output'];
  amount1: Scalars['BigInt']['output'];
};

export type UniswapV3VaultLiquidityPosition = {
  __typename: 'UniswapV3VaultLiquidityPosition';
  externalPositionId?: Maybe<Scalars['Address']['output']>;
  nfts: Array<UniswapV3Nft>;
};

export type UpdateAccountProfileInput = {
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  telegram?: InputMaybe<Scalars['String']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAccountProfilePayload = {
  __typename: 'UpdateAccountProfilePayload';
  accountProfile?: Maybe<AccountProfile>;
  errors?: Maybe<Array<UserError>>;
};

export type UpdateApiTokenInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  reCaptchaToken: Scalars['String']['input'];
  /** The token that is requested by the `requestAuthToken` mutation */
  token: Scalars['String']['input'];
};

export type UpdateApiTokenPayload = {
  __typename: 'UpdateApiTokenPayload';
  account?: Maybe<Account>;
  errors?: Maybe<Array<UserError>>;
  token?: Maybe<Scalars['String']['output']>;
};

export type UpdateEmailCompleteInput = {
  token: Scalars['String']['input'];
};

export type UpdateEmailCompletePayload = {
  __typename: 'UpdateEmailCompletePayload';
  account?: Maybe<Account>;
  errors?: Maybe<Array<UserError>>;
};

export type UpdateEmailInput = {
  email: Scalars['String']['input'];
  reCaptchaToken: Scalars['String']['input'];
  /** The token that is requested by the `requestAuthToken` mutation */
  token: Scalars['String']['input'];
};

export type UpdateEmailPayload = {
  __typename: 'UpdateEmailPayload';
  account?: Maybe<Account>;
  errors?: Maybe<Array<UserError>>;
};

export type UpdateUserRepresentativeApplicationInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  experience?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  miscExperience?: InputMaybe<Scalars['String']['input']>;
  motivation?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserRepresentativeApplicationPayload = {
  __typename: 'UpdateUserRepresentativeApplicationPayload';
  errors?: Maybe<Array<UserError>>;
  userRepresentativeApplication?: Maybe<UserRepresentativeApplication>;
};

export type UpdateUserRepresentativeApplicationStatusInput = {
  id: Scalars['ID']['input'];
  status: UserRepresentativeApplicationStatus;
};

export type UpdateUserRepresentativeApplicationStatusPayload = {
  __typename: 'UpdateUserRepresentativeApplicationStatusPayload';
  errors?: Maybe<Array<UserError>>;
  userRepresentativeApplication?: Maybe<UserRepresentativeApplication>;
};

export type UpdateUserRepresentativeElectionInput = {
  applyingEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  applyingStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  votingEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  votingStartDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateUserRepresentativeElectionPayload = {
  __typename: 'UpdateUserRepresentativeElectionPayload';
  errors?: Maybe<Array<UserError>>;
  userRepresentativeElection?: Maybe<UserRepresentativeElection>;
};

export type UpdateVaultProfileInput = {
  contactInfo?: InputMaybe<Scalars['String']['input']>;
  depositorSignatureMessage?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  managerDescription?: InputMaybe<Scalars['String']['input']>;
  network: Deployment;
  requireDepositorSignature?: InputMaybe<Scalars['Boolean']['input']>;
  tagline?: InputMaybe<Scalars['String']['input']>;
  telegram?: InputMaybe<Scalars['String']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  vaultAddress: Scalars['Address']['input'];
  vaultStrategies?: InputMaybe<Array<Scalars['ID']['input']>>;
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateVaultProfilePayload = {
  __typename: 'UpdateVaultProfilePayload';
  errors?: Maybe<Array<UserError>>;
  vaultProfile?: Maybe<VaultProfile>;
};

export type UpdateWalletClaimInput = {
  id: Scalars['ID']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWalletClaimPayload = {
  __typename: 'UpdateWalletClaimPayload';
  errors?: Maybe<Array<UserError>>;
  walletClaim?: Maybe<WalletClaim>;
};

export type UpdateWhiteLabelCoverInput = {
  file: Scalars['Upload']['input'];
  id: Scalars['String']['input'];
};

export type UpdateWhiteLabelCoverPayload = {
  __typename: 'UpdateWhiteLabelCoverPayload';
  account?: Maybe<Account>;
  errors?: Maybe<Array<UserError>>;
  whiteLabel?: Maybe<WhiteLabel>;
};

export type UpdateWhiteLabelInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  network: Deployment;
  subdomain: Scalars['String']['input'];
  vaultAddresses: Array<Scalars['String']['input']>;
};

export type UpdateWhiteLabelLogoInput = {
  file: Scalars['Upload']['input'];
  id: Scalars['String']['input'];
};

export type UpdateWhiteLabelLogoPayload = {
  __typename: 'UpdateWhiteLabelLogoPayload';
  account?: Maybe<Account>;
  errors?: Maybe<Array<UserError>>;
  whiteLabel?: Maybe<WhiteLabel>;
};

export type UpdateWhiteLabelPayload = {
  __typename: 'UpdateWhiteLabelPayload';
  account?: Maybe<Account>;
  errors?: Maybe<Array<UserError>>;
  whiteLabel?: Maybe<WhiteLabel>;
};

export type UpdateWhiteLabelThemeInput = {
  colorScheme?: InputMaybe<ColorScheme>;
  id: Scalars['String']['input'];
  primaryColor?: InputMaybe<Scalars['String']['input']>;
  secondaryColor?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWhiteLabelThemePayload = {
  __typename: 'UpdateWhiteLabelThemePayload';
  account?: Maybe<Account>;
  errors?: Maybe<Array<UserError>>;
  whiteLabel?: Maybe<WhiteLabel>;
};

export type UserError = {
  message: Scalars['String']['output'];
};

export type UserRepresentativeApplication = {
  __typename: 'UserRepresentativeApplication';
  account: Account;
  aumPercentage: Scalars['Float']['output'];
  election: UserRepresentativeElection;
  email?: Maybe<Scalars['String']['output']>;
  experience: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  miscExperience: Scalars['String']['output'];
  motivation: Scalars['String']['output'];
  name: Scalars['String']['output'];
  status: UserRepresentativeApplicationStatus;
  votes: Array<UserRepresentativeVote>;
};

export enum UserRepresentativeApplicationStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

export type UserRepresentativeElection = {
  __typename: 'UserRepresentativeElection';
  applications: Array<UserRepresentativeApplication>;
  applyingEndDate: Scalars['DateTime']['output'];
  applyingStartDate: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  eligible: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  votes: Array<UserRepresentativeVote>;
  votingEndDate: Scalars['DateTime']['output'];
  votingStartDate: Scalars['DateTime']['output'];
};

export type UserRepresentativeVote = {
  __typename: 'UserRepresentativeVote';
  account: Account;
  application: UserRepresentativeApplication;
  createdAt: Scalars['DateTime']['output'];
  election: UserRepresentativeElection;
  id: Scalars['ID']['output'];
};

export type Vault = {
  __typename: 'Vault';
  address: Scalars['Address']['output'];
  id: Scalars['ID']['output'];
  vaultProfile?: Maybe<VaultProfile>;
};

export type VaultBalance = {
  __typename: 'VaultBalance';
  asset: Scalars['Address']['output'];
  balance: Scalars['Float']['output'];
  price: Scalars['Float']['output'];
  timestamp: Scalars['DateTime']['output'];
  tracked: Scalars['Boolean']['output'];
  valid: Scalars['Boolean']['output'];
  value: Scalars['Float']['output'];
};

export type VaultDepositorMetrics = {
  __typename: 'VaultDepositorMetrics';
  amountDeposited?: Maybe<Scalars['Float']['output']>;
  amountRedeemed?: Maybe<Scalars['Float']['output']>;
  costBaseFifo?: Maybe<Scalars['Float']['output']>;
  costBaseLifo?: Maybe<Scalars['Float']['output']>;
  currentShares?: Maybe<Scalars['Float']['output']>;
  currentValue?: Maybe<Scalars['Float']['output']>;
  sharesBought?: Maybe<Scalars['Float']['output']>;
  sharesReceivedFromFees?: Maybe<Scalars['Float']['output']>;
  sharesRedeemed?: Maybe<Scalars['Float']['output']>;
  sharesTransferredIn?: Maybe<Scalars['Float']['output']>;
  sharesTransferredOut?: Maybe<Scalars['Float']['output']>;
  totalReturn?: Maybe<Scalars['Float']['output']>;
};

export type VaultDepositorSignature = {
  __typename: 'VaultDepositorSignature';
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  signature: Scalars['String']['output'];
  vault: Vault;
  vaultId: Scalars['String']['output'];
  wallet: Wallet;
  walletId: Scalars['String']['output'];
};

export type VaultListForDepositorAggregated = {
  __typename: 'VaultListForDepositorAggregated';
  avgPerformanceThisMonth: Scalars['Float']['output'];
  totalDepositorValue: Scalars['Float']['output'];
  totalVaultsDeposited: Scalars['Int']['output'];
};

export type VaultListForManagerAggregated = {
  __typename: 'VaultListForManagerAggregated';
  avgPerformanceThisMonth: Scalars['Float']['output'];
  totalGrossAssetValue: Scalars['Float']['output'];
  totalNumberOfDepositors: Scalars['Int']['output'];
};

export type VaultListItem = {
  __typename: 'VaultListItem';
  address: Scalars['Address']['output'];
  assetPercentages?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  assets?: Maybe<Array<Maybe<Scalars['Address']['output']>>>;
  depositorValue?: Maybe<Scalars['Float']['output']>;
  gavCurrent?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nsvCurrent?: Maybe<Scalars['Float']['output']>;
  numberOfAssets?: Maybe<Scalars['Int']['output']>;
  numberOfDepositors?: Maybe<Scalars['Int']['output']>;
  performance24h?: Maybe<Scalars['Float']['output']>;
  performanceSinceInception?: Maybe<Scalars['Float']['output']>;
  performanceThisMonth?: Maybe<Scalars['Float']['output']>;
  performanceThisQuarter?: Maybe<Scalars['Float']['output']>;
  shareBalance?: Maybe<Scalars['Float']['output']>;
  supply?: Maybe<Scalars['Float']['output']>;
  valid?: Maybe<Scalars['Boolean']['output']>;
};

export enum VaultListOrderBy {
  LATEST_VAULTS = 'LATEST_VAULTS',
  MOST_POPULAR = 'MOST_POPULAR',
  TOP_GAINERS = 'TOP_GAINERS'
}

export type VaultMonthlyReturn = {
  __typename: 'VaultMonthlyReturn';
  month: Scalars['Int']['output'];
  monthlyReturn: Scalars['Float']['output'];
  valid: Scalars['Boolean']['output'];
  year: Scalars['Int']['output'];
  yearMonth?: Maybe<Scalars['Int']['output']>;
};

export type VaultPerformanceLatest = {
  __typename: 'VaultPerformanceLatest';
  firstDepositDate?: Maybe<Scalars['DateTime']['output']>;
  grossAssetValue?: Maybe<Scalars['Float']['output']>;
  netAssetValue?: Maybe<Scalars['Float']['output']>;
  netShareValue?: Maybe<Scalars['Float']['output']>;
  performance24h?: Maybe<Scalars['Float']['output']>;
  performanceMtd?: Maybe<Scalars['Float']['output']>;
  performanceQtd?: Maybe<Scalars['Float']['output']>;
  performanceSinceInception?: Maybe<Scalars['Float']['output']>;
  performanceYtd?: Maybe<Scalars['Float']['output']>;
  supply?: Maybe<Scalars['Float']['output']>;
  valid: Scalars['Boolean']['output'];
};

export type VaultProfile = {
  __typename: 'VaultProfile';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  contactInfo?: Maybe<Scalars['String']['output']>;
  depositorSignatureMessage?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  managerDescription?: Maybe<Scalars['String']['output']>;
  requireDepositorSignature: Scalars['Boolean']['output'];
  tagline?: Maybe<Scalars['String']['output']>;
  telegram?: Maybe<Scalars['String']['output']>;
  twitter?: Maybe<Scalars['String']['output']>;
  vault: Vault;
  vaultStrategies: Array<VaultStrategy>;
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export type VaultRiskMetrics = {
  __typename: 'VaultRiskMetrics';
  annualizedSharpeRatio?: Maybe<Scalars['Float']['output']>;
  annualizedVolatility?: Maybe<Scalars['Float']['output']>;
  averageDailyReturn?: Maybe<Scalars['Float']['output']>;
  dailyVolatility?: Maybe<Scalars['Float']['output']>;
  numberOfDays: Scalars['Int']['output'];
};

export type VaultStrategy = {
  __typename: 'VaultStrategy';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type VoteUserRepresentativeInput = {
  applicationId: Scalars['String']['input'];
  electionId: Scalars['String']['input'];
  network: Deployment;
};

export type VoteUserRepresentativePayload = {
  __typename: 'VoteUserRepresentativePayload';
  errors?: Maybe<Array<UserError>>;
  userRepresentativeVote?: Maybe<UserRepresentativeVote>;
};

export type VotiumClaim = {
  __typename: 'VotiumClaim';
  amount: Scalars['String']['output'];
  index: Scalars['Float']['output'];
  merkleProof: Array<Scalars['String']['output']>;
  token: Scalars['Address']['output'];
};

export type Wallet = {
  __typename: 'Wallet';
  address: Scalars['Address']['output'];
  id: Scalars['ID']['output'];
};

export type WalletClaim = {
  __typename: 'WalletClaim';
  account?: Maybe<Account>;
  /**
   *
   * - `true`: Current signed in user has this wallet as sign in method configured.
   * - `false`: Another user has this wallet as sign in method configured.
   * - `null`: This wallet has not been configured as sign in method.
   *
   */
  auth?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  wallet: Wallet;
  walletClaimMessage?: Maybe<WalletClaimMessage>;
};


export type WalletClaimAuthArgs = {
  network: Network;
};

export type WalletClaimMessage = {
  __typename: 'WalletClaimMessage';
  accountId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  messageHash?: Maybe<Scalars['BytesLike']['output']>;
  signature?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['Int']['output'];
  walletAddress: Scalars['Address']['output'];
  walletClaim?: Maybe<WalletClaim>;
};

export type WalletConnectInput = {
  address: Scalars['Address']['input'];
  network: Network;
  signature: Scalars['String']['input'];
  timestamp: Scalars['Int']['input'];
  walletClaimId?: InputMaybe<Scalars['String']['input']>;
};

export type WalletDisconnectInput = {
  address: Scalars['Address']['input'];
};

export type WalletSignInInput = {
  address: Scalars['Address']['input'];
  network: Network;
  signature: Scalars['String']['input'];
  timestamp: Scalars['Int']['input'];
};

export type WalletSignUpInput = {
  address: Scalars['Address']['input'];
  network: Network;
  signature: Scalars['String']['input'];
  timestamp: Scalars['Int']['input'];
};

export type WhiteLabel = {
  __typename: 'WhiteLabel';
  id: Scalars['ID']['output'];
  isOwner: Scalars['Boolean']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  profile?: Maybe<WhiteLabelProfile>;
  settings?: Maybe<WhiteLabelSettings>;
  vaults: Array<Vault>;
};

export type WhiteLabelProfile = {
  __typename: 'WhiteLabelProfile';
  contactInfo?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type WhiteLabelSettings = {
  __typename: 'WhiteLabelSettings';
  colorScheme?: Maybe<ColorScheme>;
  cover?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  primaryColor?: Maybe<Scalars['String']['output']>;
  secondaryColor?: Maybe<Scalars['String']['output']>;
  subdomain: Scalars['String']['output'];
};

export enum KilnValidatorState {
  ACTIVE_EXITING = 'ACTIVE_EXITING',
  ACTIVE_ONGOING = 'ACTIVE_ONGOING',
  ACTIVE_SLASHED = 'ACTIVE_SLASHED',
  DEPOSIT_IN_PROGRESS = 'DEPOSIT_IN_PROGRESS',
  EXITED_SLASHED = 'EXITED_SLASHED',
  EXITED_UNSLASHED = 'EXITED_UNSLASHED',
  PENDING_INITIALIZED = 'PENDING_INITIALIZED',
  PENDING_QUEUED = 'PENDING_QUEUED',
  UNKNOWN = 'UNKNOWN',
  UNSTAKED = 'UNSTAKED',
  WITHDRAWAL_DONE = 'WITHDRAWAL_DONE',
  WITHDRAWAL_POSSIBLE = 'WITHDRAWAL_POSSIBLE'
}

export type LendingDataForAaveV2AssetsQueryVariables = Exact<{
  network: Deployment;
  currency: Currency;
}>;


export type LendingDataForAaveV2AssetsQuery = { __typename: 'Query', lendingDataForAaveV2Assets: Array<{ __typename: 'SingleAaveV2AssetLendingData', assetAddress: Address, underlyingAddress: Address, totalApy: number, reserveSize?: number | null, isFrozen: boolean }> };

export type AaveV2BorrowingRatesQueryVariables = Exact<{
  network: Deployment;
  currency: Currency;
}>;


export type AaveV2BorrowingRatesQuery = { __typename: 'Query', borrowingForAaveV2Assets?: Array<{ __typename: 'SingleAaveV2AssetBorrowingData', totalApy: number, derivativeAddress: Address, underlyingAssetAddress: Address, collateralFactor: number, borrowingEnabled: boolean, usageAsCollateralEnabled: boolean, reserveSize?: number | null, isFrozen: boolean }> | null };

export type RewardFragment = { __typename: 'Reward', assetAddress: Address, claimable: number, value: number };

export type RewardsForAaveV2QueryVariables = Exact<{
  address: Scalars['Address']['input'];
  currency: Currency;
  network: Deployment;
}>;


export type RewardsForAaveV2Query = { __typename: 'Query', rewardsForAaveV2?: { __typename: 'AaveV2RewardsData', globalRewards?: Array<{ __typename: 'Reward', assetAddress: Address, claimable: number, value: number }> | null, individualRewards?: Array<{ __typename: 'IndividualReward', address: Address, rewards: Array<{ __typename: 'Reward', assetAddress: Address, claimable: number, value: number }> }> | null } | null };

export type ExternalPositionsAaveV2DebtPositionsQueryVariables = Exact<{
  vaultId: Scalars['Address']['input'];
  currency: Currency;
  network: Deployment;
}>;


export type ExternalPositionsAaveV2DebtPositionsQuery = { __typename: 'Query', externalPositionsAaveV2DebtPositions: Array<{ __typename: 'SingleAaveV2DebtPositionData', claimableRewardValue: number, rewardToken?: Address | null, tokensWithRewardsToClaim: Array<Address>, active: boolean, externalPositionAddress: Address, currentBorrowValue: number, maxLoanToValueRatio?: number | null, currentLoanToValueRatio?: number | null, maxSafeDecreaseInCollateral: number, maxDecreaseInCollateral: number, maxSafeIncreaseInBorrow: number, maxIncreaseInBorrow: number, safeLoanToValueRatio: number, weightedApy?: number | null, collateralAssets: Array<{ __typename: 'AssetBalanceCurrency', address: Address, amount: string, currencyValue?: number | null }>, borrowedAssets: Array<{ __typename: 'AssetBalanceCurrency', address: Address, amount: string, currencyValue?: number | null }> }> };

export type ExternalPositionsAaveV2DebtPortfolioQueryVariables = Exact<{
  vaultId: Scalars['Address']['input'];
  currency: Scalars['CurrencyOrAddress']['input'];
  network: Deployment;
}>;


export type ExternalPositionsAaveV2DebtPortfolioQuery = { __typename: 'Query', externalPositionsAaveV2DebtPortfolio?: { __typename: 'ExternalPositionAaveV2DebtPortfolioData', netValue?: number | null, valueChange24h?: number | null, collateralAssets: Array<{ __typename: 'AssetBalanceCurrency', address: Address, amount: string, currencyValue?: number | null }>, borrowedAssets: Array<{ __typename: 'AssetBalanceCurrency', address: Address, amount: string, currencyValue?: number | null }> } | null };

export type LendingDataForAaveV3AssetsQueryVariables = Exact<{
  network: Deployment;
  currency: Currency;
}>;


export type LendingDataForAaveV3AssetsQuery = { __typename: 'Query', lendingDataForAaveV3Assets: Array<{ __typename: 'SingleAaveV3AssetLendingData', assetAddress: Address, underlyingAddress: Address, totalApy: number, reserveSize?: number | null, isFrozen: boolean, isSupplyCapReached: boolean }> };

export type AaveV3BorrowingRatesQueryVariables = Exact<{
  network: Deployment;
  currency: Currency;
}>;


export type AaveV3BorrowingRatesQuery = { __typename: 'Query', borrowingForAaveV3Assets?: Array<{ __typename: 'SingleAaveV3AssetBorrowingData', totalApy: number, derivativeAddress: Address, underlyingAssetAddress: Address, collateralFactor: number, borrowingEnabled: boolean, usageAsCollateralEnabled: boolean, reserveSize?: number | null, isFrozen: boolean, eModeCategoryId?: number | null }> | null };

export type ExternalPositionsAaveV3DebtPositionsQueryVariables = Exact<{
  vaultId: Scalars['Address']['input'];
  currency: Currency;
  network: Deployment;
}>;


export type ExternalPositionsAaveV3DebtPositionsQuery = { __typename: 'Query', externalPositionsAaveV3DebtPositions: Array<{ __typename: 'SingleAaveV3DebtPositionData', active: boolean, eModeCategoryId: number, eModeCategoryLabel: string, externalPositionAddress: Address, healthFactor?: number | null, currentBorrowValue: number, maxLoanToValueRatio?: number | null, currentLoanToValueRatio?: number | null, maxSafeDecreaseInCollateral: number, maxDecreaseInCollateral: number, maxSafeIncreaseInBorrow: number, maxIncreaseInBorrow: number, safeLoanToValueRatio: number, weightedApy?: number | null, collateralAssets: Array<{ __typename: 'AssetBalanceCurrency', address: Address, amount: string, currencyValue?: number | null }>, borrowedAssets: Array<{ __typename: 'AssetBalanceCurrency', address: Address, amount: string, currencyValue?: number | null }> }> };

export type ExternalPositionsAaveV3DebtPortfolioQueryVariables = Exact<{
  vaultId: Scalars['Address']['input'];
  currency: Scalars['CurrencyOrAddress']['input'];
  network: Deployment;
}>;


export type ExternalPositionsAaveV3DebtPortfolioQuery = { __typename: 'Query', externalPositionsAaveV3DebtPortfolio?: { __typename: 'ExternalPositionAaveV3DebtPortfolioData', netValue?: number | null, valueChange24h?: number | null, collateralAssets: Array<{ __typename: 'AssetBalanceCurrency', address: Address, amount: string, currencyValue?: number | null }>, borrowedAssets: Array<{ __typename: 'AssetBalanceCurrency', address: Address, amount: string, currencyValue?: number | null }> } | null };

export type EModeCategoriesQueryVariables = Exact<{
  network: Deployment;
}>;


export type EModeCategoriesQuery = { __typename: 'Query', eModeCategories: Array<{ __typename: 'EModeCategoriesAaveV3Data', id: number, label: string, assets: Array<Address> }> };

export type EModePositionLiquidationInfoQueryVariables = Exact<{
  externalPosition: Scalars['Address']['input'];
  network: Deployment;
  eModeCategory: Scalars['Int']['input'];
}>;


export type EModePositionLiquidationInfoQuery = { __typename: 'Query', eModePositionLiquidationInfo?: { __typename: 'EModePositionLiquidationInfoData', healthFactor?: number | null, maxLoanToValueRatio?: number | null } | null };

export type AccountInfoFragment = { __typename: 'Account', id: string, email?: string | null, features: Array<Feature>, accountRoles: Array<AccountRole>, accountProfile?: { __typename: 'AccountProfile', id: string, username?: string | null, name?: string | null, avatarUrl?: string | null, telegram?: string | null, twitter?: string | null, websiteUrl?: string | null } | null };

export type AccountAuthInfoFragment = { __typename: 'Account', id: string, auth?: { __typename: 'AccountAuth', authenticator: boolean, backupCodes: boolean, password: boolean, google?: { __typename: 'AccountAuthGoogle', email?: string | null } | null, wallets: Array<{ __typename: 'AccountAuthWallet', address: Address, id: string }> } | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename: 'Query', me?: { __typename: 'Account', id: string, email?: string | null, features: Array<Feature>, accountRoles: Array<AccountRole>, accountProfile?: { __typename: 'AccountProfile', id: string, username?: string | null, name?: string | null, avatarUrl?: string | null, telegram?: string | null, twitter?: string | null, websiteUrl?: string | null } | null } | null };

export type AccountAuthQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountAuthQuery = { __typename: 'Query', me?: { __typename: 'Account', id: string, auth?: { __typename: 'AccountAuth', authenticator: boolean, backupCodes: boolean, password: boolean, google?: { __typename: 'AccountAuthGoogle', email?: string | null } | null, wallets: Array<{ __typename: 'AccountAuthWallet', address: Address, id: string }> } | null } | null };

export type DeleteAccountMutationVariables = Exact<{
  input: DeleteAccountInput;
}>;


export type DeleteAccountMutation = { __typename: 'Mutation', deleteAccount: { __typename: 'DeleteAccountPayload', errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type UpdateEmailMutationVariables = Exact<{
  input: UpdateEmailInput;
}>;


export type UpdateEmailMutation = { __typename: 'Mutation', updateEmail: { __typename: 'UpdateEmailPayload', account?: { __typename: 'Account', id: string, email?: string | null, features: Array<Feature>, accountRoles: Array<AccountRole>, accountProfile?: { __typename: 'AccountProfile', id: string, username?: string | null, name?: string | null, avatarUrl?: string | null, telegram?: string | null, twitter?: string | null, websiteUrl?: string | null } | null } | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type UpdateEmailCompleteMutationVariables = Exact<{
  input: UpdateEmailCompleteInput;
}>;


export type UpdateEmailCompleteMutation = { __typename: 'Mutation', updateEmailComplete: { __typename: 'UpdateEmailCompletePayload', account?: { __typename: 'Account', id: string, email?: string | null, features: Array<Feature>, accountRoles: Array<AccountRole>, accountProfile?: { __typename: 'AccountProfile', id: string, username?: string | null, name?: string | null, avatarUrl?: string | null, telegram?: string | null, twitter?: string | null, websiteUrl?: string | null } | null } | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type UpdateAccountProfileMutationVariables = Exact<{
  input: UpdateAccountProfileInput;
}>;


export type UpdateAccountProfileMutation = { __typename: 'Mutation', updateAccountProfile: { __typename: 'UpdateAccountProfilePayload', accountProfile?: { __typename: 'AccountProfile', id: string, username?: string | null, name?: string | null, avatarUrl?: string | null, telegram?: string | null, twitter?: string | null, websiteUrl?: string | null } | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type AccountProfileInfoFragment = { __typename: 'AccountProfile', id: string, username?: string | null, name?: string | null, avatarUrl?: string | null, telegram?: string | null, twitter?: string | null, websiteUrl?: string | null };

export type AccountProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountProfileQuery = { __typename: 'Query', me?: { __typename: 'Account', id: string, accountProfile?: { __typename: 'AccountProfile', id: string, username?: string | null, name?: string | null, avatarUrl?: string | null, telegram?: string | null, twitter?: string | null, websiteUrl?: string | null } | null } | null };

export type ApiTokenInfoFragment = { __typename: 'ApiToken', id: string, createdAt: Date, updatedAt?: Date | null, name: string };

export type ApiTokensQueryVariables = Exact<{ [key: string]: never; }>;


export type ApiTokensQuery = { __typename: 'Query', me?: { __typename: 'Account', apiTokens: Array<{ __typename: 'ApiToken', id: string, createdAt: Date, updatedAt?: Date | null, name: string }> } | null };

export type CreateApiTokenMutationVariables = Exact<{
  input: CreateApiTokenInput;
}>;


export type CreateApiTokenMutation = { __typename: 'Mutation', createApiToken: { __typename: 'CreateApiTokenPayload', token?: string | null, account?: { __typename: 'Account', id: string, email?: string | null, features: Array<Feature>, accountRoles: Array<AccountRole>, accountProfile?: { __typename: 'AccountProfile', id: string, username?: string | null, name?: string | null, avatarUrl?: string | null, telegram?: string | null, twitter?: string | null, websiteUrl?: string | null } | null } | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type DeleteApiTokenMutationVariables = Exact<{
  input: DeleteApiTokenInput;
}>;


export type DeleteApiTokenMutation = { __typename: 'Mutation', deleteApiToken: { __typename: 'DeleteApiTokenPayload', errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type UpdateApiTokenMutationVariables = Exact<{
  input: UpdateApiTokenInput;
}>;


export type UpdateApiTokenMutation = { __typename: 'Mutation', updateApiToken: { __typename: 'UpdateApiTokenPayload', account?: { __typename: 'Account', id: string, email?: string | null, features: Array<Feature>, accountRoles: Array<AccountRole>, accountProfile?: { __typename: 'AccountProfile', id: string, username?: string | null, name?: string | null, avatarUrl?: string | null, telegram?: string | null, twitter?: string | null, websiteUrl?: string | null } | null } | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type ExternalPositionsArbitraryLoanPositionsQueryVariables = Exact<{
  network: Deployment;
  vaultId: Scalars['Address']['input'];
}>;


export type ExternalPositionsArbitraryLoanPositionsQuery = { __typename: 'Query', externalPositionsArbitraryLoanPositions: { __typename: 'ExternalPositionsArbitraryLoanPositionsData', arbitraryLoansBasic: Array<{ __typename: 'SingleArbitraryLoanBasicData', status: ArbitraryLoanStatus, externalPosition: Address, borrower: Address, loanAsset: Address, description: string, borrowableAmount: string, amountToRepay: string, borrowedAmount: string, totalRepaid: string }> } };

export type ExternalPositionsArbitraryLoanPortfolioQueryVariables = Exact<{
  vaultId: Scalars['Address']['input'];
  currency: Scalars['CurrencyOrAddress']['input'];
  network: Deployment;
}>;


export type ExternalPositionsArbitraryLoanPortfolioQuery = { __typename: 'Query', externalPositionsArbitraryLoanPortfolio?: Array<{ __typename: 'ExternalPositionsArbitraryLoanPositionData', valueChange24h?: number | null, amountToRepay: string, borrowableAmount: string, currentInterestRate: number, asset: { __typename: 'ArbitraryLoanPortfolioAsset', assetAddress: Address, amount: number, currencyValue?: number | null } }> | null };

export type BorrowerArbitraryLoanPositionsQueryVariables = Exact<{
  network: Deployment;
  borrower: Scalars['Address']['input'];
}>;


export type BorrowerArbitraryLoanPositionsQuery = { __typename: 'Query', borrowerArbitraryLoanPositions: { __typename: 'BorrowerArbitraryLoanPositionsData', arbitraryLoansBasic: Array<{ __typename: 'SingleBorrowerArbitraryLoanBasicData', status: ArbitraryLoanStatus, externalPosition: Address, loanAsset: Address, description: string, borrowableAmount: string, amountToRepay: string, borrowedAmount: string, totalRepaid: string, vaultName: string, vaultAddress: Address }> } };

export type AssetPricesQueryVariables = Exact<{
  network: Deployment;
  currency: Scalars['CurrencyOrAddress']['input'];
}>;


export type AssetPricesQuery = { __typename: 'Query', assetPrices: Array<{ __typename: 'AssetPrice', id: Address, price?: number | null, change24h?: number | null }> };

export type SignOutMutationVariables = Exact<{ [key: string]: never; }>;


export type SignOutMutation = { __typename: 'Mutation', signOut?: { __typename: 'SignOutPayload', errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } | null };

export type SignInMutationVariables = Exact<{
  input: SignInInput;
}>;


export type SignInMutation = { __typename: 'Mutation', signIn: { __typename: 'SignInPayload', mfa?: Array<MultiFactorAuthMethod> | null, token?: string | null, account?: { __typename: 'Account', id: string, email?: string | null, features: Array<Feature>, accountRoles: Array<AccountRole>, accountProfile?: { __typename: 'AccountProfile', id: string, username?: string | null, name?: string | null, avatarUrl?: string | null, telegram?: string | null, twitter?: string | null, websiteUrl?: string | null } | null } | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type SignInWithGoogleMutationVariables = Exact<{
  credentials: SignInCredentialsInput;
  reCaptchaToken: Scalars['String']['input'];
}>;


export type SignInWithGoogleMutation = { __typename: 'Mutation', signInWithGoogle: { __typename: 'SignInPayload', mfa?: Array<MultiFactorAuthMethod> | null, token?: string | null, account?: { __typename: 'Account', id: string, email?: string | null, features: Array<Feature>, accountRoles: Array<AccountRole>, accountProfile?: { __typename: 'AccountProfile', id: string, username?: string | null, name?: string | null, avatarUrl?: string | null, telegram?: string | null, twitter?: string | null, websiteUrl?: string | null } | null } | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type SignInCompleteMutationVariables = Exact<{
  input: SignInCompleteInput;
}>;


export type SignInCompleteMutation = { __typename: 'Mutation', signInComplete: { __typename: 'SignInCompletePayload', account?: { __typename: 'Account', id: string, email?: string | null, features: Array<Feature>, accountRoles: Array<AccountRole>, accountProfile?: { __typename: 'AccountProfile', id: string, username?: string | null, name?: string | null, avatarUrl?: string | null, telegram?: string | null, twitter?: string | null, websiteUrl?: string | null } | null } | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type SignUpMutationVariables = Exact<{
  input: SignUpInput;
}>;


export type SignUpMutation = { __typename: 'Mutation', signUp: { __typename: 'SignUpPayload', name?: string | null, email?: string | null, address?: string | null, method?: PrimaryAuthMethod | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type SignUpCompleteMutationVariables = Exact<{
  input: SignUpCompleteInput;
}>;


export type SignUpCompleteMutation = { __typename: 'Mutation', signUpComplete: { __typename: 'SignUpCompletePayload', account?: { __typename: 'Account', id: string, email?: string | null, features: Array<Feature>, accountRoles: Array<AccountRole>, accountProfile?: { __typename: 'AccountProfile', id: string, username?: string | null, name?: string | null, avatarUrl?: string | null, telegram?: string | null, twitter?: string | null, websiteUrl?: string | null } | null } | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type RequestAuthTokenMutationVariables = Exact<{
  input: RequestAuthTokenInput;
}>;


export type RequestAuthTokenMutation = { __typename: 'Mutation', requestAuthToken: { __typename: 'RequestAuthTokenPayload', token?: string | null, account?: { __typename: 'Account', id: string, email?: string | null, features: Array<Feature>, accountRoles: Array<AccountRole>, accountProfile?: { __typename: 'AccountProfile', id: string, username?: string | null, name?: string | null, avatarUrl?: string | null, telegram?: string | null, twitter?: string | null, websiteUrl?: string | null } | null } | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type ConnectAuthMethodMutationVariables = Exact<{
  input: ConnectAuthMethodInput;
}>;


export type ConnectAuthMethodMutation = { __typename: 'Mutation', connectAuthMethod: { __typename: 'ConnectAuthMethodPayload', name?: string | null, email?: string | null, token?: string | null, address?: string | null, method?: PrimaryAuthMethod | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type ConnectAuthMethodCompleteMutationVariables = Exact<{
  input: ConnectAuthMethodCompleteInput;
}>;


export type ConnectAuthMethodCompleteMutation = { __typename: 'Mutation', connectAuthMethodComplete: { __typename: 'ConnectAuthMethodCompletePayload', account?: { __typename: 'Account', id: string, email?: string | null, features: Array<Feature>, accountRoles: Array<AccountRole>, accountProfile?: { __typename: 'AccountProfile', id: string, username?: string | null, name?: string | null, avatarUrl?: string | null, telegram?: string | null, twitter?: string | null, websiteUrl?: string | null } | null } | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type DisconnectAuthMethodMutationVariables = Exact<{
  input: DisconnectAuthMethodInput;
}>;


export type DisconnectAuthMethodMutation = { __typename: 'Mutation', disconnectAuthMethod: { __typename: 'DisconnectAuthMethodPayload', account?: { __typename: 'Account', id: string, email?: string | null, features: Array<Feature>, accountRoles: Array<AccountRole>, accountProfile?: { __typename: 'AccountProfile', id: string, username?: string | null, name?: string | null, avatarUrl?: string | null, telegram?: string | null, twitter?: string | null, websiteUrl?: string | null } | null } | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type CheckTokenStatusMutationVariables = Exact<{
  input: CheckTokenStatusInput;
}>;


export type CheckTokenStatusMutation = { __typename: 'Mutation', checkTokenStatus: { __typename: 'CheckTokenStatusPayload', token?: string | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type AllBalancerPoolsQueryVariables = Exact<{
  vaultId: Scalars['Address']['input'];
  currency: Currency;
  network: Deployment;
}>;


export type AllBalancerPoolsQuery = { __typename: 'Query', allBalancerPools: Array<{ __typename: 'SingleBalancerPoolData', poolSize?: number | null, poolAddress: Address, poolId: string, gaugeAddress?: Address | null, depositDisabled: boolean }> };

export type BalancerPoolPositionsQueryVariables = Exact<{
  vaultId: Scalars['Address']['input'];
  currency: Currency;
  network: Deployment;
}>;


export type BalancerPoolPositionsQuery = { __typename: 'Query', balancerPoolPositions: Array<{ __typename: 'SingleBalancerPoolPositionData', poolId: string, depositDisabled: boolean, poolAddress: Address, isGauge: boolean, gaugeAddress?: Address | null, assetBalance: { __typename: 'AssetBalanceCurrency', address: Address, amount: string, currencyValue?: number | null } }> };

export type BalancerDepositInfoQueryVariables = Exact<{
  poolId: Scalars['String']['input'];
  amounts: Array<Scalars['BigInt']['input']> | Scalars['BigInt']['input'];
  network: Deployment;
}>;


export type BalancerDepositInfoQuery = { __typename: 'Query', balancerDepositInfo?: { __typename: 'BalancerDepositInfo', priceImpact: number, bptAmount: string } | null };

export type BalancerWithdrawInfoExactBptInQueryVariables = Exact<{
  poolId: Scalars['String']['input'];
  bptAmountIn: Scalars['BigInt']['input'];
  network: Deployment;
  tokenIndex?: InputMaybe<Scalars['BigInt']['input']>;
}>;


export type BalancerWithdrawInfoExactBptInQuery = { __typename: 'Query', balancerWithdrawInfoExactBptIn?: { __typename: 'BalancerWithdrawInfoExactBptIn', priceImpact: number, amounts: Array<string> } | null };

export type IndividualRewardFragment = { __typename: 'IndividualReward', address: Address, rewards: Array<{ __typename: 'Reward', assetAddress: Address, claimable: number, value: number }> };

export type RewardsForBalancerQueryVariables = Exact<{
  address: Scalars['Address']['input'];
  currency: Currency;
  network: Deployment;
}>;


export type RewardsForBalancerQuery = { __typename: 'Query', rewardsForBalancer?: { __typename: 'RewardsData', individualRewards?: Array<{ __typename: 'IndividualReward', address: Address, rewards: Array<{ __typename: 'Reward', assetAddress: Address, claimable: number, value: number }> }> | null } | null };

export type LendingDataForCompoundV2AssetsQueryVariables = Exact<{
  network: Deployment;
  currency: Currency;
}>;


export type LendingDataForCompoundV2AssetsQuery = { __typename: 'Query', lendingDataForCompoundV2Assets: Array<{ __typename: 'SingleCompoundV2AssetLendingData', assetAddress: Address, underlyingAddress: Address, baseApy: number, totalApy: number, totalSupply?: number | null, isMintPaused: boolean, rewardApys: Array<{ __typename: 'RewardApy', assetAddress: Address, apy: number }> }> };

export type CompoundV2BorrowingRatesQueryVariables = Exact<{
  network: Deployment;
  currency: Currency;
}>;


export type CompoundV2BorrowingRatesQuery = { __typename: 'Query', borrowingForCompoundV2Assets?: Array<{ __typename: 'SingleCompoundV2AssetBorrowingData', totalApy: number, apyCurrent: number, rewardApy: number, rewardAssetAddress: Address, derivativeAddress: Address, underlyingAssetAddress: Address, collateralFactor: number, usageAsCollateralEnabled: boolean, totalSupply?: number | null }> | null };

export type RewardsForCompoundV2QueryVariables = Exact<{
  address: Scalars['Address']['input'];
  currency: Currency;
  network: Deployment;
}>;


export type RewardsForCompoundV2Query = { __typename: 'Query', rewardsForCompoundV2?: { __typename: 'CompoundV2RewardsData', globalRewards?: Array<{ __typename: 'Reward', assetAddress: Address, claimable: number, value: number }> | null } | null };

export type CompoundV2AssetBalanceFragment = { __typename: 'CompoundV2AssetBalance', assetAddress: Address, amount: string, currencyValue?: number | null };

export type ExternalPositionsCompoundV2DebtPositionsQueryVariables = Exact<{
  vaultId: Scalars['Address']['input'];
  currency: Currency;
  network: Deployment;
}>;


export type ExternalPositionsCompoundV2DebtPositionsQuery = { __typename: 'Query', externalPositionsCompoundV2DebtPositions: Array<{ __typename: 'SingleCompoundV2DebtPositionData', claimableRewardValue: number, rewardToken?: Address | null, active: boolean, externalPositionAddress: Address, currentBorrowValue: number, maxLoanToValueRatio?: number | null, currentLoanToValueRatio?: number | null, maxSafeDecreaseInCollateral: number, maxDecreaseInCollateral: number, maxSafeIncreaseInBorrow: number, maxIncreaseInBorrow: number, safeLoanToValueRatio: number, weightedApy?: number | null, collateralAssets: Array<{ __typename: 'CompoundV2AssetBalance', assetAddress: Address, amount: string, currencyValue?: number | null }>, borrowedAssets: Array<{ __typename: 'CompoundV2AssetBalance', assetAddress: Address, amount: string, currencyValue?: number | null }> }> };

export type ExternalPositionsCompoundV2DebtPortfolioQueryVariables = Exact<{
  vaultId: Scalars['Address']['input'];
  currency: Scalars['CurrencyOrAddress']['input'];
  network: Deployment;
}>;


export type ExternalPositionsCompoundV2DebtPortfolioQuery = { __typename: 'Query', externalPositionsCompoundV2DebtPortfolio?: { __typename: 'ExternalPositionCompoundV2DebtPortfolioData', netValue?: number | null, valueChange24h?: number | null, collateralAssets: Array<{ __typename: 'CompoundV2AssetBalance', assetAddress: Address, amount: string, currencyValue?: number | null }>, borrowedAssets: Array<{ __typename: 'CompoundV2AssetBalance', assetAddress: Address, amount: string, currencyValue?: number | null }> } | null };

export type LendingDataForCompoundV3AssetsQueryVariables = Exact<{
  network: Deployment;
  currency: Currency;
  vaultId: Scalars['Address']['input'];
}>;


export type LendingDataForCompoundV3AssetsQuery = { __typename: 'Query', lendingDataForCompoundV3Assets: Array<{ __typename: 'SingleCompoundV3AssetLendingData', assetAddress: Address, baseApy: number, totalApy: number, totalSupply: number, underlyingAsset: { __typename: 'AssetBalanceCurrency', address: Address, amount: string, currencyValue?: number | null }, rewardApys: Array<{ __typename: 'RewardApy', assetAddress: Address, apy: number }> }> };

export type LendingDataForCompoundV3AssetsPositionQueryVariables = Exact<{
  network: Deployment;
  currency: Currency;
  vaultId: Scalars['Address']['input'];
}>;


export type LendingDataForCompoundV3AssetsPositionQuery = { __typename: 'Query', lendingDataForCompoundV3AssetsPosition: Array<{ __typename: 'SingleCompoundAssetV3PositionLendingData', isLendDisabled: boolean, baseApy: number, totalApy: number, asset: { __typename: 'AssetBalanceCurrency', address: Address, amount: string, currencyValue?: number | null }, rewardApys: Array<{ __typename: 'RewardApy', assetAddress: Address, apy: number }> }> };

export type RewardsForCompoundV3QueryVariables = Exact<{
  address: Scalars['Address']['input'];
  currency: Currency;
  network: Deployment;
}>;


export type RewardsForCompoundV3Query = { __typename: 'Query', rewardsForCompoundV3?: { __typename: 'CompoundV3RewardsData', cTokensToClaim: Array<Address>, globalRewards?: Array<{ __typename: 'Reward', assetAddress: Address, claimable: number, value: number }> | null } | null };

export type ConvexVotingInfoFragment = { __typename: 'ConvexVotingInfo', lockedBalance: number, unlockedBalance: number, nextEpochStart: number, hasAnyCvxCrvStakingRewards: boolean, delegate?: Address | null, voteState: { __typename: 'ConvexVotingVoteState', status: ConvexVoteStatus, currentEndTime?: number | null, nextStartTime?: number | null, nextEndTime?: number | null }, votiumClaims: Array<{ __typename: 'VotiumClaim', amount: string, index: number, merkleProof: Array<string>, token: Address }>, claimableRewards: Array<{ __typename: 'ConvexVotingReward', assetAddress: Address, amount: number, currencyValue?: number | null }>, positions: Array<{ __typename: 'ConvexVotingPosition', amount: number, epochsAmountInsideLocker: number, status: ConvexVotingPosStatus, unlockTime: number }> };

export type ExternalPositionConvexVotingPortfolioDataFragment = { __typename: 'ExternalPositionConvexVotingPortfolioData', valueChange24h?: number | null, lockedAsset: { __typename: 'ConvexVotingLockedAsset', amount: number, assetAddress: Address, currencyValue?: number | null } };

export type ExternalPositionConvexVotingInfoQueryVariables = Exact<{
  address: Scalars['Address']['input'];
  currency: Currency;
  network: Deployment;
}>;


export type ExternalPositionConvexVotingInfoQuery = { __typename: 'Query', externalPositionConvexVotingInfo?: { __typename: 'ConvexVotingInfo', lockedBalance: number, unlockedBalance: number, nextEpochStart: number, hasAnyCvxCrvStakingRewards: boolean, delegate?: Address | null, voteState: { __typename: 'ConvexVotingVoteState', status: ConvexVoteStatus, currentEndTime?: number | null, nextStartTime?: number | null, nextEndTime?: number | null }, votiumClaims: Array<{ __typename: 'VotiumClaim', amount: string, index: number, merkleProof: Array<string>, token: Address }>, claimableRewards: Array<{ __typename: 'ConvexVotingReward', assetAddress: Address, amount: number, currencyValue?: number | null }>, positions: Array<{ __typename: 'ConvexVotingPosition', amount: number, epochsAmountInsideLocker: number, status: ConvexVotingPosStatus, unlockTime: number }> } | null };

export type ExternalPositionConvexVotingPortfolioQueryVariables = Exact<{
  address: Scalars['Address']['input'];
  currency: Scalars['CurrencyOrAddress']['input'];
  network: Deployment;
}>;


export type ExternalPositionConvexVotingPortfolioQuery = { __typename: 'Query', externalPositionConvexVotingPortfolio?: { __typename: 'ExternalPositionConvexVotingPortfolioData', valueChange24h?: number | null, lockedAsset: { __typename: 'ConvexVotingLockedAsset', amount: number, assetAddress: Address, currencyValue?: number | null } } | null };

export type CurvePoolsQueryVariables = Exact<{
  currency: Currency;
  network: Deployment;
}>;


export type CurvePoolsQuery = { __typename: 'Query', curvePools: Array<{ __typename: 'SinglePoolData', address: Address, poolSize: number, baseApy: number, totalApy: number, rewardApys: Array<{ __typename: 'RewardApy', assetAddress: Address, apy: number }> }> };

export type RewardsForCurveQueryVariables = Exact<{
  address: Scalars['Address']['input'];
  currency: Currency;
  network: Deployment;
}>;


export type RewardsForCurveQuery = { __typename: 'Query', rewardsForCurve?: { __typename: 'RewardsData', globalRewards?: Array<{ __typename: 'Reward', assetAddress: Address, claimable: number, value: number }> | null, individualRewards?: Array<{ __typename: 'IndividualReward', address: Address, rewards: Array<{ __typename: 'Reward', assetAddress: Address, claimable: number, value: number }> }> | null } | null };

export type DelegateVotesAssetsQueryVariables = Exact<{
  network: Deployment;
  vaultId: Scalars['Address']['input'];
  currency: Currency;
}>;


export type DelegateVotesAssetsQuery = { __typename: 'Query', delegateVotesAssets?: Array<{ __typename: 'DelegateVoteAsset', delegatee?: Address | null, protocol: Protocol, assetBalance: { __typename: 'DelegateVoteAssetBalance', assetAddress: Address, amount: string, currencyValue?: number | null } }> | null };

export type DivaStakingDepositorInfoQueryVariables = Exact<{
  network: Deployment;
  depositor: Scalars['Address']['input'];
  currency: Currency;
}>;


export type DivaStakingDepositorInfoQuery = { __typename: 'Query', divaStakingDepositorInfo?: { __typename: 'DivaStakingDepositorInfo', totalEthInTranches: { __typename: 'AssetBalanceCurrency', address: Address, amount: string, currencyValue?: number | null }, nektarInfo: { __typename: 'DivaStakingNektarInfo', maxDrops: number, accruedDrops: number, epoch: number, dropsPerEthPerDay: number }, tranches: Array<{ __typename: 'DivaStakingDepositorTranche', maxRewards: string, accruedRewards: string, trancheId: number, asset: { __typename: 'AssetBalanceCurrency', address: Address, amount: string, currencyValue?: number | null } }>, activities: Array<{ __typename: 'DivaStakingDepositorActivity', createdAt: number, type: DivaStakingDepositorActivityType, vaultName: string, trancheAmounts: Array<{ __typename: 'DivaStakingTrancheAmount', trancheId: number, asset: { __typename: 'AssetBalanceCurrency', address: Address, amount: string, currencyValue?: number | null } }> }> } | null };

export type DivaStakingGeneralInfoQueryVariables = Exact<{
  network: Deployment;
}>;


export type DivaStakingGeneralInfoQuery = { __typename: 'Query', divaStakingGeneralInfo?: { __typename: 'DivaStakingGeneralInfo', stakers: number, tvl: number, tvlEth: number, tvlstEth: number } | null };

export type LendingDataForErc4626AssetsQueryVariables = Exact<{
  network: Deployment;
  currency: Currency;
  protocol: Erc4626Protocol;
  vaultId: Scalars['Address']['input'];
}>;


export type LendingDataForErc4626AssetsQuery = { __typename: 'Query', lendingDataForERC4626Assets?: Array<{ __typename: 'SingleERC4626AssetLendingData', assetAddress: Address, isPaused: boolean, totalApy: number, underlyingAsset: { __typename: 'AssetBalanceCurrency', address: Address, amount: string, currencyValue?: number | null } }> | null };

export type LendingDataForErc4626AssetsPositionQueryVariables = Exact<{
  network: Deployment;
  currency: Currency;
  protocol: Erc4626Protocol;
  vaultId: Scalars['Address']['input'];
}>;


export type LendingDataForErc4626AssetsPositionQuery = { __typename: 'Query', lendingDataForERC4626AssetsPosition?: Array<{ __typename: 'SingleERC4626AssetPositionLendingData', isPaused: boolean, totalApy: number, underlyingAddress: Address, asset: { __typename: 'AssetBalanceCurrency', address: Address, amount: string, currencyValue?: number | null } }> | null };

type UserErrorFragmentArgValidationErrorFragment = { __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> };

type UserErrorFragmentGenericUserErrorFragment = { __typename: 'GenericUserError', message: string };

type UserErrorFragmentRateLimitErrorFragment = { __typename: 'RateLimitError', message: string };

export type UserErrorFragmentFragment = UserErrorFragmentArgValidationErrorFragment | UserErrorFragmentGenericUserErrorFragment | UserErrorFragmentRateLimitErrorFragment;

export type ArgValidationErrorFragmentFragment = { __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> };

export type GenericUserErrorFragmentFragment = { __typename: 'GenericUserError', message: string };

export type RateLimitErrorFragmentFragment = { __typename: 'RateLimitError', message: string };

export type AssetBalanceCurrencyFragment = { __typename: 'AssetBalanceCurrency', address: Address, amount: string, currencyValue?: number | null };

export type AssetBalanceObligatoryCurrencyFragment = { __typename: 'AssetBalanceObligatoryCurrency', address: Address, amount: string, currencyValue: number };

export type ErrorResponseFragment = { __typename: 'ErrorResponse', error: string };

export type RewardApyFragment = { __typename: 'RewardApy', assetAddress: Address, apy: number };

export type SingleIdleAssetLendingDataFragment = { __typename: 'SingleIdleAssetLendingData', assetAddress: Address, underlyingAddress: Address, baseApy: number, totalApy: number, tvl?: number | null, rewardApys: Array<{ __typename: 'RewardApy', assetAddress: Address, apy: number }> };

export type LendingDataForIdleAssetsQueryVariables = Exact<{
  currency: Currency;
  network: Deployment;
}>;


export type LendingDataForIdleAssetsQuery = { __typename: 'Query', lendingDataForIdleAssets: Array<{ __typename: 'SingleIdleAssetLendingData', assetAddress: Address, underlyingAddress: Address, baseApy: number, totalApy: number, tvl?: number | null, rewardApys: Array<{ __typename: 'RewardApy', assetAddress: Address, apy: number }> }> };

export type RewardsForIdleQueryVariables = Exact<{
  address: Scalars['Address']['input'];
  currency: Currency;
  network: Deployment;
}>;


export type RewardsForIdleQuery = { __typename: 'Query', rewardsForIdle?: { __typename: 'RewardsData', globalRewards?: Array<{ __typename: 'Reward', assetAddress: Address, claimable: number, value: number }> | null, individualRewards?: Array<{ __typename: 'IndividualReward', address: Address, rewards: Array<{ __typename: 'Reward', assetAddress: Address, claimable: number, value: number }> }> | null } | null };

export type KilnStakingPositionDataFragment = { __typename: 'KilnStakingPositionData', id: Address, active: boolean, apy: number, kilnStakingContractAddress: Address, stakedEth: string, stakedEthCurrencyValue?: number | null, executionLayerRewards: string, executionLayerRewardsCurrencyValue?: number | null, consensusLayerRewards: string, consensusLayerRewardsCurrencyValue?: number | null, totalRewards: string, totalRewardsCurrencyValue?: number | null, positionValuePaused: boolean, validators: Array<{ __typename: 'KilnValidator', publicKey: BytesLike, unstakeSignalled: boolean, state: KilnValidatorState, executionLayerRewards: string, consensusLayerRewards: string }> };

export type ExternalPositionsKilnPositionsQueryVariables = Exact<{
  vaultId: Scalars['Address']['input'];
  network: Deployment;
  currency: Currency;
}>;


export type ExternalPositionsKilnPositionsQuery = { __typename: 'Query', externalPositionsKilnPositions?: Array<{ __typename: 'KilnStakingPositionData', id: Address, active: boolean, apy: number, kilnStakingContractAddress: Address, stakedEth: string, stakedEthCurrencyValue?: number | null, executionLayerRewards: string, executionLayerRewardsCurrencyValue?: number | null, consensusLayerRewards: string, consensusLayerRewardsCurrencyValue?: number | null, totalRewards: string, totalRewardsCurrencyValue?: number | null, positionValuePaused: boolean, validators: Array<{ __typename: 'KilnValidator', publicKey: BytesLike, unstakeSignalled: boolean, state: KilnValidatorState, executionLayerRewards: string, consensusLayerRewards: string }> }> | null };

export type ExternalPositionKilnPortfolioDataFragment = { __typename: 'ExternalPositionKilnPortfolioData', stakedEthAmount: number, stakedEthCurrencyValue?: number | null, netValue?: number | null, valueChange24h?: number | null };

export type ExternalPositionsKilnPortfolioQueryVariables = Exact<{
  network: Deployment;
  vaultId: Scalars['Address']['input'];
  currency: Scalars['CurrencyOrAddress']['input'];
}>;


export type ExternalPositionsKilnPortfolioQuery = { __typename: 'Query', externalPositionsKilnPortfolio?: { __typename: 'ExternalPositionKilnPortfolioData', stakedEthAmount: number, stakedEthCurrencyValue?: number | null, netValue?: number | null, valueChange24h?: number | null } | null };

export type ExternalPositionsLidoUnclaimedWithdrawalRequestsQueryVariables = Exact<{
  vaultId: Scalars['Address']['input'];
  network: Deployment;
  currency: Currency;
}>;


export type ExternalPositionsLidoUnclaimedWithdrawalRequestsQuery = { __typename: 'Query', lidoUnclaimedWithdrawalRequests?: Array<{ __typename: 'LidoUnclaimedWithdrawalRequest', id: string, amount: number, hint: string, claimable: boolean, lidoWithdrawalsPosition: string, requestedTimestamp: number }> | null };

export type ExternalPositionsLidoWithdrawalsPositionsQueryVariables = Exact<{
  vaultId: Scalars['Address']['input'];
  network: Deployment;
}>;


export type ExternalPositionsLidoWithdrawalsPositionsQuery = { __typename: 'Query', externalPositionsLidoWithdrawalsPositions?: Array<{ __typename: 'LidoWithdrawalsPositionData', id: string, active: boolean, withdrawalRequests: Array<{ __typename: 'LidoPositionWithdrawalRequest', id: string, amount: number, claimed: boolean }> }> | null };

export type ExternalPositionsLidoWithdrawalsPortfolioQueryVariables = Exact<{
  currency: Scalars['CurrencyOrAddress']['input'];
  network: Deployment;
  vaultId: Scalars['Address']['input'];
}>;


export type ExternalPositionsLidoWithdrawalsPortfolioQuery = { __typename: 'Query', externalPositionsLidoWithdrawalsPortfolio?: Array<{ __typename: 'LidoWithdrawalsPortfolioData', id: string, amount: number, valueChange24h?: number | null }> | null };

export type LiquityAssetFragment = { __typename: 'LiquityAsset', id: Address, name: string, symbol: string, decimals: number };

export type LiquityAssetBalanceFragment = { __typename: 'LiquityAssetBalance', amount?: number | null, currencyValue?: number | null, price?: number | null, asset?: { __typename: 'LiquityAsset', id: Address, name: string, symbol: string, decimals: number } | null };

export type LiquityDebtPositionDataFragment = { __typename: 'LiquityDebtPositionData', id: Address, active: boolean, troveStatus: TroveStatus, currentCollateralRatio: number, maxSafeDecreaseInCollateral: number, maxSafeIncreaseInBorrow: number, safeCollateralRatio: number, borrowedAssetBalance: { __typename: 'LiquityAssetBalance', amount?: number | null, currencyValue?: number | null, price?: number | null, asset?: { __typename: 'LiquityAsset', id: Address, name: string, symbol: string, decimals: number } | null }, collateralAssetBalance: { __typename: 'LiquityAssetBalance', amount?: number | null, currencyValue?: number | null, price?: number | null, asset?: { __typename: 'LiquityAsset', id: Address, name: string, symbol: string, decimals: number } | null } };

export type ExternalPositionLiquityPortfolioDataFragment = { __typename: 'ExternalPositionLiquityPortfolioData', netValue?: number | null, valueChange24h?: number | null, borrowedAsset?: { __typename: 'LiquityPortfolioAsset', assetAddress: Address, amount?: number | null, currencyValue?: number | null } | null, collateralAsset?: { __typename: 'LiquityPortfolioAsset', assetAddress: Address, amount?: number | null, currencyValue?: number | null } | null };

export type ExternalPositionsLiquityPositionsQueryVariables = Exact<{
  vaultId: Scalars['Address']['input'];
  network: Deployment;
  currency: Currency;
}>;


export type ExternalPositionsLiquityPositionsQuery = { __typename: 'Query', externalPositionsLiquityPositions?: Array<{ __typename: 'LiquityDebtPositionData', id: Address, active: boolean, troveStatus: TroveStatus, currentCollateralRatio: number, maxSafeDecreaseInCollateral: number, maxSafeIncreaseInBorrow: number, safeCollateralRatio: number, borrowedAssetBalance: { __typename: 'LiquityAssetBalance', amount?: number | null, currencyValue?: number | null, price?: number | null, asset?: { __typename: 'LiquityAsset', id: Address, name: string, symbol: string, decimals: number } | null }, collateralAssetBalance: { __typename: 'LiquityAssetBalance', amount?: number | null, currencyValue?: number | null, price?: number | null, asset?: { __typename: 'LiquityAsset', id: Address, name: string, symbol: string, decimals: number } | null } }> | null };

export type ExternalPositionsLiquityPortfolioQueryVariables = Exact<{
  network: Deployment;
  vaultId: Scalars['Address']['input'];
  currency: Scalars['CurrencyOrAddress']['input'];
}>;


export type ExternalPositionsLiquityPortfolioQuery = { __typename: 'Query', externalPositionsLiquityPortfolio?: { __typename: 'ExternalPositionLiquityPortfolioData', netValue?: number | null, valueChange24h?: number | null, borrowedAsset?: { __typename: 'LiquityPortfolioAsset', assetAddress: Address, amount?: number | null, currencyValue?: number | null } | null, collateralAsset?: { __typename: 'LiquityPortfolioAsset', assetAddress: Address, amount?: number | null, currencyValue?: number | null } | null } | null };

export type LiquityHintsQueryVariables = Exact<{
  actionType: Scalars['String']['input'];
  collateralAmount: Scalars['BigInt']['input'];
  lusdAmount: Scalars['BigInt']['input'];
  network: Deployment;
}>;


export type LiquityHintsQuery = { __typename: 'Query', liquityHints?: { __typename: 'LiquityHintsData', upperHint: Address, lowerHint: Address } | null };

export type SingleMapleAssetLendingDataFragment = { __typename: 'SingleMapleAssetLendingData', liquidityAssetAddress: Address, poolAddress: Address, poolSize?: number | null, poolName: string, totalApy: number, maxDeposit: string, totalShares: string, totalAssets: string, totalUnrealizedLosses: string };

export type MapleAssetFragment = { __typename: 'MapleAsset', balance: string, address: Address };

export type SingleMaplePoolPositionDataFragment = { __typename: 'SingleMaplePoolPositionData', pool: Address, isActive: boolean, poolName: string, status: MapleStatus, shares: string, maxDeposit: string, unrealizedLosses: string, totalShares: string, totalAssets: string, totalUnrealizedLosses: string, lockedShares: string, redeemableShares: string, redeemableWithdrawalAmount: string, requestedWithdrawalAmount: string, exitWithdrawalWindowStart: number, exitWithdrawalWindowEnd: number, newWithdrawalRequestWindowStart: number, newWithdrawalRequestWindowEnd: number, liquidityAsset: { __typename: 'MapleAsset', balance: string, address: Address } };

export type MaplePortfolioAssetFragment = { __typename: 'MaplePortfolioAsset', assetAddress: Address, amount: number, currencyValue?: number | null };

export type ExternalPositionMaplePortfolioDataFragment = { __typename: 'ExternalPositionMaplePortfolioData', netValue?: number | null, valueChange24h?: number | null, lentAssets: Array<{ __typename: 'MaplePortfolioAsset', assetAddress: Address, amount: number, currencyValue?: number | null }> };

export type ExternalPositionsMaplePositionsQueryVariables = Exact<{
  vaultId: Scalars['Address']['input'];
  network: Deployment;
}>;


export type ExternalPositionsMaplePositionsQuery = { __typename: 'Query', externalPositionsMaplePositions?: Array<{ __typename: 'SingleMaplePositionData', claimableRewards: string, active: boolean, externalPositionIndex: number, externalPositionAddress: Address, pools: Array<{ __typename: 'SingleMaplePoolPositionData', pool: Address, isActive: boolean, poolName: string, status: MapleStatus, shares: string, maxDeposit: string, unrealizedLosses: string, totalShares: string, totalAssets: string, totalUnrealizedLosses: string, lockedShares: string, redeemableShares: string, redeemableWithdrawalAmount: string, requestedWithdrawalAmount: string, exitWithdrawalWindowStart: number, exitWithdrawalWindowEnd: number, newWithdrawalRequestWindowStart: number, newWithdrawalRequestWindowEnd: number, liquidityAsset: { __typename: 'MapleAsset', balance: string, address: Address } }> }> | null };

export type LendingDataForAllMapleAssetsQueryVariables = Exact<{
  currency: Currency;
  network: Deployment;
}>;


export type LendingDataForAllMapleAssetsQuery = { __typename: 'Query', lendingDataForAllMapleAssets?: Array<{ __typename: 'SingleMapleAssetLendingData', liquidityAssetAddress: Address, poolAddress: Address, poolSize?: number | null, poolName: string, totalApy: number, maxDeposit: string, totalShares: string, totalAssets: string, totalUnrealizedLosses: string }> | null };

export type ExternalPositionsMaplePortfolioQueryVariables = Exact<{
  vaultId: Scalars['Address']['input'];
  currency: Scalars['CurrencyOrAddress']['input'];
  network: Deployment;
}>;


export type ExternalPositionsMaplePortfolioQuery = { __typename: 'Query', externalPositionsMaplePortfolio?: { __typename: 'ExternalPositionMaplePortfolioData', netValue?: number | null, valueChange24h?: number | null, lentAssets: Array<{ __typename: 'MaplePortfolioAsset', assetAddress: Address, amount: number, currencyValue?: number | null }> } | null };

export type MapleConvertSharesToExitAssetsQueryVariables = Exact<{
  pool: Scalars['Address']['input'];
  shares: Scalars['BigInt']['input'];
  network: Deployment;
}>;


export type MapleConvertSharesToExitAssetsQuery = { __typename: 'Query', mapleConvertSharesToExitAssets?: { __typename: 'MapleSharesToExitAssets', assets: string } | null };

export type InitializeAuthenticatorMutationVariables = Exact<{ [key: string]: never; }>;


export type InitializeAuthenticatorMutation = { __typename: 'Mutation', initializeAuthenticator: { __typename: 'InitializeAuthenticatorPayload', secret?: string | null, url?: string | null, qrcode?: string | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type CreateAuthenticatorMutationVariables = Exact<{
  input: CreateAuthenticatorInput;
}>;


export type CreateAuthenticatorMutation = { __typename: 'Mutation', createAuthenticator: { __typename: 'CreateAuthenticatorPayload', account?: { __typename: 'Account', id: string, auth?: { __typename: 'AccountAuth', authenticator: boolean, backupCodes: boolean, password: boolean, google?: { __typename: 'AccountAuthGoogle', email?: string | null } | null, wallets: Array<{ __typename: 'AccountAuthWallet', address: Address, id: string }> } | null } | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type DeleteAuthenticatorMutationVariables = Exact<{
  input: DeleteAuthenticatorInput;
}>;


export type DeleteAuthenticatorMutation = { __typename: 'Mutation', deleteAuthenticator: { __typename: 'DeleteAuthenticatorPayload', account?: { __typename: 'Account', id: string, auth?: { __typename: 'AccountAuth', authenticator: boolean, backupCodes: boolean, password: boolean, google?: { __typename: 'AccountAuthGoogle', email?: string | null } | null, wallets: Array<{ __typename: 'AccountAuthWallet', address: Address, id: string }> } | null } | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type GenerateBackupCodesMutationVariables = Exact<{
  input: GenerateBackupCodesInput;
}>;


export type GenerateBackupCodesMutation = { __typename: 'Mutation', generateBackupCodes: { __typename: 'GenerateBackupCodesPayload', codes?: Array<string> | null, account?: { __typename: 'Account', id: string, auth?: { __typename: 'AccountAuth', authenticator: boolean, backupCodes: boolean, password: boolean, google?: { __typename: 'AccountAuthGoogle', email?: string | null } | null, wallets: Array<{ __typename: 'AccountAuthWallet', address: Address, id: string }> } | null } | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type DeleteBackupCodesMutationVariables = Exact<{
  input: DeleteBackupCodesInput;
}>;


export type DeleteBackupCodesMutation = { __typename: 'Mutation', deleteBackupCodes: { __typename: 'DeleteBackupCodesPayload', account?: { __typename: 'Account', id: string, auth?: { __typename: 'AccountAuth', authenticator: boolean, backupCodes: boolean, password: boolean, google?: { __typename: 'AccountAuthGoogle', email?: string | null } | null, wallets: Array<{ __typename: 'AccountAuthWallet', address: Address, id: string }> } | null } | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type NetworkAssetBalancesQueryVariables = Exact<{
  currency: Currency;
  network: Deployment;
}>;


export type NetworkAssetBalancesQuery = { __typename: 'Query', networkAssetBalances: Array<{ __typename: 'NetworkAssetBalance', asset: Address, numberOfVaults: number, balance: number, balanceChange24h?: number | null, price?: number | null, priceChange24h?: number | null, valid: boolean, value?: number | null }> };

export type NetworkLatestStatsQueryVariables = Exact<{
  currency: Currency;
  network: Deployment;
}>;


export type NetworkLatestStatsQuery = { __typename: 'Query', networkLatestStats: { __typename: 'NetworkLatestStats', gav: number, vaults: number, deposits: number } };

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;


export type ChangePasswordMutation = { __typename: 'Mutation', changePassword: { __typename: 'ChangePasswordPayload', account?: { __typename: 'Account', id: string, email?: string | null, features: Array<Feature>, accountRoles: Array<AccountRole>, accountProfile?: { __typename: 'AccountProfile', id: string, username?: string | null, name?: string | null, avatarUrl?: string | null, telegram?: string | null, twitter?: string | null, websiteUrl?: string | null } | null } | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type ForgotPasswordMutationVariables = Exact<{
  input: ForgotPasswordInput;
}>;


export type ForgotPasswordMutation = { __typename: 'Mutation', forgotPassword: { __typename: 'ForgotPasswordPayload', errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { __typename: 'Mutation', resetPassword: { __typename: 'ResetPasswordPayload', errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type ExternalPositionsPendleV2PositionsQueryVariables = Exact<{
  network: Deployment;
  vaultId: Scalars['Address']['input'];
  currency: Currency;
}>;


export type ExternalPositionsPendleV2PositionsQuery = { __typename: 'Query', externalPositionsPendleV2Positions: Array<{ __typename: 'SinglePendleV2PositionData', active: boolean, externalPositionIndex: number, externalPositionAddress: Address, lpHoldings: Array<{ __typename: 'LPHoldingPendleV2', balance: string, asset: Address, marketName: string, apy: number, expiry: number, tvl: number, icon: string, underlying: Address, syToken: Address, currencyValue?: number | null }>, ptHoldings: Array<{ __typename: 'PTHoldingPendleV2', balance: string, asset: Address, marketName: string, apy: number, expiry: number, icon: string, underlying: Address, syToken: Address, market: Address, currencyValue?: number | null }>, rewards: { __typename: 'MarketRewardsPendleV2', markets: Array<Address>, assetBalances: Array<{ __typename: 'AssetBalanceCurrency', address: Address, amount: string, currencyValue?: number | null }> } }> };

export type AvailableToRegisterPendleV2MarketsQueryVariables = Exact<{
  network: Deployment;
  vaultId: Scalars['Address']['input'];
  currency: Currency;
}>;


export type AvailableToRegisterPendleV2MarketsQuery = { __typename: 'Query', availableToRegisterPendleV2Markets: Array<{ __typename: 'MarketInfoToRegisterPendleV2', market: Address, marketName: string, fixedApy: number, swapApy: number, expiry: number, tvl: number }> };

export type AvailableToUnregisterPendleV2MarketsQueryVariables = Exact<{
  network: Deployment;
  vaultId: Scalars['Address']['input'];
  currency: Currency;
}>;


export type AvailableToUnregisterPendleV2MarketsQuery = { __typename: 'Query', availableToUnregisterPendleV2Markets: Array<{ __typename: 'MarketInfoToUpdatePendleV2', market: Address, marketName: string, fixedApy: number, swapApy: number, expiry: number, tvl: number, duration: number }> };

export type AvailableToUpdatePendleV2MarketsQueryVariables = Exact<{
  network: Deployment;
  vaultId: Scalars['Address']['input'];
  currency: Currency;
}>;


export type AvailableToUpdatePendleV2MarketsQuery = { __typename: 'Query', availableToUpdatePendleV2Markets: Array<{ __typename: 'MarketInfoToUpdatePendleV2', market: Address, marketName: string, fixedApy: number, swapApy: number, expiry: number, tvl: number, duration: number }> };

export type SwapExactTokenForPtPendleV2QueryVariables = Exact<{
  network: Deployment;
  vaultId: Scalars['Address']['input'];
  market: Scalars['Address']['input'];
  tokenInAddr: Scalars['Address']['input'];
  amountTokenIn: Scalars['BigInt']['input'];
  slippage: Scalars['Float']['input'];
}>;


export type SwapExactTokenForPtPendleV2Query = { __typename: 'Query', swapExactTokenForPtPendleV2: { __typename: 'SwapExactTokenForPtPendleV2Data', amountMinOut: string, priceImpact: number, guessOut: { __typename: 'GuessOutPendleV2', guessMin: string, guessMax: string, guessOffchain: string, maxIteration: string, eps: string } } };

export type SellPtForTokenPendleV2QueryVariables = Exact<{
  network: Deployment;
  vaultId: Scalars['Address']['input'];
  market: Scalars['Address']['input'];
  tokenOutAddr: Scalars['Address']['input'];
  amountPtIn: Scalars['BigInt']['input'];
  slippage: Scalars['Float']['input'];
  hasExpired: Scalars['Boolean']['input'];
}>;


export type SellPtForTokenPendleV2Query = { __typename: 'Query', sellPtForTokenPendleV2: { __typename: 'SellPtForTokenPendleV2Data', amountMinOut: string, priceImpact: number } };

export type AddLiquidityForPendleV2QueryVariables = Exact<{
  network: Deployment;
  vaultId: Scalars['Address']['input'];
  market: Scalars['Address']['input'];
  tokenInAddr: Scalars['Address']['input'];
  amountTokenIn: Scalars['BigInt']['input'];
  slippage: Scalars['Float']['input'];
}>;


export type AddLiquidityForPendleV2Query = { __typename: 'Query', addLiquidityForPendleV2: { __typename: 'AddLiquidityForPendleV2Data', amountMinOut: string, priceImpact: number, guessOut: { __typename: 'GuessOutPendleV2', guessMin: string, guessMax: string, guessOffchain: string, maxIteration: string, eps: string } } };

export type RemoveLiquidityForPendleV2QueryVariables = Exact<{
  network: Deployment;
  vaultId: Scalars['Address']['input'];
  market: Scalars['Address']['input'];
  tokenOutAddr: Scalars['Address']['input'];
  amountLpToRemove: Scalars['BigInt']['input'];
  slippage: Scalars['Float']['input'];
}>;


export type RemoveLiquidityForPendleV2Query = { __typename: 'Query', removeLiquidityForPendleV2: { __typename: 'RemoveLiquidityForPendleV2Data', minSyOut: string, minIncomingAmount: string, priceImpact: number } };

export type ExternalPositionsPendleV2PortfolioQueryVariables = Exact<{
  vaultId: Scalars['Address']['input'];
  currency: Scalars['CurrencyOrAddress']['input'];
  network: Deployment;
}>;


export type ExternalPositionsPendleV2PortfolioQuery = { __typename: 'Query', externalPositionsPendleV2Portfolio?: { __typename: 'ExternalPositionPendleV2PortfolioData', netValue?: number | null, valueChange24h?: number | null, assetBalances: Array<{ __typename: 'AssetBalanceCurrency', address: Address, amount: string, currencyValue?: number | null }> } | null };

export type RewardsForWalletQueryVariables = Exact<{
  address: Scalars['Address']['input'];
  currency: Currency;
  network: Deployment;
}>;


export type RewardsForWalletQuery = { __typename: 'Query', rewardsForWallet?: Array<{ __typename: 'RewardsData', protocol: string, globalRewards?: Array<{ __typename: 'Reward', assetAddress: Address, claimable: number, value: number }> | null, individualRewards?: Array<{ __typename: 'IndividualReward', address: Address, rewards: Array<{ __typename: 'Reward', assetAddress: Address, claimable: number, value: number }> }> | null }> | null };

export type ExternalPositionStakeWisePortfolioDataFragment = { __typename: 'ExternalPositionStakeWisePortfolioData', stakedEthAmount: string, stakedEthCurrencyValue?: number | null, netValue?: number | null, valueChange24h?: number | null };

export type ExternalPositionsStakeWisePortfolioQueryVariables = Exact<{
  network: Deployment;
  vaultId: Scalars['Address']['input'];
  currency: Scalars['CurrencyOrAddress']['input'];
}>;


export type ExternalPositionsStakeWisePortfolioQuery = { __typename: 'Query', externalPositionsStakeWisePortfolio?: { __typename: 'ExternalPositionStakeWisePortfolioData', stakedEthAmount: string, stakedEthCurrencyValue?: number | null, netValue?: number | null, valueChange24h?: number | null } | null };

export type StakeWiseVaultHoldingDataFragment = { __typename: 'StakeWiseVaultHoldingData', active: boolean, externalPositionAddress: Address, shares: string, sharesCurrencyValue?: number | null, stakedEthAmount: string, claimableShares: number, unclaimableShares: number, exitRequests: Array<{ __typename: 'StakeWiseExitRequest', claimable: boolean, exitQueueIndex: string, positionTicket: string, shares: string, timestamp: string }> };

export type StakeWiseVaultDataFragment = { __typename: 'StakeWiseVaultData', stakeWiseVaultAddress: Address, totalApy: number, underlyingAddress: Address, totalValueLocked?: string | null, totalValueLockedCurrencyValue?: number | null, name?: string | null, imageUrl?: string | null, fallbackExternalPosition?: Address | null, externalPositionHolding?: { __typename: 'StakeWiseVaultHoldingData', active: boolean, externalPositionAddress: Address, shares: string, sharesCurrencyValue?: number | null, stakedEthAmount: string, claimableShares: number, unclaimableShares: number, exitRequests: Array<{ __typename: 'StakeWiseExitRequest', claimable: boolean, exitQueueIndex: string, positionTicket: string, shares: string, timestamp: string }> } | null };

export type ExternalPositionsStakeWisePositionsQueryVariables = Exact<{
  vaultId: Scalars['Address']['input'];
  network: Deployment;
  currency: Currency;
}>;


export type ExternalPositionsStakeWisePositionsQuery = { __typename: 'Query', externalPositionsStakeWisePositions?: Array<{ __typename: 'StakeWiseVaultData', stakeWiseVaultAddress: Address, totalApy: number, underlyingAddress: Address, totalValueLocked?: string | null, totalValueLockedCurrencyValue?: number | null, name?: string | null, imageUrl?: string | null, fallbackExternalPosition?: Address | null, externalPositionHolding?: { __typename: 'StakeWiseVaultHoldingData', active: boolean, externalPositionAddress: Address, shares: string, sharesCurrencyValue?: number | null, stakedEthAmount: string, claimableShares: number, unclaimableShares: number, exitRequests: Array<{ __typename: 'StakeWiseExitRequest', claimable: boolean, exitQueueIndex: string, positionTicket: string, shares: string, timestamp: string }> } | null }> | null };

export type StakingDataForAllStakeWiseAssetsQueryVariables = Exact<{
  currency: Currency;
  network: Deployment;
  vaultId: Scalars['Address']['input'];
}>;


export type StakingDataForAllStakeWiseAssetsQuery = { __typename: 'Query', stakingDataForAllStakeWiseAssets?: Array<{ __typename: 'StakeWiseVaultData', stakeWiseVaultAddress: Address, totalApy: number, underlyingAddress: Address, totalValueLocked?: string | null, totalValueLockedCurrencyValue?: number | null, name?: string | null, imageUrl?: string | null, fallbackExternalPosition?: Address | null, externalPositionHolding?: { __typename: 'StakeWiseVaultHoldingData', active: boolean, externalPositionAddress: Address, shares: string, sharesCurrencyValue?: number | null, stakedEthAmount: string, claimableShares: number, unclaimableShares: number, exitRequests: Array<{ __typename: 'StakeWiseExitRequest', claimable: boolean, exitQueueIndex: string, positionTicket: string, shares: string, timestamp: string }> } | null }> | null };

export type SwellStakingTransactionQueryVariables = Exact<{
  comptrollerProxy: Scalars['Address']['input'];
  expectedIncomingAmount: Scalars['BigInt']['input'];
  maxSlippage: Scalars['Float']['input'];
  network: Deployment;
  originAddress?: InputMaybe<Scalars['Address']['input']>;
  quantity: Scalars['BigInt']['input'];
  userAddress: Scalars['Address']['input'];
  vaultProxy: Scalars['Address']['input'];
}>;


export type SwellStakingTransactionQuery = { __typename: 'Query', swellStakingTransaction: { __typename: 'TokenSwapTransaction', reason?: string | null, status: string, transaction?: { __typename: 'Transaction', data: BytesLike, to: Address, value?: string | null } | null } };

export type ExternalPositionsTheGraphDelegationPositionsQueryVariables = Exact<{
  address: Scalars['Address']['input'];
  network: Deployment;
}>;


export type ExternalPositionsTheGraphDelegationPositionsQuery = { __typename: 'Query', externalPositionsTheGraphDelegationPositions?: Array<Array<{ __typename: 'SingleTheGraphDelegationPositionData', externalPosition: Address, indexer: Address, delegatedAmount: string, unbondingAmount: string, unbondingEndEstimatedTime: number, unlockedAmount: string, shares: string, active: boolean, externalPositionIndex: number, defaultDisplayName?: string | null }>> | null };

export type ExternalPositionsTheGraphDelegationPortfolioQueryVariables = Exact<{
  address: Scalars['Address']['input'];
  currency: Scalars['CurrencyOrAddress']['input'];
  network: Deployment;
}>;


export type ExternalPositionsTheGraphDelegationPortfolioQuery = { __typename: 'Query', externalPositionsTheGraphDelegationPortfolio?: Array<{ __typename: 'ExternalPositionTheGraphDelegationPortfolioPositionData', indexer: Address, defaultDisplayName?: string | null, asset: { __typename: 'TheGraphDelegationPortfolioAsset', address: Address, amount: string, currencyValue?: number | null } }> | null };

export type TheGraphDelegationInfoQueryVariables = Exact<{
  network: Deployment;
}>;


export type TheGraphDelegationInfoQuery = { __typename: 'Query', theGraphDelegationInfo?: { __typename: 'TheGraphDelegationInfoData', delegationTaxPercentage: number } | null };

export type TheGraphDelegationIndexerInfoQueryVariables = Exact<{
  network: Deployment;
  indexer: Scalars['Address']['input'];
}>;


export type TheGraphDelegationIndexerInfoQuery = { __typename: 'Query', theGraphDelegationIndexerInfo?: { __typename: 'TheGraphDelegationIndexerInfoData', id?: Address | null, defaultDisplayName?: string | null, availableCapacity?: string | null } | null };

export type TheGraphDelegationSharesToTokensQueryVariables = Exact<{
  network: Deployment;
  indexer: Scalars['Address']['input'];
  shares: Scalars['BigInt']['input'];
}>;


export type TheGraphDelegationSharesToTokensQuery = { __typename: 'Query', theGraphDelegationSharesToTokens?: { __typename: 'TheGraphDelegationSharesToTokensData', tokens: string } | null };

export type TheGraphDelegationTokensToSharesQueryVariables = Exact<{
  network: Deployment;
  indexer: Scalars['Address']['input'];
  tokens: Scalars['BigInt']['input'];
}>;


export type TheGraphDelegationTokensToSharesQuery = { __typename: 'Query', theGraphDelegationTokensToShares?: { __typename: 'TheGraphDelegationTokensToSharesData', shares: string } | null };

export type ThreeOneThirdAssetsToTradeQueryVariables = Exact<{
  network: Deployment;
  vaultId: Scalars['Address']['input'];
  currency: Currency;
}>;


export type ThreeOneThirdAssetsToTradeQuery = { __typename: 'Query', threeOneThirdAssetsToTrade: Array<{ __typename: 'ThreeOneThirdAssetToTrade', isTracked: boolean, allocation?: number | null, price: number, assetBalance: { __typename: 'AssetBalanceObligatoryCurrency', address: Address, amount: string, currencyValue: number } }> };

export type ThreeOneThirdRebalanceDataFragment = { __typename: 'ThreeOneThirdRebalanceData', sellValue: number, estimatedReceiveValue: number, expirationTimestamp: number, minReceivedValue: number, trades: Array<{ __typename: 'ThreeOneThirdTradeData', data: BytesLike, signature: BytesLike, exchangeName: string, minToReceiveBeforeFees: string, fromAssetBalance: { __typename: 'AssetBalanceObligatoryCurrency', address: Address, amount: string, currencyValue: number }, toAssetBalance: { __typename: 'AssetBalanceObligatoryCurrency', address: Address, amount: string, currencyValue: number } }> };

export type ThreeOneThirdRebalanceQueryVariables = Exact<{
  network: Deployment;
  vaultId: Scalars['Address']['input'];
  currency: Currency;
  signer: Scalars['Address']['input'];
  maxSlippage: Scalars['Float']['input'];
  maxPriceImpact: Scalars['Float']['input'];
  tokensIn: Array<Scalars['Address']['input']> | Scalars['Address']['input'];
  tokensInAmounts: Array<Scalars['BigInt']['input']> | Scalars['BigInt']['input'];
  tokensOut: Array<Scalars['Address']['input']> | Scalars['Address']['input'];
  tokensOutAllocations: Array<Scalars['Float']['input']> | Scalars['Float']['input'];
}>;


export type ThreeOneThirdRebalanceQuery = { __typename: 'Query', threeOneThirdRebalance: { __typename: 'ErrorResponse', error: string } | { __typename: 'ThreeOneThirdRebalanceData', sellValue: number, estimatedReceiveValue: number, expirationTimestamp: number, minReceivedValue: number, trades: Array<{ __typename: 'ThreeOneThirdTradeData', data: BytesLike, signature: BytesLike, exchangeName: string, minToReceiveBeforeFees: string, fromAssetBalance: { __typename: 'AssetBalanceObligatoryCurrency', address: Address, amount: string, currencyValue: number }, toAssetBalance: { __typename: 'AssetBalanceObligatoryCurrency', address: Address, amount: string, currencyValue: number } }> } };

export type TokenSwapPricesQueryVariables = Exact<{
  incoming: Scalars['Address']['input'];
  network: Deployment;
  outgoing: Scalars['Address']['input'];
  quantity: Scalars['BigInt']['input'];
}>;


export type TokenSwapPricesQuery = { __typename: 'Query', tokenSwapPrices: Array<{ __typename: 'TokenSwapPrices', exchange: Exchange, status: string, amount?: string | null, price?: number | null, reason?: string | null, bestRoute?: string | null, fastTransactionRequired?: boolean | null, fromUsd?: number | null, toUsd?: number | null }> };

export type TokenPriceInNativeCurrencyQueryVariables = Exact<{
  token: Scalars['Address']['input'];
  network: Deployment;
  quantity: Scalars['BigInt']['input'];
}>;


export type TokenPriceInNativeCurrencyQuery = { __typename: 'Query', tokenPriceInNativeCurrency: { __typename: 'TokenPriceInNativeCurrency', exchange: Exchange, status: string, amount?: string | null, price?: number | null, reason?: string | null, bestRoute?: string | null, fastTransactionRequired?: boolean | null, fromUsd?: number | null, toUsd?: number | null } };

export type TokenSwapPriceQueryVariables = Exact<{
  exchange: Exchange;
  incoming: Scalars['Address']['input'];
  network: Deployment;
  outgoing: Scalars['Address']['input'];
  quantity: Scalars['BigInt']['input'];
}>;


export type TokenSwapPriceQuery = { __typename: 'Query', tokenSwapPrice: { __typename: 'TokenSwapPrice', exchange: Exchange, status: string, amount?: string | null, price?: number | null, reason?: string | null, bestRoute?: string | null, fastTransactionRequired?: boolean | null, fromUsd?: number | null, toUsd?: number | null } };

export type ReferencePriceQueryVariables = Exact<{
  incoming: Scalars['Address']['input'];
  network: Deployment;
  outgoing: Scalars['Address']['input'];
}>;


export type ReferencePriceQuery = { __typename: 'Query', referencePrice: { __typename: 'ReferencePrice', status: string, price?: number | null, reason?: string | null } };

export type TokenSwapTransactionQueryVariables = Exact<{
  vaultProxy: Scalars['Address']['input'];
  comptrollerProxy: Scalars['Address']['input'];
  exchange: Exchange;
  expectedIncomingAmount: Scalars['BigInt']['input'];
  incoming: Scalars['Address']['input'];
  maxSlippage: Scalars['Float']['input'];
  network: Deployment;
  originAddress?: InputMaybe<Scalars['Address']['input']>;
  outgoing: Scalars['Address']['input'];
  quantity: Scalars['BigInt']['input'];
  userAddress: Scalars['Address']['input'];
}>;


export type TokenSwapTransactionQuery = { __typename: 'Query', tokenSwapTransaction: { __typename: 'TokenSwapTransaction', reason?: string | null, status: string, transaction?: { __typename: 'Transaction', data: BytesLike, to: Address, value?: string | null } | null } };

export type NativeTokenDepositTransactionQueryVariables = Exact<{
  comptrollerProxy: Scalars['Address']['input'];
  expectedDenominationAmount: Scalars['BigInt']['input'];
  incoming: Scalars['Address']['input'];
  maxSlippage: Scalars['Float']['input'];
  network: Deployment;
  originAddress?: InputMaybe<Scalars['Address']['input']>;
  quantity: Scalars['BigInt']['input'];
  userAddress: Scalars['Address']['input'];
}>;


export type NativeTokenDepositTransactionQuery = { __typename: 'Query', nativeTokenDepositTransaction: { __typename: 'TokenSwapTransaction', reason?: string | null, status: string, transaction?: { __typename: 'Transaction', data: BytesLike, to: Address, value?: string | null } | null } };

export type Erc20TokenDepositTransactionQueryVariables = Exact<{
  comptrollerProxy: Scalars['Address']['input'];
  expectedDenominationAmount: Scalars['BigInt']['input'];
  incoming: Scalars['Address']['input'];
  outgoing: Scalars['Address']['input'];
  maxSlippage: Scalars['Float']['input'];
  network: Deployment;
  originAddress?: InputMaybe<Scalars['Address']['input']>;
  quantity: Scalars['BigInt']['input'];
  userAddress: Scalars['Address']['input'];
}>;


export type Erc20TokenDepositTransactionQuery = { __typename: 'Query', erc20TokenDepositTransaction: { __typename: 'TokenSwapTransaction', reason?: string | null, status: string, transaction?: { __typename: 'Transaction', data: BytesLike, to: Address, value?: string | null } | null } };

export type TokenSwapAndReferencePricesQueryVariables = Exact<{
  comptroller: Scalars['Address']['input'];
  currency: Scalars['CurrencyOrAddress']['input'];
  incoming: Scalars['Address']['input'];
  network: Deployment;
  outgoing: Scalars['Address']['input'];
  quantity: Scalars['BigInt']['input'];
}>;


export type TokenSwapAndReferencePricesQuery = { __typename: 'Query', tokenSwapAndReferencePrices: { __typename: 'TokenSwapAndReferencePrices', outgoingValueInCurrency: number, currentCumulativeSlippagePolicy: { __typename: 'CumulativeSlippagePolicy', currentCumulativeSlippage: string, enabled: boolean, tolerance: string }, referencePrice: { __typename: 'ReferencePrice', status: string, price?: number | null, reason?: string | null }, tokenSwapPrices: Array<{ __typename: 'TokenSwapPrices', exchange: Exchange, status: string, amount?: string | null, price?: number | null, reason?: string | null, bestRoute?: string | null, fastTransactionRequired?: boolean | null, fromUsd?: number | null, toUsd?: number | null, incomingValueInCurrency?: number | null, isBadTrade?: boolean | null, slippage?: number | null }> } };

export type UniswapV2PoolsQueryVariables = Exact<{
  currency: Currency;
  network: Deployment;
}>;


export type UniswapV2PoolsQuery = { __typename: 'Query', uniswapV2Pools?: Array<{ __typename: 'SinglePoolData', address: Address, poolSize: number, baseApy: number, totalApy: number, rewardApys: Array<{ __typename: 'RewardApy', assetAddress: Address, apy: number }> }> | null };

export type UniswapV3VaultLiquidityPositionsQueryVariables = Exact<{
  deployment: Deployment;
  vaultId: Scalars['Address']['input'];
}>;


export type UniswapV3VaultLiquidityPositionsQuery = { __typename: 'Query', uniswapV3VaultLiquidityPositions: Array<{ __typename: 'UniswapV3VaultLiquidityPosition', nfts: Array<{ __typename: 'UniswapV3Nft', externalPositionId?: Address | null, feeTier: number, id: string, liquidity: string, nftTokenUri: string, poolAddress: Address, poolCurrentTick?: number | null, tickLower: number, tickUpper: number, amount0: string, amount1: string, token0: Address, token1: Address }> }> };

export type UniswapV3ExternalPositionsAddressesQueryVariables = Exact<{
  deployment: Deployment;
  vaultId: Scalars['Address']['input'];
}>;


export type UniswapV3ExternalPositionsAddressesQuery = { __typename: 'Query', uniswapV3VaultLiquidityPositions: Array<{ __typename: 'UniswapV3VaultLiquidityPosition', externalPositionId?: Address | null }> };

export type UniswapV3FeeTierDistributionQueryVariables = Exact<{
  deployment: Deployment;
  token0: Scalars['Address']['input'];
  token1: Scalars['Address']['input'];
}>;


export type UniswapV3FeeTierDistributionQuery = { __typename: 'Query', uniswapV3FeeTierDistribution: Array<{ __typename: 'UniswapV3FeeTierDistribution', feeTier: number, id: Address, percentage?: number | null }> };

export type UniswapV3PoolDetailsQueryVariables = Exact<{
  deployment: Deployment;
  poolAddress: Scalars['Address']['input'];
  fetchCurrentTickOnChain: Scalars['Boolean']['input'];
}>;


export type UniswapV3PoolDetailsQuery = { __typename: 'Query', uniswapV3PoolDetails: { __typename: 'UniswapV3PoolDetails', currentTick: number, ticks: Array<{ __typename: 'UniswapV3Tick', tickIdx: number, liquidityNet: string }> } };

export type UniswapV3NftQueryVariables = Exact<{
  deployment: Deployment;
  nftId: Scalars['String']['input'];
}>;


export type UniswapV3NftQuery = { __typename: 'Query', uniswapV3NftDetails?: { __typename: 'UniswapV3Nft', externalPositionId?: Address | null, feeTier: number, id: string, liquidity: string, nftTokenUri: string, pendingFees0?: string | null, pendingFees1?: string | null, poolAddress: Address, poolCurrentTick?: number | null, poolLiquidity?: string | null, tickLower: number, tickUpper: number, amount0: string, amount1: string, token0: Address, token1: Address } | null };

export type UniswapV3UtilsTokenAmountsQueryVariables = Exact<{
  deployment: Deployment;
  feeTier: Scalars['Int']['input'];
  positionLiquidity: Scalars['BigInt']['input'];
  tickLower: Scalars['Int']['input'];
  tickUpper: Scalars['Int']['input'];
  token0: Scalars['Address']['input'];
  token1: Scalars['Address']['input'];
  pool: Scalars['Address']['input'];
}>;


export type UniswapV3UtilsTokenAmountsQuery = { __typename: 'Query', uniswapV3UtilsTokenAmounts: { __typename: 'UniswapV3TokenAmounts', amount0: string, amount1: string } };

export type UniswapV3UtilsTokenAmountsFromAmount0QueryVariables = Exact<{
  deployment: Deployment;
  feeTier: Scalars['Int']['input'];
  amount0: Scalars['BigInt']['input'];
  tickLower: Scalars['Int']['input'];
  tickUpper: Scalars['Int']['input'];
  asset0: Scalars['Address']['input'];
  asset1: Scalars['Address']['input'];
  pool: Scalars['Address']['input'];
}>;


export type UniswapV3UtilsTokenAmountsFromAmount0Query = { __typename: 'Query', uniswapV3UtilsTokenAmountsFromAmount0?: { __typename: 'UniswapV3TokenAmounts', amount0: string, amount1: string } | null };

export type UniswapV3UtilsTokenAmountsFromAmount1QueryVariables = Exact<{
  deployment: Deployment;
  feeTier: Scalars['Int']['input'];
  amount1: Scalars['BigInt']['input'];
  tickLower: Scalars['Int']['input'];
  tickUpper: Scalars['Int']['input'];
  asset0: Scalars['Address']['input'];
  asset1: Scalars['Address']['input'];
  pool: Scalars['Address']['input'];
}>;


export type UniswapV3UtilsTokenAmountsFromAmount1Query = { __typename: 'Query', uniswapV3UtilsTokenAmountsFromAmount1?: { __typename: 'UniswapV3TokenAmounts', amount0: string, amount1: string } | null };

export type UserRepresentativeApplicationInfoFragment = { __typename: 'UserRepresentativeApplication', id: string, email?: string | null, name: string, experience: string, motivation: string, miscExperience: string, aumPercentage: number, status: UserRepresentativeApplicationStatus, account: { __typename: 'Account', id: string }, votes: Array<{ __typename: 'UserRepresentativeVote', id: string }> };

export type UserRepresentativeVoteInfoFragment = { __typename: 'UserRepresentativeVote', createdAt: Date, id: string, account: { __typename: 'Account', id: string } };

export type UserRepresentativeElectionInfoFragment = { __typename: 'UserRepresentativeElection', id: string, name: string, description?: string | null, eligible: boolean, applyingStartDate: Date, applyingEndDate: Date, votingStartDate: Date, votingEndDate: Date, applications: Array<{ __typename: 'UserRepresentativeApplication', id: string, email?: string | null, name: string, experience: string, motivation: string, miscExperience: string, aumPercentage: number, status: UserRepresentativeApplicationStatus, account: { __typename: 'Account', id: string }, votes: Array<{ __typename: 'UserRepresentativeVote', id: string }> }>, votes: Array<{ __typename: 'UserRepresentativeVote', createdAt: Date, id: string, account: { __typename: 'Account', id: string } }> };

export type UserRepresentativeElectionsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserRepresentativeElectionsQuery = { __typename: 'Query', userRepresentativeElections: Array<{ __typename: 'UserRepresentativeElection', id: string, name: string, description?: string | null, eligible: boolean, applyingStartDate: Date, applyingEndDate: Date, votingStartDate: Date, votingEndDate: Date, applications: Array<{ __typename: 'UserRepresentativeApplication', id: string, email?: string | null, name: string, experience: string, motivation: string, miscExperience: string, aumPercentage: number, status: UserRepresentativeApplicationStatus, account: { __typename: 'Account', id: string }, votes: Array<{ __typename: 'UserRepresentativeVote', id: string }> }>, votes: Array<{ __typename: 'UserRepresentativeVote', createdAt: Date, id: string, account: { __typename: 'Account', id: string } }> }> };

export type UserRepresentativeElectionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type UserRepresentativeElectionQuery = { __typename: 'Query', userRepresentativeElection: { __typename: 'UserRepresentativeElection', id: string, name: string, description?: string | null, eligible: boolean, applyingStartDate: Date, applyingEndDate: Date, votingStartDate: Date, votingEndDate: Date, applications: Array<{ __typename: 'UserRepresentativeApplication', id: string, email?: string | null, name: string, experience: string, motivation: string, miscExperience: string, aumPercentage: number, status: UserRepresentativeApplicationStatus, account: { __typename: 'Account', id: string }, votes: Array<{ __typename: 'UserRepresentativeVote', id: string }> }>, votes: Array<{ __typename: 'UserRepresentativeVote', createdAt: Date, id: string, account: { __typename: 'Account', id: string } }> } };

export type CreateUserRepresentativeElectionMutationVariables = Exact<{
  input: CreateUserRepresentativeElectionInput;
}>;


export type CreateUserRepresentativeElectionMutation = { __typename: 'Mutation', createUserRepresentativeElection: { __typename: 'CreateUserRepresentativeElectionPayload', userRepresentativeElection?: { __typename: 'UserRepresentativeElection', id: string, name: string, description?: string | null, eligible: boolean, applyingStartDate: Date, applyingEndDate: Date, votingStartDate: Date, votingEndDate: Date, applications: Array<{ __typename: 'UserRepresentativeApplication', id: string, email?: string | null, name: string, experience: string, motivation: string, miscExperience: string, aumPercentage: number, status: UserRepresentativeApplicationStatus, account: { __typename: 'Account', id: string }, votes: Array<{ __typename: 'UserRepresentativeVote', id: string }> }>, votes: Array<{ __typename: 'UserRepresentativeVote', createdAt: Date, id: string, account: { __typename: 'Account', id: string } }> } | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type DeleteUserRepresentativeElectionMutationVariables = Exact<{
  input: DeleteUserRepresentativeElectionInput;
}>;


export type DeleteUserRepresentativeElectionMutation = { __typename: 'Mutation', deleteUserRepresentativeElection: { __typename: 'DeleteUserRepresentativeElectionPayload', errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type UpdateUserRepresentativeElectionMutationVariables = Exact<{
  input: UpdateUserRepresentativeElectionInput;
}>;


export type UpdateUserRepresentativeElectionMutation = { __typename: 'Mutation', updateUserRepresentativeElection: { __typename: 'UpdateUserRepresentativeElectionPayload', userRepresentativeElection?: { __typename: 'UserRepresentativeElection', id: string, name: string, description?: string | null, eligible: boolean, applyingStartDate: Date, applyingEndDate: Date, votingStartDate: Date, votingEndDate: Date, applications: Array<{ __typename: 'UserRepresentativeApplication', id: string, email?: string | null, name: string, experience: string, motivation: string, miscExperience: string, aumPercentage: number, status: UserRepresentativeApplicationStatus, account: { __typename: 'Account', id: string }, votes: Array<{ __typename: 'UserRepresentativeVote', id: string }> }>, votes: Array<{ __typename: 'UserRepresentativeVote', createdAt: Date, id: string, account: { __typename: 'Account', id: string } }> } | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type CreateUserRepresentativeApplicationMutationVariables = Exact<{
  input: CreateUserRepresentativeApplicationInput;
}>;


export type CreateUserRepresentativeApplicationMutation = { __typename: 'Mutation', createUserRepresentativeApplication: { __typename: 'CreateUserRepresentativeApplicationPayload', userRepresentativeApplication?: { __typename: 'UserRepresentativeApplication', id: string, email?: string | null, name: string, experience: string, motivation: string, miscExperience: string, aumPercentage: number, status: UserRepresentativeApplicationStatus, account: { __typename: 'Account', id: string }, votes: Array<{ __typename: 'UserRepresentativeVote', id: string }> } | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type DeleteUserRepresentativeApplicationMutationVariables = Exact<{
  input: DeleteUserRepresentativeApplicationInput;
}>;


export type DeleteUserRepresentativeApplicationMutation = { __typename: 'Mutation', deleteUserRepresentativeApplication: { __typename: 'DeleteUserRepresentativeApplicationPayload', errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type UpdateUserRepresentativeApplicationMutationVariables = Exact<{
  input: UpdateUserRepresentativeApplicationInput;
}>;


export type UpdateUserRepresentativeApplicationMutation = { __typename: 'Mutation', updateUserRepresentativeApplication: { __typename: 'UpdateUserRepresentativeApplicationPayload', userRepresentativeApplication?: { __typename: 'UserRepresentativeApplication', id: string, email?: string | null, name: string, experience: string, motivation: string, miscExperience: string, aumPercentage: number, status: UserRepresentativeApplicationStatus, account: { __typename: 'Account', id: string }, votes: Array<{ __typename: 'UserRepresentativeVote', id: string }> } | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type UpdateUserRepresentativeApplicationStatusMutationVariables = Exact<{
  input: UpdateUserRepresentativeApplicationStatusInput;
}>;


export type UpdateUserRepresentativeApplicationStatusMutation = { __typename: 'Mutation', updateUserRepresentativeApplicationStatus: { __typename: 'UpdateUserRepresentativeApplicationStatusPayload', userRepresentativeApplication?: { __typename: 'UserRepresentativeApplication', id: string, email?: string | null, name: string, experience: string, motivation: string, miscExperience: string, aumPercentage: number, status: UserRepresentativeApplicationStatus, account: { __typename: 'Account', id: string }, votes: Array<{ __typename: 'UserRepresentativeVote', id: string }> } | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type VoteUserRepresentativeMutationVariables = Exact<{
  input: VoteUserRepresentativeInput;
}>;


export type VoteUserRepresentativeMutation = { __typename: 'Mutation', voteUserRepresentative: { __typename: 'VoteUserRepresentativePayload', userRepresentativeVote?: { __typename: 'UserRepresentativeVote', createdAt: Date, id: string, account: { __typename: 'Account', id: string } } | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type VaultMonthlyReturnsQueryVariables = Exact<{
  currency: Scalars['CurrencyOrAddress']['input'];
  network: Deployment;
  vault: Scalars['Address']['input'];
}>;


export type VaultMonthlyReturnsQuery = { __typename: 'Query', vaultMonthlyReturns: Array<{ __typename: 'VaultMonthlyReturn', yearMonth?: number | null, year: number, month: number, monthlyReturn: number, valid: boolean }> };

export type VaultBalancesQueryVariables = Exact<{
  currency: Scalars['CurrencyOrAddress']['input'];
  network: Deployment;
  vault: Scalars['Address']['input'];
}>;


export type VaultBalancesQuery = { __typename: 'Query', vaultBalances: Array<{ __typename: 'VaultBalance', asset: Address, balance: number, value: number, valid: boolean, price: number }> };

export type VaultUntrackedBalancesQueryVariables = Exact<{
  currency: Scalars['CurrencyOrAddress']['input'];
  network: Deployment;
  vault: Scalars['Address']['input'];
}>;


export type VaultUntrackedBalancesQuery = { __typename: 'Query', vaultUntrackedBalances: Array<{ __typename: 'VaultBalance', asset: Address, balance: number, value: number, valid: boolean, price: number }> };

export type VaultsQueryVariables = Exact<{
  currency: Currency;
  first?: InputMaybe<Scalars['Int']['input']>;
  network: Deployment;
  vaultAddresses?: InputMaybe<Array<Scalars['Address']['input']> | Scalars['Address']['input']>;
}>;


export type VaultsQuery = { __typename: 'Query', vaultList: { __typename: 'QueryVaultListConnection', nodes: Array<{ __typename: 'VaultListItem', name?: string | null, address: Address, valid?: boolean | null, nsvCurrent?: number | null, gavCurrent?: number | null, numberOfAssets?: number | null, assets?: Array<Address | null> | null, assetPercentages?: Array<number | null> | null, performance24h?: number | null, performanceThisMonth?: number | null, performanceThisQuarter?: number | null, performanceSinceInception?: number | null, numberOfDepositors?: number | null }> } };

export type VaultsForDepositorQueryVariables = Exact<{
  currency: Currency;
  first?: InputMaybe<Scalars['Int']['input']>;
  network: Deployment;
}>;


export type VaultsForDepositorQuery = { __typename: 'Query', vaultListForDepositor: { __typename: 'QueryVaultListForDepositorConnection', aggregated: { __typename: 'VaultListForDepositorAggregated', avgPerformanceThisMonth: number, totalVaultsDeposited: number, totalDepositorValue: number }, nodes: Array<{ __typename: 'VaultListItem', name?: string | null, address: Address, valid?: boolean | null, nsvCurrent?: number | null, gavCurrent?: number | null, numberOfAssets?: number | null, assets?: Array<Address | null> | null, assetPercentages?: Array<number | null> | null, performance24h?: number | null, performanceThisMonth?: number | null, performanceThisQuarter?: number | null, performanceSinceInception?: number | null, numberOfDepositors?: number | null, supply?: number | null, shareBalance?: number | null, depositorValue?: number | null }> } };

export type VaultsForManagerQueryVariables = Exact<{
  currency: Currency;
  first?: InputMaybe<Scalars['Int']['input']>;
  network: Deployment;
}>;


export type VaultsForManagerQuery = { __typename: 'Query', vaultListForManager: { __typename: 'QueryVaultListForManagerConnection', aggregated: { __typename: 'VaultListForManagerAggregated', avgPerformanceThisMonth: number, totalNumberOfDepositors: number, totalGrossAssetValue: number }, nodes: Array<{ __typename: 'VaultListItem', name?: string | null, address: Address, valid?: boolean | null, nsvCurrent?: number | null, gavCurrent?: number | null, numberOfAssets?: number | null, assets?: Array<Address | null> | null, assetPercentages?: Array<number | null> | null, performance24h?: number | null, performanceThisMonth?: number | null, performanceThisQuarter?: number | null, performanceSinceInception?: number | null, numberOfDepositors?: number | null, supply?: number | null, shareBalance?: number | null }> } };

export type DiscoverVaultsQueryVariables = Exact<{
  currency: Currency;
  first: Scalars['Int']['input'];
  network: Deployment;
}>;


export type DiscoverVaultsQuery = { __typename: 'Query', topGainers: { __typename: 'QueryVaultListConnection', nodes: Array<{ __typename: 'VaultListItem', name?: string | null, address: Address, valid?: boolean | null, nsvCurrent?: number | null, gavCurrent?: number | null, numberOfDepositors?: number | null, performance24h?: number | null }> }, latest: { __typename: 'QueryVaultListConnection', nodes: Array<{ __typename: 'VaultListItem', name?: string | null, address: Address, valid?: boolean | null, nsvCurrent?: number | null, gavCurrent?: number | null, numberOfDepositors?: number | null, performance24h?: number | null }> }, mostPopular: { __typename: 'QueryVaultListConnection', nodes: Array<{ __typename: 'VaultListItem', name?: string | null, address: Address, valid?: boolean | null, nsvCurrent?: number | null, gavCurrent?: number | null, numberOfDepositors?: number | null, performance24h?: number | null }> }, largest: { __typename: 'QueryVaultListConnection', nodes: Array<{ __typename: 'VaultListItem', name?: string | null, address: Address, valid?: boolean | null, nsvCurrent?: number | null, gavCurrent?: number | null, numberOfDepositors?: number | null, performance24h?: number | null }> } };

export type VaultPerformanceLatestQueryVariables = Exact<{
  currency: Scalars['CurrencyOrAddress']['input'];
  network: Deployment;
  vault: Scalars['Address']['input'];
}>;


export type VaultPerformanceLatestQuery = { __typename: 'Query', vaultPerformanceLatest?: { __typename: 'VaultPerformanceLatest', supply?: number | null, netShareValue?: number | null, valid: boolean, grossAssetValue?: number | null, netAssetValue?: number | null, performance24h?: number | null, performanceMtd?: number | null, performanceQtd?: number | null, performanceYtd?: number | null, performanceSinceInception?: number | null } | null };

export type VaultRiskMetricsQueryVariables = Exact<{
  currency: Scalars['CurrencyOrAddress']['input'];
  network: Deployment;
  vault: Scalars['Address']['input'];
}>;


export type VaultRiskMetricsQuery = { __typename: 'Query', vaultRiskMetrics?: { __typename: 'VaultRiskMetrics', numberOfDays: number, averageDailyReturn?: number | null, dailyVolatility?: number | null, annualizedVolatility?: number | null, annualizedSharpeRatio?: number | null } | null };

export type ArbitraryLoansEnabledForVaultQueryVariables = Exact<{
  network: Deployment;
  comptroller: Scalars['Address']['input'];
  ownerId: Scalars['Address']['input'];
}>;


export type ArbitraryLoansEnabledForVaultQuery = { __typename: 'Query', arbitraryLoansEnabledForVault: { __typename: 'ArbitraryLoansEnabledForVault', isFeatureEnabled: boolean } };

export type VaultDepositorMetricsQueryVariables = Exact<{
  currency: Scalars['CurrencyOrAddress']['input'];
  network: Deployment;
  vault: Scalars['Address']['input'];
  depositor: Scalars['Address']['input'];
}>;


export type VaultDepositorMetricsQuery = { __typename: 'Query', vaultDepositorMetrics?: { __typename: 'VaultDepositorMetrics', amountDeposited?: number | null, amountRedeemed?: number | null, costBaseFifo?: number | null, costBaseLifo?: number | null, currentShares?: number | null, currentValue?: number | null, sharesBought?: number | null, sharesRedeemed?: number | null, sharesReceivedFromFees?: number | null, sharesTransferredIn?: number | null, sharesTransferredOut?: number | null, totalReturn?: number | null } | null };

export type VaultActiveProtocolIntegrationsQueryVariables = Exact<{
  network: Deployment;
  vault: Scalars['Address']['input'];
}>;


export type VaultActiveProtocolIntegrationsQuery = { __typename: 'Query', activeProtocolIntegrations?: { __typename: 'ActiveIntegrations', activeIntegrations: Array<string> } | null };

export type VaultDepositorSignatureDetailFragment = { __typename: 'VaultDepositorSignature', id: string, message: string, signature: string };

export type IsDepositorSignatureRequiredQueryVariables = Exact<{
  vaultAddress: Scalars['Address']['input'];
  walletAddress: Scalars['Address']['input'];
}>;


export type IsDepositorSignatureRequiredQuery = { __typename: 'Query', isDepositorSignatureRequired: boolean };

export type SubmitDepositorSignatureMutationVariables = Exact<{
  input: SubmitDepositorSignatureInput;
}>;


export type SubmitDepositorSignatureMutation = { __typename: 'Mutation', submitDepositorSignature: { __typename: 'SubmitDepositorSignaturePayload', vaultDepositorSignature: { __typename: 'VaultDepositorSignature', id: string, message: string, signature: string }, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type VaultStrategyInfoFragment = { __typename: 'VaultStrategy', description?: string | null, name: string };

export type VaultStrategiesQueryVariables = Exact<{ [key: string]: never; }>;


export type VaultStrategiesQuery = { __typename: 'Query', vaultStrategies: Array<{ __typename: 'VaultStrategy', id: string, description?: string | null, name: string }> };

export type VaultProfileInfoFragment = { __typename: 'VaultProfile', avatarUrl?: string | null, tagline?: string | null, description?: string | null, managerDescription?: string | null, contactInfo?: string | null, email?: string | null, telegram?: string | null, websiteUrl?: string | null, twitter?: string | null, requireDepositorSignature: boolean, depositorSignatureMessage?: string | null, vaultStrategies: Array<{ __typename: 'VaultStrategy', id: string, description?: string | null, name: string }> };

export type VaultProfileQueryVariables = Exact<{
  vaultAddress: Scalars['Address']['input'];
}>;


export type VaultProfileQuery = { __typename: 'Query', vaultProfile?: { __typename: 'VaultProfile', id: string, avatarUrl?: string | null, tagline?: string | null, description?: string | null, managerDescription?: string | null, contactInfo?: string | null, email?: string | null, telegram?: string | null, websiteUrl?: string | null, twitter?: string | null, requireDepositorSignature: boolean, depositorSignatureMessage?: string | null, vaultStrategies: Array<{ __typename: 'VaultStrategy', id: string, description?: string | null, name: string }> } | null };

export type UpdateVaultProfileMutationVariables = Exact<{
  input: UpdateVaultProfileInput;
}>;


export type UpdateVaultProfileMutation = { __typename: 'Mutation', updateVaultProfile: { __typename: 'UpdateVaultProfilePayload', vaultProfile?: { __typename: 'VaultProfile', id: string, avatarUrl?: string | null, tagline?: string | null, description?: string | null, managerDescription?: string | null, contactInfo?: string | null, email?: string | null, telegram?: string | null, websiteUrl?: string | null, twitter?: string | null, requireDepositorSignature: boolean, depositorSignatureMessage?: string | null, vaultStrategies: Array<{ __typename: 'VaultStrategy', id: string, description?: string | null, name: string }> } | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type WalletClaimsQueryVariables = Exact<{ [key: string]: never; }>;


export type WalletClaimsQuery = { __typename: 'Query', me?: { __typename: 'Account', id: string, walletClaims: Array<{ __typename: 'WalletClaim', id: string, label?: string | null, wallet: { __typename: 'Wallet', id: string, address: Address } }> } | null };

export type WalletClaimsAuthQueryVariables = Exact<{
  network: Network;
}>;


export type WalletClaimsAuthQuery = { __typename: 'Query', me?: { __typename: 'Account', id: string, walletClaims: Array<{ __typename: 'WalletClaim', auth?: boolean | null, id: string, label?: string | null, wallet: { __typename: 'Wallet', id: string, address: Address } }> } | null };

export type WalletClaimInfoFragment = { __typename: 'WalletClaim', id: string, label?: string | null, wallet: { __typename: 'Wallet', id: string, address: Address } };

export type CreateWalletClaimMutationVariables = Exact<{
  input: CreateWalletClaimInput;
}>;


export type CreateWalletClaimMutation = { __typename: 'Mutation', createWalletClaim: { __typename: 'CreateWalletClaimPayload', walletClaim?: { __typename: 'WalletClaim', id: string, label?: string | null, wallet: { __typename: 'Wallet', id: string, address: Address } } | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type DeleteWalletClaimMutationVariables = Exact<{
  input: DeleteWalletClaimInput;
}>;


export type DeleteWalletClaimMutation = { __typename: 'Mutation', deleteWalletClaim: { __typename: 'DeleteWalletClaimPayload', walletClaim?: { __typename: 'WalletClaim', id: string } | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type UpdateWalletClaimMutationVariables = Exact<{
  input: UpdateWalletClaimInput;
}>;


export type UpdateWalletClaimMutation = { __typename: 'Mutation', updateWalletClaim: { __typename: 'UpdateWalletClaimPayload', walletClaim?: { __typename: 'WalletClaim', id: string, label?: string | null, wallet: { __typename: 'Wallet', id: string, address: Address } } | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type WalletClaimMessageInfoFragment = { __typename: 'WalletClaimMessage', id: string, message: string, timestamp: number };

export type CreateWalletClaimMessageMutationVariables = Exact<{
  input: CreateWalletClaimMessageInput;
}>;


export type CreateWalletClaimMessageMutation = { __typename: 'Mutation', createWalletClaimMessage: { __typename: 'CreateWalletClaimMessagePayload', walletClaimMessage?: { __typename: 'WalletClaimMessage', id: string, message: string, timestamp: number } | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type WhiteLabelInfoFragment = { __typename: 'WhiteLabel', id: string, name: string, profile?: { __typename: 'WhiteLabelProfile', id: string, contactInfo?: string | null, description?: string | null } | null, settings?: { __typename: 'WhiteLabelSettings', colorScheme?: ColorScheme | null, cover?: string | null, id: string, logo?: string | null, primaryColor?: string | null, secondaryColor?: string | null, subdomain: string } | null, vaults: Array<{ __typename: 'Vault', address: Address, id: string }> };

export type WhiteLabelsQueryVariables = Exact<{ [key: string]: never; }>;


export type WhiteLabelsQuery = { __typename: 'Query', me?: { __typename: 'Account', id: string, whiteLabels: Array<{ __typename: 'WhiteLabel', id: string, name: string, profile?: { __typename: 'WhiteLabelProfile', id: string, contactInfo?: string | null, description?: string | null } | null, settings?: { __typename: 'WhiteLabelSettings', colorScheme?: ColorScheme | null, cover?: string | null, id: string, logo?: string | null, primaryColor?: string | null, secondaryColor?: string | null, subdomain: string } | null, vaults: Array<{ __typename: 'Vault', address: Address, id: string }> }> } | null };

export type WhiteLabelBySubdomainQueryVariables = Exact<{
  subdomain: Scalars['String']['input'];
}>;


export type WhiteLabelBySubdomainQuery = { __typename: 'Query', whiteLabelBySubdomain?: { __typename: 'WhiteLabel', isOwner: boolean, id: string, name: string, profile?: { __typename: 'WhiteLabelProfile', id: string, contactInfo?: string | null, description?: string | null } | null, settings?: { __typename: 'WhiteLabelSettings', colorScheme?: ColorScheme | null, cover?: string | null, id: string, logo?: string | null, primaryColor?: string | null, secondaryColor?: string | null, subdomain: string } | null, vaults: Array<{ __typename: 'Vault', address: Address, id: string }> } | null };

export type WhiteLabelByIdQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type WhiteLabelByIdQuery = { __typename: 'Query', whiteLabelById?: { __typename: 'WhiteLabel', isOwner: boolean, id: string, name: string, profile?: { __typename: 'WhiteLabelProfile', id: string, contactInfo?: string | null, description?: string | null } | null, settings?: { __typename: 'WhiteLabelSettings', colorScheme?: ColorScheme | null, cover?: string | null, id: string, logo?: string | null, primaryColor?: string | null, secondaryColor?: string | null, subdomain: string } | null, vaults: Array<{ __typename: 'Vault', address: Address, id: string }> } | null };

export type CreateWhiteLabelMutationVariables = Exact<{
  input: CreateWhiteLabelInput;
}>;


export type CreateWhiteLabelMutation = { __typename: 'Mutation', createWhiteLabel: { __typename: 'CreateWhiteLabelPayload', whiteLabel?: { __typename: 'WhiteLabel', id: string, name: string, profile?: { __typename: 'WhiteLabelProfile', id: string, contactInfo?: string | null, description?: string | null } | null, settings?: { __typename: 'WhiteLabelSettings', colorScheme?: ColorScheme | null, cover?: string | null, id: string, logo?: string | null, primaryColor?: string | null, secondaryColor?: string | null, subdomain: string } | null, vaults: Array<{ __typename: 'Vault', address: Address, id: string }> } | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type UpdateWhiteLabelMutationVariables = Exact<{
  input: UpdateWhiteLabelInput;
}>;


export type UpdateWhiteLabelMutation = { __typename: 'Mutation', updateWhiteLabel: { __typename: 'UpdateWhiteLabelPayload', whiteLabel?: { __typename: 'WhiteLabel', id: string, name: string, profile?: { __typename: 'WhiteLabelProfile', id: string, contactInfo?: string | null, description?: string | null } | null, settings?: { __typename: 'WhiteLabelSettings', colorScheme?: ColorScheme | null, cover?: string | null, id: string, logo?: string | null, primaryColor?: string | null, secondaryColor?: string | null, subdomain: string } | null, vaults: Array<{ __typename: 'Vault', address: Address, id: string }> } | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type UpdateWhiteLabelCoverMutationVariables = Exact<{
  input: UpdateWhiteLabelCoverInput;
}>;


export type UpdateWhiteLabelCoverMutation = { __typename: 'Mutation', updateWhiteLabelCover: { __typename: 'UpdateWhiteLabelCoverPayload', whiteLabel?: { __typename: 'WhiteLabel', id: string, name: string, profile?: { __typename: 'WhiteLabelProfile', id: string, contactInfo?: string | null, description?: string | null } | null, settings?: { __typename: 'WhiteLabelSettings', colorScheme?: ColorScheme | null, cover?: string | null, id: string, logo?: string | null, primaryColor?: string | null, secondaryColor?: string | null, subdomain: string } | null, vaults: Array<{ __typename: 'Vault', address: Address, id: string }> } | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type UpdateWhiteLabelLogoMutationVariables = Exact<{
  input: UpdateWhiteLabelLogoInput;
}>;


export type UpdateWhiteLabelLogoMutation = { __typename: 'Mutation', updateWhiteLabelLogo: { __typename: 'UpdateWhiteLabelLogoPayload', whiteLabel?: { __typename: 'WhiteLabel', id: string, name: string, profile?: { __typename: 'WhiteLabelProfile', id: string, contactInfo?: string | null, description?: string | null } | null, settings?: { __typename: 'WhiteLabelSettings', colorScheme?: ColorScheme | null, cover?: string | null, id: string, logo?: string | null, primaryColor?: string | null, secondaryColor?: string | null, subdomain: string } | null, vaults: Array<{ __typename: 'Vault', address: Address, id: string }> } | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type UpdateWhiteLabelThemeMutationVariables = Exact<{
  input: UpdateWhiteLabelThemeInput;
}>;


export type UpdateWhiteLabelThemeMutation = { __typename: 'Mutation', updateWhiteLabelTheme: { __typename: 'UpdateWhiteLabelThemePayload', whiteLabel?: { __typename: 'WhiteLabel', id: string, name: string, profile?: { __typename: 'WhiteLabelProfile', id: string, contactInfo?: string | null, description?: string | null } | null, settings?: { __typename: 'WhiteLabelSettings', colorScheme?: ColorScheme | null, cover?: string | null, id: string, logo?: string | null, primaryColor?: string | null, secondaryColor?: string | null, subdomain: string } | null, vaults: Array<{ __typename: 'Vault', address: Address, id: string }> } | null, errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type DeleteWhiteLabelMutationVariables = Exact<{
  input: DeleteWhiteLabelInput;
}>;


export type DeleteWhiteLabelMutation = { __typename: 'Mutation', deleteWhiteLabel: { __typename: 'DeleteWhiteLabelPayload', errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type DeleteWhiteLabelCoverMutationVariables = Exact<{
  input: DeleteWhiteLabelCoverInput;
}>;


export type DeleteWhiteLabelCoverMutation = { __typename: 'Mutation', deleteWhiteLabelCover: { __typename: 'DeleteWhiteLabelCoverPayload', errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type DeleteWhiteLabelLogoMutationVariables = Exact<{
  input: DeleteWhiteLabelLogoInput;
}>;


export type DeleteWhiteLabelLogoMutation = { __typename: 'Mutation', deleteWhiteLabelLogo: { __typename: 'DeleteWhiteLabelLogoPayload', errors?: Array<{ __typename: 'ArgValidationError', message: string, issues: Array<{ __typename: 'ArgValidationIssue', message: string, path: Array<string> }> } | { __typename: 'GenericUserError', message: string } | { __typename: 'RateLimitError', message: string }> | null } };

export type SingleYearnAssetLendingDataFragment = { __typename: 'SingleYearnAssetLendingData', assetAddress: Address, underlyingAddress: Address, apy?: number | null, tvl?: number | null };

export type LendingDataForYearnAssetsQueryVariables = Exact<{
  currency: Currency;
  network: Deployment;
}>;


export type LendingDataForYearnAssetsQuery = { __typename: 'Query', lendingDataForYearnAssets: Array<{ __typename: 'SingleYearnAssetLendingData', assetAddress: Address, underlyingAddress: Address, apy?: number | null, tvl?: number | null }> };

export const AccountProfileInfoFragmentDoc = gql`
    fragment AccountProfileInfo on AccountProfile {
  id
  username
  name
  avatarUrl
  telegram
  twitter
  websiteUrl
}
    `;
export const AccountInfoFragmentDoc = gql`
    fragment AccountInfo on Account {
  id
  accountProfile {
    ...AccountProfileInfo
  }
  email
  features
  accountRoles
}
    `;
export const AccountAuthInfoFragmentDoc = gql`
    fragment AccountAuthInfo on Account {
  id
  auth {
    authenticator
    backupCodes
    google {
      email
    }
    password
    wallets {
      address
      id
    }
  }
}
    `;
export const ApiTokenInfoFragmentDoc = gql`
    fragment ApiTokenInfo on ApiToken {
  id
  createdAt
  updatedAt
  name
}
    `;
export const RewardFragmentDoc = gql`
    fragment Reward on Reward {
  assetAddress
  claimable
  value
}
    `;
export const IndividualRewardFragmentDoc = gql`
    fragment IndividualReward on IndividualReward {
  address
  rewards {
    ...Reward
  }
}
    `;
export const CompoundV2AssetBalanceFragmentDoc = gql`
    fragment CompoundV2AssetBalance on CompoundV2AssetBalance {
  assetAddress
  amount
  currencyValue
}
    `;
export const ConvexVotingInfoFragmentDoc = gql`
    fragment ConvexVotingInfo on ConvexVotingInfo {
  lockedBalance
  unlockedBalance
  nextEpochStart
  hasAnyCvxCrvStakingRewards
  voteState {
    status
    currentEndTime
    nextStartTime
    nextEndTime
  }
  delegate
  votiumClaims {
    amount
    index
    merkleProof
    token
  }
  claimableRewards {
    assetAddress
    amount
    currencyValue
  }
  positions {
    amount
    epochsAmountInsideLocker
    status
    unlockTime
  }
}
    `;
export const ExternalPositionConvexVotingPortfolioDataFragmentDoc = gql`
    fragment ExternalPositionConvexVotingPortfolioData on ExternalPositionConvexVotingPortfolioData {
  valueChange24h
  lockedAsset {
    amount
    assetAddress
    currencyValue
  }
}
    `;
export const GenericUserErrorFragmentFragmentDoc = gql`
    fragment GenericUserErrorFragment on GenericUserError {
  message
}
    `;
export const ArgValidationErrorFragmentFragmentDoc = gql`
    fragment ArgValidationErrorFragment on ArgValidationError {
  message
  issues {
    message
    path
  }
}
    `;
export const RateLimitErrorFragmentFragmentDoc = gql`
    fragment RateLimitErrorFragment on RateLimitError {
  message
}
    `;
export const UserErrorFragmentFragmentDoc = gql`
    fragment UserErrorFragment on UserError {
  __typename
  ...GenericUserErrorFragment
  ...ArgValidationErrorFragment
  ...RateLimitErrorFragment
}
    `;
export const AssetBalanceCurrencyFragmentDoc = gql`
    fragment AssetBalanceCurrency on AssetBalanceCurrency {
  address
  amount
  currencyValue
}
    `;
export const ErrorResponseFragmentDoc = gql`
    fragment ErrorResponse on ErrorResponse {
  error
}
    `;
export const RewardApyFragmentDoc = gql`
    fragment RewardApy on RewardApy {
  assetAddress
  apy
}
    `;
export const SingleIdleAssetLendingDataFragmentDoc = gql`
    fragment SingleIdleAssetLendingData on SingleIdleAssetLendingData {
  assetAddress
  underlyingAddress
  baseApy
  totalApy
  tvl
  rewardApys {
    ...RewardApy
  }
}
    `;
export const KilnStakingPositionDataFragmentDoc = gql`
    fragment KilnStakingPositionData on KilnStakingPositionData {
  id
  active
  apy
  kilnStakingContractAddress
  validators {
    publicKey
    unstakeSignalled
    state
    executionLayerRewards
    consensusLayerRewards
  }
  stakedEth
  stakedEthCurrencyValue
  executionLayerRewards
  executionLayerRewardsCurrencyValue
  consensusLayerRewards
  consensusLayerRewardsCurrencyValue
  totalRewards
  totalRewardsCurrencyValue
  positionValuePaused
}
    `;
export const ExternalPositionKilnPortfolioDataFragmentDoc = gql`
    fragment ExternalPositionKilnPortfolioData on ExternalPositionKilnPortfolioData {
  stakedEthAmount
  stakedEthCurrencyValue
  netValue
  valueChange24h
}
    `;
export const LiquityAssetFragmentDoc = gql`
    fragment LiquityAsset on LiquityAsset {
  id
  name
  symbol
  decimals
}
    `;
export const LiquityAssetBalanceFragmentDoc = gql`
    fragment LiquityAssetBalance on LiquityAssetBalance {
  amount
  asset {
    ...LiquityAsset
  }
  currencyValue
  price
}
    `;
export const LiquityDebtPositionDataFragmentDoc = gql`
    fragment LiquityDebtPositionData on LiquityDebtPositionData {
  id
  active
  troveStatus
  borrowedAssetBalance {
    ...LiquityAssetBalance
  }
  collateralAssetBalance {
    ...LiquityAssetBalance
  }
  currentCollateralRatio
  maxSafeDecreaseInCollateral
  maxSafeIncreaseInBorrow
  safeCollateralRatio
}
    `;
export const ExternalPositionLiquityPortfolioDataFragmentDoc = gql`
    fragment ExternalPositionLiquityPortfolioData on ExternalPositionLiquityPortfolioData {
  borrowedAsset {
    assetAddress
    amount
    currencyValue
  }
  collateralAsset {
    assetAddress
    amount
    currencyValue
  }
  netValue
  valueChange24h
}
    `;
export const SingleMapleAssetLendingDataFragmentDoc = gql`
    fragment SingleMapleAssetLendingData on SingleMapleAssetLendingData {
  liquidityAssetAddress
  poolAddress
  poolSize
  poolName
  totalApy
  maxDeposit
  totalShares
  totalAssets
  totalUnrealizedLosses
}
    `;
export const MapleAssetFragmentDoc = gql`
    fragment MapleAsset on MapleAsset {
  balance
  address
}
    `;
export const SingleMaplePoolPositionDataFragmentDoc = gql`
    fragment SingleMaplePoolPositionData on SingleMaplePoolPositionData {
  pool
  liquidityAsset {
    ...MapleAsset
  }
  isActive
  poolName
  status
  shares
  maxDeposit
  unrealizedLosses
  totalShares
  totalAssets
  totalUnrealizedLosses
  lockedShares
  redeemableShares
  redeemableWithdrawalAmount
  requestedWithdrawalAmount
  exitWithdrawalWindowStart
  exitWithdrawalWindowEnd
  newWithdrawalRequestWindowStart
  newWithdrawalRequestWindowEnd
}
    `;
export const MaplePortfolioAssetFragmentDoc = gql`
    fragment MaplePortfolioAsset on MaplePortfolioAsset {
  assetAddress
  amount
  currencyValue
}
    `;
export const ExternalPositionMaplePortfolioDataFragmentDoc = gql`
    fragment ExternalPositionMaplePortfolioData on ExternalPositionMaplePortfolioData {
  netValue
  valueChange24h
  lentAssets {
    ...MaplePortfolioAsset
  }
}
    `;
export const ExternalPositionStakeWisePortfolioDataFragmentDoc = gql`
    fragment ExternalPositionStakeWisePortfolioData on ExternalPositionStakeWisePortfolioData {
  stakedEthAmount
  stakedEthCurrencyValue
  netValue
  valueChange24h
}
    `;
export const StakeWiseVaultHoldingDataFragmentDoc = gql`
    fragment StakeWiseVaultHoldingData on StakeWiseVaultHoldingData {
  active
  externalPositionAddress
  shares
  sharesCurrencyValue
  stakedEthAmount
  claimableShares
  unclaimableShares
  exitRequests {
    claimable
    exitQueueIndex
    positionTicket
    shares
    timestamp
  }
}
    `;
export const StakeWiseVaultDataFragmentDoc = gql`
    fragment StakeWiseVaultData on StakeWiseVaultData {
  stakeWiseVaultAddress
  totalApy
  underlyingAddress
  totalValueLocked
  totalValueLockedCurrencyValue
  name
  imageUrl
  externalPositionHolding {
    ...StakeWiseVaultHoldingData
  }
  fallbackExternalPosition
}
    `;
export const AssetBalanceObligatoryCurrencyFragmentDoc = gql`
    fragment AssetBalanceObligatoryCurrency on AssetBalanceObligatoryCurrency {
  address
  amount
  currencyValue
}
    `;
export const ThreeOneThirdRebalanceDataFragmentDoc = gql`
    fragment ThreeOneThirdRebalanceData on ThreeOneThirdRebalanceData {
  sellValue
  estimatedReceiveValue
  expirationTimestamp
  minReceivedValue
  trades {
    fromAssetBalance {
      ...AssetBalanceObligatoryCurrency
    }
    toAssetBalance {
      ...AssetBalanceObligatoryCurrency
    }
    data
    signature
    exchangeName
    minToReceiveBeforeFees
  }
}
    `;
export const UserRepresentativeApplicationInfoFragmentDoc = gql`
    fragment UserRepresentativeApplicationInfo on UserRepresentativeApplication {
  account {
    id
  }
  id
  email
  name
  experience
  motivation
  miscExperience
  aumPercentage
  status
  votes {
    id
  }
}
    `;
export const UserRepresentativeVoteInfoFragmentDoc = gql`
    fragment UserRepresentativeVoteInfo on UserRepresentativeVote {
  account {
    id
  }
  createdAt
  id
}
    `;
export const UserRepresentativeElectionInfoFragmentDoc = gql`
    fragment UserRepresentativeElectionInfo on UserRepresentativeElection {
  id
  name
  description
  eligible
  applyingStartDate
  applyingEndDate
  votingStartDate
  votingEndDate
  applications {
    ...UserRepresentativeApplicationInfo
  }
  votes {
    ...UserRepresentativeVoteInfo
  }
}
    `;
export const VaultDepositorSignatureDetailFragmentDoc = gql`
    fragment VaultDepositorSignatureDetail on VaultDepositorSignature {
  id
  message
  signature
}
    `;
export const VaultStrategyInfoFragmentDoc = gql`
    fragment VaultStrategyInfo on VaultStrategy {
  description
  name
}
    `;
export const VaultProfileInfoFragmentDoc = gql`
    fragment VaultProfileInfo on VaultProfile {
  avatarUrl
  tagline
  description
  managerDescription
  contactInfo
  email
  telegram
  websiteUrl
  twitter
  requireDepositorSignature
  depositorSignatureMessage
  vaultStrategies {
    id
    ...VaultStrategyInfo
  }
}
    `;
export const WalletClaimInfoFragmentDoc = gql`
    fragment WalletClaimInfo on WalletClaim {
  id
  label
  wallet {
    id
    address
  }
}
    `;
export const WalletClaimMessageInfoFragmentDoc = gql`
    fragment WalletClaimMessageInfo on WalletClaimMessage {
  id
  message
  timestamp
}
    `;
export const WhiteLabelInfoFragmentDoc = gql`
    fragment WhiteLabelInfo on WhiteLabel {
  id
  name
  profile {
    id
    contactInfo
    description
  }
  settings {
    colorScheme
    cover
    id
    logo
    primaryColor
    secondaryColor
    subdomain
  }
  vaults {
    address
    id
  }
}
    `;
export const SingleYearnAssetLendingDataFragmentDoc = gql`
    fragment SingleYearnAssetLendingData on SingleYearnAssetLendingData {
  assetAddress
  underlyingAddress
  apy
  tvl
}
    `;
export const LendingDataForAaveV2AssetsDocument = gql`
    query LendingDataForAaveV2Assets($network: Deployment!, $currency: Currency!) {
  lendingDataForAaveV2Assets(network: $network, currency: $currency) {
    assetAddress
    underlyingAddress
    totalApy
    reserveSize
    isFrozen
  }
}
    `;

/**
 * __useLendingDataForAaveV2AssetsQuery__
 *
 * To run a query within a React component, call `useLendingDataForAaveV2AssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLendingDataForAaveV2AssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLendingDataForAaveV2AssetsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useLendingDataForAaveV2AssetsQuery(baseOptions: Apollo.QueryHookOptions<LendingDataForAaveV2AssetsQuery, LendingDataForAaveV2AssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LendingDataForAaveV2AssetsQuery, LendingDataForAaveV2AssetsQueryVariables>(LendingDataForAaveV2AssetsDocument, options);
      }
export function useLendingDataForAaveV2AssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LendingDataForAaveV2AssetsQuery, LendingDataForAaveV2AssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LendingDataForAaveV2AssetsQuery, LendingDataForAaveV2AssetsQueryVariables>(LendingDataForAaveV2AssetsDocument, options);
        }
export type LendingDataForAaveV2AssetsQueryHookResult = ReturnType<typeof useLendingDataForAaveV2AssetsQuery>;
export type LendingDataForAaveV2AssetsLazyQueryHookResult = ReturnType<typeof useLendingDataForAaveV2AssetsLazyQuery>;
export type LendingDataForAaveV2AssetsQueryResult = Apollo.QueryResult<LendingDataForAaveV2AssetsQuery, LendingDataForAaveV2AssetsQueryVariables>;
export const AaveV2BorrowingRatesDocument = gql`
    query AaveV2BorrowingRates($network: Deployment!, $currency: Currency!) {
  borrowingForAaveV2Assets(network: $network, currency: $currency) {
    totalApy
    derivativeAddress
    underlyingAssetAddress
    collateralFactor
    borrowingEnabled
    usageAsCollateralEnabled
    reserveSize
    isFrozen
  }
}
    `;

/**
 * __useAaveV2BorrowingRatesQuery__
 *
 * To run a query within a React component, call `useAaveV2BorrowingRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAaveV2BorrowingRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAaveV2BorrowingRatesQuery({
 *   variables: {
 *      network: // value for 'network'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useAaveV2BorrowingRatesQuery(baseOptions: Apollo.QueryHookOptions<AaveV2BorrowingRatesQuery, AaveV2BorrowingRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AaveV2BorrowingRatesQuery, AaveV2BorrowingRatesQueryVariables>(AaveV2BorrowingRatesDocument, options);
      }
export function useAaveV2BorrowingRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AaveV2BorrowingRatesQuery, AaveV2BorrowingRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AaveV2BorrowingRatesQuery, AaveV2BorrowingRatesQueryVariables>(AaveV2BorrowingRatesDocument, options);
        }
export type AaveV2BorrowingRatesQueryHookResult = ReturnType<typeof useAaveV2BorrowingRatesQuery>;
export type AaveV2BorrowingRatesLazyQueryHookResult = ReturnType<typeof useAaveV2BorrowingRatesLazyQuery>;
export type AaveV2BorrowingRatesQueryResult = Apollo.QueryResult<AaveV2BorrowingRatesQuery, AaveV2BorrowingRatesQueryVariables>;
export const RewardsForAaveV2Document = gql`
    query RewardsForAaveV2($address: Address!, $currency: Currency!, $network: Deployment!) {
  rewardsForAaveV2(address: $address, currency: $currency, network: $network) {
    globalRewards {
      ...Reward
    }
    individualRewards {
      address
      rewards {
        ...Reward
      }
    }
  }
}
    ${RewardFragmentDoc}`;

/**
 * __useRewardsForAaveV2Query__
 *
 * To run a query within a React component, call `useRewardsForAaveV2Query` and pass it any options that fit your needs.
 * When your component renders, `useRewardsForAaveV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardsForAaveV2Query({
 *   variables: {
 *      address: // value for 'address'
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useRewardsForAaveV2Query(baseOptions: Apollo.QueryHookOptions<RewardsForAaveV2Query, RewardsForAaveV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RewardsForAaveV2Query, RewardsForAaveV2QueryVariables>(RewardsForAaveV2Document, options);
      }
export function useRewardsForAaveV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RewardsForAaveV2Query, RewardsForAaveV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RewardsForAaveV2Query, RewardsForAaveV2QueryVariables>(RewardsForAaveV2Document, options);
        }
export type RewardsForAaveV2QueryHookResult = ReturnType<typeof useRewardsForAaveV2Query>;
export type RewardsForAaveV2LazyQueryHookResult = ReturnType<typeof useRewardsForAaveV2LazyQuery>;
export type RewardsForAaveV2QueryResult = Apollo.QueryResult<RewardsForAaveV2Query, RewardsForAaveV2QueryVariables>;
export const ExternalPositionsAaveV2DebtPositionsDocument = gql`
    query ExternalPositionsAaveV2DebtPositions($vaultId: Address!, $currency: Currency!, $network: Deployment!) {
  externalPositionsAaveV2DebtPositions(
    vaultId: $vaultId
    currency: $currency
    network: $network
  ) {
    claimableRewardValue
    rewardToken
    tokensWithRewardsToClaim
    active
    externalPositionAddress
    collateralAssets {
      ...AssetBalanceCurrency
    }
    borrowedAssets {
      ...AssetBalanceCurrency
    }
    currentBorrowValue
    maxLoanToValueRatio
    currentLoanToValueRatio
    maxSafeDecreaseInCollateral
    maxDecreaseInCollateral
    maxSafeIncreaseInBorrow
    maxIncreaseInBorrow
    safeLoanToValueRatio
    weightedApy
  }
}
    ${AssetBalanceCurrencyFragmentDoc}`;

/**
 * __useExternalPositionsAaveV2DebtPositionsQuery__
 *
 * To run a query within a React component, call `useExternalPositionsAaveV2DebtPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalPositionsAaveV2DebtPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalPositionsAaveV2DebtPositionsQuery({
 *   variables: {
 *      vaultId: // value for 'vaultId'
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useExternalPositionsAaveV2DebtPositionsQuery(baseOptions: Apollo.QueryHookOptions<ExternalPositionsAaveV2DebtPositionsQuery, ExternalPositionsAaveV2DebtPositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExternalPositionsAaveV2DebtPositionsQuery, ExternalPositionsAaveV2DebtPositionsQueryVariables>(ExternalPositionsAaveV2DebtPositionsDocument, options);
      }
export function useExternalPositionsAaveV2DebtPositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExternalPositionsAaveV2DebtPositionsQuery, ExternalPositionsAaveV2DebtPositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExternalPositionsAaveV2DebtPositionsQuery, ExternalPositionsAaveV2DebtPositionsQueryVariables>(ExternalPositionsAaveV2DebtPositionsDocument, options);
        }
export type ExternalPositionsAaveV2DebtPositionsQueryHookResult = ReturnType<typeof useExternalPositionsAaveV2DebtPositionsQuery>;
export type ExternalPositionsAaveV2DebtPositionsLazyQueryHookResult = ReturnType<typeof useExternalPositionsAaveV2DebtPositionsLazyQuery>;
export type ExternalPositionsAaveV2DebtPositionsQueryResult = Apollo.QueryResult<ExternalPositionsAaveV2DebtPositionsQuery, ExternalPositionsAaveV2DebtPositionsQueryVariables>;
export const ExternalPositionsAaveV2DebtPortfolioDocument = gql`
    query ExternalPositionsAaveV2DebtPortfolio($vaultId: Address!, $currency: CurrencyOrAddress!, $network: Deployment!) {
  externalPositionsAaveV2DebtPortfolio(
    vaultId: $vaultId
    currency: $currency
    network: $network
  ) {
    netValue
    valueChange24h
    collateralAssets {
      ...AssetBalanceCurrency
    }
    borrowedAssets {
      ...AssetBalanceCurrency
    }
  }
}
    ${AssetBalanceCurrencyFragmentDoc}`;

/**
 * __useExternalPositionsAaveV2DebtPortfolioQuery__
 *
 * To run a query within a React component, call `useExternalPositionsAaveV2DebtPortfolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalPositionsAaveV2DebtPortfolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalPositionsAaveV2DebtPortfolioQuery({
 *   variables: {
 *      vaultId: // value for 'vaultId'
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useExternalPositionsAaveV2DebtPortfolioQuery(baseOptions: Apollo.QueryHookOptions<ExternalPositionsAaveV2DebtPortfolioQuery, ExternalPositionsAaveV2DebtPortfolioQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExternalPositionsAaveV2DebtPortfolioQuery, ExternalPositionsAaveV2DebtPortfolioQueryVariables>(ExternalPositionsAaveV2DebtPortfolioDocument, options);
      }
export function useExternalPositionsAaveV2DebtPortfolioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExternalPositionsAaveV2DebtPortfolioQuery, ExternalPositionsAaveV2DebtPortfolioQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExternalPositionsAaveV2DebtPortfolioQuery, ExternalPositionsAaveV2DebtPortfolioQueryVariables>(ExternalPositionsAaveV2DebtPortfolioDocument, options);
        }
export type ExternalPositionsAaveV2DebtPortfolioQueryHookResult = ReturnType<typeof useExternalPositionsAaveV2DebtPortfolioQuery>;
export type ExternalPositionsAaveV2DebtPortfolioLazyQueryHookResult = ReturnType<typeof useExternalPositionsAaveV2DebtPortfolioLazyQuery>;
export type ExternalPositionsAaveV2DebtPortfolioQueryResult = Apollo.QueryResult<ExternalPositionsAaveV2DebtPortfolioQuery, ExternalPositionsAaveV2DebtPortfolioQueryVariables>;
export const LendingDataForAaveV3AssetsDocument = gql`
    query LendingDataForAaveV3Assets($network: Deployment!, $currency: Currency!) {
  lendingDataForAaveV3Assets(network: $network, currency: $currency) {
    assetAddress
    underlyingAddress
    totalApy
    reserveSize
    isFrozen
    isSupplyCapReached
  }
}
    `;

/**
 * __useLendingDataForAaveV3AssetsQuery__
 *
 * To run a query within a React component, call `useLendingDataForAaveV3AssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLendingDataForAaveV3AssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLendingDataForAaveV3AssetsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useLendingDataForAaveV3AssetsQuery(baseOptions: Apollo.QueryHookOptions<LendingDataForAaveV3AssetsQuery, LendingDataForAaveV3AssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LendingDataForAaveV3AssetsQuery, LendingDataForAaveV3AssetsQueryVariables>(LendingDataForAaveV3AssetsDocument, options);
      }
export function useLendingDataForAaveV3AssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LendingDataForAaveV3AssetsQuery, LendingDataForAaveV3AssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LendingDataForAaveV3AssetsQuery, LendingDataForAaveV3AssetsQueryVariables>(LendingDataForAaveV3AssetsDocument, options);
        }
export type LendingDataForAaveV3AssetsQueryHookResult = ReturnType<typeof useLendingDataForAaveV3AssetsQuery>;
export type LendingDataForAaveV3AssetsLazyQueryHookResult = ReturnType<typeof useLendingDataForAaveV3AssetsLazyQuery>;
export type LendingDataForAaveV3AssetsQueryResult = Apollo.QueryResult<LendingDataForAaveV3AssetsQuery, LendingDataForAaveV3AssetsQueryVariables>;
export const AaveV3BorrowingRatesDocument = gql`
    query AaveV3BorrowingRates($network: Deployment!, $currency: Currency!) {
  borrowingForAaveV3Assets(network: $network, currency: $currency) {
    totalApy
    derivativeAddress
    underlyingAssetAddress
    collateralFactor
    borrowingEnabled
    usageAsCollateralEnabled
    reserveSize
    isFrozen
    eModeCategoryId
  }
}
    `;

/**
 * __useAaveV3BorrowingRatesQuery__
 *
 * To run a query within a React component, call `useAaveV3BorrowingRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAaveV3BorrowingRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAaveV3BorrowingRatesQuery({
 *   variables: {
 *      network: // value for 'network'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useAaveV3BorrowingRatesQuery(baseOptions: Apollo.QueryHookOptions<AaveV3BorrowingRatesQuery, AaveV3BorrowingRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AaveV3BorrowingRatesQuery, AaveV3BorrowingRatesQueryVariables>(AaveV3BorrowingRatesDocument, options);
      }
export function useAaveV3BorrowingRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AaveV3BorrowingRatesQuery, AaveV3BorrowingRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AaveV3BorrowingRatesQuery, AaveV3BorrowingRatesQueryVariables>(AaveV3BorrowingRatesDocument, options);
        }
export type AaveV3BorrowingRatesQueryHookResult = ReturnType<typeof useAaveV3BorrowingRatesQuery>;
export type AaveV3BorrowingRatesLazyQueryHookResult = ReturnType<typeof useAaveV3BorrowingRatesLazyQuery>;
export type AaveV3BorrowingRatesQueryResult = Apollo.QueryResult<AaveV3BorrowingRatesQuery, AaveV3BorrowingRatesQueryVariables>;
export const ExternalPositionsAaveV3DebtPositionsDocument = gql`
    query ExternalPositionsAaveV3DebtPositions($vaultId: Address!, $currency: Currency!, $network: Deployment!) {
  externalPositionsAaveV3DebtPositions(
    vaultId: $vaultId
    currency: $currency
    network: $network
  ) {
    active
    eModeCategoryId
    eModeCategoryLabel
    externalPositionAddress
    collateralAssets {
      ...AssetBalanceCurrency
    }
    borrowedAssets {
      ...AssetBalanceCurrency
    }
    healthFactor
    currentBorrowValue
    maxLoanToValueRatio
    currentLoanToValueRatio
    maxSafeDecreaseInCollateral
    maxDecreaseInCollateral
    maxSafeIncreaseInBorrow
    maxIncreaseInBorrow
    safeLoanToValueRatio
    weightedApy
  }
}
    ${AssetBalanceCurrencyFragmentDoc}`;

/**
 * __useExternalPositionsAaveV3DebtPositionsQuery__
 *
 * To run a query within a React component, call `useExternalPositionsAaveV3DebtPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalPositionsAaveV3DebtPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalPositionsAaveV3DebtPositionsQuery({
 *   variables: {
 *      vaultId: // value for 'vaultId'
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useExternalPositionsAaveV3DebtPositionsQuery(baseOptions: Apollo.QueryHookOptions<ExternalPositionsAaveV3DebtPositionsQuery, ExternalPositionsAaveV3DebtPositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExternalPositionsAaveV3DebtPositionsQuery, ExternalPositionsAaveV3DebtPositionsQueryVariables>(ExternalPositionsAaveV3DebtPositionsDocument, options);
      }
export function useExternalPositionsAaveV3DebtPositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExternalPositionsAaveV3DebtPositionsQuery, ExternalPositionsAaveV3DebtPositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExternalPositionsAaveV3DebtPositionsQuery, ExternalPositionsAaveV3DebtPositionsQueryVariables>(ExternalPositionsAaveV3DebtPositionsDocument, options);
        }
export type ExternalPositionsAaveV3DebtPositionsQueryHookResult = ReturnType<typeof useExternalPositionsAaveV3DebtPositionsQuery>;
export type ExternalPositionsAaveV3DebtPositionsLazyQueryHookResult = ReturnType<typeof useExternalPositionsAaveV3DebtPositionsLazyQuery>;
export type ExternalPositionsAaveV3DebtPositionsQueryResult = Apollo.QueryResult<ExternalPositionsAaveV3DebtPositionsQuery, ExternalPositionsAaveV3DebtPositionsQueryVariables>;
export const ExternalPositionsAaveV3DebtPortfolioDocument = gql`
    query ExternalPositionsAaveV3DebtPortfolio($vaultId: Address!, $currency: CurrencyOrAddress!, $network: Deployment!) {
  externalPositionsAaveV3DebtPortfolio(
    vaultId: $vaultId
    currency: $currency
    network: $network
  ) {
    netValue
    valueChange24h
    collateralAssets {
      ...AssetBalanceCurrency
    }
    borrowedAssets {
      ...AssetBalanceCurrency
    }
  }
}
    ${AssetBalanceCurrencyFragmentDoc}`;

/**
 * __useExternalPositionsAaveV3DebtPortfolioQuery__
 *
 * To run a query within a React component, call `useExternalPositionsAaveV3DebtPortfolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalPositionsAaveV3DebtPortfolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalPositionsAaveV3DebtPortfolioQuery({
 *   variables: {
 *      vaultId: // value for 'vaultId'
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useExternalPositionsAaveV3DebtPortfolioQuery(baseOptions: Apollo.QueryHookOptions<ExternalPositionsAaveV3DebtPortfolioQuery, ExternalPositionsAaveV3DebtPortfolioQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExternalPositionsAaveV3DebtPortfolioQuery, ExternalPositionsAaveV3DebtPortfolioQueryVariables>(ExternalPositionsAaveV3DebtPortfolioDocument, options);
      }
export function useExternalPositionsAaveV3DebtPortfolioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExternalPositionsAaveV3DebtPortfolioQuery, ExternalPositionsAaveV3DebtPortfolioQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExternalPositionsAaveV3DebtPortfolioQuery, ExternalPositionsAaveV3DebtPortfolioQueryVariables>(ExternalPositionsAaveV3DebtPortfolioDocument, options);
        }
export type ExternalPositionsAaveV3DebtPortfolioQueryHookResult = ReturnType<typeof useExternalPositionsAaveV3DebtPortfolioQuery>;
export type ExternalPositionsAaveV3DebtPortfolioLazyQueryHookResult = ReturnType<typeof useExternalPositionsAaveV3DebtPortfolioLazyQuery>;
export type ExternalPositionsAaveV3DebtPortfolioQueryResult = Apollo.QueryResult<ExternalPositionsAaveV3DebtPortfolioQuery, ExternalPositionsAaveV3DebtPortfolioQueryVariables>;
export const EModeCategoriesDocument = gql`
    query EModeCategories($network: Deployment!) {
  eModeCategories(network: $network) {
    id
    label
    assets
  }
}
    `;

/**
 * __useEModeCategoriesQuery__
 *
 * To run a query within a React component, call `useEModeCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEModeCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEModeCategoriesQuery({
 *   variables: {
 *      network: // value for 'network'
 *   },
 * });
 */
export function useEModeCategoriesQuery(baseOptions: Apollo.QueryHookOptions<EModeCategoriesQuery, EModeCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EModeCategoriesQuery, EModeCategoriesQueryVariables>(EModeCategoriesDocument, options);
      }
export function useEModeCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EModeCategoriesQuery, EModeCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EModeCategoriesQuery, EModeCategoriesQueryVariables>(EModeCategoriesDocument, options);
        }
export type EModeCategoriesQueryHookResult = ReturnType<typeof useEModeCategoriesQuery>;
export type EModeCategoriesLazyQueryHookResult = ReturnType<typeof useEModeCategoriesLazyQuery>;
export type EModeCategoriesQueryResult = Apollo.QueryResult<EModeCategoriesQuery, EModeCategoriesQueryVariables>;
export const EModePositionLiquidationInfoDocument = gql`
    query EModePositionLiquidationInfo($externalPosition: Address!, $network: Deployment!, $eModeCategory: Int!) {
  eModePositionLiquidationInfo(
    network: $network
    externalPosition: $externalPosition
    eModeCategory: $eModeCategory
  ) {
    healthFactor
    maxLoanToValueRatio
  }
}
    `;

/**
 * __useEModePositionLiquidationInfoQuery__
 *
 * To run a query within a React component, call `useEModePositionLiquidationInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useEModePositionLiquidationInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEModePositionLiquidationInfoQuery({
 *   variables: {
 *      externalPosition: // value for 'externalPosition'
 *      network: // value for 'network'
 *      eModeCategory: // value for 'eModeCategory'
 *   },
 * });
 */
export function useEModePositionLiquidationInfoQuery(baseOptions: Apollo.QueryHookOptions<EModePositionLiquidationInfoQuery, EModePositionLiquidationInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EModePositionLiquidationInfoQuery, EModePositionLiquidationInfoQueryVariables>(EModePositionLiquidationInfoDocument, options);
      }
export function useEModePositionLiquidationInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EModePositionLiquidationInfoQuery, EModePositionLiquidationInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EModePositionLiquidationInfoQuery, EModePositionLiquidationInfoQueryVariables>(EModePositionLiquidationInfoDocument, options);
        }
export type EModePositionLiquidationInfoQueryHookResult = ReturnType<typeof useEModePositionLiquidationInfoQuery>;
export type EModePositionLiquidationInfoLazyQueryHookResult = ReturnType<typeof useEModePositionLiquidationInfoLazyQuery>;
export type EModePositionLiquidationInfoQueryResult = Apollo.QueryResult<EModePositionLiquidationInfoQuery, EModePositionLiquidationInfoQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...AccountInfo
  }
}
    ${AccountInfoFragmentDoc}
${AccountProfileInfoFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const AccountAuthDocument = gql`
    query AccountAuth {
  me {
    ...AccountAuthInfo
  }
}
    ${AccountAuthInfoFragmentDoc}`;

/**
 * __useAccountAuthQuery__
 *
 * To run a query within a React component, call `useAccountAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountAuthQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountAuthQuery(baseOptions?: Apollo.QueryHookOptions<AccountAuthQuery, AccountAuthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountAuthQuery, AccountAuthQueryVariables>(AccountAuthDocument, options);
      }
export function useAccountAuthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountAuthQuery, AccountAuthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountAuthQuery, AccountAuthQueryVariables>(AccountAuthDocument, options);
        }
export type AccountAuthQueryHookResult = ReturnType<typeof useAccountAuthQuery>;
export type AccountAuthLazyQueryHookResult = ReturnType<typeof useAccountAuthLazyQuery>;
export type AccountAuthQueryResult = Apollo.QueryResult<AccountAuthQuery, AccountAuthQueryVariables>;
export const DeleteAccountDocument = gql`
    mutation DeleteAccount($input: DeleteAccountInput!) {
  deleteAccount(input: $input) {
    errors {
      ...UserErrorFragment
    }
  }
}
    ${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type DeleteAccountMutationFn = Apollo.MutationFunction<DeleteAccountMutation, DeleteAccountMutationVariables>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAccountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAccountMutation, DeleteAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DeleteAccountDocument, options);
      }
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = Apollo.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<DeleteAccountMutation, DeleteAccountMutationVariables>;
export const UpdateEmailDocument = gql`
    mutation UpdateEmail($input: UpdateEmailInput!) {
  updateEmail(input: $input) {
    account {
      ...AccountInfo
    }
    errors {
      ...UserErrorFragment
    }
  }
}
    ${AccountInfoFragmentDoc}
${AccountProfileInfoFragmentDoc}
${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type UpdateEmailMutationFn = Apollo.MutationFunction<UpdateEmailMutation, UpdateEmailMutationVariables>;

/**
 * __useUpdateEmailMutation__
 *
 * To run a mutation, you first call `useUpdateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailMutation, { data, loading, error }] = useUpdateEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmailMutation, UpdateEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmailMutation, UpdateEmailMutationVariables>(UpdateEmailDocument, options);
      }
export type UpdateEmailMutationHookResult = ReturnType<typeof useUpdateEmailMutation>;
export type UpdateEmailMutationResult = Apollo.MutationResult<UpdateEmailMutation>;
export type UpdateEmailMutationOptions = Apollo.BaseMutationOptions<UpdateEmailMutation, UpdateEmailMutationVariables>;
export const UpdateEmailCompleteDocument = gql`
    mutation UpdateEmailComplete($input: UpdateEmailCompleteInput!) {
  updateEmailComplete(input: $input) {
    account {
      ...AccountInfo
    }
    errors {
      ...UserErrorFragment
    }
  }
}
    ${AccountInfoFragmentDoc}
${AccountProfileInfoFragmentDoc}
${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type UpdateEmailCompleteMutationFn = Apollo.MutationFunction<UpdateEmailCompleteMutation, UpdateEmailCompleteMutationVariables>;

/**
 * __useUpdateEmailCompleteMutation__
 *
 * To run a mutation, you first call `useUpdateEmailCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailCompleteMutation, { data, loading, error }] = useUpdateEmailCompleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmailCompleteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmailCompleteMutation, UpdateEmailCompleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmailCompleteMutation, UpdateEmailCompleteMutationVariables>(UpdateEmailCompleteDocument, options);
      }
export type UpdateEmailCompleteMutationHookResult = ReturnType<typeof useUpdateEmailCompleteMutation>;
export type UpdateEmailCompleteMutationResult = Apollo.MutationResult<UpdateEmailCompleteMutation>;
export type UpdateEmailCompleteMutationOptions = Apollo.BaseMutationOptions<UpdateEmailCompleteMutation, UpdateEmailCompleteMutationVariables>;
export const UpdateAccountProfileDocument = gql`
    mutation UpdateAccountProfile($input: UpdateAccountProfileInput!) {
  updateAccountProfile(input: $input) {
    accountProfile {
      ...AccountProfileInfo
    }
    errors {
      ...UserErrorFragment
    }
  }
}
    ${AccountProfileInfoFragmentDoc}
${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type UpdateAccountProfileMutationFn = Apollo.MutationFunction<UpdateAccountProfileMutation, UpdateAccountProfileMutationVariables>;

/**
 * __useUpdateAccountProfileMutation__
 *
 * To run a mutation, you first call `useUpdateAccountProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountProfileMutation, { data, loading, error }] = useUpdateAccountProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountProfileMutation, UpdateAccountProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountProfileMutation, UpdateAccountProfileMutationVariables>(UpdateAccountProfileDocument, options);
      }
export type UpdateAccountProfileMutationHookResult = ReturnType<typeof useUpdateAccountProfileMutation>;
export type UpdateAccountProfileMutationResult = Apollo.MutationResult<UpdateAccountProfileMutation>;
export type UpdateAccountProfileMutationOptions = Apollo.BaseMutationOptions<UpdateAccountProfileMutation, UpdateAccountProfileMutationVariables>;
export const AccountProfileDocument = gql`
    query AccountProfile {
  me {
    id
    accountProfile {
      ...AccountProfileInfo
    }
  }
}
    ${AccountProfileInfoFragmentDoc}`;

/**
 * __useAccountProfileQuery__
 *
 * To run a query within a React component, call `useAccountProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountProfileQuery(baseOptions?: Apollo.QueryHookOptions<AccountProfileQuery, AccountProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountProfileQuery, AccountProfileQueryVariables>(AccountProfileDocument, options);
      }
export function useAccountProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountProfileQuery, AccountProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountProfileQuery, AccountProfileQueryVariables>(AccountProfileDocument, options);
        }
export type AccountProfileQueryHookResult = ReturnType<typeof useAccountProfileQuery>;
export type AccountProfileLazyQueryHookResult = ReturnType<typeof useAccountProfileLazyQuery>;
export type AccountProfileQueryResult = Apollo.QueryResult<AccountProfileQuery, AccountProfileQueryVariables>;
export const ApiTokensDocument = gql`
    query ApiTokens {
  me {
    apiTokens {
      ...ApiTokenInfo
    }
  }
}
    ${ApiTokenInfoFragmentDoc}`;

/**
 * __useApiTokensQuery__
 *
 * To run a query within a React component, call `useApiTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiTokensQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiTokensQuery(baseOptions?: Apollo.QueryHookOptions<ApiTokensQuery, ApiTokensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiTokensQuery, ApiTokensQueryVariables>(ApiTokensDocument, options);
      }
export function useApiTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiTokensQuery, ApiTokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiTokensQuery, ApiTokensQueryVariables>(ApiTokensDocument, options);
        }
export type ApiTokensQueryHookResult = ReturnType<typeof useApiTokensQuery>;
export type ApiTokensLazyQueryHookResult = ReturnType<typeof useApiTokensLazyQuery>;
export type ApiTokensQueryResult = Apollo.QueryResult<ApiTokensQuery, ApiTokensQueryVariables>;
export const CreateApiTokenDocument = gql`
    mutation CreateApiToken($input: CreateApiTokenInput!) {
  createApiToken(input: $input) {
    account {
      ...AccountInfo
    }
    errors {
      ...UserErrorFragment
    }
    token
  }
}
    ${AccountInfoFragmentDoc}
${AccountProfileInfoFragmentDoc}
${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type CreateApiTokenMutationFn = Apollo.MutationFunction<CreateApiTokenMutation, CreateApiTokenMutationVariables>;

/**
 * __useCreateApiTokenMutation__
 *
 * To run a mutation, you first call `useCreateApiTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApiTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApiTokenMutation, { data, loading, error }] = useCreateApiTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApiTokenMutation(baseOptions?: Apollo.MutationHookOptions<CreateApiTokenMutation, CreateApiTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateApiTokenMutation, CreateApiTokenMutationVariables>(CreateApiTokenDocument, options);
      }
export type CreateApiTokenMutationHookResult = ReturnType<typeof useCreateApiTokenMutation>;
export type CreateApiTokenMutationResult = Apollo.MutationResult<CreateApiTokenMutation>;
export type CreateApiTokenMutationOptions = Apollo.BaseMutationOptions<CreateApiTokenMutation, CreateApiTokenMutationVariables>;
export const DeleteApiTokenDocument = gql`
    mutation DeleteApiToken($input: DeleteApiTokenInput!) {
  deleteApiToken(input: $input) {
    errors {
      ...UserErrorFragment
    }
  }
}
    ${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type DeleteApiTokenMutationFn = Apollo.MutationFunction<DeleteApiTokenMutation, DeleteApiTokenMutationVariables>;

/**
 * __useDeleteApiTokenMutation__
 *
 * To run a mutation, you first call `useDeleteApiTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApiTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApiTokenMutation, { data, loading, error }] = useDeleteApiTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteApiTokenMutation(baseOptions?: Apollo.MutationHookOptions<DeleteApiTokenMutation, DeleteApiTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteApiTokenMutation, DeleteApiTokenMutationVariables>(DeleteApiTokenDocument, options);
      }
export type DeleteApiTokenMutationHookResult = ReturnType<typeof useDeleteApiTokenMutation>;
export type DeleteApiTokenMutationResult = Apollo.MutationResult<DeleteApiTokenMutation>;
export type DeleteApiTokenMutationOptions = Apollo.BaseMutationOptions<DeleteApiTokenMutation, DeleteApiTokenMutationVariables>;
export const UpdateApiTokenDocument = gql`
    mutation UpdateApiToken($input: UpdateApiTokenInput!) {
  updateApiToken(input: $input) {
    account {
      ...AccountInfo
    }
    errors {
      ...UserErrorFragment
    }
  }
}
    ${AccountInfoFragmentDoc}
${AccountProfileInfoFragmentDoc}
${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type UpdateApiTokenMutationFn = Apollo.MutationFunction<UpdateApiTokenMutation, UpdateApiTokenMutationVariables>;

/**
 * __useUpdateApiTokenMutation__
 *
 * To run a mutation, you first call `useUpdateApiTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApiTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApiTokenMutation, { data, loading, error }] = useUpdateApiTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApiTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApiTokenMutation, UpdateApiTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateApiTokenMutation, UpdateApiTokenMutationVariables>(UpdateApiTokenDocument, options);
      }
export type UpdateApiTokenMutationHookResult = ReturnType<typeof useUpdateApiTokenMutation>;
export type UpdateApiTokenMutationResult = Apollo.MutationResult<UpdateApiTokenMutation>;
export type UpdateApiTokenMutationOptions = Apollo.BaseMutationOptions<UpdateApiTokenMutation, UpdateApiTokenMutationVariables>;
export const ExternalPositionsArbitraryLoanPositionsDocument = gql`
    query ExternalPositionsArbitraryLoanPositions($network: Deployment!, $vaultId: Address!) {
  externalPositionsArbitraryLoanPositions(network: $network, address: $vaultId) {
    arbitraryLoansBasic {
      status
      externalPosition
      borrower
      loanAsset
      description
      borrowableAmount
      amountToRepay
      borrowedAmount
      totalRepaid
    }
  }
}
    `;

/**
 * __useExternalPositionsArbitraryLoanPositionsQuery__
 *
 * To run a query within a React component, call `useExternalPositionsArbitraryLoanPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalPositionsArbitraryLoanPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalPositionsArbitraryLoanPositionsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      vaultId: // value for 'vaultId'
 *   },
 * });
 */
export function useExternalPositionsArbitraryLoanPositionsQuery(baseOptions: Apollo.QueryHookOptions<ExternalPositionsArbitraryLoanPositionsQuery, ExternalPositionsArbitraryLoanPositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExternalPositionsArbitraryLoanPositionsQuery, ExternalPositionsArbitraryLoanPositionsQueryVariables>(ExternalPositionsArbitraryLoanPositionsDocument, options);
      }
export function useExternalPositionsArbitraryLoanPositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExternalPositionsArbitraryLoanPositionsQuery, ExternalPositionsArbitraryLoanPositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExternalPositionsArbitraryLoanPositionsQuery, ExternalPositionsArbitraryLoanPositionsQueryVariables>(ExternalPositionsArbitraryLoanPositionsDocument, options);
        }
export type ExternalPositionsArbitraryLoanPositionsQueryHookResult = ReturnType<typeof useExternalPositionsArbitraryLoanPositionsQuery>;
export type ExternalPositionsArbitraryLoanPositionsLazyQueryHookResult = ReturnType<typeof useExternalPositionsArbitraryLoanPositionsLazyQuery>;
export type ExternalPositionsArbitraryLoanPositionsQueryResult = Apollo.QueryResult<ExternalPositionsArbitraryLoanPositionsQuery, ExternalPositionsArbitraryLoanPositionsQueryVariables>;
export const ExternalPositionsArbitraryLoanPortfolioDocument = gql`
    query ExternalPositionsArbitraryLoanPortfolio($vaultId: Address!, $currency: CurrencyOrAddress!, $network: Deployment!) {
  externalPositionsArbitraryLoanPortfolio(
    address: $vaultId
    currency: $currency
    network: $network
  ) {
    valueChange24h
    asset {
      assetAddress
      amount
      currencyValue
    }
    amountToRepay
    borrowableAmount
    currentInterestRate
  }
}
    `;

/**
 * __useExternalPositionsArbitraryLoanPortfolioQuery__
 *
 * To run a query within a React component, call `useExternalPositionsArbitraryLoanPortfolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalPositionsArbitraryLoanPortfolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalPositionsArbitraryLoanPortfolioQuery({
 *   variables: {
 *      vaultId: // value for 'vaultId'
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useExternalPositionsArbitraryLoanPortfolioQuery(baseOptions: Apollo.QueryHookOptions<ExternalPositionsArbitraryLoanPortfolioQuery, ExternalPositionsArbitraryLoanPortfolioQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExternalPositionsArbitraryLoanPortfolioQuery, ExternalPositionsArbitraryLoanPortfolioQueryVariables>(ExternalPositionsArbitraryLoanPortfolioDocument, options);
      }
export function useExternalPositionsArbitraryLoanPortfolioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExternalPositionsArbitraryLoanPortfolioQuery, ExternalPositionsArbitraryLoanPortfolioQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExternalPositionsArbitraryLoanPortfolioQuery, ExternalPositionsArbitraryLoanPortfolioQueryVariables>(ExternalPositionsArbitraryLoanPortfolioDocument, options);
        }
export type ExternalPositionsArbitraryLoanPortfolioQueryHookResult = ReturnType<typeof useExternalPositionsArbitraryLoanPortfolioQuery>;
export type ExternalPositionsArbitraryLoanPortfolioLazyQueryHookResult = ReturnType<typeof useExternalPositionsArbitraryLoanPortfolioLazyQuery>;
export type ExternalPositionsArbitraryLoanPortfolioQueryResult = Apollo.QueryResult<ExternalPositionsArbitraryLoanPortfolioQuery, ExternalPositionsArbitraryLoanPortfolioQueryVariables>;
export const BorrowerArbitraryLoanPositionsDocument = gql`
    query BorrowerArbitraryLoanPositions($network: Deployment!, $borrower: Address!) {
  borrowerArbitraryLoanPositions(network: $network, borrower: $borrower) {
    arbitraryLoansBasic {
      status
      externalPosition
      loanAsset
      description
      borrowableAmount
      amountToRepay
      borrowedAmount
      totalRepaid
      vaultName
      vaultAddress
    }
  }
}
    `;

/**
 * __useBorrowerArbitraryLoanPositionsQuery__
 *
 * To run a query within a React component, call `useBorrowerArbitraryLoanPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBorrowerArbitraryLoanPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBorrowerArbitraryLoanPositionsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      borrower: // value for 'borrower'
 *   },
 * });
 */
export function useBorrowerArbitraryLoanPositionsQuery(baseOptions: Apollo.QueryHookOptions<BorrowerArbitraryLoanPositionsQuery, BorrowerArbitraryLoanPositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BorrowerArbitraryLoanPositionsQuery, BorrowerArbitraryLoanPositionsQueryVariables>(BorrowerArbitraryLoanPositionsDocument, options);
      }
export function useBorrowerArbitraryLoanPositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BorrowerArbitraryLoanPositionsQuery, BorrowerArbitraryLoanPositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BorrowerArbitraryLoanPositionsQuery, BorrowerArbitraryLoanPositionsQueryVariables>(BorrowerArbitraryLoanPositionsDocument, options);
        }
export type BorrowerArbitraryLoanPositionsQueryHookResult = ReturnType<typeof useBorrowerArbitraryLoanPositionsQuery>;
export type BorrowerArbitraryLoanPositionsLazyQueryHookResult = ReturnType<typeof useBorrowerArbitraryLoanPositionsLazyQuery>;
export type BorrowerArbitraryLoanPositionsQueryResult = Apollo.QueryResult<BorrowerArbitraryLoanPositionsQuery, BorrowerArbitraryLoanPositionsQueryVariables>;
export const AssetPricesDocument = gql`
    query AssetPrices($network: Deployment!, $currency: CurrencyOrAddress!) {
  assetPrices(network: $network, currency: $currency) {
    id
    price
    change24h
  }
}
    `;

/**
 * __useAssetPricesQuery__
 *
 * To run a query within a React component, call `useAssetPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetPricesQuery({
 *   variables: {
 *      network: // value for 'network'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useAssetPricesQuery(baseOptions: Apollo.QueryHookOptions<AssetPricesQuery, AssetPricesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetPricesQuery, AssetPricesQueryVariables>(AssetPricesDocument, options);
      }
export function useAssetPricesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetPricesQuery, AssetPricesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetPricesQuery, AssetPricesQueryVariables>(AssetPricesDocument, options);
        }
export type AssetPricesQueryHookResult = ReturnType<typeof useAssetPricesQuery>;
export type AssetPricesLazyQueryHookResult = ReturnType<typeof useAssetPricesLazyQuery>;
export type AssetPricesQueryResult = Apollo.QueryResult<AssetPricesQuery, AssetPricesQueryVariables>;
export const SignOutDocument = gql`
    mutation SignOut {
  signOut {
    errors {
      ...UserErrorFragment
    }
  }
}
    ${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type SignOutMutationFn = Apollo.MutationFunction<SignOutMutation, SignOutMutationVariables>;

/**
 * __useSignOutMutation__
 *
 * To run a mutation, you first call `useSignOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signOutMutation, { data, loading, error }] = useSignOutMutation({
 *   variables: {
 *   },
 * });
 */
export function useSignOutMutation(baseOptions?: Apollo.MutationHookOptions<SignOutMutation, SignOutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignOutMutation, SignOutMutationVariables>(SignOutDocument, options);
      }
export type SignOutMutationHookResult = ReturnType<typeof useSignOutMutation>;
export type SignOutMutationResult = Apollo.MutationResult<SignOutMutation>;
export type SignOutMutationOptions = Apollo.BaseMutationOptions<SignOutMutation, SignOutMutationVariables>;
export const SignInDocument = gql`
    mutation SignIn($input: SignInInput!) {
  signIn(input: $input) {
    mfa
    token
    account {
      ...AccountInfo
    }
    errors {
      ...UserErrorFragment
      ...ArgValidationErrorFragment
    }
  }
}
    ${AccountInfoFragmentDoc}
${AccountProfileInfoFragmentDoc}
${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
      }
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const SignInWithGoogleDocument = gql`
    mutation SignInWithGoogle($credentials: SignInCredentialsInput!, $reCaptchaToken: String!) {
  signInWithGoogle: signIn(
    input: {credentials: $credentials, method: GOOGLE, reCaptchaToken: $reCaptchaToken}
  ) {
    mfa
    token
    account {
      ...AccountInfo
    }
    errors {
      ...UserErrorFragment
      ...ArgValidationErrorFragment
    }
  }
}
    ${AccountInfoFragmentDoc}
${AccountProfileInfoFragmentDoc}
${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type SignInWithGoogleMutationFn = Apollo.MutationFunction<SignInWithGoogleMutation, SignInWithGoogleMutationVariables>;

/**
 * __useSignInWithGoogleMutation__
 *
 * To run a mutation, you first call `useSignInWithGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInWithGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInWithGoogleMutation, { data, loading, error }] = useSignInWithGoogleMutation({
 *   variables: {
 *      credentials: // value for 'credentials'
 *      reCaptchaToken: // value for 'reCaptchaToken'
 *   },
 * });
 */
export function useSignInWithGoogleMutation(baseOptions?: Apollo.MutationHookOptions<SignInWithGoogleMutation, SignInWithGoogleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInWithGoogleMutation, SignInWithGoogleMutationVariables>(SignInWithGoogleDocument, options);
      }
export type SignInWithGoogleMutationHookResult = ReturnType<typeof useSignInWithGoogleMutation>;
export type SignInWithGoogleMutationResult = Apollo.MutationResult<SignInWithGoogleMutation>;
export type SignInWithGoogleMutationOptions = Apollo.BaseMutationOptions<SignInWithGoogleMutation, SignInWithGoogleMutationVariables>;
export const SignInCompleteDocument = gql`
    mutation SignInComplete($input: SignInCompleteInput!) {
  signInComplete(input: $input) {
    account {
      ...AccountInfo
    }
    errors {
      ...UserErrorFragment
    }
  }
}
    ${AccountInfoFragmentDoc}
${AccountProfileInfoFragmentDoc}
${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type SignInCompleteMutationFn = Apollo.MutationFunction<SignInCompleteMutation, SignInCompleteMutationVariables>;

/**
 * __useSignInCompleteMutation__
 *
 * To run a mutation, you first call `useSignInCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInCompleteMutation, { data, loading, error }] = useSignInCompleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignInCompleteMutation(baseOptions?: Apollo.MutationHookOptions<SignInCompleteMutation, SignInCompleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInCompleteMutation, SignInCompleteMutationVariables>(SignInCompleteDocument, options);
      }
export type SignInCompleteMutationHookResult = ReturnType<typeof useSignInCompleteMutation>;
export type SignInCompleteMutationResult = Apollo.MutationResult<SignInCompleteMutation>;
export type SignInCompleteMutationOptions = Apollo.BaseMutationOptions<SignInCompleteMutation, SignInCompleteMutationVariables>;
export const SignUpDocument = gql`
    mutation SignUp($input: SignUpInput!) {
  signUp(input: $input) {
    name
    email
    address
    method
    errors {
      ...UserErrorFragment
    }
  }
}
    ${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const SignUpCompleteDocument = gql`
    mutation SignUpComplete($input: SignUpCompleteInput!) {
  signUpComplete(input: $input) {
    account {
      ...AccountInfo
    }
    errors {
      ...UserErrorFragment
    }
  }
}
    ${AccountInfoFragmentDoc}
${AccountProfileInfoFragmentDoc}
${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type SignUpCompleteMutationFn = Apollo.MutationFunction<SignUpCompleteMutation, SignUpCompleteMutationVariables>;

/**
 * __useSignUpCompleteMutation__
 *
 * To run a mutation, you first call `useSignUpCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpCompleteMutation, { data, loading, error }] = useSignUpCompleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpCompleteMutation(baseOptions?: Apollo.MutationHookOptions<SignUpCompleteMutation, SignUpCompleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpCompleteMutation, SignUpCompleteMutationVariables>(SignUpCompleteDocument, options);
      }
export type SignUpCompleteMutationHookResult = ReturnType<typeof useSignUpCompleteMutation>;
export type SignUpCompleteMutationResult = Apollo.MutationResult<SignUpCompleteMutation>;
export type SignUpCompleteMutationOptions = Apollo.BaseMutationOptions<SignUpCompleteMutation, SignUpCompleteMutationVariables>;
export const RequestAuthTokenDocument = gql`
    mutation RequestAuthToken($input: RequestAuthTokenInput!) {
  requestAuthToken(input: $input) {
    account {
      ...AccountInfo
    }
    errors {
      ...UserErrorFragment
    }
    token
  }
}
    ${AccountInfoFragmentDoc}
${AccountProfileInfoFragmentDoc}
${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type RequestAuthTokenMutationFn = Apollo.MutationFunction<RequestAuthTokenMutation, RequestAuthTokenMutationVariables>;

/**
 * __useRequestAuthTokenMutation__
 *
 * To run a mutation, you first call `useRequestAuthTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestAuthTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestAuthTokenMutation, { data, loading, error }] = useRequestAuthTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestAuthTokenMutation(baseOptions?: Apollo.MutationHookOptions<RequestAuthTokenMutation, RequestAuthTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestAuthTokenMutation, RequestAuthTokenMutationVariables>(RequestAuthTokenDocument, options);
      }
export type RequestAuthTokenMutationHookResult = ReturnType<typeof useRequestAuthTokenMutation>;
export type RequestAuthTokenMutationResult = Apollo.MutationResult<RequestAuthTokenMutation>;
export type RequestAuthTokenMutationOptions = Apollo.BaseMutationOptions<RequestAuthTokenMutation, RequestAuthTokenMutationVariables>;
export const ConnectAuthMethodDocument = gql`
    mutation ConnectAuthMethod($input: ConnectAuthMethodInput!) {
  connectAuthMethod(input: $input) {
    name
    email
    token
    address
    method
    errors {
      ...UserErrorFragment
    }
  }
}
    ${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type ConnectAuthMethodMutationFn = Apollo.MutationFunction<ConnectAuthMethodMutation, ConnectAuthMethodMutationVariables>;

/**
 * __useConnectAuthMethodMutation__
 *
 * To run a mutation, you first call `useConnectAuthMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectAuthMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectAuthMethodMutation, { data, loading, error }] = useConnectAuthMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConnectAuthMethodMutation(baseOptions?: Apollo.MutationHookOptions<ConnectAuthMethodMutation, ConnectAuthMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectAuthMethodMutation, ConnectAuthMethodMutationVariables>(ConnectAuthMethodDocument, options);
      }
export type ConnectAuthMethodMutationHookResult = ReturnType<typeof useConnectAuthMethodMutation>;
export type ConnectAuthMethodMutationResult = Apollo.MutationResult<ConnectAuthMethodMutation>;
export type ConnectAuthMethodMutationOptions = Apollo.BaseMutationOptions<ConnectAuthMethodMutation, ConnectAuthMethodMutationVariables>;
export const ConnectAuthMethodCompleteDocument = gql`
    mutation ConnectAuthMethodComplete($input: ConnectAuthMethodCompleteInput!) {
  connectAuthMethodComplete(input: $input) {
    account {
      ...AccountInfo
    }
    errors {
      ...UserErrorFragment
    }
  }
}
    ${AccountInfoFragmentDoc}
${AccountProfileInfoFragmentDoc}
${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type ConnectAuthMethodCompleteMutationFn = Apollo.MutationFunction<ConnectAuthMethodCompleteMutation, ConnectAuthMethodCompleteMutationVariables>;

/**
 * __useConnectAuthMethodCompleteMutation__
 *
 * To run a mutation, you first call `useConnectAuthMethodCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectAuthMethodCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectAuthMethodCompleteMutation, { data, loading, error }] = useConnectAuthMethodCompleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConnectAuthMethodCompleteMutation(baseOptions?: Apollo.MutationHookOptions<ConnectAuthMethodCompleteMutation, ConnectAuthMethodCompleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectAuthMethodCompleteMutation, ConnectAuthMethodCompleteMutationVariables>(ConnectAuthMethodCompleteDocument, options);
      }
export type ConnectAuthMethodCompleteMutationHookResult = ReturnType<typeof useConnectAuthMethodCompleteMutation>;
export type ConnectAuthMethodCompleteMutationResult = Apollo.MutationResult<ConnectAuthMethodCompleteMutation>;
export type ConnectAuthMethodCompleteMutationOptions = Apollo.BaseMutationOptions<ConnectAuthMethodCompleteMutation, ConnectAuthMethodCompleteMutationVariables>;
export const DisconnectAuthMethodDocument = gql`
    mutation DisconnectAuthMethod($input: DisconnectAuthMethodInput!) {
  disconnectAuthMethod(input: $input) {
    account {
      ...AccountInfo
    }
    errors {
      ...UserErrorFragment
    }
  }
}
    ${AccountInfoFragmentDoc}
${AccountProfileInfoFragmentDoc}
${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type DisconnectAuthMethodMutationFn = Apollo.MutationFunction<DisconnectAuthMethodMutation, DisconnectAuthMethodMutationVariables>;

/**
 * __useDisconnectAuthMethodMutation__
 *
 * To run a mutation, you first call `useDisconnectAuthMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectAuthMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectAuthMethodMutation, { data, loading, error }] = useDisconnectAuthMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisconnectAuthMethodMutation(baseOptions?: Apollo.MutationHookOptions<DisconnectAuthMethodMutation, DisconnectAuthMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisconnectAuthMethodMutation, DisconnectAuthMethodMutationVariables>(DisconnectAuthMethodDocument, options);
      }
export type DisconnectAuthMethodMutationHookResult = ReturnType<typeof useDisconnectAuthMethodMutation>;
export type DisconnectAuthMethodMutationResult = Apollo.MutationResult<DisconnectAuthMethodMutation>;
export type DisconnectAuthMethodMutationOptions = Apollo.BaseMutationOptions<DisconnectAuthMethodMutation, DisconnectAuthMethodMutationVariables>;
export const CheckTokenStatusDocument = gql`
    mutation CheckTokenStatus($input: CheckTokenStatusInput!) {
  checkTokenStatus(input: $input) {
    token
    errors {
      ...UserErrorFragment
    }
  }
}
    ${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type CheckTokenStatusMutationFn = Apollo.MutationFunction<CheckTokenStatusMutation, CheckTokenStatusMutationVariables>;

/**
 * __useCheckTokenStatusMutation__
 *
 * To run a mutation, you first call `useCheckTokenStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckTokenStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkTokenStatusMutation, { data, loading, error }] = useCheckTokenStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckTokenStatusMutation(baseOptions?: Apollo.MutationHookOptions<CheckTokenStatusMutation, CheckTokenStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckTokenStatusMutation, CheckTokenStatusMutationVariables>(CheckTokenStatusDocument, options);
      }
export type CheckTokenStatusMutationHookResult = ReturnType<typeof useCheckTokenStatusMutation>;
export type CheckTokenStatusMutationResult = Apollo.MutationResult<CheckTokenStatusMutation>;
export type CheckTokenStatusMutationOptions = Apollo.BaseMutationOptions<CheckTokenStatusMutation, CheckTokenStatusMutationVariables>;
export const AllBalancerPoolsDocument = gql`
    query AllBalancerPools($vaultId: Address!, $currency: Currency!, $network: Deployment!) {
  allBalancerPools(vaultId: $vaultId, currency: $currency, network: $network) {
    poolSize
    poolAddress
    poolId
    gaugeAddress
    depositDisabled
  }
}
    `;

/**
 * __useAllBalancerPoolsQuery__
 *
 * To run a query within a React component, call `useAllBalancerPoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllBalancerPoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllBalancerPoolsQuery({
 *   variables: {
 *      vaultId: // value for 'vaultId'
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useAllBalancerPoolsQuery(baseOptions: Apollo.QueryHookOptions<AllBalancerPoolsQuery, AllBalancerPoolsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllBalancerPoolsQuery, AllBalancerPoolsQueryVariables>(AllBalancerPoolsDocument, options);
      }
export function useAllBalancerPoolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllBalancerPoolsQuery, AllBalancerPoolsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllBalancerPoolsQuery, AllBalancerPoolsQueryVariables>(AllBalancerPoolsDocument, options);
        }
export type AllBalancerPoolsQueryHookResult = ReturnType<typeof useAllBalancerPoolsQuery>;
export type AllBalancerPoolsLazyQueryHookResult = ReturnType<typeof useAllBalancerPoolsLazyQuery>;
export type AllBalancerPoolsQueryResult = Apollo.QueryResult<AllBalancerPoolsQuery, AllBalancerPoolsQueryVariables>;
export const BalancerPoolPositionsDocument = gql`
    query BalancerPoolPositions($vaultId: Address!, $currency: Currency!, $network: Deployment!) {
  balancerPoolPositions(vaultId: $vaultId, currency: $currency, network: $network) {
    poolId
    assetBalance {
      address
      amount
      currencyValue
    }
    depositDisabled
    poolAddress
    isGauge
    gaugeAddress
  }
}
    `;

/**
 * __useBalancerPoolPositionsQuery__
 *
 * To run a query within a React component, call `useBalancerPoolPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBalancerPoolPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBalancerPoolPositionsQuery({
 *   variables: {
 *      vaultId: // value for 'vaultId'
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useBalancerPoolPositionsQuery(baseOptions: Apollo.QueryHookOptions<BalancerPoolPositionsQuery, BalancerPoolPositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BalancerPoolPositionsQuery, BalancerPoolPositionsQueryVariables>(BalancerPoolPositionsDocument, options);
      }
export function useBalancerPoolPositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BalancerPoolPositionsQuery, BalancerPoolPositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BalancerPoolPositionsQuery, BalancerPoolPositionsQueryVariables>(BalancerPoolPositionsDocument, options);
        }
export type BalancerPoolPositionsQueryHookResult = ReturnType<typeof useBalancerPoolPositionsQuery>;
export type BalancerPoolPositionsLazyQueryHookResult = ReturnType<typeof useBalancerPoolPositionsLazyQuery>;
export type BalancerPoolPositionsQueryResult = Apollo.QueryResult<BalancerPoolPositionsQuery, BalancerPoolPositionsQueryVariables>;
export const BalancerDepositInfoDocument = gql`
    query BalancerDepositInfo($poolId: String!, $amounts: [BigInt!]!, $network: Deployment!) {
  balancerDepositInfo(poolId: $poolId, amounts: $amounts, network: $network) {
    priceImpact
    bptAmount
  }
}
    `;

/**
 * __useBalancerDepositInfoQuery__
 *
 * To run a query within a React component, call `useBalancerDepositInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useBalancerDepositInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBalancerDepositInfoQuery({
 *   variables: {
 *      poolId: // value for 'poolId'
 *      amounts: // value for 'amounts'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useBalancerDepositInfoQuery(baseOptions: Apollo.QueryHookOptions<BalancerDepositInfoQuery, BalancerDepositInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BalancerDepositInfoQuery, BalancerDepositInfoQueryVariables>(BalancerDepositInfoDocument, options);
      }
export function useBalancerDepositInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BalancerDepositInfoQuery, BalancerDepositInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BalancerDepositInfoQuery, BalancerDepositInfoQueryVariables>(BalancerDepositInfoDocument, options);
        }
export type BalancerDepositInfoQueryHookResult = ReturnType<typeof useBalancerDepositInfoQuery>;
export type BalancerDepositInfoLazyQueryHookResult = ReturnType<typeof useBalancerDepositInfoLazyQuery>;
export type BalancerDepositInfoQueryResult = Apollo.QueryResult<BalancerDepositInfoQuery, BalancerDepositInfoQueryVariables>;
export const BalancerWithdrawInfoExactBptInDocument = gql`
    query BalancerWithdrawInfoExactBptIn($poolId: String!, $bptAmountIn: BigInt!, $network: Deployment!, $tokenIndex: BigInt) {
  balancerWithdrawInfoExactBptIn(
    poolId: $poolId
    bptAmountIn: $bptAmountIn
    network: $network
    tokenIndex: $tokenIndex
  ) {
    priceImpact
    amounts
  }
}
    `;

/**
 * __useBalancerWithdrawInfoExactBptInQuery__
 *
 * To run a query within a React component, call `useBalancerWithdrawInfoExactBptInQuery` and pass it any options that fit your needs.
 * When your component renders, `useBalancerWithdrawInfoExactBptInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBalancerWithdrawInfoExactBptInQuery({
 *   variables: {
 *      poolId: // value for 'poolId'
 *      bptAmountIn: // value for 'bptAmountIn'
 *      network: // value for 'network'
 *      tokenIndex: // value for 'tokenIndex'
 *   },
 * });
 */
export function useBalancerWithdrawInfoExactBptInQuery(baseOptions: Apollo.QueryHookOptions<BalancerWithdrawInfoExactBptInQuery, BalancerWithdrawInfoExactBptInQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BalancerWithdrawInfoExactBptInQuery, BalancerWithdrawInfoExactBptInQueryVariables>(BalancerWithdrawInfoExactBptInDocument, options);
      }
export function useBalancerWithdrawInfoExactBptInLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BalancerWithdrawInfoExactBptInQuery, BalancerWithdrawInfoExactBptInQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BalancerWithdrawInfoExactBptInQuery, BalancerWithdrawInfoExactBptInQueryVariables>(BalancerWithdrawInfoExactBptInDocument, options);
        }
export type BalancerWithdrawInfoExactBptInQueryHookResult = ReturnType<typeof useBalancerWithdrawInfoExactBptInQuery>;
export type BalancerWithdrawInfoExactBptInLazyQueryHookResult = ReturnType<typeof useBalancerWithdrawInfoExactBptInLazyQuery>;
export type BalancerWithdrawInfoExactBptInQueryResult = Apollo.QueryResult<BalancerWithdrawInfoExactBptInQuery, BalancerWithdrawInfoExactBptInQueryVariables>;
export const RewardsForBalancerDocument = gql`
    query RewardsForBalancer($address: Address!, $currency: Currency!, $network: Deployment!) {
  rewardsForBalancer(address: $address, currency: $currency, network: $network) {
    individualRewards {
      address
      rewards {
        assetAddress
        claimable
        value
      }
    }
  }
}
    `;

/**
 * __useRewardsForBalancerQuery__
 *
 * To run a query within a React component, call `useRewardsForBalancerQuery` and pass it any options that fit your needs.
 * When your component renders, `useRewardsForBalancerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardsForBalancerQuery({
 *   variables: {
 *      address: // value for 'address'
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useRewardsForBalancerQuery(baseOptions: Apollo.QueryHookOptions<RewardsForBalancerQuery, RewardsForBalancerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RewardsForBalancerQuery, RewardsForBalancerQueryVariables>(RewardsForBalancerDocument, options);
      }
export function useRewardsForBalancerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RewardsForBalancerQuery, RewardsForBalancerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RewardsForBalancerQuery, RewardsForBalancerQueryVariables>(RewardsForBalancerDocument, options);
        }
export type RewardsForBalancerQueryHookResult = ReturnType<typeof useRewardsForBalancerQuery>;
export type RewardsForBalancerLazyQueryHookResult = ReturnType<typeof useRewardsForBalancerLazyQuery>;
export type RewardsForBalancerQueryResult = Apollo.QueryResult<RewardsForBalancerQuery, RewardsForBalancerQueryVariables>;
export const LendingDataForCompoundV2AssetsDocument = gql`
    query LendingDataForCompoundV2Assets($network: Deployment!, $currency: Currency!) {
  lendingDataForCompoundV2Assets(network: $network, currency: $currency) {
    assetAddress
    underlyingAddress
    baseApy
    totalApy
    rewardApys {
      ...RewardApy
    }
    totalSupply
    isMintPaused
  }
}
    ${RewardApyFragmentDoc}`;

/**
 * __useLendingDataForCompoundV2AssetsQuery__
 *
 * To run a query within a React component, call `useLendingDataForCompoundV2AssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLendingDataForCompoundV2AssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLendingDataForCompoundV2AssetsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useLendingDataForCompoundV2AssetsQuery(baseOptions: Apollo.QueryHookOptions<LendingDataForCompoundV2AssetsQuery, LendingDataForCompoundV2AssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LendingDataForCompoundV2AssetsQuery, LendingDataForCompoundV2AssetsQueryVariables>(LendingDataForCompoundV2AssetsDocument, options);
      }
export function useLendingDataForCompoundV2AssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LendingDataForCompoundV2AssetsQuery, LendingDataForCompoundV2AssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LendingDataForCompoundV2AssetsQuery, LendingDataForCompoundV2AssetsQueryVariables>(LendingDataForCompoundV2AssetsDocument, options);
        }
export type LendingDataForCompoundV2AssetsQueryHookResult = ReturnType<typeof useLendingDataForCompoundV2AssetsQuery>;
export type LendingDataForCompoundV2AssetsLazyQueryHookResult = ReturnType<typeof useLendingDataForCompoundV2AssetsLazyQuery>;
export type LendingDataForCompoundV2AssetsQueryResult = Apollo.QueryResult<LendingDataForCompoundV2AssetsQuery, LendingDataForCompoundV2AssetsQueryVariables>;
export const CompoundV2BorrowingRatesDocument = gql`
    query CompoundV2BorrowingRates($network: Deployment!, $currency: Currency!) {
  borrowingForCompoundV2Assets(network: $network, currency: $currency) {
    totalApy
    apyCurrent
    rewardApy
    rewardAssetAddress
    derivativeAddress
    underlyingAssetAddress
    collateralFactor
    usageAsCollateralEnabled
    totalSupply
  }
}
    `;

/**
 * __useCompoundV2BorrowingRatesQuery__
 *
 * To run a query within a React component, call `useCompoundV2BorrowingRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompoundV2BorrowingRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompoundV2BorrowingRatesQuery({
 *   variables: {
 *      network: // value for 'network'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useCompoundV2BorrowingRatesQuery(baseOptions: Apollo.QueryHookOptions<CompoundV2BorrowingRatesQuery, CompoundV2BorrowingRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompoundV2BorrowingRatesQuery, CompoundV2BorrowingRatesQueryVariables>(CompoundV2BorrowingRatesDocument, options);
      }
export function useCompoundV2BorrowingRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompoundV2BorrowingRatesQuery, CompoundV2BorrowingRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompoundV2BorrowingRatesQuery, CompoundV2BorrowingRatesQueryVariables>(CompoundV2BorrowingRatesDocument, options);
        }
export type CompoundV2BorrowingRatesQueryHookResult = ReturnType<typeof useCompoundV2BorrowingRatesQuery>;
export type CompoundV2BorrowingRatesLazyQueryHookResult = ReturnType<typeof useCompoundV2BorrowingRatesLazyQuery>;
export type CompoundV2BorrowingRatesQueryResult = Apollo.QueryResult<CompoundV2BorrowingRatesQuery, CompoundV2BorrowingRatesQueryVariables>;
export const RewardsForCompoundV2Document = gql`
    query RewardsForCompoundV2($address: Address!, $currency: Currency!, $network: Deployment!) {
  rewardsForCompoundV2(address: $address, currency: $currency, network: $network) {
    globalRewards {
      ...Reward
    }
  }
}
    ${RewardFragmentDoc}`;

/**
 * __useRewardsForCompoundV2Query__
 *
 * To run a query within a React component, call `useRewardsForCompoundV2Query` and pass it any options that fit your needs.
 * When your component renders, `useRewardsForCompoundV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardsForCompoundV2Query({
 *   variables: {
 *      address: // value for 'address'
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useRewardsForCompoundV2Query(baseOptions: Apollo.QueryHookOptions<RewardsForCompoundV2Query, RewardsForCompoundV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RewardsForCompoundV2Query, RewardsForCompoundV2QueryVariables>(RewardsForCompoundV2Document, options);
      }
export function useRewardsForCompoundV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RewardsForCompoundV2Query, RewardsForCompoundV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RewardsForCompoundV2Query, RewardsForCompoundV2QueryVariables>(RewardsForCompoundV2Document, options);
        }
export type RewardsForCompoundV2QueryHookResult = ReturnType<typeof useRewardsForCompoundV2Query>;
export type RewardsForCompoundV2LazyQueryHookResult = ReturnType<typeof useRewardsForCompoundV2LazyQuery>;
export type RewardsForCompoundV2QueryResult = Apollo.QueryResult<RewardsForCompoundV2Query, RewardsForCompoundV2QueryVariables>;
export const ExternalPositionsCompoundV2DebtPositionsDocument = gql`
    query ExternalPositionsCompoundV2DebtPositions($vaultId: Address!, $currency: Currency!, $network: Deployment!) {
  externalPositionsCompoundV2DebtPositions(
    vaultId: $vaultId
    currency: $currency
    network: $network
  ) {
    claimableRewardValue
    rewardToken
    active
    externalPositionAddress
    collateralAssets {
      ...CompoundV2AssetBalance
    }
    borrowedAssets {
      ...CompoundV2AssetBalance
    }
    currentBorrowValue
    maxLoanToValueRatio
    currentLoanToValueRatio
    maxSafeDecreaseInCollateral
    maxDecreaseInCollateral
    maxSafeIncreaseInBorrow
    maxIncreaseInBorrow
    safeLoanToValueRatio
    weightedApy
  }
}
    ${CompoundV2AssetBalanceFragmentDoc}`;

/**
 * __useExternalPositionsCompoundV2DebtPositionsQuery__
 *
 * To run a query within a React component, call `useExternalPositionsCompoundV2DebtPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalPositionsCompoundV2DebtPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalPositionsCompoundV2DebtPositionsQuery({
 *   variables: {
 *      vaultId: // value for 'vaultId'
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useExternalPositionsCompoundV2DebtPositionsQuery(baseOptions: Apollo.QueryHookOptions<ExternalPositionsCompoundV2DebtPositionsQuery, ExternalPositionsCompoundV2DebtPositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExternalPositionsCompoundV2DebtPositionsQuery, ExternalPositionsCompoundV2DebtPositionsQueryVariables>(ExternalPositionsCompoundV2DebtPositionsDocument, options);
      }
export function useExternalPositionsCompoundV2DebtPositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExternalPositionsCompoundV2DebtPositionsQuery, ExternalPositionsCompoundV2DebtPositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExternalPositionsCompoundV2DebtPositionsQuery, ExternalPositionsCompoundV2DebtPositionsQueryVariables>(ExternalPositionsCompoundV2DebtPositionsDocument, options);
        }
export type ExternalPositionsCompoundV2DebtPositionsQueryHookResult = ReturnType<typeof useExternalPositionsCompoundV2DebtPositionsQuery>;
export type ExternalPositionsCompoundV2DebtPositionsLazyQueryHookResult = ReturnType<typeof useExternalPositionsCompoundV2DebtPositionsLazyQuery>;
export type ExternalPositionsCompoundV2DebtPositionsQueryResult = Apollo.QueryResult<ExternalPositionsCompoundV2DebtPositionsQuery, ExternalPositionsCompoundV2DebtPositionsQueryVariables>;
export const ExternalPositionsCompoundV2DebtPortfolioDocument = gql`
    query ExternalPositionsCompoundV2DebtPortfolio($vaultId: Address!, $currency: CurrencyOrAddress!, $network: Deployment!) {
  externalPositionsCompoundV2DebtPortfolio(
    vaultId: $vaultId
    currency: $currency
    network: $network
  ) {
    netValue
    valueChange24h
    collateralAssets {
      ...CompoundV2AssetBalance
    }
    borrowedAssets {
      ...CompoundV2AssetBalance
    }
  }
}
    ${CompoundV2AssetBalanceFragmentDoc}`;

/**
 * __useExternalPositionsCompoundV2DebtPortfolioQuery__
 *
 * To run a query within a React component, call `useExternalPositionsCompoundV2DebtPortfolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalPositionsCompoundV2DebtPortfolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalPositionsCompoundV2DebtPortfolioQuery({
 *   variables: {
 *      vaultId: // value for 'vaultId'
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useExternalPositionsCompoundV2DebtPortfolioQuery(baseOptions: Apollo.QueryHookOptions<ExternalPositionsCompoundV2DebtPortfolioQuery, ExternalPositionsCompoundV2DebtPortfolioQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExternalPositionsCompoundV2DebtPortfolioQuery, ExternalPositionsCompoundV2DebtPortfolioQueryVariables>(ExternalPositionsCompoundV2DebtPortfolioDocument, options);
      }
export function useExternalPositionsCompoundV2DebtPortfolioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExternalPositionsCompoundV2DebtPortfolioQuery, ExternalPositionsCompoundV2DebtPortfolioQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExternalPositionsCompoundV2DebtPortfolioQuery, ExternalPositionsCompoundV2DebtPortfolioQueryVariables>(ExternalPositionsCompoundV2DebtPortfolioDocument, options);
        }
export type ExternalPositionsCompoundV2DebtPortfolioQueryHookResult = ReturnType<typeof useExternalPositionsCompoundV2DebtPortfolioQuery>;
export type ExternalPositionsCompoundV2DebtPortfolioLazyQueryHookResult = ReturnType<typeof useExternalPositionsCompoundV2DebtPortfolioLazyQuery>;
export type ExternalPositionsCompoundV2DebtPortfolioQueryResult = Apollo.QueryResult<ExternalPositionsCompoundV2DebtPortfolioQuery, ExternalPositionsCompoundV2DebtPortfolioQueryVariables>;
export const LendingDataForCompoundV3AssetsDocument = gql`
    query LendingDataForCompoundV3Assets($network: Deployment!, $currency: Currency!, $vaultId: Address!) {
  lendingDataForCompoundV3Assets(
    network: $network
    currency: $currency
    vaultId: $vaultId
  ) {
    underlyingAsset {
      address
      amount
      currencyValue
    }
    assetAddress
    baseApy
    totalApy
    rewardApys {
      assetAddress
      apy
    }
    totalSupply
  }
}
    `;

/**
 * __useLendingDataForCompoundV3AssetsQuery__
 *
 * To run a query within a React component, call `useLendingDataForCompoundV3AssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLendingDataForCompoundV3AssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLendingDataForCompoundV3AssetsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      currency: // value for 'currency'
 *      vaultId: // value for 'vaultId'
 *   },
 * });
 */
export function useLendingDataForCompoundV3AssetsQuery(baseOptions: Apollo.QueryHookOptions<LendingDataForCompoundV3AssetsQuery, LendingDataForCompoundV3AssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LendingDataForCompoundV3AssetsQuery, LendingDataForCompoundV3AssetsQueryVariables>(LendingDataForCompoundV3AssetsDocument, options);
      }
export function useLendingDataForCompoundV3AssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LendingDataForCompoundV3AssetsQuery, LendingDataForCompoundV3AssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LendingDataForCompoundV3AssetsQuery, LendingDataForCompoundV3AssetsQueryVariables>(LendingDataForCompoundV3AssetsDocument, options);
        }
export type LendingDataForCompoundV3AssetsQueryHookResult = ReturnType<typeof useLendingDataForCompoundV3AssetsQuery>;
export type LendingDataForCompoundV3AssetsLazyQueryHookResult = ReturnType<typeof useLendingDataForCompoundV3AssetsLazyQuery>;
export type LendingDataForCompoundV3AssetsQueryResult = Apollo.QueryResult<LendingDataForCompoundV3AssetsQuery, LendingDataForCompoundV3AssetsQueryVariables>;
export const LendingDataForCompoundV3AssetsPositionDocument = gql`
    query LendingDataForCompoundV3AssetsPosition($network: Deployment!, $currency: Currency!, $vaultId: Address!) {
  lendingDataForCompoundV3AssetsPosition(
    network: $network
    currency: $currency
    vaultId: $vaultId
  ) {
    asset {
      address
      amount
      currencyValue
    }
    isLendDisabled
    baseApy
    totalApy
    rewardApys {
      assetAddress
      apy
    }
  }
}
    `;

/**
 * __useLendingDataForCompoundV3AssetsPositionQuery__
 *
 * To run a query within a React component, call `useLendingDataForCompoundV3AssetsPositionQuery` and pass it any options that fit your needs.
 * When your component renders, `useLendingDataForCompoundV3AssetsPositionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLendingDataForCompoundV3AssetsPositionQuery({
 *   variables: {
 *      network: // value for 'network'
 *      currency: // value for 'currency'
 *      vaultId: // value for 'vaultId'
 *   },
 * });
 */
export function useLendingDataForCompoundV3AssetsPositionQuery(baseOptions: Apollo.QueryHookOptions<LendingDataForCompoundV3AssetsPositionQuery, LendingDataForCompoundV3AssetsPositionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LendingDataForCompoundV3AssetsPositionQuery, LendingDataForCompoundV3AssetsPositionQueryVariables>(LendingDataForCompoundV3AssetsPositionDocument, options);
      }
export function useLendingDataForCompoundV3AssetsPositionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LendingDataForCompoundV3AssetsPositionQuery, LendingDataForCompoundV3AssetsPositionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LendingDataForCompoundV3AssetsPositionQuery, LendingDataForCompoundV3AssetsPositionQueryVariables>(LendingDataForCompoundV3AssetsPositionDocument, options);
        }
export type LendingDataForCompoundV3AssetsPositionQueryHookResult = ReturnType<typeof useLendingDataForCompoundV3AssetsPositionQuery>;
export type LendingDataForCompoundV3AssetsPositionLazyQueryHookResult = ReturnType<typeof useLendingDataForCompoundV3AssetsPositionLazyQuery>;
export type LendingDataForCompoundV3AssetsPositionQueryResult = Apollo.QueryResult<LendingDataForCompoundV3AssetsPositionQuery, LendingDataForCompoundV3AssetsPositionQueryVariables>;
export const RewardsForCompoundV3Document = gql`
    query RewardsForCompoundV3($address: Address!, $currency: Currency!, $network: Deployment!) {
  rewardsForCompoundV3(address: $address, currency: $currency, network: $network) {
    globalRewards {
      assetAddress
      claimable
      value
    }
    cTokensToClaim
  }
}
    `;

/**
 * __useRewardsForCompoundV3Query__
 *
 * To run a query within a React component, call `useRewardsForCompoundV3Query` and pass it any options that fit your needs.
 * When your component renders, `useRewardsForCompoundV3Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardsForCompoundV3Query({
 *   variables: {
 *      address: // value for 'address'
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useRewardsForCompoundV3Query(baseOptions: Apollo.QueryHookOptions<RewardsForCompoundV3Query, RewardsForCompoundV3QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RewardsForCompoundV3Query, RewardsForCompoundV3QueryVariables>(RewardsForCompoundV3Document, options);
      }
export function useRewardsForCompoundV3LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RewardsForCompoundV3Query, RewardsForCompoundV3QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RewardsForCompoundV3Query, RewardsForCompoundV3QueryVariables>(RewardsForCompoundV3Document, options);
        }
export type RewardsForCompoundV3QueryHookResult = ReturnType<typeof useRewardsForCompoundV3Query>;
export type RewardsForCompoundV3LazyQueryHookResult = ReturnType<typeof useRewardsForCompoundV3LazyQuery>;
export type RewardsForCompoundV3QueryResult = Apollo.QueryResult<RewardsForCompoundV3Query, RewardsForCompoundV3QueryVariables>;
export const ExternalPositionConvexVotingInfoDocument = gql`
    query ExternalPositionConvexVotingInfo($address: Address!, $currency: Currency!, $network: Deployment!) {
  externalPositionConvexVotingInfo(
    address: $address
    currency: $currency
    network: $network
  ) {
    ...ConvexVotingInfo
  }
}
    ${ConvexVotingInfoFragmentDoc}`;

/**
 * __useExternalPositionConvexVotingInfoQuery__
 *
 * To run a query within a React component, call `useExternalPositionConvexVotingInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalPositionConvexVotingInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalPositionConvexVotingInfoQuery({
 *   variables: {
 *      address: // value for 'address'
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useExternalPositionConvexVotingInfoQuery(baseOptions: Apollo.QueryHookOptions<ExternalPositionConvexVotingInfoQuery, ExternalPositionConvexVotingInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExternalPositionConvexVotingInfoQuery, ExternalPositionConvexVotingInfoQueryVariables>(ExternalPositionConvexVotingInfoDocument, options);
      }
export function useExternalPositionConvexVotingInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExternalPositionConvexVotingInfoQuery, ExternalPositionConvexVotingInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExternalPositionConvexVotingInfoQuery, ExternalPositionConvexVotingInfoQueryVariables>(ExternalPositionConvexVotingInfoDocument, options);
        }
export type ExternalPositionConvexVotingInfoQueryHookResult = ReturnType<typeof useExternalPositionConvexVotingInfoQuery>;
export type ExternalPositionConvexVotingInfoLazyQueryHookResult = ReturnType<typeof useExternalPositionConvexVotingInfoLazyQuery>;
export type ExternalPositionConvexVotingInfoQueryResult = Apollo.QueryResult<ExternalPositionConvexVotingInfoQuery, ExternalPositionConvexVotingInfoQueryVariables>;
export const ExternalPositionConvexVotingPortfolioDocument = gql`
    query ExternalPositionConvexVotingPortfolio($address: Address!, $currency: CurrencyOrAddress!, $network: Deployment!) {
  externalPositionConvexVotingPortfolio(
    address: $address
    currency: $currency
    network: $network
  ) {
    ...ExternalPositionConvexVotingPortfolioData
  }
}
    ${ExternalPositionConvexVotingPortfolioDataFragmentDoc}`;

/**
 * __useExternalPositionConvexVotingPortfolioQuery__
 *
 * To run a query within a React component, call `useExternalPositionConvexVotingPortfolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalPositionConvexVotingPortfolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalPositionConvexVotingPortfolioQuery({
 *   variables: {
 *      address: // value for 'address'
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useExternalPositionConvexVotingPortfolioQuery(baseOptions: Apollo.QueryHookOptions<ExternalPositionConvexVotingPortfolioQuery, ExternalPositionConvexVotingPortfolioQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExternalPositionConvexVotingPortfolioQuery, ExternalPositionConvexVotingPortfolioQueryVariables>(ExternalPositionConvexVotingPortfolioDocument, options);
      }
export function useExternalPositionConvexVotingPortfolioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExternalPositionConvexVotingPortfolioQuery, ExternalPositionConvexVotingPortfolioQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExternalPositionConvexVotingPortfolioQuery, ExternalPositionConvexVotingPortfolioQueryVariables>(ExternalPositionConvexVotingPortfolioDocument, options);
        }
export type ExternalPositionConvexVotingPortfolioQueryHookResult = ReturnType<typeof useExternalPositionConvexVotingPortfolioQuery>;
export type ExternalPositionConvexVotingPortfolioLazyQueryHookResult = ReturnType<typeof useExternalPositionConvexVotingPortfolioLazyQuery>;
export type ExternalPositionConvexVotingPortfolioQueryResult = Apollo.QueryResult<ExternalPositionConvexVotingPortfolioQuery, ExternalPositionConvexVotingPortfolioQueryVariables>;
export const CurvePoolsDocument = gql`
    query CurvePools($currency: Currency!, $network: Deployment!) {
  curvePools(currency: $currency, network: $network) {
    address
    poolSize
    baseApy
    totalApy
    rewardApys {
      ...RewardApy
    }
  }
}
    ${RewardApyFragmentDoc}`;

/**
 * __useCurvePoolsQuery__
 *
 * To run a query within a React component, call `useCurvePoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurvePoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurvePoolsQuery({
 *   variables: {
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useCurvePoolsQuery(baseOptions: Apollo.QueryHookOptions<CurvePoolsQuery, CurvePoolsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurvePoolsQuery, CurvePoolsQueryVariables>(CurvePoolsDocument, options);
      }
export function useCurvePoolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurvePoolsQuery, CurvePoolsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurvePoolsQuery, CurvePoolsQueryVariables>(CurvePoolsDocument, options);
        }
export type CurvePoolsQueryHookResult = ReturnType<typeof useCurvePoolsQuery>;
export type CurvePoolsLazyQueryHookResult = ReturnType<typeof useCurvePoolsLazyQuery>;
export type CurvePoolsQueryResult = Apollo.QueryResult<CurvePoolsQuery, CurvePoolsQueryVariables>;
export const RewardsForCurveDocument = gql`
    query RewardsForCurve($address: Address!, $currency: Currency!, $network: Deployment!) {
  rewardsForCurve(address: $address, currency: $currency, network: $network) {
    globalRewards {
      ...Reward
    }
    individualRewards {
      ...IndividualReward
    }
  }
}
    ${RewardFragmentDoc}
${IndividualRewardFragmentDoc}`;

/**
 * __useRewardsForCurveQuery__
 *
 * To run a query within a React component, call `useRewardsForCurveQuery` and pass it any options that fit your needs.
 * When your component renders, `useRewardsForCurveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardsForCurveQuery({
 *   variables: {
 *      address: // value for 'address'
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useRewardsForCurveQuery(baseOptions: Apollo.QueryHookOptions<RewardsForCurveQuery, RewardsForCurveQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RewardsForCurveQuery, RewardsForCurveQueryVariables>(RewardsForCurveDocument, options);
      }
export function useRewardsForCurveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RewardsForCurveQuery, RewardsForCurveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RewardsForCurveQuery, RewardsForCurveQueryVariables>(RewardsForCurveDocument, options);
        }
export type RewardsForCurveQueryHookResult = ReturnType<typeof useRewardsForCurveQuery>;
export type RewardsForCurveLazyQueryHookResult = ReturnType<typeof useRewardsForCurveLazyQuery>;
export type RewardsForCurveQueryResult = Apollo.QueryResult<RewardsForCurveQuery, RewardsForCurveQueryVariables>;
export const DelegateVotesAssetsDocument = gql`
    query DelegateVotesAssets($network: Deployment!, $vaultId: Address!, $currency: Currency!) {
  delegateVotesAssets(network: $network, vaultId: $vaultId, currency: $currency) {
    assetBalance {
      assetAddress
      amount
      currencyValue
    }
    delegatee
    protocol
  }
}
    `;

/**
 * __useDelegateVotesAssetsQuery__
 *
 * To run a query within a React component, call `useDelegateVotesAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDelegateVotesAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDelegateVotesAssetsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      vaultId: // value for 'vaultId'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useDelegateVotesAssetsQuery(baseOptions: Apollo.QueryHookOptions<DelegateVotesAssetsQuery, DelegateVotesAssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DelegateVotesAssetsQuery, DelegateVotesAssetsQueryVariables>(DelegateVotesAssetsDocument, options);
      }
export function useDelegateVotesAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DelegateVotesAssetsQuery, DelegateVotesAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DelegateVotesAssetsQuery, DelegateVotesAssetsQueryVariables>(DelegateVotesAssetsDocument, options);
        }
export type DelegateVotesAssetsQueryHookResult = ReturnType<typeof useDelegateVotesAssetsQuery>;
export type DelegateVotesAssetsLazyQueryHookResult = ReturnType<typeof useDelegateVotesAssetsLazyQuery>;
export type DelegateVotesAssetsQueryResult = Apollo.QueryResult<DelegateVotesAssetsQuery, DelegateVotesAssetsQueryVariables>;
export const DivaStakingDepositorInfoDocument = gql`
    query DivaStakingDepositorInfo($network: Deployment!, $depositor: Address!, $currency: Currency!) {
  divaStakingDepositorInfo(
    network: $network
    depositor: $depositor
    currency: $currency
  ) {
    totalEthInTranches {
      ...AssetBalanceCurrency
    }
    nektarInfo {
      maxDrops
      accruedDrops
      epoch
      dropsPerEthPerDay
    }
    tranches {
      asset {
        ...AssetBalanceCurrency
      }
      maxRewards
      accruedRewards
      trancheId
    }
    activities {
      createdAt
      type
      vaultName
      trancheAmounts {
        asset {
          ...AssetBalanceCurrency
        }
        trancheId
      }
    }
  }
}
    ${AssetBalanceCurrencyFragmentDoc}`;

/**
 * __useDivaStakingDepositorInfoQuery__
 *
 * To run a query within a React component, call `useDivaStakingDepositorInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDivaStakingDepositorInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDivaStakingDepositorInfoQuery({
 *   variables: {
 *      network: // value for 'network'
 *      depositor: // value for 'depositor'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useDivaStakingDepositorInfoQuery(baseOptions: Apollo.QueryHookOptions<DivaStakingDepositorInfoQuery, DivaStakingDepositorInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DivaStakingDepositorInfoQuery, DivaStakingDepositorInfoQueryVariables>(DivaStakingDepositorInfoDocument, options);
      }
export function useDivaStakingDepositorInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DivaStakingDepositorInfoQuery, DivaStakingDepositorInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DivaStakingDepositorInfoQuery, DivaStakingDepositorInfoQueryVariables>(DivaStakingDepositorInfoDocument, options);
        }
export type DivaStakingDepositorInfoQueryHookResult = ReturnType<typeof useDivaStakingDepositorInfoQuery>;
export type DivaStakingDepositorInfoLazyQueryHookResult = ReturnType<typeof useDivaStakingDepositorInfoLazyQuery>;
export type DivaStakingDepositorInfoQueryResult = Apollo.QueryResult<DivaStakingDepositorInfoQuery, DivaStakingDepositorInfoQueryVariables>;
export const DivaStakingGeneralInfoDocument = gql`
    query DivaStakingGeneralInfo($network: Deployment!) {
  divaStakingGeneralInfo(network: $network) {
    stakers
    tvl
    tvlEth
    tvlstEth
  }
}
    `;

/**
 * __useDivaStakingGeneralInfoQuery__
 *
 * To run a query within a React component, call `useDivaStakingGeneralInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDivaStakingGeneralInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDivaStakingGeneralInfoQuery({
 *   variables: {
 *      network: // value for 'network'
 *   },
 * });
 */
export function useDivaStakingGeneralInfoQuery(baseOptions: Apollo.QueryHookOptions<DivaStakingGeneralInfoQuery, DivaStakingGeneralInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DivaStakingGeneralInfoQuery, DivaStakingGeneralInfoQueryVariables>(DivaStakingGeneralInfoDocument, options);
      }
export function useDivaStakingGeneralInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DivaStakingGeneralInfoQuery, DivaStakingGeneralInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DivaStakingGeneralInfoQuery, DivaStakingGeneralInfoQueryVariables>(DivaStakingGeneralInfoDocument, options);
        }
export type DivaStakingGeneralInfoQueryHookResult = ReturnType<typeof useDivaStakingGeneralInfoQuery>;
export type DivaStakingGeneralInfoLazyQueryHookResult = ReturnType<typeof useDivaStakingGeneralInfoLazyQuery>;
export type DivaStakingGeneralInfoQueryResult = Apollo.QueryResult<DivaStakingGeneralInfoQuery, DivaStakingGeneralInfoQueryVariables>;
export const LendingDataForErc4626AssetsDocument = gql`
    query LendingDataForERC4626Assets($network: Deployment!, $currency: Currency!, $protocol: ERC4626Protocol!, $vaultId: Address!) {
  lendingDataForERC4626Assets(
    network: $network
    currency: $currency
    protocol: $protocol
    vaultId: $vaultId
  ) {
    assetAddress
    isPaused
    totalApy
    underlyingAsset {
      address
      amount
      currencyValue
    }
  }
}
    `;

/**
 * __useLendingDataForErc4626AssetsQuery__
 *
 * To run a query within a React component, call `useLendingDataForErc4626AssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLendingDataForErc4626AssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLendingDataForErc4626AssetsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      currency: // value for 'currency'
 *      protocol: // value for 'protocol'
 *      vaultId: // value for 'vaultId'
 *   },
 * });
 */
export function useLendingDataForErc4626AssetsQuery(baseOptions: Apollo.QueryHookOptions<LendingDataForErc4626AssetsQuery, LendingDataForErc4626AssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LendingDataForErc4626AssetsQuery, LendingDataForErc4626AssetsQueryVariables>(LendingDataForErc4626AssetsDocument, options);
      }
export function useLendingDataForErc4626AssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LendingDataForErc4626AssetsQuery, LendingDataForErc4626AssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LendingDataForErc4626AssetsQuery, LendingDataForErc4626AssetsQueryVariables>(LendingDataForErc4626AssetsDocument, options);
        }
export type LendingDataForErc4626AssetsQueryHookResult = ReturnType<typeof useLendingDataForErc4626AssetsQuery>;
export type LendingDataForErc4626AssetsLazyQueryHookResult = ReturnType<typeof useLendingDataForErc4626AssetsLazyQuery>;
export type LendingDataForErc4626AssetsQueryResult = Apollo.QueryResult<LendingDataForErc4626AssetsQuery, LendingDataForErc4626AssetsQueryVariables>;
export const LendingDataForErc4626AssetsPositionDocument = gql`
    query LendingDataForERC4626AssetsPosition($network: Deployment!, $currency: Currency!, $protocol: ERC4626Protocol!, $vaultId: Address!) {
  lendingDataForERC4626AssetsPosition(
    network: $network
    currency: $currency
    protocol: $protocol
    vaultId: $vaultId
  ) {
    asset {
      address
      amount
      currencyValue
    }
    isPaused
    totalApy
    underlyingAddress
  }
}
    `;

/**
 * __useLendingDataForErc4626AssetsPositionQuery__
 *
 * To run a query within a React component, call `useLendingDataForErc4626AssetsPositionQuery` and pass it any options that fit your needs.
 * When your component renders, `useLendingDataForErc4626AssetsPositionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLendingDataForErc4626AssetsPositionQuery({
 *   variables: {
 *      network: // value for 'network'
 *      currency: // value for 'currency'
 *      protocol: // value for 'protocol'
 *      vaultId: // value for 'vaultId'
 *   },
 * });
 */
export function useLendingDataForErc4626AssetsPositionQuery(baseOptions: Apollo.QueryHookOptions<LendingDataForErc4626AssetsPositionQuery, LendingDataForErc4626AssetsPositionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LendingDataForErc4626AssetsPositionQuery, LendingDataForErc4626AssetsPositionQueryVariables>(LendingDataForErc4626AssetsPositionDocument, options);
      }
export function useLendingDataForErc4626AssetsPositionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LendingDataForErc4626AssetsPositionQuery, LendingDataForErc4626AssetsPositionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LendingDataForErc4626AssetsPositionQuery, LendingDataForErc4626AssetsPositionQueryVariables>(LendingDataForErc4626AssetsPositionDocument, options);
        }
export type LendingDataForErc4626AssetsPositionQueryHookResult = ReturnType<typeof useLendingDataForErc4626AssetsPositionQuery>;
export type LendingDataForErc4626AssetsPositionLazyQueryHookResult = ReturnType<typeof useLendingDataForErc4626AssetsPositionLazyQuery>;
export type LendingDataForErc4626AssetsPositionQueryResult = Apollo.QueryResult<LendingDataForErc4626AssetsPositionQuery, LendingDataForErc4626AssetsPositionQueryVariables>;
export const LendingDataForIdleAssetsDocument = gql`
    query LendingDataForIdleAssets($currency: Currency!, $network: Deployment!) {
  lendingDataForIdleAssets(currency: $currency, network: $network) {
    ...SingleIdleAssetLendingData
  }
}
    ${SingleIdleAssetLendingDataFragmentDoc}
${RewardApyFragmentDoc}`;

/**
 * __useLendingDataForIdleAssetsQuery__
 *
 * To run a query within a React component, call `useLendingDataForIdleAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLendingDataForIdleAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLendingDataForIdleAssetsQuery({
 *   variables: {
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useLendingDataForIdleAssetsQuery(baseOptions: Apollo.QueryHookOptions<LendingDataForIdleAssetsQuery, LendingDataForIdleAssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LendingDataForIdleAssetsQuery, LendingDataForIdleAssetsQueryVariables>(LendingDataForIdleAssetsDocument, options);
      }
export function useLendingDataForIdleAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LendingDataForIdleAssetsQuery, LendingDataForIdleAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LendingDataForIdleAssetsQuery, LendingDataForIdleAssetsQueryVariables>(LendingDataForIdleAssetsDocument, options);
        }
export type LendingDataForIdleAssetsQueryHookResult = ReturnType<typeof useLendingDataForIdleAssetsQuery>;
export type LendingDataForIdleAssetsLazyQueryHookResult = ReturnType<typeof useLendingDataForIdleAssetsLazyQuery>;
export type LendingDataForIdleAssetsQueryResult = Apollo.QueryResult<LendingDataForIdleAssetsQuery, LendingDataForIdleAssetsQueryVariables>;
export const RewardsForIdleDocument = gql`
    query RewardsForIdle($address: Address!, $currency: Currency!, $network: Deployment!) {
  rewardsForIdle(address: $address, currency: $currency, network: $network) {
    globalRewards {
      ...Reward
    }
    individualRewards {
      ...IndividualReward
    }
  }
}
    ${RewardFragmentDoc}
${IndividualRewardFragmentDoc}`;

/**
 * __useRewardsForIdleQuery__
 *
 * To run a query within a React component, call `useRewardsForIdleQuery` and pass it any options that fit your needs.
 * When your component renders, `useRewardsForIdleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardsForIdleQuery({
 *   variables: {
 *      address: // value for 'address'
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useRewardsForIdleQuery(baseOptions: Apollo.QueryHookOptions<RewardsForIdleQuery, RewardsForIdleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RewardsForIdleQuery, RewardsForIdleQueryVariables>(RewardsForIdleDocument, options);
      }
export function useRewardsForIdleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RewardsForIdleQuery, RewardsForIdleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RewardsForIdleQuery, RewardsForIdleQueryVariables>(RewardsForIdleDocument, options);
        }
export type RewardsForIdleQueryHookResult = ReturnType<typeof useRewardsForIdleQuery>;
export type RewardsForIdleLazyQueryHookResult = ReturnType<typeof useRewardsForIdleLazyQuery>;
export type RewardsForIdleQueryResult = Apollo.QueryResult<RewardsForIdleQuery, RewardsForIdleQueryVariables>;
export const ExternalPositionsKilnPositionsDocument = gql`
    query ExternalPositionsKilnPositions($vaultId: Address!, $network: Deployment!, $currency: Currency!) {
  externalPositionsKilnPositions(
    vaultId: $vaultId
    network: $network
    currency: $currency
  ) {
    ...KilnStakingPositionData
  }
}
    ${KilnStakingPositionDataFragmentDoc}`;

/**
 * __useExternalPositionsKilnPositionsQuery__
 *
 * To run a query within a React component, call `useExternalPositionsKilnPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalPositionsKilnPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalPositionsKilnPositionsQuery({
 *   variables: {
 *      vaultId: // value for 'vaultId'
 *      network: // value for 'network'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useExternalPositionsKilnPositionsQuery(baseOptions: Apollo.QueryHookOptions<ExternalPositionsKilnPositionsQuery, ExternalPositionsKilnPositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExternalPositionsKilnPositionsQuery, ExternalPositionsKilnPositionsQueryVariables>(ExternalPositionsKilnPositionsDocument, options);
      }
export function useExternalPositionsKilnPositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExternalPositionsKilnPositionsQuery, ExternalPositionsKilnPositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExternalPositionsKilnPositionsQuery, ExternalPositionsKilnPositionsQueryVariables>(ExternalPositionsKilnPositionsDocument, options);
        }
export type ExternalPositionsKilnPositionsQueryHookResult = ReturnType<typeof useExternalPositionsKilnPositionsQuery>;
export type ExternalPositionsKilnPositionsLazyQueryHookResult = ReturnType<typeof useExternalPositionsKilnPositionsLazyQuery>;
export type ExternalPositionsKilnPositionsQueryResult = Apollo.QueryResult<ExternalPositionsKilnPositionsQuery, ExternalPositionsKilnPositionsQueryVariables>;
export const ExternalPositionsKilnPortfolioDocument = gql`
    query ExternalPositionsKilnPortfolio($network: Deployment!, $vaultId: Address!, $currency: CurrencyOrAddress!) {
  externalPositionsKilnPortfolio(
    network: $network
    vaultId: $vaultId
    currency: $currency
  ) {
    ...ExternalPositionKilnPortfolioData
  }
}
    ${ExternalPositionKilnPortfolioDataFragmentDoc}`;

/**
 * __useExternalPositionsKilnPortfolioQuery__
 *
 * To run a query within a React component, call `useExternalPositionsKilnPortfolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalPositionsKilnPortfolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalPositionsKilnPortfolioQuery({
 *   variables: {
 *      network: // value for 'network'
 *      vaultId: // value for 'vaultId'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useExternalPositionsKilnPortfolioQuery(baseOptions: Apollo.QueryHookOptions<ExternalPositionsKilnPortfolioQuery, ExternalPositionsKilnPortfolioQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExternalPositionsKilnPortfolioQuery, ExternalPositionsKilnPortfolioQueryVariables>(ExternalPositionsKilnPortfolioDocument, options);
      }
export function useExternalPositionsKilnPortfolioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExternalPositionsKilnPortfolioQuery, ExternalPositionsKilnPortfolioQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExternalPositionsKilnPortfolioQuery, ExternalPositionsKilnPortfolioQueryVariables>(ExternalPositionsKilnPortfolioDocument, options);
        }
export type ExternalPositionsKilnPortfolioQueryHookResult = ReturnType<typeof useExternalPositionsKilnPortfolioQuery>;
export type ExternalPositionsKilnPortfolioLazyQueryHookResult = ReturnType<typeof useExternalPositionsKilnPortfolioLazyQuery>;
export type ExternalPositionsKilnPortfolioQueryResult = Apollo.QueryResult<ExternalPositionsKilnPortfolioQuery, ExternalPositionsKilnPortfolioQueryVariables>;
export const ExternalPositionsLidoUnclaimedWithdrawalRequestsDocument = gql`
    query ExternalPositionsLidoUnclaimedWithdrawalRequests($vaultId: Address!, $network: Deployment!, $currency: Currency!) {
  lidoUnclaimedWithdrawalRequests(
    vaultId: $vaultId
    network: $network
    currency: $currency
  ) {
    id
    amount
    hint
    claimable
    lidoWithdrawalsPosition
    requestedTimestamp
  }
}
    `;

/**
 * __useExternalPositionsLidoUnclaimedWithdrawalRequestsQuery__
 *
 * To run a query within a React component, call `useExternalPositionsLidoUnclaimedWithdrawalRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalPositionsLidoUnclaimedWithdrawalRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalPositionsLidoUnclaimedWithdrawalRequestsQuery({
 *   variables: {
 *      vaultId: // value for 'vaultId'
 *      network: // value for 'network'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useExternalPositionsLidoUnclaimedWithdrawalRequestsQuery(baseOptions: Apollo.QueryHookOptions<ExternalPositionsLidoUnclaimedWithdrawalRequestsQuery, ExternalPositionsLidoUnclaimedWithdrawalRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExternalPositionsLidoUnclaimedWithdrawalRequestsQuery, ExternalPositionsLidoUnclaimedWithdrawalRequestsQueryVariables>(ExternalPositionsLidoUnclaimedWithdrawalRequestsDocument, options);
      }
export function useExternalPositionsLidoUnclaimedWithdrawalRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExternalPositionsLidoUnclaimedWithdrawalRequestsQuery, ExternalPositionsLidoUnclaimedWithdrawalRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExternalPositionsLidoUnclaimedWithdrawalRequestsQuery, ExternalPositionsLidoUnclaimedWithdrawalRequestsQueryVariables>(ExternalPositionsLidoUnclaimedWithdrawalRequestsDocument, options);
        }
export type ExternalPositionsLidoUnclaimedWithdrawalRequestsQueryHookResult = ReturnType<typeof useExternalPositionsLidoUnclaimedWithdrawalRequestsQuery>;
export type ExternalPositionsLidoUnclaimedWithdrawalRequestsLazyQueryHookResult = ReturnType<typeof useExternalPositionsLidoUnclaimedWithdrawalRequestsLazyQuery>;
export type ExternalPositionsLidoUnclaimedWithdrawalRequestsQueryResult = Apollo.QueryResult<ExternalPositionsLidoUnclaimedWithdrawalRequestsQuery, ExternalPositionsLidoUnclaimedWithdrawalRequestsQueryVariables>;
export const ExternalPositionsLidoWithdrawalsPositionsDocument = gql`
    query ExternalPositionsLidoWithdrawalsPositions($vaultId: Address!, $network: Deployment!) {
  externalPositionsLidoWithdrawalsPositions(vaultId: $vaultId, network: $network) {
    id
    active
    withdrawalRequests {
      id
      amount
      claimed
    }
  }
}
    `;

/**
 * __useExternalPositionsLidoWithdrawalsPositionsQuery__
 *
 * To run a query within a React component, call `useExternalPositionsLidoWithdrawalsPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalPositionsLidoWithdrawalsPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalPositionsLidoWithdrawalsPositionsQuery({
 *   variables: {
 *      vaultId: // value for 'vaultId'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useExternalPositionsLidoWithdrawalsPositionsQuery(baseOptions: Apollo.QueryHookOptions<ExternalPositionsLidoWithdrawalsPositionsQuery, ExternalPositionsLidoWithdrawalsPositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExternalPositionsLidoWithdrawalsPositionsQuery, ExternalPositionsLidoWithdrawalsPositionsQueryVariables>(ExternalPositionsLidoWithdrawalsPositionsDocument, options);
      }
export function useExternalPositionsLidoWithdrawalsPositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExternalPositionsLidoWithdrawalsPositionsQuery, ExternalPositionsLidoWithdrawalsPositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExternalPositionsLidoWithdrawalsPositionsQuery, ExternalPositionsLidoWithdrawalsPositionsQueryVariables>(ExternalPositionsLidoWithdrawalsPositionsDocument, options);
        }
export type ExternalPositionsLidoWithdrawalsPositionsQueryHookResult = ReturnType<typeof useExternalPositionsLidoWithdrawalsPositionsQuery>;
export type ExternalPositionsLidoWithdrawalsPositionsLazyQueryHookResult = ReturnType<typeof useExternalPositionsLidoWithdrawalsPositionsLazyQuery>;
export type ExternalPositionsLidoWithdrawalsPositionsQueryResult = Apollo.QueryResult<ExternalPositionsLidoWithdrawalsPositionsQuery, ExternalPositionsLidoWithdrawalsPositionsQueryVariables>;
export const ExternalPositionsLidoWithdrawalsPortfolioDocument = gql`
    query ExternalPositionsLidoWithdrawalsPortfolio($currency: CurrencyOrAddress!, $network: Deployment!, $vaultId: Address!) {
  externalPositionsLidoWithdrawalsPortfolio(
    currency: $currency
    network: $network
    vaultId: $vaultId
  ) {
    id
    amount
    valueChange24h
  }
}
    `;

/**
 * __useExternalPositionsLidoWithdrawalsPortfolioQuery__
 *
 * To run a query within a React component, call `useExternalPositionsLidoWithdrawalsPortfolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalPositionsLidoWithdrawalsPortfolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalPositionsLidoWithdrawalsPortfolioQuery({
 *   variables: {
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *      vaultId: // value for 'vaultId'
 *   },
 * });
 */
export function useExternalPositionsLidoWithdrawalsPortfolioQuery(baseOptions: Apollo.QueryHookOptions<ExternalPositionsLidoWithdrawalsPortfolioQuery, ExternalPositionsLidoWithdrawalsPortfolioQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExternalPositionsLidoWithdrawalsPortfolioQuery, ExternalPositionsLidoWithdrawalsPortfolioQueryVariables>(ExternalPositionsLidoWithdrawalsPortfolioDocument, options);
      }
export function useExternalPositionsLidoWithdrawalsPortfolioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExternalPositionsLidoWithdrawalsPortfolioQuery, ExternalPositionsLidoWithdrawalsPortfolioQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExternalPositionsLidoWithdrawalsPortfolioQuery, ExternalPositionsLidoWithdrawalsPortfolioQueryVariables>(ExternalPositionsLidoWithdrawalsPortfolioDocument, options);
        }
export type ExternalPositionsLidoWithdrawalsPortfolioQueryHookResult = ReturnType<typeof useExternalPositionsLidoWithdrawalsPortfolioQuery>;
export type ExternalPositionsLidoWithdrawalsPortfolioLazyQueryHookResult = ReturnType<typeof useExternalPositionsLidoWithdrawalsPortfolioLazyQuery>;
export type ExternalPositionsLidoWithdrawalsPortfolioQueryResult = Apollo.QueryResult<ExternalPositionsLidoWithdrawalsPortfolioQuery, ExternalPositionsLidoWithdrawalsPortfolioQueryVariables>;
export const ExternalPositionsLiquityPositionsDocument = gql`
    query ExternalPositionsLiquityPositions($vaultId: Address!, $network: Deployment!, $currency: Currency!) {
  externalPositionsLiquityPositions(
    vaultId: $vaultId
    network: $network
    currency: $currency
  ) {
    ...LiquityDebtPositionData
  }
}
    ${LiquityDebtPositionDataFragmentDoc}
${LiquityAssetBalanceFragmentDoc}
${LiquityAssetFragmentDoc}`;

/**
 * __useExternalPositionsLiquityPositionsQuery__
 *
 * To run a query within a React component, call `useExternalPositionsLiquityPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalPositionsLiquityPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalPositionsLiquityPositionsQuery({
 *   variables: {
 *      vaultId: // value for 'vaultId'
 *      network: // value for 'network'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useExternalPositionsLiquityPositionsQuery(baseOptions: Apollo.QueryHookOptions<ExternalPositionsLiquityPositionsQuery, ExternalPositionsLiquityPositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExternalPositionsLiquityPositionsQuery, ExternalPositionsLiquityPositionsQueryVariables>(ExternalPositionsLiquityPositionsDocument, options);
      }
export function useExternalPositionsLiquityPositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExternalPositionsLiquityPositionsQuery, ExternalPositionsLiquityPositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExternalPositionsLiquityPositionsQuery, ExternalPositionsLiquityPositionsQueryVariables>(ExternalPositionsLiquityPositionsDocument, options);
        }
export type ExternalPositionsLiquityPositionsQueryHookResult = ReturnType<typeof useExternalPositionsLiquityPositionsQuery>;
export type ExternalPositionsLiquityPositionsLazyQueryHookResult = ReturnType<typeof useExternalPositionsLiquityPositionsLazyQuery>;
export type ExternalPositionsLiquityPositionsQueryResult = Apollo.QueryResult<ExternalPositionsLiquityPositionsQuery, ExternalPositionsLiquityPositionsQueryVariables>;
export const ExternalPositionsLiquityPortfolioDocument = gql`
    query ExternalPositionsLiquityPortfolio($network: Deployment!, $vaultId: Address!, $currency: CurrencyOrAddress!) {
  externalPositionsLiquityPortfolio(
    network: $network
    vaultId: $vaultId
    currency: $currency
  ) {
    ...ExternalPositionLiquityPortfolioData
  }
}
    ${ExternalPositionLiquityPortfolioDataFragmentDoc}`;

/**
 * __useExternalPositionsLiquityPortfolioQuery__
 *
 * To run a query within a React component, call `useExternalPositionsLiquityPortfolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalPositionsLiquityPortfolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalPositionsLiquityPortfolioQuery({
 *   variables: {
 *      network: // value for 'network'
 *      vaultId: // value for 'vaultId'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useExternalPositionsLiquityPortfolioQuery(baseOptions: Apollo.QueryHookOptions<ExternalPositionsLiquityPortfolioQuery, ExternalPositionsLiquityPortfolioQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExternalPositionsLiquityPortfolioQuery, ExternalPositionsLiquityPortfolioQueryVariables>(ExternalPositionsLiquityPortfolioDocument, options);
      }
export function useExternalPositionsLiquityPortfolioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExternalPositionsLiquityPortfolioQuery, ExternalPositionsLiquityPortfolioQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExternalPositionsLiquityPortfolioQuery, ExternalPositionsLiquityPortfolioQueryVariables>(ExternalPositionsLiquityPortfolioDocument, options);
        }
export type ExternalPositionsLiquityPortfolioQueryHookResult = ReturnType<typeof useExternalPositionsLiquityPortfolioQuery>;
export type ExternalPositionsLiquityPortfolioLazyQueryHookResult = ReturnType<typeof useExternalPositionsLiquityPortfolioLazyQuery>;
export type ExternalPositionsLiquityPortfolioQueryResult = Apollo.QueryResult<ExternalPositionsLiquityPortfolioQuery, ExternalPositionsLiquityPortfolioQueryVariables>;
export const LiquityHintsDocument = gql`
    query LiquityHints($actionType: String!, $collateralAmount: BigInt!, $lusdAmount: BigInt!, $network: Deployment!) {
  liquityHints(
    actionType: $actionType
    collateralAmount: $collateralAmount
    lusdAmount: $lusdAmount
    network: $network
  ) {
    upperHint
    lowerHint
  }
}
    `;

/**
 * __useLiquityHintsQuery__
 *
 * To run a query within a React component, call `useLiquityHintsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiquityHintsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiquityHintsQuery({
 *   variables: {
 *      actionType: // value for 'actionType'
 *      collateralAmount: // value for 'collateralAmount'
 *      lusdAmount: // value for 'lusdAmount'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useLiquityHintsQuery(baseOptions: Apollo.QueryHookOptions<LiquityHintsQuery, LiquityHintsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LiquityHintsQuery, LiquityHintsQueryVariables>(LiquityHintsDocument, options);
      }
export function useLiquityHintsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LiquityHintsQuery, LiquityHintsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LiquityHintsQuery, LiquityHintsQueryVariables>(LiquityHintsDocument, options);
        }
export type LiquityHintsQueryHookResult = ReturnType<typeof useLiquityHintsQuery>;
export type LiquityHintsLazyQueryHookResult = ReturnType<typeof useLiquityHintsLazyQuery>;
export type LiquityHintsQueryResult = Apollo.QueryResult<LiquityHintsQuery, LiquityHintsQueryVariables>;
export const ExternalPositionsMaplePositionsDocument = gql`
    query ExternalPositionsMaplePositions($vaultId: Address!, $network: Deployment!) {
  externalPositionsMaplePositions(vaultId: $vaultId, network: $network) {
    claimableRewards
    active
    externalPositionIndex
    externalPositionAddress
    pools {
      ...SingleMaplePoolPositionData
    }
  }
}
    ${SingleMaplePoolPositionDataFragmentDoc}
${MapleAssetFragmentDoc}`;

/**
 * __useExternalPositionsMaplePositionsQuery__
 *
 * To run a query within a React component, call `useExternalPositionsMaplePositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalPositionsMaplePositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalPositionsMaplePositionsQuery({
 *   variables: {
 *      vaultId: // value for 'vaultId'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useExternalPositionsMaplePositionsQuery(baseOptions: Apollo.QueryHookOptions<ExternalPositionsMaplePositionsQuery, ExternalPositionsMaplePositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExternalPositionsMaplePositionsQuery, ExternalPositionsMaplePositionsQueryVariables>(ExternalPositionsMaplePositionsDocument, options);
      }
export function useExternalPositionsMaplePositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExternalPositionsMaplePositionsQuery, ExternalPositionsMaplePositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExternalPositionsMaplePositionsQuery, ExternalPositionsMaplePositionsQueryVariables>(ExternalPositionsMaplePositionsDocument, options);
        }
export type ExternalPositionsMaplePositionsQueryHookResult = ReturnType<typeof useExternalPositionsMaplePositionsQuery>;
export type ExternalPositionsMaplePositionsLazyQueryHookResult = ReturnType<typeof useExternalPositionsMaplePositionsLazyQuery>;
export type ExternalPositionsMaplePositionsQueryResult = Apollo.QueryResult<ExternalPositionsMaplePositionsQuery, ExternalPositionsMaplePositionsQueryVariables>;
export const LendingDataForAllMapleAssetsDocument = gql`
    query LendingDataForAllMapleAssets($currency: Currency!, $network: Deployment!) {
  lendingDataForAllMapleAssets(currency: $currency, network: $network) {
    ...SingleMapleAssetLendingData
  }
}
    ${SingleMapleAssetLendingDataFragmentDoc}`;

/**
 * __useLendingDataForAllMapleAssetsQuery__
 *
 * To run a query within a React component, call `useLendingDataForAllMapleAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLendingDataForAllMapleAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLendingDataForAllMapleAssetsQuery({
 *   variables: {
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useLendingDataForAllMapleAssetsQuery(baseOptions: Apollo.QueryHookOptions<LendingDataForAllMapleAssetsQuery, LendingDataForAllMapleAssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LendingDataForAllMapleAssetsQuery, LendingDataForAllMapleAssetsQueryVariables>(LendingDataForAllMapleAssetsDocument, options);
      }
export function useLendingDataForAllMapleAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LendingDataForAllMapleAssetsQuery, LendingDataForAllMapleAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LendingDataForAllMapleAssetsQuery, LendingDataForAllMapleAssetsQueryVariables>(LendingDataForAllMapleAssetsDocument, options);
        }
export type LendingDataForAllMapleAssetsQueryHookResult = ReturnType<typeof useLendingDataForAllMapleAssetsQuery>;
export type LendingDataForAllMapleAssetsLazyQueryHookResult = ReturnType<typeof useLendingDataForAllMapleAssetsLazyQuery>;
export type LendingDataForAllMapleAssetsQueryResult = Apollo.QueryResult<LendingDataForAllMapleAssetsQuery, LendingDataForAllMapleAssetsQueryVariables>;
export const ExternalPositionsMaplePortfolioDocument = gql`
    query ExternalPositionsMaplePortfolio($vaultId: Address!, $currency: CurrencyOrAddress!, $network: Deployment!) {
  externalPositionsMaplePortfolio(
    vaultId: $vaultId
    currency: $currency
    network: $network
  ) {
    ...ExternalPositionMaplePortfolioData
  }
}
    ${ExternalPositionMaplePortfolioDataFragmentDoc}
${MaplePortfolioAssetFragmentDoc}`;

/**
 * __useExternalPositionsMaplePortfolioQuery__
 *
 * To run a query within a React component, call `useExternalPositionsMaplePortfolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalPositionsMaplePortfolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalPositionsMaplePortfolioQuery({
 *   variables: {
 *      vaultId: // value for 'vaultId'
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useExternalPositionsMaplePortfolioQuery(baseOptions: Apollo.QueryHookOptions<ExternalPositionsMaplePortfolioQuery, ExternalPositionsMaplePortfolioQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExternalPositionsMaplePortfolioQuery, ExternalPositionsMaplePortfolioQueryVariables>(ExternalPositionsMaplePortfolioDocument, options);
      }
export function useExternalPositionsMaplePortfolioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExternalPositionsMaplePortfolioQuery, ExternalPositionsMaplePortfolioQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExternalPositionsMaplePortfolioQuery, ExternalPositionsMaplePortfolioQueryVariables>(ExternalPositionsMaplePortfolioDocument, options);
        }
export type ExternalPositionsMaplePortfolioQueryHookResult = ReturnType<typeof useExternalPositionsMaplePortfolioQuery>;
export type ExternalPositionsMaplePortfolioLazyQueryHookResult = ReturnType<typeof useExternalPositionsMaplePortfolioLazyQuery>;
export type ExternalPositionsMaplePortfolioQueryResult = Apollo.QueryResult<ExternalPositionsMaplePortfolioQuery, ExternalPositionsMaplePortfolioQueryVariables>;
export const MapleConvertSharesToExitAssetsDocument = gql`
    query MapleConvertSharesToExitAssets($pool: Address!, $shares: BigInt!, $network: Deployment!) {
  mapleConvertSharesToExitAssets(pool: $pool, shares: $shares, network: $network) {
    assets
  }
}
    `;

/**
 * __useMapleConvertSharesToExitAssetsQuery__
 *
 * To run a query within a React component, call `useMapleConvertSharesToExitAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMapleConvertSharesToExitAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMapleConvertSharesToExitAssetsQuery({
 *   variables: {
 *      pool: // value for 'pool'
 *      shares: // value for 'shares'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useMapleConvertSharesToExitAssetsQuery(baseOptions: Apollo.QueryHookOptions<MapleConvertSharesToExitAssetsQuery, MapleConvertSharesToExitAssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MapleConvertSharesToExitAssetsQuery, MapleConvertSharesToExitAssetsQueryVariables>(MapleConvertSharesToExitAssetsDocument, options);
      }
export function useMapleConvertSharesToExitAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MapleConvertSharesToExitAssetsQuery, MapleConvertSharesToExitAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MapleConvertSharesToExitAssetsQuery, MapleConvertSharesToExitAssetsQueryVariables>(MapleConvertSharesToExitAssetsDocument, options);
        }
export type MapleConvertSharesToExitAssetsQueryHookResult = ReturnType<typeof useMapleConvertSharesToExitAssetsQuery>;
export type MapleConvertSharesToExitAssetsLazyQueryHookResult = ReturnType<typeof useMapleConvertSharesToExitAssetsLazyQuery>;
export type MapleConvertSharesToExitAssetsQueryResult = Apollo.QueryResult<MapleConvertSharesToExitAssetsQuery, MapleConvertSharesToExitAssetsQueryVariables>;
export const InitializeAuthenticatorDocument = gql`
    mutation InitializeAuthenticator {
  initializeAuthenticator {
    secret
    url
    qrcode
    errors {
      ...UserErrorFragment
    }
  }
}
    ${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type InitializeAuthenticatorMutationFn = Apollo.MutationFunction<InitializeAuthenticatorMutation, InitializeAuthenticatorMutationVariables>;

/**
 * __useInitializeAuthenticatorMutation__
 *
 * To run a mutation, you first call `useInitializeAuthenticatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitializeAuthenticatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initializeAuthenticatorMutation, { data, loading, error }] = useInitializeAuthenticatorMutation({
 *   variables: {
 *   },
 * });
 */
export function useInitializeAuthenticatorMutation(baseOptions?: Apollo.MutationHookOptions<InitializeAuthenticatorMutation, InitializeAuthenticatorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitializeAuthenticatorMutation, InitializeAuthenticatorMutationVariables>(InitializeAuthenticatorDocument, options);
      }
export type InitializeAuthenticatorMutationHookResult = ReturnType<typeof useInitializeAuthenticatorMutation>;
export type InitializeAuthenticatorMutationResult = Apollo.MutationResult<InitializeAuthenticatorMutation>;
export type InitializeAuthenticatorMutationOptions = Apollo.BaseMutationOptions<InitializeAuthenticatorMutation, InitializeAuthenticatorMutationVariables>;
export const CreateAuthenticatorDocument = gql`
    mutation CreateAuthenticator($input: CreateAuthenticatorInput!) {
  createAuthenticator(input: $input) {
    account {
      ...AccountAuthInfo
    }
    errors {
      ...UserErrorFragment
    }
  }
}
    ${AccountAuthInfoFragmentDoc}
${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type CreateAuthenticatorMutationFn = Apollo.MutationFunction<CreateAuthenticatorMutation, CreateAuthenticatorMutationVariables>;

/**
 * __useCreateAuthenticatorMutation__
 *
 * To run a mutation, you first call `useCreateAuthenticatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAuthenticatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAuthenticatorMutation, { data, loading, error }] = useCreateAuthenticatorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAuthenticatorMutation(baseOptions?: Apollo.MutationHookOptions<CreateAuthenticatorMutation, CreateAuthenticatorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAuthenticatorMutation, CreateAuthenticatorMutationVariables>(CreateAuthenticatorDocument, options);
      }
export type CreateAuthenticatorMutationHookResult = ReturnType<typeof useCreateAuthenticatorMutation>;
export type CreateAuthenticatorMutationResult = Apollo.MutationResult<CreateAuthenticatorMutation>;
export type CreateAuthenticatorMutationOptions = Apollo.BaseMutationOptions<CreateAuthenticatorMutation, CreateAuthenticatorMutationVariables>;
export const DeleteAuthenticatorDocument = gql`
    mutation DeleteAuthenticator($input: DeleteAuthenticatorInput!) {
  deleteAuthenticator(input: $input) {
    account {
      ...AccountAuthInfo
    }
    errors {
      ...UserErrorFragment
    }
  }
}
    ${AccountAuthInfoFragmentDoc}
${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type DeleteAuthenticatorMutationFn = Apollo.MutationFunction<DeleteAuthenticatorMutation, DeleteAuthenticatorMutationVariables>;

/**
 * __useDeleteAuthenticatorMutation__
 *
 * To run a mutation, you first call `useDeleteAuthenticatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAuthenticatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAuthenticatorMutation, { data, loading, error }] = useDeleteAuthenticatorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAuthenticatorMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAuthenticatorMutation, DeleteAuthenticatorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAuthenticatorMutation, DeleteAuthenticatorMutationVariables>(DeleteAuthenticatorDocument, options);
      }
export type DeleteAuthenticatorMutationHookResult = ReturnType<typeof useDeleteAuthenticatorMutation>;
export type DeleteAuthenticatorMutationResult = Apollo.MutationResult<DeleteAuthenticatorMutation>;
export type DeleteAuthenticatorMutationOptions = Apollo.BaseMutationOptions<DeleteAuthenticatorMutation, DeleteAuthenticatorMutationVariables>;
export const GenerateBackupCodesDocument = gql`
    mutation GenerateBackupCodes($input: GenerateBackupCodesInput!) {
  generateBackupCodes(input: $input) {
    account {
      ...AccountAuthInfo
    }
    codes
    errors {
      ...UserErrorFragment
    }
  }
}
    ${AccountAuthInfoFragmentDoc}
${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type GenerateBackupCodesMutationFn = Apollo.MutationFunction<GenerateBackupCodesMutation, GenerateBackupCodesMutationVariables>;

/**
 * __useGenerateBackupCodesMutation__
 *
 * To run a mutation, you first call `useGenerateBackupCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateBackupCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateBackupCodesMutation, { data, loading, error }] = useGenerateBackupCodesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateBackupCodesMutation(baseOptions?: Apollo.MutationHookOptions<GenerateBackupCodesMutation, GenerateBackupCodesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateBackupCodesMutation, GenerateBackupCodesMutationVariables>(GenerateBackupCodesDocument, options);
      }
export type GenerateBackupCodesMutationHookResult = ReturnType<typeof useGenerateBackupCodesMutation>;
export type GenerateBackupCodesMutationResult = Apollo.MutationResult<GenerateBackupCodesMutation>;
export type GenerateBackupCodesMutationOptions = Apollo.BaseMutationOptions<GenerateBackupCodesMutation, GenerateBackupCodesMutationVariables>;
export const DeleteBackupCodesDocument = gql`
    mutation DeleteBackupCodes($input: DeleteBackupCodesInput!) {
  deleteBackupCodes(input: $input) {
    account {
      ...AccountAuthInfo
    }
    errors {
      ...UserErrorFragment
    }
  }
}
    ${AccountAuthInfoFragmentDoc}
${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type DeleteBackupCodesMutationFn = Apollo.MutationFunction<DeleteBackupCodesMutation, DeleteBackupCodesMutationVariables>;

/**
 * __useDeleteBackupCodesMutation__
 *
 * To run a mutation, you first call `useDeleteBackupCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBackupCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBackupCodesMutation, { data, loading, error }] = useDeleteBackupCodesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBackupCodesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBackupCodesMutation, DeleteBackupCodesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBackupCodesMutation, DeleteBackupCodesMutationVariables>(DeleteBackupCodesDocument, options);
      }
export type DeleteBackupCodesMutationHookResult = ReturnType<typeof useDeleteBackupCodesMutation>;
export type DeleteBackupCodesMutationResult = Apollo.MutationResult<DeleteBackupCodesMutation>;
export type DeleteBackupCodesMutationOptions = Apollo.BaseMutationOptions<DeleteBackupCodesMutation, DeleteBackupCodesMutationVariables>;
export const NetworkAssetBalancesDocument = gql`
    query NetworkAssetBalances($currency: Currency!, $network: Deployment!) {
  networkAssetBalances(currency: $currency, network: $network) {
    asset
    numberOfVaults
    balance
    balanceChange24h
    price
    priceChange24h
    valid
    value
  }
}
    `;

/**
 * __useNetworkAssetBalancesQuery__
 *
 * To run a query within a React component, call `useNetworkAssetBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworkAssetBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetworkAssetBalancesQuery({
 *   variables: {
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useNetworkAssetBalancesQuery(baseOptions: Apollo.QueryHookOptions<NetworkAssetBalancesQuery, NetworkAssetBalancesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NetworkAssetBalancesQuery, NetworkAssetBalancesQueryVariables>(NetworkAssetBalancesDocument, options);
      }
export function useNetworkAssetBalancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NetworkAssetBalancesQuery, NetworkAssetBalancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NetworkAssetBalancesQuery, NetworkAssetBalancesQueryVariables>(NetworkAssetBalancesDocument, options);
        }
export type NetworkAssetBalancesQueryHookResult = ReturnType<typeof useNetworkAssetBalancesQuery>;
export type NetworkAssetBalancesLazyQueryHookResult = ReturnType<typeof useNetworkAssetBalancesLazyQuery>;
export type NetworkAssetBalancesQueryResult = Apollo.QueryResult<NetworkAssetBalancesQuery, NetworkAssetBalancesQueryVariables>;
export const NetworkLatestStatsDocument = gql`
    query NetworkLatestStats($currency: Currency!, $network: Deployment!) {
  networkLatestStats(currency: $currency, network: $network) {
    gav
    vaults
    deposits
  }
}
    `;

/**
 * __useNetworkLatestStatsQuery__
 *
 * To run a query within a React component, call `useNetworkLatestStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworkLatestStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetworkLatestStatsQuery({
 *   variables: {
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useNetworkLatestStatsQuery(baseOptions: Apollo.QueryHookOptions<NetworkLatestStatsQuery, NetworkLatestStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NetworkLatestStatsQuery, NetworkLatestStatsQueryVariables>(NetworkLatestStatsDocument, options);
      }
export function useNetworkLatestStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NetworkLatestStatsQuery, NetworkLatestStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NetworkLatestStatsQuery, NetworkLatestStatsQueryVariables>(NetworkLatestStatsDocument, options);
        }
export type NetworkLatestStatsQueryHookResult = ReturnType<typeof useNetworkLatestStatsQuery>;
export type NetworkLatestStatsLazyQueryHookResult = ReturnType<typeof useNetworkLatestStatsLazyQuery>;
export type NetworkLatestStatsQueryResult = Apollo.QueryResult<NetworkLatestStatsQuery, NetworkLatestStatsQueryVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($input: ChangePasswordInput!) {
  changePassword(input: $input) {
    account {
      ...AccountInfo
    }
    errors {
      ...UserErrorFragment
    }
  }
}
    ${AccountInfoFragmentDoc}
${AccountProfileInfoFragmentDoc}
${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($input: ForgotPasswordInput!) {
  forgotPassword(input: $input) {
    errors {
      ...UserErrorFragment
    }
  }
}
    ${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input) {
    errors {
      ...UserErrorFragment
    }
  }
}
    ${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ExternalPositionsPendleV2PositionsDocument = gql`
    query ExternalPositionsPendleV2Positions($network: Deployment!, $vaultId: Address!, $currency: Currency!) {
  externalPositionsPendleV2Positions(
    network: $network
    currency: $currency
    vaultId: $vaultId
  ) {
    active
    externalPositionIndex
    externalPositionAddress
    lpHoldings {
      balance
      asset
      marketName
      apy
      expiry
      tvl
      icon
      underlying
      syToken
      currencyValue
    }
    ptHoldings {
      balance
      asset
      marketName
      apy
      expiry
      icon
      underlying
      syToken
      market
      currencyValue
    }
    rewards {
      markets
      assetBalances {
        ...AssetBalanceCurrency
      }
    }
  }
}
    ${AssetBalanceCurrencyFragmentDoc}`;

/**
 * __useExternalPositionsPendleV2PositionsQuery__
 *
 * To run a query within a React component, call `useExternalPositionsPendleV2PositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalPositionsPendleV2PositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalPositionsPendleV2PositionsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      vaultId: // value for 'vaultId'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useExternalPositionsPendleV2PositionsQuery(baseOptions: Apollo.QueryHookOptions<ExternalPositionsPendleV2PositionsQuery, ExternalPositionsPendleV2PositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExternalPositionsPendleV2PositionsQuery, ExternalPositionsPendleV2PositionsQueryVariables>(ExternalPositionsPendleV2PositionsDocument, options);
      }
export function useExternalPositionsPendleV2PositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExternalPositionsPendleV2PositionsQuery, ExternalPositionsPendleV2PositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExternalPositionsPendleV2PositionsQuery, ExternalPositionsPendleV2PositionsQueryVariables>(ExternalPositionsPendleV2PositionsDocument, options);
        }
export type ExternalPositionsPendleV2PositionsQueryHookResult = ReturnType<typeof useExternalPositionsPendleV2PositionsQuery>;
export type ExternalPositionsPendleV2PositionsLazyQueryHookResult = ReturnType<typeof useExternalPositionsPendleV2PositionsLazyQuery>;
export type ExternalPositionsPendleV2PositionsQueryResult = Apollo.QueryResult<ExternalPositionsPendleV2PositionsQuery, ExternalPositionsPendleV2PositionsQueryVariables>;
export const AvailableToRegisterPendleV2MarketsDocument = gql`
    query AvailableToRegisterPendleV2Markets($network: Deployment!, $vaultId: Address!, $currency: Currency!) {
  availableToRegisterPendleV2Markets(
    network: $network
    currency: $currency
    vaultId: $vaultId
  ) {
    market
    marketName
    fixedApy
    swapApy
    expiry
    tvl
  }
}
    `;

/**
 * __useAvailableToRegisterPendleV2MarketsQuery__
 *
 * To run a query within a React component, call `useAvailableToRegisterPendleV2MarketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableToRegisterPendleV2MarketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableToRegisterPendleV2MarketsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      vaultId: // value for 'vaultId'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useAvailableToRegisterPendleV2MarketsQuery(baseOptions: Apollo.QueryHookOptions<AvailableToRegisterPendleV2MarketsQuery, AvailableToRegisterPendleV2MarketsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableToRegisterPendleV2MarketsQuery, AvailableToRegisterPendleV2MarketsQueryVariables>(AvailableToRegisterPendleV2MarketsDocument, options);
      }
export function useAvailableToRegisterPendleV2MarketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableToRegisterPendleV2MarketsQuery, AvailableToRegisterPendleV2MarketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableToRegisterPendleV2MarketsQuery, AvailableToRegisterPendleV2MarketsQueryVariables>(AvailableToRegisterPendleV2MarketsDocument, options);
        }
export type AvailableToRegisterPendleV2MarketsQueryHookResult = ReturnType<typeof useAvailableToRegisterPendleV2MarketsQuery>;
export type AvailableToRegisterPendleV2MarketsLazyQueryHookResult = ReturnType<typeof useAvailableToRegisterPendleV2MarketsLazyQuery>;
export type AvailableToRegisterPendleV2MarketsQueryResult = Apollo.QueryResult<AvailableToRegisterPendleV2MarketsQuery, AvailableToRegisterPendleV2MarketsQueryVariables>;
export const AvailableToUnregisterPendleV2MarketsDocument = gql`
    query AvailableToUnregisterPendleV2Markets($network: Deployment!, $vaultId: Address!, $currency: Currency!) {
  availableToUnregisterPendleV2Markets(
    network: $network
    currency: $currency
    vaultId: $vaultId
  ) {
    market
    marketName
    fixedApy
    swapApy
    expiry
    tvl
    duration
  }
}
    `;

/**
 * __useAvailableToUnregisterPendleV2MarketsQuery__
 *
 * To run a query within a React component, call `useAvailableToUnregisterPendleV2MarketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableToUnregisterPendleV2MarketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableToUnregisterPendleV2MarketsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      vaultId: // value for 'vaultId'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useAvailableToUnregisterPendleV2MarketsQuery(baseOptions: Apollo.QueryHookOptions<AvailableToUnregisterPendleV2MarketsQuery, AvailableToUnregisterPendleV2MarketsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableToUnregisterPendleV2MarketsQuery, AvailableToUnregisterPendleV2MarketsQueryVariables>(AvailableToUnregisterPendleV2MarketsDocument, options);
      }
export function useAvailableToUnregisterPendleV2MarketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableToUnregisterPendleV2MarketsQuery, AvailableToUnregisterPendleV2MarketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableToUnregisterPendleV2MarketsQuery, AvailableToUnregisterPendleV2MarketsQueryVariables>(AvailableToUnregisterPendleV2MarketsDocument, options);
        }
export type AvailableToUnregisterPendleV2MarketsQueryHookResult = ReturnType<typeof useAvailableToUnregisterPendleV2MarketsQuery>;
export type AvailableToUnregisterPendleV2MarketsLazyQueryHookResult = ReturnType<typeof useAvailableToUnregisterPendleV2MarketsLazyQuery>;
export type AvailableToUnregisterPendleV2MarketsQueryResult = Apollo.QueryResult<AvailableToUnregisterPendleV2MarketsQuery, AvailableToUnregisterPendleV2MarketsQueryVariables>;
export const AvailableToUpdatePendleV2MarketsDocument = gql`
    query AvailableToUpdatePendleV2Markets($network: Deployment!, $vaultId: Address!, $currency: Currency!) {
  availableToUpdatePendleV2Markets(
    network: $network
    currency: $currency
    vaultId: $vaultId
  ) {
    market
    marketName
    fixedApy
    swapApy
    expiry
    tvl
    duration
  }
}
    `;

/**
 * __useAvailableToUpdatePendleV2MarketsQuery__
 *
 * To run a query within a React component, call `useAvailableToUpdatePendleV2MarketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableToUpdatePendleV2MarketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableToUpdatePendleV2MarketsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      vaultId: // value for 'vaultId'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useAvailableToUpdatePendleV2MarketsQuery(baseOptions: Apollo.QueryHookOptions<AvailableToUpdatePendleV2MarketsQuery, AvailableToUpdatePendleV2MarketsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableToUpdatePendleV2MarketsQuery, AvailableToUpdatePendleV2MarketsQueryVariables>(AvailableToUpdatePendleV2MarketsDocument, options);
      }
export function useAvailableToUpdatePendleV2MarketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableToUpdatePendleV2MarketsQuery, AvailableToUpdatePendleV2MarketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableToUpdatePendleV2MarketsQuery, AvailableToUpdatePendleV2MarketsQueryVariables>(AvailableToUpdatePendleV2MarketsDocument, options);
        }
export type AvailableToUpdatePendleV2MarketsQueryHookResult = ReturnType<typeof useAvailableToUpdatePendleV2MarketsQuery>;
export type AvailableToUpdatePendleV2MarketsLazyQueryHookResult = ReturnType<typeof useAvailableToUpdatePendleV2MarketsLazyQuery>;
export type AvailableToUpdatePendleV2MarketsQueryResult = Apollo.QueryResult<AvailableToUpdatePendleV2MarketsQuery, AvailableToUpdatePendleV2MarketsQueryVariables>;
export const SwapExactTokenForPtPendleV2Document = gql`
    query SwapExactTokenForPtPendleV2($network: Deployment!, $vaultId: Address!, $market: Address!, $tokenInAddr: Address!, $amountTokenIn: BigInt!, $slippage: Float!) {
  swapExactTokenForPtPendleV2(
    network: $network
    vaultId: $vaultId
    market: $market
    tokenInAddr: $tokenInAddr
    amountTokenIn: $amountTokenIn
    slippage: $slippage
  ) {
    guessOut {
      guessMin
      guessMax
      guessOffchain
      maxIteration
      eps
    }
    amountMinOut
    priceImpact
  }
}
    `;

/**
 * __useSwapExactTokenForPtPendleV2Query__
 *
 * To run a query within a React component, call `useSwapExactTokenForPtPendleV2Query` and pass it any options that fit your needs.
 * When your component renders, `useSwapExactTokenForPtPendleV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSwapExactTokenForPtPendleV2Query({
 *   variables: {
 *      network: // value for 'network'
 *      vaultId: // value for 'vaultId'
 *      market: // value for 'market'
 *      tokenInAddr: // value for 'tokenInAddr'
 *      amountTokenIn: // value for 'amountTokenIn'
 *      slippage: // value for 'slippage'
 *   },
 * });
 */
export function useSwapExactTokenForPtPendleV2Query(baseOptions: Apollo.QueryHookOptions<SwapExactTokenForPtPendleV2Query, SwapExactTokenForPtPendleV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SwapExactTokenForPtPendleV2Query, SwapExactTokenForPtPendleV2QueryVariables>(SwapExactTokenForPtPendleV2Document, options);
      }
export function useSwapExactTokenForPtPendleV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SwapExactTokenForPtPendleV2Query, SwapExactTokenForPtPendleV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SwapExactTokenForPtPendleV2Query, SwapExactTokenForPtPendleV2QueryVariables>(SwapExactTokenForPtPendleV2Document, options);
        }
export type SwapExactTokenForPtPendleV2QueryHookResult = ReturnType<typeof useSwapExactTokenForPtPendleV2Query>;
export type SwapExactTokenForPtPendleV2LazyQueryHookResult = ReturnType<typeof useSwapExactTokenForPtPendleV2LazyQuery>;
export type SwapExactTokenForPtPendleV2QueryResult = Apollo.QueryResult<SwapExactTokenForPtPendleV2Query, SwapExactTokenForPtPendleV2QueryVariables>;
export const SellPtForTokenPendleV2Document = gql`
    query SellPtForTokenPendleV2($network: Deployment!, $vaultId: Address!, $market: Address!, $tokenOutAddr: Address!, $amountPtIn: BigInt!, $slippage: Float!, $hasExpired: Boolean!) {
  sellPtForTokenPendleV2(
    network: $network
    vaultId: $vaultId
    market: $market
    tokenOutAddr: $tokenOutAddr
    amountPtIn: $amountPtIn
    slippage: $slippage
    hasExpired: $hasExpired
  ) {
    amountMinOut
    priceImpact
  }
}
    `;

/**
 * __useSellPtForTokenPendleV2Query__
 *
 * To run a query within a React component, call `useSellPtForTokenPendleV2Query` and pass it any options that fit your needs.
 * When your component renders, `useSellPtForTokenPendleV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellPtForTokenPendleV2Query({
 *   variables: {
 *      network: // value for 'network'
 *      vaultId: // value for 'vaultId'
 *      market: // value for 'market'
 *      tokenOutAddr: // value for 'tokenOutAddr'
 *      amountPtIn: // value for 'amountPtIn'
 *      slippage: // value for 'slippage'
 *      hasExpired: // value for 'hasExpired'
 *   },
 * });
 */
export function useSellPtForTokenPendleV2Query(baseOptions: Apollo.QueryHookOptions<SellPtForTokenPendleV2Query, SellPtForTokenPendleV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SellPtForTokenPendleV2Query, SellPtForTokenPendleV2QueryVariables>(SellPtForTokenPendleV2Document, options);
      }
export function useSellPtForTokenPendleV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SellPtForTokenPendleV2Query, SellPtForTokenPendleV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SellPtForTokenPendleV2Query, SellPtForTokenPendleV2QueryVariables>(SellPtForTokenPendleV2Document, options);
        }
export type SellPtForTokenPendleV2QueryHookResult = ReturnType<typeof useSellPtForTokenPendleV2Query>;
export type SellPtForTokenPendleV2LazyQueryHookResult = ReturnType<typeof useSellPtForTokenPendleV2LazyQuery>;
export type SellPtForTokenPendleV2QueryResult = Apollo.QueryResult<SellPtForTokenPendleV2Query, SellPtForTokenPendleV2QueryVariables>;
export const AddLiquidityForPendleV2Document = gql`
    query AddLiquidityForPendleV2($network: Deployment!, $vaultId: Address!, $market: Address!, $tokenInAddr: Address!, $amountTokenIn: BigInt!, $slippage: Float!) {
  addLiquidityForPendleV2(
    network: $network
    vaultId: $vaultId
    market: $market
    tokenInAddr: $tokenInAddr
    amountTokenIn: $amountTokenIn
    slippage: $slippage
  ) {
    guessOut {
      guessMin
      guessMax
      guessOffchain
      maxIteration
      eps
    }
    amountMinOut
    priceImpact
  }
}
    `;

/**
 * __useAddLiquidityForPendleV2Query__
 *
 * To run a query within a React component, call `useAddLiquidityForPendleV2Query` and pass it any options that fit your needs.
 * When your component renders, `useAddLiquidityForPendleV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddLiquidityForPendleV2Query({
 *   variables: {
 *      network: // value for 'network'
 *      vaultId: // value for 'vaultId'
 *      market: // value for 'market'
 *      tokenInAddr: // value for 'tokenInAddr'
 *      amountTokenIn: // value for 'amountTokenIn'
 *      slippage: // value for 'slippage'
 *   },
 * });
 */
export function useAddLiquidityForPendleV2Query(baseOptions: Apollo.QueryHookOptions<AddLiquidityForPendleV2Query, AddLiquidityForPendleV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddLiquidityForPendleV2Query, AddLiquidityForPendleV2QueryVariables>(AddLiquidityForPendleV2Document, options);
      }
export function useAddLiquidityForPendleV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddLiquidityForPendleV2Query, AddLiquidityForPendleV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddLiquidityForPendleV2Query, AddLiquidityForPendleV2QueryVariables>(AddLiquidityForPendleV2Document, options);
        }
export type AddLiquidityForPendleV2QueryHookResult = ReturnType<typeof useAddLiquidityForPendleV2Query>;
export type AddLiquidityForPendleV2LazyQueryHookResult = ReturnType<typeof useAddLiquidityForPendleV2LazyQuery>;
export type AddLiquidityForPendleV2QueryResult = Apollo.QueryResult<AddLiquidityForPendleV2Query, AddLiquidityForPendleV2QueryVariables>;
export const RemoveLiquidityForPendleV2Document = gql`
    query RemoveLiquidityForPendleV2($network: Deployment!, $vaultId: Address!, $market: Address!, $tokenOutAddr: Address!, $amountLpToRemove: BigInt!, $slippage: Float!) {
  removeLiquidityForPendleV2(
    network: $network
    vaultId: $vaultId
    market: $market
    tokenOutAddr: $tokenOutAddr
    amountLpToRemove: $amountLpToRemove
    slippage: $slippage
  ) {
    minSyOut
    minIncomingAmount
    priceImpact
  }
}
    `;

/**
 * __useRemoveLiquidityForPendleV2Query__
 *
 * To run a query within a React component, call `useRemoveLiquidityForPendleV2Query` and pass it any options that fit your needs.
 * When your component renders, `useRemoveLiquidityForPendleV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRemoveLiquidityForPendleV2Query({
 *   variables: {
 *      network: // value for 'network'
 *      vaultId: // value for 'vaultId'
 *      market: // value for 'market'
 *      tokenOutAddr: // value for 'tokenOutAddr'
 *      amountLpToRemove: // value for 'amountLpToRemove'
 *      slippage: // value for 'slippage'
 *   },
 * });
 */
export function useRemoveLiquidityForPendleV2Query(baseOptions: Apollo.QueryHookOptions<RemoveLiquidityForPendleV2Query, RemoveLiquidityForPendleV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RemoveLiquidityForPendleV2Query, RemoveLiquidityForPendleV2QueryVariables>(RemoveLiquidityForPendleV2Document, options);
      }
export function useRemoveLiquidityForPendleV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RemoveLiquidityForPendleV2Query, RemoveLiquidityForPendleV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RemoveLiquidityForPendleV2Query, RemoveLiquidityForPendleV2QueryVariables>(RemoveLiquidityForPendleV2Document, options);
        }
export type RemoveLiquidityForPendleV2QueryHookResult = ReturnType<typeof useRemoveLiquidityForPendleV2Query>;
export type RemoveLiquidityForPendleV2LazyQueryHookResult = ReturnType<typeof useRemoveLiquidityForPendleV2LazyQuery>;
export type RemoveLiquidityForPendleV2QueryResult = Apollo.QueryResult<RemoveLiquidityForPendleV2Query, RemoveLiquidityForPendleV2QueryVariables>;
export const ExternalPositionsPendleV2PortfolioDocument = gql`
    query ExternalPositionsPendleV2Portfolio($vaultId: Address!, $currency: CurrencyOrAddress!, $network: Deployment!) {
  externalPositionsPendleV2Portfolio(
    vaultId: $vaultId
    currency: $currency
    network: $network
  ) {
    netValue
    valueChange24h
    assetBalances {
      ...AssetBalanceCurrency
    }
  }
}
    ${AssetBalanceCurrencyFragmentDoc}`;

/**
 * __useExternalPositionsPendleV2PortfolioQuery__
 *
 * To run a query within a React component, call `useExternalPositionsPendleV2PortfolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalPositionsPendleV2PortfolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalPositionsPendleV2PortfolioQuery({
 *   variables: {
 *      vaultId: // value for 'vaultId'
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useExternalPositionsPendleV2PortfolioQuery(baseOptions: Apollo.QueryHookOptions<ExternalPositionsPendleV2PortfolioQuery, ExternalPositionsPendleV2PortfolioQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExternalPositionsPendleV2PortfolioQuery, ExternalPositionsPendleV2PortfolioQueryVariables>(ExternalPositionsPendleV2PortfolioDocument, options);
      }
export function useExternalPositionsPendleV2PortfolioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExternalPositionsPendleV2PortfolioQuery, ExternalPositionsPendleV2PortfolioQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExternalPositionsPendleV2PortfolioQuery, ExternalPositionsPendleV2PortfolioQueryVariables>(ExternalPositionsPendleV2PortfolioDocument, options);
        }
export type ExternalPositionsPendleV2PortfolioQueryHookResult = ReturnType<typeof useExternalPositionsPendleV2PortfolioQuery>;
export type ExternalPositionsPendleV2PortfolioLazyQueryHookResult = ReturnType<typeof useExternalPositionsPendleV2PortfolioLazyQuery>;
export type ExternalPositionsPendleV2PortfolioQueryResult = Apollo.QueryResult<ExternalPositionsPendleV2PortfolioQuery, ExternalPositionsPendleV2PortfolioQueryVariables>;
export const RewardsForWalletDocument = gql`
    query RewardsForWallet($address: Address!, $currency: Currency!, $network: Deployment!) {
  rewardsForWallet(address: $address, currency: $currency, network: $network) {
    protocol
    globalRewards {
      ...Reward
    }
    individualRewards {
      ...IndividualReward
    }
  }
}
    ${RewardFragmentDoc}
${IndividualRewardFragmentDoc}`;

/**
 * __useRewardsForWalletQuery__
 *
 * To run a query within a React component, call `useRewardsForWalletQuery` and pass it any options that fit your needs.
 * When your component renders, `useRewardsForWalletQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardsForWalletQuery({
 *   variables: {
 *      address: // value for 'address'
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useRewardsForWalletQuery(baseOptions: Apollo.QueryHookOptions<RewardsForWalletQuery, RewardsForWalletQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RewardsForWalletQuery, RewardsForWalletQueryVariables>(RewardsForWalletDocument, options);
      }
export function useRewardsForWalletLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RewardsForWalletQuery, RewardsForWalletQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RewardsForWalletQuery, RewardsForWalletQueryVariables>(RewardsForWalletDocument, options);
        }
export type RewardsForWalletQueryHookResult = ReturnType<typeof useRewardsForWalletQuery>;
export type RewardsForWalletLazyQueryHookResult = ReturnType<typeof useRewardsForWalletLazyQuery>;
export type RewardsForWalletQueryResult = Apollo.QueryResult<RewardsForWalletQuery, RewardsForWalletQueryVariables>;
export const ExternalPositionsStakeWisePortfolioDocument = gql`
    query ExternalPositionsStakeWisePortfolio($network: Deployment!, $vaultId: Address!, $currency: CurrencyOrAddress!) {
  externalPositionsStakeWisePortfolio(
    network: $network
    vaultId: $vaultId
    currency: $currency
  ) {
    ...ExternalPositionStakeWisePortfolioData
  }
}
    ${ExternalPositionStakeWisePortfolioDataFragmentDoc}`;

/**
 * __useExternalPositionsStakeWisePortfolioQuery__
 *
 * To run a query within a React component, call `useExternalPositionsStakeWisePortfolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalPositionsStakeWisePortfolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalPositionsStakeWisePortfolioQuery({
 *   variables: {
 *      network: // value for 'network'
 *      vaultId: // value for 'vaultId'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useExternalPositionsStakeWisePortfolioQuery(baseOptions: Apollo.QueryHookOptions<ExternalPositionsStakeWisePortfolioQuery, ExternalPositionsStakeWisePortfolioQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExternalPositionsStakeWisePortfolioQuery, ExternalPositionsStakeWisePortfolioQueryVariables>(ExternalPositionsStakeWisePortfolioDocument, options);
      }
export function useExternalPositionsStakeWisePortfolioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExternalPositionsStakeWisePortfolioQuery, ExternalPositionsStakeWisePortfolioQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExternalPositionsStakeWisePortfolioQuery, ExternalPositionsStakeWisePortfolioQueryVariables>(ExternalPositionsStakeWisePortfolioDocument, options);
        }
export type ExternalPositionsStakeWisePortfolioQueryHookResult = ReturnType<typeof useExternalPositionsStakeWisePortfolioQuery>;
export type ExternalPositionsStakeWisePortfolioLazyQueryHookResult = ReturnType<typeof useExternalPositionsStakeWisePortfolioLazyQuery>;
export type ExternalPositionsStakeWisePortfolioQueryResult = Apollo.QueryResult<ExternalPositionsStakeWisePortfolioQuery, ExternalPositionsStakeWisePortfolioQueryVariables>;
export const ExternalPositionsStakeWisePositionsDocument = gql`
    query ExternalPositionsStakeWisePositions($vaultId: Address!, $network: Deployment!, $currency: Currency!) {
  externalPositionsStakeWisePositions(
    currency: $currency
    network: $network
    vaultId: $vaultId
  ) {
    ...StakeWiseVaultData
  }
}
    ${StakeWiseVaultDataFragmentDoc}
${StakeWiseVaultHoldingDataFragmentDoc}`;

/**
 * __useExternalPositionsStakeWisePositionsQuery__
 *
 * To run a query within a React component, call `useExternalPositionsStakeWisePositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalPositionsStakeWisePositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalPositionsStakeWisePositionsQuery({
 *   variables: {
 *      vaultId: // value for 'vaultId'
 *      network: // value for 'network'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useExternalPositionsStakeWisePositionsQuery(baseOptions: Apollo.QueryHookOptions<ExternalPositionsStakeWisePositionsQuery, ExternalPositionsStakeWisePositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExternalPositionsStakeWisePositionsQuery, ExternalPositionsStakeWisePositionsQueryVariables>(ExternalPositionsStakeWisePositionsDocument, options);
      }
export function useExternalPositionsStakeWisePositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExternalPositionsStakeWisePositionsQuery, ExternalPositionsStakeWisePositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExternalPositionsStakeWisePositionsQuery, ExternalPositionsStakeWisePositionsQueryVariables>(ExternalPositionsStakeWisePositionsDocument, options);
        }
export type ExternalPositionsStakeWisePositionsQueryHookResult = ReturnType<typeof useExternalPositionsStakeWisePositionsQuery>;
export type ExternalPositionsStakeWisePositionsLazyQueryHookResult = ReturnType<typeof useExternalPositionsStakeWisePositionsLazyQuery>;
export type ExternalPositionsStakeWisePositionsQueryResult = Apollo.QueryResult<ExternalPositionsStakeWisePositionsQuery, ExternalPositionsStakeWisePositionsQueryVariables>;
export const StakingDataForAllStakeWiseAssetsDocument = gql`
    query StakingDataForAllStakeWiseAssets($currency: Currency!, $network: Deployment!, $vaultId: Address!) {
  stakingDataForAllStakeWiseAssets(
    currency: $currency
    network: $network
    vaultId: $vaultId
  ) {
    ...StakeWiseVaultData
  }
}
    ${StakeWiseVaultDataFragmentDoc}
${StakeWiseVaultHoldingDataFragmentDoc}`;

/**
 * __useStakingDataForAllStakeWiseAssetsQuery__
 *
 * To run a query within a React component, call `useStakingDataForAllStakeWiseAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStakingDataForAllStakeWiseAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStakingDataForAllStakeWiseAssetsQuery({
 *   variables: {
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *      vaultId: // value for 'vaultId'
 *   },
 * });
 */
export function useStakingDataForAllStakeWiseAssetsQuery(baseOptions: Apollo.QueryHookOptions<StakingDataForAllStakeWiseAssetsQuery, StakingDataForAllStakeWiseAssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StakingDataForAllStakeWiseAssetsQuery, StakingDataForAllStakeWiseAssetsQueryVariables>(StakingDataForAllStakeWiseAssetsDocument, options);
      }
export function useStakingDataForAllStakeWiseAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StakingDataForAllStakeWiseAssetsQuery, StakingDataForAllStakeWiseAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StakingDataForAllStakeWiseAssetsQuery, StakingDataForAllStakeWiseAssetsQueryVariables>(StakingDataForAllStakeWiseAssetsDocument, options);
        }
export type StakingDataForAllStakeWiseAssetsQueryHookResult = ReturnType<typeof useStakingDataForAllStakeWiseAssetsQuery>;
export type StakingDataForAllStakeWiseAssetsLazyQueryHookResult = ReturnType<typeof useStakingDataForAllStakeWiseAssetsLazyQuery>;
export type StakingDataForAllStakeWiseAssetsQueryResult = Apollo.QueryResult<StakingDataForAllStakeWiseAssetsQuery, StakingDataForAllStakeWiseAssetsQueryVariables>;
export const SwellStakingTransactionDocument = gql`
    query SwellStakingTransaction($comptrollerProxy: Address!, $expectedIncomingAmount: BigInt!, $maxSlippage: Float!, $network: Deployment!, $originAddress: Address, $quantity: BigInt!, $userAddress: Address!, $vaultProxy: Address!) {
  swellStakingTransaction(
    vaultProxy: $vaultProxy
    comptrollerProxy: $comptrollerProxy
    expectedIncomingAmount: $expectedIncomingAmount
    maxSlippage: $maxSlippage
    network: $network
    originAddress: $originAddress
    quantity: $quantity
    userAddress: $userAddress
  ) {
    reason
    status
    transaction {
      data
      to
      value
    }
  }
}
    `;

/**
 * __useSwellStakingTransactionQuery__
 *
 * To run a query within a React component, call `useSwellStakingTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSwellStakingTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSwellStakingTransactionQuery({
 *   variables: {
 *      comptrollerProxy: // value for 'comptrollerProxy'
 *      expectedIncomingAmount: // value for 'expectedIncomingAmount'
 *      maxSlippage: // value for 'maxSlippage'
 *      network: // value for 'network'
 *      originAddress: // value for 'originAddress'
 *      quantity: // value for 'quantity'
 *      userAddress: // value for 'userAddress'
 *      vaultProxy: // value for 'vaultProxy'
 *   },
 * });
 */
export function useSwellStakingTransactionQuery(baseOptions: Apollo.QueryHookOptions<SwellStakingTransactionQuery, SwellStakingTransactionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SwellStakingTransactionQuery, SwellStakingTransactionQueryVariables>(SwellStakingTransactionDocument, options);
      }
export function useSwellStakingTransactionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SwellStakingTransactionQuery, SwellStakingTransactionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SwellStakingTransactionQuery, SwellStakingTransactionQueryVariables>(SwellStakingTransactionDocument, options);
        }
export type SwellStakingTransactionQueryHookResult = ReturnType<typeof useSwellStakingTransactionQuery>;
export type SwellStakingTransactionLazyQueryHookResult = ReturnType<typeof useSwellStakingTransactionLazyQuery>;
export type SwellStakingTransactionQueryResult = Apollo.QueryResult<SwellStakingTransactionQuery, SwellStakingTransactionQueryVariables>;
export const ExternalPositionsTheGraphDelegationPositionsDocument = gql`
    query ExternalPositionsTheGraphDelegationPositions($address: Address!, $network: Deployment!) {
  externalPositionsTheGraphDelegationPositions(
    address: $address
    network: $network
  ) {
    externalPosition
    indexer
    delegatedAmount
    unbondingAmount
    unbondingEndEstimatedTime
    unlockedAmount
    shares
    active
    externalPositionIndex
    defaultDisplayName
  }
}
    `;

/**
 * __useExternalPositionsTheGraphDelegationPositionsQuery__
 *
 * To run a query within a React component, call `useExternalPositionsTheGraphDelegationPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalPositionsTheGraphDelegationPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalPositionsTheGraphDelegationPositionsQuery({
 *   variables: {
 *      address: // value for 'address'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useExternalPositionsTheGraphDelegationPositionsQuery(baseOptions: Apollo.QueryHookOptions<ExternalPositionsTheGraphDelegationPositionsQuery, ExternalPositionsTheGraphDelegationPositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExternalPositionsTheGraphDelegationPositionsQuery, ExternalPositionsTheGraphDelegationPositionsQueryVariables>(ExternalPositionsTheGraphDelegationPositionsDocument, options);
      }
export function useExternalPositionsTheGraphDelegationPositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExternalPositionsTheGraphDelegationPositionsQuery, ExternalPositionsTheGraphDelegationPositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExternalPositionsTheGraphDelegationPositionsQuery, ExternalPositionsTheGraphDelegationPositionsQueryVariables>(ExternalPositionsTheGraphDelegationPositionsDocument, options);
        }
export type ExternalPositionsTheGraphDelegationPositionsQueryHookResult = ReturnType<typeof useExternalPositionsTheGraphDelegationPositionsQuery>;
export type ExternalPositionsTheGraphDelegationPositionsLazyQueryHookResult = ReturnType<typeof useExternalPositionsTheGraphDelegationPositionsLazyQuery>;
export type ExternalPositionsTheGraphDelegationPositionsQueryResult = Apollo.QueryResult<ExternalPositionsTheGraphDelegationPositionsQuery, ExternalPositionsTheGraphDelegationPositionsQueryVariables>;
export const ExternalPositionsTheGraphDelegationPortfolioDocument = gql`
    query ExternalPositionsTheGraphDelegationPortfolio($address: Address!, $currency: CurrencyOrAddress!, $network: Deployment!) {
  externalPositionsTheGraphDelegationPortfolio(
    address: $address
    currency: $currency
    network: $network
  ) {
    indexer
    defaultDisplayName
    asset {
      address
      amount
      currencyValue
    }
  }
}
    `;

/**
 * __useExternalPositionsTheGraphDelegationPortfolioQuery__
 *
 * To run a query within a React component, call `useExternalPositionsTheGraphDelegationPortfolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalPositionsTheGraphDelegationPortfolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalPositionsTheGraphDelegationPortfolioQuery({
 *   variables: {
 *      address: // value for 'address'
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useExternalPositionsTheGraphDelegationPortfolioQuery(baseOptions: Apollo.QueryHookOptions<ExternalPositionsTheGraphDelegationPortfolioQuery, ExternalPositionsTheGraphDelegationPortfolioQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExternalPositionsTheGraphDelegationPortfolioQuery, ExternalPositionsTheGraphDelegationPortfolioQueryVariables>(ExternalPositionsTheGraphDelegationPortfolioDocument, options);
      }
export function useExternalPositionsTheGraphDelegationPortfolioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExternalPositionsTheGraphDelegationPortfolioQuery, ExternalPositionsTheGraphDelegationPortfolioQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExternalPositionsTheGraphDelegationPortfolioQuery, ExternalPositionsTheGraphDelegationPortfolioQueryVariables>(ExternalPositionsTheGraphDelegationPortfolioDocument, options);
        }
export type ExternalPositionsTheGraphDelegationPortfolioQueryHookResult = ReturnType<typeof useExternalPositionsTheGraphDelegationPortfolioQuery>;
export type ExternalPositionsTheGraphDelegationPortfolioLazyQueryHookResult = ReturnType<typeof useExternalPositionsTheGraphDelegationPortfolioLazyQuery>;
export type ExternalPositionsTheGraphDelegationPortfolioQueryResult = Apollo.QueryResult<ExternalPositionsTheGraphDelegationPortfolioQuery, ExternalPositionsTheGraphDelegationPortfolioQueryVariables>;
export const TheGraphDelegationInfoDocument = gql`
    query TheGraphDelegationInfo($network: Deployment!) {
  theGraphDelegationInfo(network: $network) {
    delegationTaxPercentage
  }
}
    `;

/**
 * __useTheGraphDelegationInfoQuery__
 *
 * To run a query within a React component, call `useTheGraphDelegationInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useTheGraphDelegationInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTheGraphDelegationInfoQuery({
 *   variables: {
 *      network: // value for 'network'
 *   },
 * });
 */
export function useTheGraphDelegationInfoQuery(baseOptions: Apollo.QueryHookOptions<TheGraphDelegationInfoQuery, TheGraphDelegationInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TheGraphDelegationInfoQuery, TheGraphDelegationInfoQueryVariables>(TheGraphDelegationInfoDocument, options);
      }
export function useTheGraphDelegationInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TheGraphDelegationInfoQuery, TheGraphDelegationInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TheGraphDelegationInfoQuery, TheGraphDelegationInfoQueryVariables>(TheGraphDelegationInfoDocument, options);
        }
export type TheGraphDelegationInfoQueryHookResult = ReturnType<typeof useTheGraphDelegationInfoQuery>;
export type TheGraphDelegationInfoLazyQueryHookResult = ReturnType<typeof useTheGraphDelegationInfoLazyQuery>;
export type TheGraphDelegationInfoQueryResult = Apollo.QueryResult<TheGraphDelegationInfoQuery, TheGraphDelegationInfoQueryVariables>;
export const TheGraphDelegationIndexerInfoDocument = gql`
    query TheGraphDelegationIndexerInfo($network: Deployment!, $indexer: Address!) {
  theGraphDelegationIndexerInfo(network: $network, indexer: $indexer) {
    id
    defaultDisplayName
    availableCapacity
  }
}
    `;

/**
 * __useTheGraphDelegationIndexerInfoQuery__
 *
 * To run a query within a React component, call `useTheGraphDelegationIndexerInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useTheGraphDelegationIndexerInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTheGraphDelegationIndexerInfoQuery({
 *   variables: {
 *      network: // value for 'network'
 *      indexer: // value for 'indexer'
 *   },
 * });
 */
export function useTheGraphDelegationIndexerInfoQuery(baseOptions: Apollo.QueryHookOptions<TheGraphDelegationIndexerInfoQuery, TheGraphDelegationIndexerInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TheGraphDelegationIndexerInfoQuery, TheGraphDelegationIndexerInfoQueryVariables>(TheGraphDelegationIndexerInfoDocument, options);
      }
export function useTheGraphDelegationIndexerInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TheGraphDelegationIndexerInfoQuery, TheGraphDelegationIndexerInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TheGraphDelegationIndexerInfoQuery, TheGraphDelegationIndexerInfoQueryVariables>(TheGraphDelegationIndexerInfoDocument, options);
        }
export type TheGraphDelegationIndexerInfoQueryHookResult = ReturnType<typeof useTheGraphDelegationIndexerInfoQuery>;
export type TheGraphDelegationIndexerInfoLazyQueryHookResult = ReturnType<typeof useTheGraphDelegationIndexerInfoLazyQuery>;
export type TheGraphDelegationIndexerInfoQueryResult = Apollo.QueryResult<TheGraphDelegationIndexerInfoQuery, TheGraphDelegationIndexerInfoQueryVariables>;
export const TheGraphDelegationSharesToTokensDocument = gql`
    query TheGraphDelegationSharesToTokens($network: Deployment!, $indexer: Address!, $shares: BigInt!) {
  theGraphDelegationSharesToTokens(
    network: $network
    indexer: $indexer
    shares: $shares
  ) {
    tokens
  }
}
    `;

/**
 * __useTheGraphDelegationSharesToTokensQuery__
 *
 * To run a query within a React component, call `useTheGraphDelegationSharesToTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useTheGraphDelegationSharesToTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTheGraphDelegationSharesToTokensQuery({
 *   variables: {
 *      network: // value for 'network'
 *      indexer: // value for 'indexer'
 *      shares: // value for 'shares'
 *   },
 * });
 */
export function useTheGraphDelegationSharesToTokensQuery(baseOptions: Apollo.QueryHookOptions<TheGraphDelegationSharesToTokensQuery, TheGraphDelegationSharesToTokensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TheGraphDelegationSharesToTokensQuery, TheGraphDelegationSharesToTokensQueryVariables>(TheGraphDelegationSharesToTokensDocument, options);
      }
export function useTheGraphDelegationSharesToTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TheGraphDelegationSharesToTokensQuery, TheGraphDelegationSharesToTokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TheGraphDelegationSharesToTokensQuery, TheGraphDelegationSharesToTokensQueryVariables>(TheGraphDelegationSharesToTokensDocument, options);
        }
export type TheGraphDelegationSharesToTokensQueryHookResult = ReturnType<typeof useTheGraphDelegationSharesToTokensQuery>;
export type TheGraphDelegationSharesToTokensLazyQueryHookResult = ReturnType<typeof useTheGraphDelegationSharesToTokensLazyQuery>;
export type TheGraphDelegationSharesToTokensQueryResult = Apollo.QueryResult<TheGraphDelegationSharesToTokensQuery, TheGraphDelegationSharesToTokensQueryVariables>;
export const TheGraphDelegationTokensToSharesDocument = gql`
    query TheGraphDelegationTokensToShares($network: Deployment!, $indexer: Address!, $tokens: BigInt!) {
  theGraphDelegationTokensToShares(
    network: $network
    indexer: $indexer
    tokens: $tokens
  ) {
    shares
  }
}
    `;

/**
 * __useTheGraphDelegationTokensToSharesQuery__
 *
 * To run a query within a React component, call `useTheGraphDelegationTokensToSharesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTheGraphDelegationTokensToSharesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTheGraphDelegationTokensToSharesQuery({
 *   variables: {
 *      network: // value for 'network'
 *      indexer: // value for 'indexer'
 *      tokens: // value for 'tokens'
 *   },
 * });
 */
export function useTheGraphDelegationTokensToSharesQuery(baseOptions: Apollo.QueryHookOptions<TheGraphDelegationTokensToSharesQuery, TheGraphDelegationTokensToSharesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TheGraphDelegationTokensToSharesQuery, TheGraphDelegationTokensToSharesQueryVariables>(TheGraphDelegationTokensToSharesDocument, options);
      }
export function useTheGraphDelegationTokensToSharesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TheGraphDelegationTokensToSharesQuery, TheGraphDelegationTokensToSharesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TheGraphDelegationTokensToSharesQuery, TheGraphDelegationTokensToSharesQueryVariables>(TheGraphDelegationTokensToSharesDocument, options);
        }
export type TheGraphDelegationTokensToSharesQueryHookResult = ReturnType<typeof useTheGraphDelegationTokensToSharesQuery>;
export type TheGraphDelegationTokensToSharesLazyQueryHookResult = ReturnType<typeof useTheGraphDelegationTokensToSharesLazyQuery>;
export type TheGraphDelegationTokensToSharesQueryResult = Apollo.QueryResult<TheGraphDelegationTokensToSharesQuery, TheGraphDelegationTokensToSharesQueryVariables>;
export const ThreeOneThirdAssetsToTradeDocument = gql`
    query ThreeOneThirdAssetsToTrade($network: Deployment!, $vaultId: Address!, $currency: Currency!) {
  threeOneThirdAssetsToTrade(
    network: $network
    currency: $currency
    vaultId: $vaultId
  ) {
    isTracked
    allocation
    price
    assetBalance {
      ...AssetBalanceObligatoryCurrency
    }
  }
}
    ${AssetBalanceObligatoryCurrencyFragmentDoc}`;

/**
 * __useThreeOneThirdAssetsToTradeQuery__
 *
 * To run a query within a React component, call `useThreeOneThirdAssetsToTradeQuery` and pass it any options that fit your needs.
 * When your component renders, `useThreeOneThirdAssetsToTradeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreeOneThirdAssetsToTradeQuery({
 *   variables: {
 *      network: // value for 'network'
 *      vaultId: // value for 'vaultId'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useThreeOneThirdAssetsToTradeQuery(baseOptions: Apollo.QueryHookOptions<ThreeOneThirdAssetsToTradeQuery, ThreeOneThirdAssetsToTradeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ThreeOneThirdAssetsToTradeQuery, ThreeOneThirdAssetsToTradeQueryVariables>(ThreeOneThirdAssetsToTradeDocument, options);
      }
export function useThreeOneThirdAssetsToTradeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThreeOneThirdAssetsToTradeQuery, ThreeOneThirdAssetsToTradeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ThreeOneThirdAssetsToTradeQuery, ThreeOneThirdAssetsToTradeQueryVariables>(ThreeOneThirdAssetsToTradeDocument, options);
        }
export type ThreeOneThirdAssetsToTradeQueryHookResult = ReturnType<typeof useThreeOneThirdAssetsToTradeQuery>;
export type ThreeOneThirdAssetsToTradeLazyQueryHookResult = ReturnType<typeof useThreeOneThirdAssetsToTradeLazyQuery>;
export type ThreeOneThirdAssetsToTradeQueryResult = Apollo.QueryResult<ThreeOneThirdAssetsToTradeQuery, ThreeOneThirdAssetsToTradeQueryVariables>;
export const ThreeOneThirdRebalanceDocument = gql`
    query ThreeOneThirdRebalance($network: Deployment!, $vaultId: Address!, $currency: Currency!, $signer: Address!, $maxSlippage: Float!, $maxPriceImpact: Float!, $tokensIn: [Address!]!, $tokensInAmounts: [BigInt!]!, $tokensOut: [Address!]!, $tokensOutAllocations: [Float!]!) {
  threeOneThirdRebalance(
    network: $network
    currency: $currency
    vaultId: $vaultId
    signer: $signer
    maxSlippage: $maxSlippage
    maxPriceImpact: $maxPriceImpact
    tokensIn: $tokensIn
    tokensInAmounts: $tokensInAmounts
    tokensOut: $tokensOut
    tokensOutAllocations: $tokensOutAllocations
  ) {
    ...ThreeOneThirdRebalanceData
    ...ErrorResponse
  }
}
    ${ThreeOneThirdRebalanceDataFragmentDoc}
${AssetBalanceObligatoryCurrencyFragmentDoc}
${ErrorResponseFragmentDoc}`;

/**
 * __useThreeOneThirdRebalanceQuery__
 *
 * To run a query within a React component, call `useThreeOneThirdRebalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useThreeOneThirdRebalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreeOneThirdRebalanceQuery({
 *   variables: {
 *      network: // value for 'network'
 *      vaultId: // value for 'vaultId'
 *      currency: // value for 'currency'
 *      signer: // value for 'signer'
 *      maxSlippage: // value for 'maxSlippage'
 *      maxPriceImpact: // value for 'maxPriceImpact'
 *      tokensIn: // value for 'tokensIn'
 *      tokensInAmounts: // value for 'tokensInAmounts'
 *      tokensOut: // value for 'tokensOut'
 *      tokensOutAllocations: // value for 'tokensOutAllocations'
 *   },
 * });
 */
export function useThreeOneThirdRebalanceQuery(baseOptions: Apollo.QueryHookOptions<ThreeOneThirdRebalanceQuery, ThreeOneThirdRebalanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ThreeOneThirdRebalanceQuery, ThreeOneThirdRebalanceQueryVariables>(ThreeOneThirdRebalanceDocument, options);
      }
export function useThreeOneThirdRebalanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThreeOneThirdRebalanceQuery, ThreeOneThirdRebalanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ThreeOneThirdRebalanceQuery, ThreeOneThirdRebalanceQueryVariables>(ThreeOneThirdRebalanceDocument, options);
        }
export type ThreeOneThirdRebalanceQueryHookResult = ReturnType<typeof useThreeOneThirdRebalanceQuery>;
export type ThreeOneThirdRebalanceLazyQueryHookResult = ReturnType<typeof useThreeOneThirdRebalanceLazyQuery>;
export type ThreeOneThirdRebalanceQueryResult = Apollo.QueryResult<ThreeOneThirdRebalanceQuery, ThreeOneThirdRebalanceQueryVariables>;
export const TokenSwapPricesDocument = gql`
    query TokenSwapPrices($incoming: Address!, $network: Deployment!, $outgoing: Address!, $quantity: BigInt!) {
  tokenSwapPrices(
    incoming: $incoming
    network: $network
    outgoing: $outgoing
    quantity: $quantity
  ) {
    exchange
    status
    amount
    price
    reason
    bestRoute
    fastTransactionRequired
    fromUsd
    toUsd
  }
}
    `;

/**
 * __useTokenSwapPricesQuery__
 *
 * To run a query within a React component, call `useTokenSwapPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenSwapPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenSwapPricesQuery({
 *   variables: {
 *      incoming: // value for 'incoming'
 *      network: // value for 'network'
 *      outgoing: // value for 'outgoing'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useTokenSwapPricesQuery(baseOptions: Apollo.QueryHookOptions<TokenSwapPricesQuery, TokenSwapPricesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TokenSwapPricesQuery, TokenSwapPricesQueryVariables>(TokenSwapPricesDocument, options);
      }
export function useTokenSwapPricesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TokenSwapPricesQuery, TokenSwapPricesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TokenSwapPricesQuery, TokenSwapPricesQueryVariables>(TokenSwapPricesDocument, options);
        }
export type TokenSwapPricesQueryHookResult = ReturnType<typeof useTokenSwapPricesQuery>;
export type TokenSwapPricesLazyQueryHookResult = ReturnType<typeof useTokenSwapPricesLazyQuery>;
export type TokenSwapPricesQueryResult = Apollo.QueryResult<TokenSwapPricesQuery, TokenSwapPricesQueryVariables>;
export const TokenPriceInNativeCurrencyDocument = gql`
    query TokenPriceInNativeCurrency($token: Address!, $network: Deployment!, $quantity: BigInt!) {
  tokenPriceInNativeCurrency(
    token: $token
    network: $network
    quantity: $quantity
  ) {
    exchange
    status
    amount
    price
    reason
    bestRoute
    fastTransactionRequired
    fromUsd
    toUsd
  }
}
    `;

/**
 * __useTokenPriceInNativeCurrencyQuery__
 *
 * To run a query within a React component, call `useTokenPriceInNativeCurrencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenPriceInNativeCurrencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenPriceInNativeCurrencyQuery({
 *   variables: {
 *      token: // value for 'token'
 *      network: // value for 'network'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useTokenPriceInNativeCurrencyQuery(baseOptions: Apollo.QueryHookOptions<TokenPriceInNativeCurrencyQuery, TokenPriceInNativeCurrencyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TokenPriceInNativeCurrencyQuery, TokenPriceInNativeCurrencyQueryVariables>(TokenPriceInNativeCurrencyDocument, options);
      }
export function useTokenPriceInNativeCurrencyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TokenPriceInNativeCurrencyQuery, TokenPriceInNativeCurrencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TokenPriceInNativeCurrencyQuery, TokenPriceInNativeCurrencyQueryVariables>(TokenPriceInNativeCurrencyDocument, options);
        }
export type TokenPriceInNativeCurrencyQueryHookResult = ReturnType<typeof useTokenPriceInNativeCurrencyQuery>;
export type TokenPriceInNativeCurrencyLazyQueryHookResult = ReturnType<typeof useTokenPriceInNativeCurrencyLazyQuery>;
export type TokenPriceInNativeCurrencyQueryResult = Apollo.QueryResult<TokenPriceInNativeCurrencyQuery, TokenPriceInNativeCurrencyQueryVariables>;
export const TokenSwapPriceDocument = gql`
    query TokenSwapPrice($exchange: Exchange!, $incoming: Address!, $network: Deployment!, $outgoing: Address!, $quantity: BigInt!) {
  tokenSwapPrice(
    exchange: $exchange
    incoming: $incoming
    network: $network
    outgoing: $outgoing
    quantity: $quantity
  ) {
    exchange
    status
    amount
    price
    reason
    bestRoute
    fastTransactionRequired
    fromUsd
    toUsd
  }
}
    `;

/**
 * __useTokenSwapPriceQuery__
 *
 * To run a query within a React component, call `useTokenSwapPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenSwapPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenSwapPriceQuery({
 *   variables: {
 *      exchange: // value for 'exchange'
 *      incoming: // value for 'incoming'
 *      network: // value for 'network'
 *      outgoing: // value for 'outgoing'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useTokenSwapPriceQuery(baseOptions: Apollo.QueryHookOptions<TokenSwapPriceQuery, TokenSwapPriceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TokenSwapPriceQuery, TokenSwapPriceQueryVariables>(TokenSwapPriceDocument, options);
      }
export function useTokenSwapPriceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TokenSwapPriceQuery, TokenSwapPriceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TokenSwapPriceQuery, TokenSwapPriceQueryVariables>(TokenSwapPriceDocument, options);
        }
export type TokenSwapPriceQueryHookResult = ReturnType<typeof useTokenSwapPriceQuery>;
export type TokenSwapPriceLazyQueryHookResult = ReturnType<typeof useTokenSwapPriceLazyQuery>;
export type TokenSwapPriceQueryResult = Apollo.QueryResult<TokenSwapPriceQuery, TokenSwapPriceQueryVariables>;
export const ReferencePriceDocument = gql`
    query ReferencePrice($incoming: Address!, $network: Deployment!, $outgoing: Address!) {
  referencePrice(incoming: $incoming, network: $network, outgoing: $outgoing) {
    status
    price
    reason
  }
}
    `;

/**
 * __useReferencePriceQuery__
 *
 * To run a query within a React component, call `useReferencePriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferencePriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferencePriceQuery({
 *   variables: {
 *      incoming: // value for 'incoming'
 *      network: // value for 'network'
 *      outgoing: // value for 'outgoing'
 *   },
 * });
 */
export function useReferencePriceQuery(baseOptions: Apollo.QueryHookOptions<ReferencePriceQuery, ReferencePriceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReferencePriceQuery, ReferencePriceQueryVariables>(ReferencePriceDocument, options);
      }
export function useReferencePriceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReferencePriceQuery, ReferencePriceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReferencePriceQuery, ReferencePriceQueryVariables>(ReferencePriceDocument, options);
        }
export type ReferencePriceQueryHookResult = ReturnType<typeof useReferencePriceQuery>;
export type ReferencePriceLazyQueryHookResult = ReturnType<typeof useReferencePriceLazyQuery>;
export type ReferencePriceQueryResult = Apollo.QueryResult<ReferencePriceQuery, ReferencePriceQueryVariables>;
export const TokenSwapTransactionDocument = gql`
    query TokenSwapTransaction($vaultProxy: Address!, $comptrollerProxy: Address!, $exchange: Exchange!, $expectedIncomingAmount: BigInt!, $incoming: Address!, $maxSlippage: Float!, $network: Deployment!, $originAddress: Address, $outgoing: Address!, $quantity: BigInt!, $userAddress: Address!) {
  tokenSwapTransaction(
    vaultProxy: $vaultProxy
    comptrollerProxy: $comptrollerProxy
    exchange: $exchange
    expectedIncomingAmount: $expectedIncomingAmount
    incoming: $incoming
    maxSlippage: $maxSlippage
    network: $network
    originAddress: $originAddress
    outgoing: $outgoing
    quantity: $quantity
    userAddress: $userAddress
  ) {
    reason
    status
    transaction {
      data
      to
      value
    }
  }
}
    `;

/**
 * __useTokenSwapTransactionQuery__
 *
 * To run a query within a React component, call `useTokenSwapTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenSwapTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenSwapTransactionQuery({
 *   variables: {
 *      vaultProxy: // value for 'vaultProxy'
 *      comptrollerProxy: // value for 'comptrollerProxy'
 *      exchange: // value for 'exchange'
 *      expectedIncomingAmount: // value for 'expectedIncomingAmount'
 *      incoming: // value for 'incoming'
 *      maxSlippage: // value for 'maxSlippage'
 *      network: // value for 'network'
 *      originAddress: // value for 'originAddress'
 *      outgoing: // value for 'outgoing'
 *      quantity: // value for 'quantity'
 *      userAddress: // value for 'userAddress'
 *   },
 * });
 */
export function useTokenSwapTransactionQuery(baseOptions: Apollo.QueryHookOptions<TokenSwapTransactionQuery, TokenSwapTransactionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TokenSwapTransactionQuery, TokenSwapTransactionQueryVariables>(TokenSwapTransactionDocument, options);
      }
export function useTokenSwapTransactionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TokenSwapTransactionQuery, TokenSwapTransactionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TokenSwapTransactionQuery, TokenSwapTransactionQueryVariables>(TokenSwapTransactionDocument, options);
        }
export type TokenSwapTransactionQueryHookResult = ReturnType<typeof useTokenSwapTransactionQuery>;
export type TokenSwapTransactionLazyQueryHookResult = ReturnType<typeof useTokenSwapTransactionLazyQuery>;
export type TokenSwapTransactionQueryResult = Apollo.QueryResult<TokenSwapTransactionQuery, TokenSwapTransactionQueryVariables>;
export const NativeTokenDepositTransactionDocument = gql`
    query NativeTokenDepositTransaction($comptrollerProxy: Address!, $expectedDenominationAmount: BigInt!, $incoming: Address!, $maxSlippage: Float!, $network: Deployment!, $originAddress: Address, $quantity: BigInt!, $userAddress: Address!) {
  nativeTokenDepositTransaction(
    comptrollerProxy: $comptrollerProxy
    expectedDenominationAmount: $expectedDenominationAmount
    incoming: $incoming
    maxSlippage: $maxSlippage
    network: $network
    originAddress: $originAddress
    quantity: $quantity
    userAddress: $userAddress
  ) {
    reason
    status
    transaction {
      data
      to
      value
    }
  }
}
    `;

/**
 * __useNativeTokenDepositTransactionQuery__
 *
 * To run a query within a React component, call `useNativeTokenDepositTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useNativeTokenDepositTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNativeTokenDepositTransactionQuery({
 *   variables: {
 *      comptrollerProxy: // value for 'comptrollerProxy'
 *      expectedDenominationAmount: // value for 'expectedDenominationAmount'
 *      incoming: // value for 'incoming'
 *      maxSlippage: // value for 'maxSlippage'
 *      network: // value for 'network'
 *      originAddress: // value for 'originAddress'
 *      quantity: // value for 'quantity'
 *      userAddress: // value for 'userAddress'
 *   },
 * });
 */
export function useNativeTokenDepositTransactionQuery(baseOptions: Apollo.QueryHookOptions<NativeTokenDepositTransactionQuery, NativeTokenDepositTransactionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NativeTokenDepositTransactionQuery, NativeTokenDepositTransactionQueryVariables>(NativeTokenDepositTransactionDocument, options);
      }
export function useNativeTokenDepositTransactionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NativeTokenDepositTransactionQuery, NativeTokenDepositTransactionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NativeTokenDepositTransactionQuery, NativeTokenDepositTransactionQueryVariables>(NativeTokenDepositTransactionDocument, options);
        }
export type NativeTokenDepositTransactionQueryHookResult = ReturnType<typeof useNativeTokenDepositTransactionQuery>;
export type NativeTokenDepositTransactionLazyQueryHookResult = ReturnType<typeof useNativeTokenDepositTransactionLazyQuery>;
export type NativeTokenDepositTransactionQueryResult = Apollo.QueryResult<NativeTokenDepositTransactionQuery, NativeTokenDepositTransactionQueryVariables>;
export const Erc20TokenDepositTransactionDocument = gql`
    query ERC20TokenDepositTransaction($comptrollerProxy: Address!, $expectedDenominationAmount: BigInt!, $incoming: Address!, $outgoing: Address!, $maxSlippage: Float!, $network: Deployment!, $originAddress: Address, $quantity: BigInt!, $userAddress: Address!) {
  erc20TokenDepositTransaction(
    comptrollerProxy: $comptrollerProxy
    expectedDenominationAmount: $expectedDenominationAmount
    incoming: $incoming
    outgoing: $outgoing
    maxSlippage: $maxSlippage
    network: $network
    originAddress: $originAddress
    quantity: $quantity
    userAddress: $userAddress
  ) {
    reason
    status
    transaction {
      data
      to
      value
    }
  }
}
    `;

/**
 * __useErc20TokenDepositTransactionQuery__
 *
 * To run a query within a React component, call `useErc20TokenDepositTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useErc20TokenDepositTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useErc20TokenDepositTransactionQuery({
 *   variables: {
 *      comptrollerProxy: // value for 'comptrollerProxy'
 *      expectedDenominationAmount: // value for 'expectedDenominationAmount'
 *      incoming: // value for 'incoming'
 *      outgoing: // value for 'outgoing'
 *      maxSlippage: // value for 'maxSlippage'
 *      network: // value for 'network'
 *      originAddress: // value for 'originAddress'
 *      quantity: // value for 'quantity'
 *      userAddress: // value for 'userAddress'
 *   },
 * });
 */
export function useErc20TokenDepositTransactionQuery(baseOptions: Apollo.QueryHookOptions<Erc20TokenDepositTransactionQuery, Erc20TokenDepositTransactionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Erc20TokenDepositTransactionQuery, Erc20TokenDepositTransactionQueryVariables>(Erc20TokenDepositTransactionDocument, options);
      }
export function useErc20TokenDepositTransactionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Erc20TokenDepositTransactionQuery, Erc20TokenDepositTransactionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Erc20TokenDepositTransactionQuery, Erc20TokenDepositTransactionQueryVariables>(Erc20TokenDepositTransactionDocument, options);
        }
export type Erc20TokenDepositTransactionQueryHookResult = ReturnType<typeof useErc20TokenDepositTransactionQuery>;
export type Erc20TokenDepositTransactionLazyQueryHookResult = ReturnType<typeof useErc20TokenDepositTransactionLazyQuery>;
export type Erc20TokenDepositTransactionQueryResult = Apollo.QueryResult<Erc20TokenDepositTransactionQuery, Erc20TokenDepositTransactionQueryVariables>;
export const TokenSwapAndReferencePricesDocument = gql`
    query TokenSwapAndReferencePrices($comptroller: Address!, $currency: CurrencyOrAddress!, $incoming: Address!, $network: Deployment!, $outgoing: Address!, $quantity: BigInt!) {
  tokenSwapAndReferencePrices(
    comptroller: $comptroller
    currency: $currency
    incoming: $incoming
    network: $network
    outgoing: $outgoing
    quantity: $quantity
  ) {
    currentCumulativeSlippagePolicy {
      currentCumulativeSlippage
      enabled
      tolerance
    }
    outgoingValueInCurrency
    referencePrice {
      status
      price
      reason
    }
    tokenSwapPrices {
      exchange
      status
      amount
      price
      reason
      bestRoute
      fastTransactionRequired
      fromUsd
      toUsd
      incomingValueInCurrency
      isBadTrade
      slippage
    }
  }
}
    `;

/**
 * __useTokenSwapAndReferencePricesQuery__
 *
 * To run a query within a React component, call `useTokenSwapAndReferencePricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenSwapAndReferencePricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenSwapAndReferencePricesQuery({
 *   variables: {
 *      comptroller: // value for 'comptroller'
 *      currency: // value for 'currency'
 *      incoming: // value for 'incoming'
 *      network: // value for 'network'
 *      outgoing: // value for 'outgoing'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useTokenSwapAndReferencePricesQuery(baseOptions: Apollo.QueryHookOptions<TokenSwapAndReferencePricesQuery, TokenSwapAndReferencePricesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TokenSwapAndReferencePricesQuery, TokenSwapAndReferencePricesQueryVariables>(TokenSwapAndReferencePricesDocument, options);
      }
export function useTokenSwapAndReferencePricesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TokenSwapAndReferencePricesQuery, TokenSwapAndReferencePricesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TokenSwapAndReferencePricesQuery, TokenSwapAndReferencePricesQueryVariables>(TokenSwapAndReferencePricesDocument, options);
        }
export type TokenSwapAndReferencePricesQueryHookResult = ReturnType<typeof useTokenSwapAndReferencePricesQuery>;
export type TokenSwapAndReferencePricesLazyQueryHookResult = ReturnType<typeof useTokenSwapAndReferencePricesLazyQuery>;
export type TokenSwapAndReferencePricesQueryResult = Apollo.QueryResult<TokenSwapAndReferencePricesQuery, TokenSwapAndReferencePricesQueryVariables>;
export const UniswapV2PoolsDocument = gql`
    query UniswapV2Pools($currency: Currency!, $network: Deployment!) {
  uniswapV2Pools(currency: $currency, network: $network) {
    address
    poolSize
    baseApy
    totalApy
    rewardApys {
      ...RewardApy
    }
  }
}
    ${RewardApyFragmentDoc}`;

/**
 * __useUniswapV2PoolsQuery__
 *
 * To run a query within a React component, call `useUniswapV2PoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUniswapV2PoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUniswapV2PoolsQuery({
 *   variables: {
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useUniswapV2PoolsQuery(baseOptions: Apollo.QueryHookOptions<UniswapV2PoolsQuery, UniswapV2PoolsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UniswapV2PoolsQuery, UniswapV2PoolsQueryVariables>(UniswapV2PoolsDocument, options);
      }
export function useUniswapV2PoolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UniswapV2PoolsQuery, UniswapV2PoolsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UniswapV2PoolsQuery, UniswapV2PoolsQueryVariables>(UniswapV2PoolsDocument, options);
        }
export type UniswapV2PoolsQueryHookResult = ReturnType<typeof useUniswapV2PoolsQuery>;
export type UniswapV2PoolsLazyQueryHookResult = ReturnType<typeof useUniswapV2PoolsLazyQuery>;
export type UniswapV2PoolsQueryResult = Apollo.QueryResult<UniswapV2PoolsQuery, UniswapV2PoolsQueryVariables>;
export const UniswapV3VaultLiquidityPositionsDocument = gql`
    query UniswapV3VaultLiquidityPositions($deployment: Deployment!, $vaultId: Address!) {
  uniswapV3VaultLiquidityPositions(deployment: $deployment, vaultId: $vaultId) {
    nfts {
      externalPositionId
      feeTier
      id
      liquidity
      nftTokenUri
      poolAddress
      poolCurrentTick
      tickLower
      tickUpper
      amount0
      amount1
      token0
      token1
    }
  }
}
    `;

/**
 * __useUniswapV3VaultLiquidityPositionsQuery__
 *
 * To run a query within a React component, call `useUniswapV3VaultLiquidityPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUniswapV3VaultLiquidityPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUniswapV3VaultLiquidityPositionsQuery({
 *   variables: {
 *      deployment: // value for 'deployment'
 *      vaultId: // value for 'vaultId'
 *   },
 * });
 */
export function useUniswapV3VaultLiquidityPositionsQuery(baseOptions: Apollo.QueryHookOptions<UniswapV3VaultLiquidityPositionsQuery, UniswapV3VaultLiquidityPositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UniswapV3VaultLiquidityPositionsQuery, UniswapV3VaultLiquidityPositionsQueryVariables>(UniswapV3VaultLiquidityPositionsDocument, options);
      }
export function useUniswapV3VaultLiquidityPositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UniswapV3VaultLiquidityPositionsQuery, UniswapV3VaultLiquidityPositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UniswapV3VaultLiquidityPositionsQuery, UniswapV3VaultLiquidityPositionsQueryVariables>(UniswapV3VaultLiquidityPositionsDocument, options);
        }
export type UniswapV3VaultLiquidityPositionsQueryHookResult = ReturnType<typeof useUniswapV3VaultLiquidityPositionsQuery>;
export type UniswapV3VaultLiquidityPositionsLazyQueryHookResult = ReturnType<typeof useUniswapV3VaultLiquidityPositionsLazyQuery>;
export type UniswapV3VaultLiquidityPositionsQueryResult = Apollo.QueryResult<UniswapV3VaultLiquidityPositionsQuery, UniswapV3VaultLiquidityPositionsQueryVariables>;
export const UniswapV3ExternalPositionsAddressesDocument = gql`
    query UniswapV3ExternalPositionsAddresses($deployment: Deployment!, $vaultId: Address!) {
  uniswapV3VaultLiquidityPositions(deployment: $deployment, vaultId: $vaultId) {
    externalPositionId
  }
}
    `;

/**
 * __useUniswapV3ExternalPositionsAddressesQuery__
 *
 * To run a query within a React component, call `useUniswapV3ExternalPositionsAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUniswapV3ExternalPositionsAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUniswapV3ExternalPositionsAddressesQuery({
 *   variables: {
 *      deployment: // value for 'deployment'
 *      vaultId: // value for 'vaultId'
 *   },
 * });
 */
export function useUniswapV3ExternalPositionsAddressesQuery(baseOptions: Apollo.QueryHookOptions<UniswapV3ExternalPositionsAddressesQuery, UniswapV3ExternalPositionsAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UniswapV3ExternalPositionsAddressesQuery, UniswapV3ExternalPositionsAddressesQueryVariables>(UniswapV3ExternalPositionsAddressesDocument, options);
      }
export function useUniswapV3ExternalPositionsAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UniswapV3ExternalPositionsAddressesQuery, UniswapV3ExternalPositionsAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UniswapV3ExternalPositionsAddressesQuery, UniswapV3ExternalPositionsAddressesQueryVariables>(UniswapV3ExternalPositionsAddressesDocument, options);
        }
export type UniswapV3ExternalPositionsAddressesQueryHookResult = ReturnType<typeof useUniswapV3ExternalPositionsAddressesQuery>;
export type UniswapV3ExternalPositionsAddressesLazyQueryHookResult = ReturnType<typeof useUniswapV3ExternalPositionsAddressesLazyQuery>;
export type UniswapV3ExternalPositionsAddressesQueryResult = Apollo.QueryResult<UniswapV3ExternalPositionsAddressesQuery, UniswapV3ExternalPositionsAddressesQueryVariables>;
export const UniswapV3FeeTierDistributionDocument = gql`
    query UniswapV3FeeTierDistribution($deployment: Deployment!, $token0: Address!, $token1: Address!) {
  uniswapV3FeeTierDistribution(
    deployment: $deployment
    token0: $token0
    token1: $token1
  ) {
    feeTier
    id
    percentage
  }
}
    `;

/**
 * __useUniswapV3FeeTierDistributionQuery__
 *
 * To run a query within a React component, call `useUniswapV3FeeTierDistributionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUniswapV3FeeTierDistributionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUniswapV3FeeTierDistributionQuery({
 *   variables: {
 *      deployment: // value for 'deployment'
 *      token0: // value for 'token0'
 *      token1: // value for 'token1'
 *   },
 * });
 */
export function useUniswapV3FeeTierDistributionQuery(baseOptions: Apollo.QueryHookOptions<UniswapV3FeeTierDistributionQuery, UniswapV3FeeTierDistributionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UniswapV3FeeTierDistributionQuery, UniswapV3FeeTierDistributionQueryVariables>(UniswapV3FeeTierDistributionDocument, options);
      }
export function useUniswapV3FeeTierDistributionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UniswapV3FeeTierDistributionQuery, UniswapV3FeeTierDistributionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UniswapV3FeeTierDistributionQuery, UniswapV3FeeTierDistributionQueryVariables>(UniswapV3FeeTierDistributionDocument, options);
        }
export type UniswapV3FeeTierDistributionQueryHookResult = ReturnType<typeof useUniswapV3FeeTierDistributionQuery>;
export type UniswapV3FeeTierDistributionLazyQueryHookResult = ReturnType<typeof useUniswapV3FeeTierDistributionLazyQuery>;
export type UniswapV3FeeTierDistributionQueryResult = Apollo.QueryResult<UniswapV3FeeTierDistributionQuery, UniswapV3FeeTierDistributionQueryVariables>;
export const UniswapV3PoolDetailsDocument = gql`
    query UniswapV3PoolDetails($deployment: Deployment!, $poolAddress: Address!, $fetchCurrentTickOnChain: Boolean!) {
  uniswapV3PoolDetails(
    deployment: $deployment
    poolAddress: $poolAddress
    fetchCurrentTickOnChain: $fetchCurrentTickOnChain
  ) {
    currentTick
    ticks {
      tickIdx
      liquidityNet
    }
  }
}
    `;

/**
 * __useUniswapV3PoolDetailsQuery__
 *
 * To run a query within a React component, call `useUniswapV3PoolDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUniswapV3PoolDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUniswapV3PoolDetailsQuery({
 *   variables: {
 *      deployment: // value for 'deployment'
 *      poolAddress: // value for 'poolAddress'
 *      fetchCurrentTickOnChain: // value for 'fetchCurrentTickOnChain'
 *   },
 * });
 */
export function useUniswapV3PoolDetailsQuery(baseOptions: Apollo.QueryHookOptions<UniswapV3PoolDetailsQuery, UniswapV3PoolDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UniswapV3PoolDetailsQuery, UniswapV3PoolDetailsQueryVariables>(UniswapV3PoolDetailsDocument, options);
      }
export function useUniswapV3PoolDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UniswapV3PoolDetailsQuery, UniswapV3PoolDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UniswapV3PoolDetailsQuery, UniswapV3PoolDetailsQueryVariables>(UniswapV3PoolDetailsDocument, options);
        }
export type UniswapV3PoolDetailsQueryHookResult = ReturnType<typeof useUniswapV3PoolDetailsQuery>;
export type UniswapV3PoolDetailsLazyQueryHookResult = ReturnType<typeof useUniswapV3PoolDetailsLazyQuery>;
export type UniswapV3PoolDetailsQueryResult = Apollo.QueryResult<UniswapV3PoolDetailsQuery, UniswapV3PoolDetailsQueryVariables>;
export const UniswapV3NftDocument = gql`
    query UniswapV3Nft($deployment: Deployment!, $nftId: String!) {
  uniswapV3NftDetails(deployment: $deployment, nftId: $nftId) {
    externalPositionId
    feeTier
    id
    liquidity
    nftTokenUri
    pendingFees0
    pendingFees1
    poolAddress
    poolCurrentTick
    poolLiquidity
    tickLower
    tickUpper
    amount0
    amount1
    token0
    token1
  }
}
    `;

/**
 * __useUniswapV3NftQuery__
 *
 * To run a query within a React component, call `useUniswapV3NftQuery` and pass it any options that fit your needs.
 * When your component renders, `useUniswapV3NftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUniswapV3NftQuery({
 *   variables: {
 *      deployment: // value for 'deployment'
 *      nftId: // value for 'nftId'
 *   },
 * });
 */
export function useUniswapV3NftQuery(baseOptions: Apollo.QueryHookOptions<UniswapV3NftQuery, UniswapV3NftQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UniswapV3NftQuery, UniswapV3NftQueryVariables>(UniswapV3NftDocument, options);
      }
export function useUniswapV3NftLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UniswapV3NftQuery, UniswapV3NftQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UniswapV3NftQuery, UniswapV3NftQueryVariables>(UniswapV3NftDocument, options);
        }
export type UniswapV3NftQueryHookResult = ReturnType<typeof useUniswapV3NftQuery>;
export type UniswapV3NftLazyQueryHookResult = ReturnType<typeof useUniswapV3NftLazyQuery>;
export type UniswapV3NftQueryResult = Apollo.QueryResult<UniswapV3NftQuery, UniswapV3NftQueryVariables>;
export const UniswapV3UtilsTokenAmountsDocument = gql`
    query UniswapV3UtilsTokenAmounts($deployment: Deployment!, $feeTier: Int!, $positionLiquidity: BigInt!, $tickLower: Int!, $tickUpper: Int!, $token0: Address!, $token1: Address!, $pool: Address!) {
  uniswapV3UtilsTokenAmounts(
    deployment: $deployment
    feeTier: $feeTier
    positionLiquidity: $positionLiquidity
    tickLower: $tickLower
    tickUpper: $tickUpper
    token0: $token0
    token1: $token1
    pool: $pool
  ) {
    amount0
    amount1
  }
}
    `;

/**
 * __useUniswapV3UtilsTokenAmountsQuery__
 *
 * To run a query within a React component, call `useUniswapV3UtilsTokenAmountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUniswapV3UtilsTokenAmountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUniswapV3UtilsTokenAmountsQuery({
 *   variables: {
 *      deployment: // value for 'deployment'
 *      feeTier: // value for 'feeTier'
 *      positionLiquidity: // value for 'positionLiquidity'
 *      tickLower: // value for 'tickLower'
 *      tickUpper: // value for 'tickUpper'
 *      token0: // value for 'token0'
 *      token1: // value for 'token1'
 *      pool: // value for 'pool'
 *   },
 * });
 */
export function useUniswapV3UtilsTokenAmountsQuery(baseOptions: Apollo.QueryHookOptions<UniswapV3UtilsTokenAmountsQuery, UniswapV3UtilsTokenAmountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UniswapV3UtilsTokenAmountsQuery, UniswapV3UtilsTokenAmountsQueryVariables>(UniswapV3UtilsTokenAmountsDocument, options);
      }
export function useUniswapV3UtilsTokenAmountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UniswapV3UtilsTokenAmountsQuery, UniswapV3UtilsTokenAmountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UniswapV3UtilsTokenAmountsQuery, UniswapV3UtilsTokenAmountsQueryVariables>(UniswapV3UtilsTokenAmountsDocument, options);
        }
export type UniswapV3UtilsTokenAmountsQueryHookResult = ReturnType<typeof useUniswapV3UtilsTokenAmountsQuery>;
export type UniswapV3UtilsTokenAmountsLazyQueryHookResult = ReturnType<typeof useUniswapV3UtilsTokenAmountsLazyQuery>;
export type UniswapV3UtilsTokenAmountsQueryResult = Apollo.QueryResult<UniswapV3UtilsTokenAmountsQuery, UniswapV3UtilsTokenAmountsQueryVariables>;
export const UniswapV3UtilsTokenAmountsFromAmount0Document = gql`
    query UniswapV3UtilsTokenAmountsFromAmount0($deployment: Deployment!, $feeTier: Int!, $amount0: BigInt!, $tickLower: Int!, $tickUpper: Int!, $asset0: Address!, $asset1: Address!, $pool: Address!) {
  uniswapV3UtilsTokenAmountsFromAmount0(
    deployment: $deployment
    feeTier: $feeTier
    amount0: $amount0
    tickLower: $tickLower
    tickUpper: $tickUpper
    asset0: $asset0
    asset1: $asset1
    pool: $pool
  ) {
    amount0
    amount1
  }
}
    `;

/**
 * __useUniswapV3UtilsTokenAmountsFromAmount0Query__
 *
 * To run a query within a React component, call `useUniswapV3UtilsTokenAmountsFromAmount0Query` and pass it any options that fit your needs.
 * When your component renders, `useUniswapV3UtilsTokenAmountsFromAmount0Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUniswapV3UtilsTokenAmountsFromAmount0Query({
 *   variables: {
 *      deployment: // value for 'deployment'
 *      feeTier: // value for 'feeTier'
 *      amount0: // value for 'amount0'
 *      tickLower: // value for 'tickLower'
 *      tickUpper: // value for 'tickUpper'
 *      asset0: // value for 'asset0'
 *      asset1: // value for 'asset1'
 *      pool: // value for 'pool'
 *   },
 * });
 */
export function useUniswapV3UtilsTokenAmountsFromAmount0Query(baseOptions: Apollo.QueryHookOptions<UniswapV3UtilsTokenAmountsFromAmount0Query, UniswapV3UtilsTokenAmountsFromAmount0QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UniswapV3UtilsTokenAmountsFromAmount0Query, UniswapV3UtilsTokenAmountsFromAmount0QueryVariables>(UniswapV3UtilsTokenAmountsFromAmount0Document, options);
      }
export function useUniswapV3UtilsTokenAmountsFromAmount0LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UniswapV3UtilsTokenAmountsFromAmount0Query, UniswapV3UtilsTokenAmountsFromAmount0QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UniswapV3UtilsTokenAmountsFromAmount0Query, UniswapV3UtilsTokenAmountsFromAmount0QueryVariables>(UniswapV3UtilsTokenAmountsFromAmount0Document, options);
        }
export type UniswapV3UtilsTokenAmountsFromAmount0QueryHookResult = ReturnType<typeof useUniswapV3UtilsTokenAmountsFromAmount0Query>;
export type UniswapV3UtilsTokenAmountsFromAmount0LazyQueryHookResult = ReturnType<typeof useUniswapV3UtilsTokenAmountsFromAmount0LazyQuery>;
export type UniswapV3UtilsTokenAmountsFromAmount0QueryResult = Apollo.QueryResult<UniswapV3UtilsTokenAmountsFromAmount0Query, UniswapV3UtilsTokenAmountsFromAmount0QueryVariables>;
export const UniswapV3UtilsTokenAmountsFromAmount1Document = gql`
    query UniswapV3UtilsTokenAmountsFromAmount1($deployment: Deployment!, $feeTier: Int!, $amount1: BigInt!, $tickLower: Int!, $tickUpper: Int!, $asset0: Address!, $asset1: Address!, $pool: Address!) {
  uniswapV3UtilsTokenAmountsFromAmount1(
    deployment: $deployment
    feeTier: $feeTier
    amount1: $amount1
    tickLower: $tickLower
    tickUpper: $tickUpper
    asset0: $asset0
    asset1: $asset1
    pool: $pool
  ) {
    amount0
    amount1
  }
}
    `;

/**
 * __useUniswapV3UtilsTokenAmountsFromAmount1Query__
 *
 * To run a query within a React component, call `useUniswapV3UtilsTokenAmountsFromAmount1Query` and pass it any options that fit your needs.
 * When your component renders, `useUniswapV3UtilsTokenAmountsFromAmount1Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUniswapV3UtilsTokenAmountsFromAmount1Query({
 *   variables: {
 *      deployment: // value for 'deployment'
 *      feeTier: // value for 'feeTier'
 *      amount1: // value for 'amount1'
 *      tickLower: // value for 'tickLower'
 *      tickUpper: // value for 'tickUpper'
 *      asset0: // value for 'asset0'
 *      asset1: // value for 'asset1'
 *      pool: // value for 'pool'
 *   },
 * });
 */
export function useUniswapV3UtilsTokenAmountsFromAmount1Query(baseOptions: Apollo.QueryHookOptions<UniswapV3UtilsTokenAmountsFromAmount1Query, UniswapV3UtilsTokenAmountsFromAmount1QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UniswapV3UtilsTokenAmountsFromAmount1Query, UniswapV3UtilsTokenAmountsFromAmount1QueryVariables>(UniswapV3UtilsTokenAmountsFromAmount1Document, options);
      }
export function useUniswapV3UtilsTokenAmountsFromAmount1LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UniswapV3UtilsTokenAmountsFromAmount1Query, UniswapV3UtilsTokenAmountsFromAmount1QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UniswapV3UtilsTokenAmountsFromAmount1Query, UniswapV3UtilsTokenAmountsFromAmount1QueryVariables>(UniswapV3UtilsTokenAmountsFromAmount1Document, options);
        }
export type UniswapV3UtilsTokenAmountsFromAmount1QueryHookResult = ReturnType<typeof useUniswapV3UtilsTokenAmountsFromAmount1Query>;
export type UniswapV3UtilsTokenAmountsFromAmount1LazyQueryHookResult = ReturnType<typeof useUniswapV3UtilsTokenAmountsFromAmount1LazyQuery>;
export type UniswapV3UtilsTokenAmountsFromAmount1QueryResult = Apollo.QueryResult<UniswapV3UtilsTokenAmountsFromAmount1Query, UniswapV3UtilsTokenAmountsFromAmount1QueryVariables>;
export const UserRepresentativeElectionsDocument = gql`
    query UserRepresentativeElections {
  userRepresentativeElections {
    ...UserRepresentativeElectionInfo
  }
}
    ${UserRepresentativeElectionInfoFragmentDoc}
${UserRepresentativeApplicationInfoFragmentDoc}
${UserRepresentativeVoteInfoFragmentDoc}`;

/**
 * __useUserRepresentativeElectionsQuery__
 *
 * To run a query within a React component, call `useUserRepresentativeElectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRepresentativeElectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRepresentativeElectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserRepresentativeElectionsQuery(baseOptions?: Apollo.QueryHookOptions<UserRepresentativeElectionsQuery, UserRepresentativeElectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserRepresentativeElectionsQuery, UserRepresentativeElectionsQueryVariables>(UserRepresentativeElectionsDocument, options);
      }
export function useUserRepresentativeElectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserRepresentativeElectionsQuery, UserRepresentativeElectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserRepresentativeElectionsQuery, UserRepresentativeElectionsQueryVariables>(UserRepresentativeElectionsDocument, options);
        }
export type UserRepresentativeElectionsQueryHookResult = ReturnType<typeof useUserRepresentativeElectionsQuery>;
export type UserRepresentativeElectionsLazyQueryHookResult = ReturnType<typeof useUserRepresentativeElectionsLazyQuery>;
export type UserRepresentativeElectionsQueryResult = Apollo.QueryResult<UserRepresentativeElectionsQuery, UserRepresentativeElectionsQueryVariables>;
export const UserRepresentativeElectionDocument = gql`
    query UserRepresentativeElection($id: ID!) {
  userRepresentativeElection(id: $id) {
    ...UserRepresentativeElectionInfo
  }
}
    ${UserRepresentativeElectionInfoFragmentDoc}
${UserRepresentativeApplicationInfoFragmentDoc}
${UserRepresentativeVoteInfoFragmentDoc}`;

/**
 * __useUserRepresentativeElectionQuery__
 *
 * To run a query within a React component, call `useUserRepresentativeElectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRepresentativeElectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRepresentativeElectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserRepresentativeElectionQuery(baseOptions: Apollo.QueryHookOptions<UserRepresentativeElectionQuery, UserRepresentativeElectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserRepresentativeElectionQuery, UserRepresentativeElectionQueryVariables>(UserRepresentativeElectionDocument, options);
      }
export function useUserRepresentativeElectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserRepresentativeElectionQuery, UserRepresentativeElectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserRepresentativeElectionQuery, UserRepresentativeElectionQueryVariables>(UserRepresentativeElectionDocument, options);
        }
export type UserRepresentativeElectionQueryHookResult = ReturnType<typeof useUserRepresentativeElectionQuery>;
export type UserRepresentativeElectionLazyQueryHookResult = ReturnType<typeof useUserRepresentativeElectionLazyQuery>;
export type UserRepresentativeElectionQueryResult = Apollo.QueryResult<UserRepresentativeElectionQuery, UserRepresentativeElectionQueryVariables>;
export const CreateUserRepresentativeElectionDocument = gql`
    mutation CreateUserRepresentativeElection($input: CreateUserRepresentativeElectionInput!) {
  createUserRepresentativeElection(input: $input) {
    userRepresentativeElection {
      ...UserRepresentativeElectionInfo
    }
    errors {
      ...UserErrorFragment
    }
  }
}
    ${UserRepresentativeElectionInfoFragmentDoc}
${UserRepresentativeApplicationInfoFragmentDoc}
${UserRepresentativeVoteInfoFragmentDoc}
${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type CreateUserRepresentativeElectionMutationFn = Apollo.MutationFunction<CreateUserRepresentativeElectionMutation, CreateUserRepresentativeElectionMutationVariables>;

/**
 * __useCreateUserRepresentativeElectionMutation__
 *
 * To run a mutation, you first call `useCreateUserRepresentativeElectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserRepresentativeElectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserRepresentativeElectionMutation, { data, loading, error }] = useCreateUserRepresentativeElectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserRepresentativeElectionMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserRepresentativeElectionMutation, CreateUserRepresentativeElectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserRepresentativeElectionMutation, CreateUserRepresentativeElectionMutationVariables>(CreateUserRepresentativeElectionDocument, options);
      }
export type CreateUserRepresentativeElectionMutationHookResult = ReturnType<typeof useCreateUserRepresentativeElectionMutation>;
export type CreateUserRepresentativeElectionMutationResult = Apollo.MutationResult<CreateUserRepresentativeElectionMutation>;
export type CreateUserRepresentativeElectionMutationOptions = Apollo.BaseMutationOptions<CreateUserRepresentativeElectionMutation, CreateUserRepresentativeElectionMutationVariables>;
export const DeleteUserRepresentativeElectionDocument = gql`
    mutation DeleteUserRepresentativeElection($input: DeleteUserRepresentativeElectionInput!) {
  deleteUserRepresentativeElection(input: $input) {
    errors {
      ...UserErrorFragment
    }
  }
}
    ${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type DeleteUserRepresentativeElectionMutationFn = Apollo.MutationFunction<DeleteUserRepresentativeElectionMutation, DeleteUserRepresentativeElectionMutationVariables>;

/**
 * __useDeleteUserRepresentativeElectionMutation__
 *
 * To run a mutation, you first call `useDeleteUserRepresentativeElectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserRepresentativeElectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserRepresentativeElectionMutation, { data, loading, error }] = useDeleteUserRepresentativeElectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserRepresentativeElectionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserRepresentativeElectionMutation, DeleteUserRepresentativeElectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserRepresentativeElectionMutation, DeleteUserRepresentativeElectionMutationVariables>(DeleteUserRepresentativeElectionDocument, options);
      }
export type DeleteUserRepresentativeElectionMutationHookResult = ReturnType<typeof useDeleteUserRepresentativeElectionMutation>;
export type DeleteUserRepresentativeElectionMutationResult = Apollo.MutationResult<DeleteUserRepresentativeElectionMutation>;
export type DeleteUserRepresentativeElectionMutationOptions = Apollo.BaseMutationOptions<DeleteUserRepresentativeElectionMutation, DeleteUserRepresentativeElectionMutationVariables>;
export const UpdateUserRepresentativeElectionDocument = gql`
    mutation UpdateUserRepresentativeElection($input: UpdateUserRepresentativeElectionInput!) {
  updateUserRepresentativeElection(input: $input) {
    userRepresentativeElection {
      ...UserRepresentativeElectionInfo
    }
    errors {
      ...UserErrorFragment
    }
  }
}
    ${UserRepresentativeElectionInfoFragmentDoc}
${UserRepresentativeApplicationInfoFragmentDoc}
${UserRepresentativeVoteInfoFragmentDoc}
${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type UpdateUserRepresentativeElectionMutationFn = Apollo.MutationFunction<UpdateUserRepresentativeElectionMutation, UpdateUserRepresentativeElectionMutationVariables>;

/**
 * __useUpdateUserRepresentativeElectionMutation__
 *
 * To run a mutation, you first call `useUpdateUserRepresentativeElectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRepresentativeElectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserRepresentativeElectionMutation, { data, loading, error }] = useUpdateUserRepresentativeElectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserRepresentativeElectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserRepresentativeElectionMutation, UpdateUserRepresentativeElectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserRepresentativeElectionMutation, UpdateUserRepresentativeElectionMutationVariables>(UpdateUserRepresentativeElectionDocument, options);
      }
export type UpdateUserRepresentativeElectionMutationHookResult = ReturnType<typeof useUpdateUserRepresentativeElectionMutation>;
export type UpdateUserRepresentativeElectionMutationResult = Apollo.MutationResult<UpdateUserRepresentativeElectionMutation>;
export type UpdateUserRepresentativeElectionMutationOptions = Apollo.BaseMutationOptions<UpdateUserRepresentativeElectionMutation, UpdateUserRepresentativeElectionMutationVariables>;
export const CreateUserRepresentativeApplicationDocument = gql`
    mutation CreateUserRepresentativeApplication($input: CreateUserRepresentativeApplicationInput!) {
  createUserRepresentativeApplication(input: $input) {
    userRepresentativeApplication {
      ...UserRepresentativeApplicationInfo
    }
    errors {
      ...UserErrorFragment
    }
  }
}
    ${UserRepresentativeApplicationInfoFragmentDoc}
${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type CreateUserRepresentativeApplicationMutationFn = Apollo.MutationFunction<CreateUserRepresentativeApplicationMutation, CreateUserRepresentativeApplicationMutationVariables>;

/**
 * __useCreateUserRepresentativeApplicationMutation__
 *
 * To run a mutation, you first call `useCreateUserRepresentativeApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserRepresentativeApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserRepresentativeApplicationMutation, { data, loading, error }] = useCreateUserRepresentativeApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserRepresentativeApplicationMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserRepresentativeApplicationMutation, CreateUserRepresentativeApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserRepresentativeApplicationMutation, CreateUserRepresentativeApplicationMutationVariables>(CreateUserRepresentativeApplicationDocument, options);
      }
export type CreateUserRepresentativeApplicationMutationHookResult = ReturnType<typeof useCreateUserRepresentativeApplicationMutation>;
export type CreateUserRepresentativeApplicationMutationResult = Apollo.MutationResult<CreateUserRepresentativeApplicationMutation>;
export type CreateUserRepresentativeApplicationMutationOptions = Apollo.BaseMutationOptions<CreateUserRepresentativeApplicationMutation, CreateUserRepresentativeApplicationMutationVariables>;
export const DeleteUserRepresentativeApplicationDocument = gql`
    mutation DeleteUserRepresentativeApplication($input: DeleteUserRepresentativeApplicationInput!) {
  deleteUserRepresentativeApplication(input: $input) {
    errors {
      ...UserErrorFragment
    }
  }
}
    ${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type DeleteUserRepresentativeApplicationMutationFn = Apollo.MutationFunction<DeleteUserRepresentativeApplicationMutation, DeleteUserRepresentativeApplicationMutationVariables>;

/**
 * __useDeleteUserRepresentativeApplicationMutation__
 *
 * To run a mutation, you first call `useDeleteUserRepresentativeApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserRepresentativeApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserRepresentativeApplicationMutation, { data, loading, error }] = useDeleteUserRepresentativeApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserRepresentativeApplicationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserRepresentativeApplicationMutation, DeleteUserRepresentativeApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserRepresentativeApplicationMutation, DeleteUserRepresentativeApplicationMutationVariables>(DeleteUserRepresentativeApplicationDocument, options);
      }
export type DeleteUserRepresentativeApplicationMutationHookResult = ReturnType<typeof useDeleteUserRepresentativeApplicationMutation>;
export type DeleteUserRepresentativeApplicationMutationResult = Apollo.MutationResult<DeleteUserRepresentativeApplicationMutation>;
export type DeleteUserRepresentativeApplicationMutationOptions = Apollo.BaseMutationOptions<DeleteUserRepresentativeApplicationMutation, DeleteUserRepresentativeApplicationMutationVariables>;
export const UpdateUserRepresentativeApplicationDocument = gql`
    mutation UpdateUserRepresentativeApplication($input: UpdateUserRepresentativeApplicationInput!) {
  updateUserRepresentativeApplication(input: $input) {
    userRepresentativeApplication {
      ...UserRepresentativeApplicationInfo
    }
    errors {
      ...UserErrorFragment
    }
  }
}
    ${UserRepresentativeApplicationInfoFragmentDoc}
${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type UpdateUserRepresentativeApplicationMutationFn = Apollo.MutationFunction<UpdateUserRepresentativeApplicationMutation, UpdateUserRepresentativeApplicationMutationVariables>;

/**
 * __useUpdateUserRepresentativeApplicationMutation__
 *
 * To run a mutation, you first call `useUpdateUserRepresentativeApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRepresentativeApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserRepresentativeApplicationMutation, { data, loading, error }] = useUpdateUserRepresentativeApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserRepresentativeApplicationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserRepresentativeApplicationMutation, UpdateUserRepresentativeApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserRepresentativeApplicationMutation, UpdateUserRepresentativeApplicationMutationVariables>(UpdateUserRepresentativeApplicationDocument, options);
      }
export type UpdateUserRepresentativeApplicationMutationHookResult = ReturnType<typeof useUpdateUserRepresentativeApplicationMutation>;
export type UpdateUserRepresentativeApplicationMutationResult = Apollo.MutationResult<UpdateUserRepresentativeApplicationMutation>;
export type UpdateUserRepresentativeApplicationMutationOptions = Apollo.BaseMutationOptions<UpdateUserRepresentativeApplicationMutation, UpdateUserRepresentativeApplicationMutationVariables>;
export const UpdateUserRepresentativeApplicationStatusDocument = gql`
    mutation UpdateUserRepresentativeApplicationStatus($input: UpdateUserRepresentativeApplicationStatusInput!) {
  updateUserRepresentativeApplicationStatus(input: $input) {
    userRepresentativeApplication {
      ...UserRepresentativeApplicationInfo
    }
    errors {
      ...UserErrorFragment
    }
  }
}
    ${UserRepresentativeApplicationInfoFragmentDoc}
${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type UpdateUserRepresentativeApplicationStatusMutationFn = Apollo.MutationFunction<UpdateUserRepresentativeApplicationStatusMutation, UpdateUserRepresentativeApplicationStatusMutationVariables>;

/**
 * __useUpdateUserRepresentativeApplicationStatusMutation__
 *
 * To run a mutation, you first call `useUpdateUserRepresentativeApplicationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRepresentativeApplicationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserRepresentativeApplicationStatusMutation, { data, loading, error }] = useUpdateUserRepresentativeApplicationStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserRepresentativeApplicationStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserRepresentativeApplicationStatusMutation, UpdateUserRepresentativeApplicationStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserRepresentativeApplicationStatusMutation, UpdateUserRepresentativeApplicationStatusMutationVariables>(UpdateUserRepresentativeApplicationStatusDocument, options);
      }
export type UpdateUserRepresentativeApplicationStatusMutationHookResult = ReturnType<typeof useUpdateUserRepresentativeApplicationStatusMutation>;
export type UpdateUserRepresentativeApplicationStatusMutationResult = Apollo.MutationResult<UpdateUserRepresentativeApplicationStatusMutation>;
export type UpdateUserRepresentativeApplicationStatusMutationOptions = Apollo.BaseMutationOptions<UpdateUserRepresentativeApplicationStatusMutation, UpdateUserRepresentativeApplicationStatusMutationVariables>;
export const VoteUserRepresentativeDocument = gql`
    mutation VoteUserRepresentative($input: VoteUserRepresentativeInput!) {
  voteUserRepresentative(input: $input) {
    userRepresentativeVote {
      ...UserRepresentativeVoteInfo
    }
    errors {
      ...UserErrorFragment
    }
  }
}
    ${UserRepresentativeVoteInfoFragmentDoc}
${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type VoteUserRepresentativeMutationFn = Apollo.MutationFunction<VoteUserRepresentativeMutation, VoteUserRepresentativeMutationVariables>;

/**
 * __useVoteUserRepresentativeMutation__
 *
 * To run a mutation, you first call `useVoteUserRepresentativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoteUserRepresentativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voteUserRepresentativeMutation, { data, loading, error }] = useVoteUserRepresentativeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVoteUserRepresentativeMutation(baseOptions?: Apollo.MutationHookOptions<VoteUserRepresentativeMutation, VoteUserRepresentativeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VoteUserRepresentativeMutation, VoteUserRepresentativeMutationVariables>(VoteUserRepresentativeDocument, options);
      }
export type VoteUserRepresentativeMutationHookResult = ReturnType<typeof useVoteUserRepresentativeMutation>;
export type VoteUserRepresentativeMutationResult = Apollo.MutationResult<VoteUserRepresentativeMutation>;
export type VoteUserRepresentativeMutationOptions = Apollo.BaseMutationOptions<VoteUserRepresentativeMutation, VoteUserRepresentativeMutationVariables>;
export const VaultMonthlyReturnsDocument = gql`
    query VaultMonthlyReturns($currency: CurrencyOrAddress!, $network: Deployment!, $vault: Address!) {
  vaultMonthlyReturns(currency: $currency, network: $network, vault: $vault) {
    yearMonth
    year
    month
    monthlyReturn
    valid
  }
}
    `;

/**
 * __useVaultMonthlyReturnsQuery__
 *
 * To run a query within a React component, call `useVaultMonthlyReturnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVaultMonthlyReturnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVaultMonthlyReturnsQuery({
 *   variables: {
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *      vault: // value for 'vault'
 *   },
 * });
 */
export function useVaultMonthlyReturnsQuery(baseOptions: Apollo.QueryHookOptions<VaultMonthlyReturnsQuery, VaultMonthlyReturnsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VaultMonthlyReturnsQuery, VaultMonthlyReturnsQueryVariables>(VaultMonthlyReturnsDocument, options);
      }
export function useVaultMonthlyReturnsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VaultMonthlyReturnsQuery, VaultMonthlyReturnsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VaultMonthlyReturnsQuery, VaultMonthlyReturnsQueryVariables>(VaultMonthlyReturnsDocument, options);
        }
export type VaultMonthlyReturnsQueryHookResult = ReturnType<typeof useVaultMonthlyReturnsQuery>;
export type VaultMonthlyReturnsLazyQueryHookResult = ReturnType<typeof useVaultMonthlyReturnsLazyQuery>;
export type VaultMonthlyReturnsQueryResult = Apollo.QueryResult<VaultMonthlyReturnsQuery, VaultMonthlyReturnsQueryVariables>;
export const VaultBalancesDocument = gql`
    query VaultBalances($currency: CurrencyOrAddress!, $network: Deployment!, $vault: Address!) {
  vaultBalances(currency: $currency, network: $network, vault: $vault) {
    asset
    balance
    value
    valid
    price
  }
}
    `;

/**
 * __useVaultBalancesQuery__
 *
 * To run a query within a React component, call `useVaultBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVaultBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVaultBalancesQuery({
 *   variables: {
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *      vault: // value for 'vault'
 *   },
 * });
 */
export function useVaultBalancesQuery(baseOptions: Apollo.QueryHookOptions<VaultBalancesQuery, VaultBalancesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VaultBalancesQuery, VaultBalancesQueryVariables>(VaultBalancesDocument, options);
      }
export function useVaultBalancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VaultBalancesQuery, VaultBalancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VaultBalancesQuery, VaultBalancesQueryVariables>(VaultBalancesDocument, options);
        }
export type VaultBalancesQueryHookResult = ReturnType<typeof useVaultBalancesQuery>;
export type VaultBalancesLazyQueryHookResult = ReturnType<typeof useVaultBalancesLazyQuery>;
export type VaultBalancesQueryResult = Apollo.QueryResult<VaultBalancesQuery, VaultBalancesQueryVariables>;
export const VaultUntrackedBalancesDocument = gql`
    query VaultUntrackedBalances($currency: CurrencyOrAddress!, $network: Deployment!, $vault: Address!) {
  vaultUntrackedBalances(currency: $currency, network: $network, vault: $vault) {
    asset
    balance
    value
    valid
    price
  }
}
    `;

/**
 * __useVaultUntrackedBalancesQuery__
 *
 * To run a query within a React component, call `useVaultUntrackedBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVaultUntrackedBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVaultUntrackedBalancesQuery({
 *   variables: {
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *      vault: // value for 'vault'
 *   },
 * });
 */
export function useVaultUntrackedBalancesQuery(baseOptions: Apollo.QueryHookOptions<VaultUntrackedBalancesQuery, VaultUntrackedBalancesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VaultUntrackedBalancesQuery, VaultUntrackedBalancesQueryVariables>(VaultUntrackedBalancesDocument, options);
      }
export function useVaultUntrackedBalancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VaultUntrackedBalancesQuery, VaultUntrackedBalancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VaultUntrackedBalancesQuery, VaultUntrackedBalancesQueryVariables>(VaultUntrackedBalancesDocument, options);
        }
export type VaultUntrackedBalancesQueryHookResult = ReturnType<typeof useVaultUntrackedBalancesQuery>;
export type VaultUntrackedBalancesLazyQueryHookResult = ReturnType<typeof useVaultUntrackedBalancesLazyQuery>;
export type VaultUntrackedBalancesQueryResult = Apollo.QueryResult<VaultUntrackedBalancesQuery, VaultUntrackedBalancesQueryVariables>;
export const VaultsDocument = gql`
    query Vaults($currency: Currency!, $first: Int, $network: Deployment!, $vaultAddresses: [Address!]) {
  vaultList(
    currency: $currency
    first: $first
    network: $network
    vaultAddresses: $vaultAddresses
  ) {
    nodes {
      name
      address
      valid
      nsvCurrent
      gavCurrent
      numberOfAssets
      assets
      assetPercentages
      performance24h
      performanceThisMonth
      performanceThisQuarter
      performanceSinceInception
      numberOfDepositors
    }
  }
}
    `;

/**
 * __useVaultsQuery__
 *
 * To run a query within a React component, call `useVaultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVaultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVaultsQuery({
 *   variables: {
 *      currency: // value for 'currency'
 *      first: // value for 'first'
 *      network: // value for 'network'
 *      vaultAddresses: // value for 'vaultAddresses'
 *   },
 * });
 */
export function useVaultsQuery(baseOptions: Apollo.QueryHookOptions<VaultsQuery, VaultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VaultsQuery, VaultsQueryVariables>(VaultsDocument, options);
      }
export function useVaultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VaultsQuery, VaultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VaultsQuery, VaultsQueryVariables>(VaultsDocument, options);
        }
export type VaultsQueryHookResult = ReturnType<typeof useVaultsQuery>;
export type VaultsLazyQueryHookResult = ReturnType<typeof useVaultsLazyQuery>;
export type VaultsQueryResult = Apollo.QueryResult<VaultsQuery, VaultsQueryVariables>;
export const VaultsForDepositorDocument = gql`
    query VaultsForDepositor($currency: Currency!, $first: Int, $network: Deployment!) {
  vaultListForDepositor(currency: $currency, first: $first, network: $network) {
    aggregated {
      avgPerformanceThisMonth
      totalVaultsDeposited
      totalDepositorValue
    }
    nodes {
      name
      address
      valid
      nsvCurrent
      gavCurrent
      numberOfAssets
      assets
      assetPercentages
      performance24h
      performanceThisMonth
      performanceThisQuarter
      performanceSinceInception
      numberOfDepositors
      supply
      shareBalance
      depositorValue
    }
  }
}
    `;

/**
 * __useVaultsForDepositorQuery__
 *
 * To run a query within a React component, call `useVaultsForDepositorQuery` and pass it any options that fit your needs.
 * When your component renders, `useVaultsForDepositorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVaultsForDepositorQuery({
 *   variables: {
 *      currency: // value for 'currency'
 *      first: // value for 'first'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useVaultsForDepositorQuery(baseOptions: Apollo.QueryHookOptions<VaultsForDepositorQuery, VaultsForDepositorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VaultsForDepositorQuery, VaultsForDepositorQueryVariables>(VaultsForDepositorDocument, options);
      }
export function useVaultsForDepositorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VaultsForDepositorQuery, VaultsForDepositorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VaultsForDepositorQuery, VaultsForDepositorQueryVariables>(VaultsForDepositorDocument, options);
        }
export type VaultsForDepositorQueryHookResult = ReturnType<typeof useVaultsForDepositorQuery>;
export type VaultsForDepositorLazyQueryHookResult = ReturnType<typeof useVaultsForDepositorLazyQuery>;
export type VaultsForDepositorQueryResult = Apollo.QueryResult<VaultsForDepositorQuery, VaultsForDepositorQueryVariables>;
export const VaultsForManagerDocument = gql`
    query VaultsForManager($currency: Currency!, $first: Int, $network: Deployment!) {
  vaultListForManager(currency: $currency, first: $first, network: $network) {
    aggregated {
      avgPerformanceThisMonth
      totalNumberOfDepositors
      totalGrossAssetValue
    }
    nodes {
      name
      address
      valid
      nsvCurrent
      gavCurrent
      numberOfAssets
      assets
      assetPercentages
      performance24h
      performanceThisMonth
      performanceThisQuarter
      performanceSinceInception
      numberOfDepositors
      supply
      shareBalance
    }
  }
}
    `;

/**
 * __useVaultsForManagerQuery__
 *
 * To run a query within a React component, call `useVaultsForManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useVaultsForManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVaultsForManagerQuery({
 *   variables: {
 *      currency: // value for 'currency'
 *      first: // value for 'first'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useVaultsForManagerQuery(baseOptions: Apollo.QueryHookOptions<VaultsForManagerQuery, VaultsForManagerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VaultsForManagerQuery, VaultsForManagerQueryVariables>(VaultsForManagerDocument, options);
      }
export function useVaultsForManagerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VaultsForManagerQuery, VaultsForManagerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VaultsForManagerQuery, VaultsForManagerQueryVariables>(VaultsForManagerDocument, options);
        }
export type VaultsForManagerQueryHookResult = ReturnType<typeof useVaultsForManagerQuery>;
export type VaultsForManagerLazyQueryHookResult = ReturnType<typeof useVaultsForManagerLazyQuery>;
export type VaultsForManagerQueryResult = Apollo.QueryResult<VaultsForManagerQuery, VaultsForManagerQueryVariables>;
export const DiscoverVaultsDocument = gql`
    query DiscoverVaults($currency: Currency!, $first: Int!, $network: Deployment!) {
  topGainers: vaultList(
    currency: $currency
    first: $first
    network: $network
    orderBy: TOP_GAINERS
  ) {
    nodes {
      name
      address
      valid
      nsvCurrent
      gavCurrent
      numberOfDepositors
      performance24h
    }
  }
  latest: vaultList(
    currency: $currency
    first: $first
    network: $network
    orderBy: LATEST_VAULTS
  ) {
    nodes {
      name
      address
      valid
      nsvCurrent
      gavCurrent
      numberOfDepositors
      performance24h
    }
  }
  mostPopular: vaultList(
    currency: $currency
    first: $first
    network: $network
    orderBy: MOST_POPULAR
  ) {
    nodes {
      name
      address
      valid
      nsvCurrent
      gavCurrent
      numberOfDepositors
      performance24h
    }
  }
  largest: vaultList(currency: $currency, first: $first, network: $network) {
    nodes {
      name
      address
      valid
      nsvCurrent
      gavCurrent
      numberOfDepositors
      performance24h
    }
  }
}
    `;

/**
 * __useDiscoverVaultsQuery__
 *
 * To run a query within a React component, call `useDiscoverVaultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscoverVaultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscoverVaultsQuery({
 *   variables: {
 *      currency: // value for 'currency'
 *      first: // value for 'first'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useDiscoverVaultsQuery(baseOptions: Apollo.QueryHookOptions<DiscoverVaultsQuery, DiscoverVaultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DiscoverVaultsQuery, DiscoverVaultsQueryVariables>(DiscoverVaultsDocument, options);
      }
export function useDiscoverVaultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiscoverVaultsQuery, DiscoverVaultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DiscoverVaultsQuery, DiscoverVaultsQueryVariables>(DiscoverVaultsDocument, options);
        }
export type DiscoverVaultsQueryHookResult = ReturnType<typeof useDiscoverVaultsQuery>;
export type DiscoverVaultsLazyQueryHookResult = ReturnType<typeof useDiscoverVaultsLazyQuery>;
export type DiscoverVaultsQueryResult = Apollo.QueryResult<DiscoverVaultsQuery, DiscoverVaultsQueryVariables>;
export const VaultPerformanceLatestDocument = gql`
    query VaultPerformanceLatest($currency: CurrencyOrAddress!, $network: Deployment!, $vault: Address!) {
  vaultPerformanceLatest(currency: $currency, network: $network, vault: $vault) {
    supply
    netShareValue
    valid
    grossAssetValue
    netAssetValue
    performance24h
    performanceMtd
    performanceQtd
    performanceYtd
    performanceSinceInception
  }
}
    `;

/**
 * __useVaultPerformanceLatestQuery__
 *
 * To run a query within a React component, call `useVaultPerformanceLatestQuery` and pass it any options that fit your needs.
 * When your component renders, `useVaultPerformanceLatestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVaultPerformanceLatestQuery({
 *   variables: {
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *      vault: // value for 'vault'
 *   },
 * });
 */
export function useVaultPerformanceLatestQuery(baseOptions: Apollo.QueryHookOptions<VaultPerformanceLatestQuery, VaultPerformanceLatestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VaultPerformanceLatestQuery, VaultPerformanceLatestQueryVariables>(VaultPerformanceLatestDocument, options);
      }
export function useVaultPerformanceLatestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VaultPerformanceLatestQuery, VaultPerformanceLatestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VaultPerformanceLatestQuery, VaultPerformanceLatestQueryVariables>(VaultPerformanceLatestDocument, options);
        }
export type VaultPerformanceLatestQueryHookResult = ReturnType<typeof useVaultPerformanceLatestQuery>;
export type VaultPerformanceLatestLazyQueryHookResult = ReturnType<typeof useVaultPerformanceLatestLazyQuery>;
export type VaultPerformanceLatestQueryResult = Apollo.QueryResult<VaultPerformanceLatestQuery, VaultPerformanceLatestQueryVariables>;
export const VaultRiskMetricsDocument = gql`
    query VaultRiskMetrics($currency: CurrencyOrAddress!, $network: Deployment!, $vault: Address!) {
  vaultRiskMetrics(currency: $currency, network: $network, vault: $vault) {
    numberOfDays
    averageDailyReturn
    dailyVolatility
    annualizedVolatility
    annualizedSharpeRatio
  }
}
    `;

/**
 * __useVaultRiskMetricsQuery__
 *
 * To run a query within a React component, call `useVaultRiskMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVaultRiskMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVaultRiskMetricsQuery({
 *   variables: {
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *      vault: // value for 'vault'
 *   },
 * });
 */
export function useVaultRiskMetricsQuery(baseOptions: Apollo.QueryHookOptions<VaultRiskMetricsQuery, VaultRiskMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VaultRiskMetricsQuery, VaultRiskMetricsQueryVariables>(VaultRiskMetricsDocument, options);
      }
export function useVaultRiskMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VaultRiskMetricsQuery, VaultRiskMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VaultRiskMetricsQuery, VaultRiskMetricsQueryVariables>(VaultRiskMetricsDocument, options);
        }
export type VaultRiskMetricsQueryHookResult = ReturnType<typeof useVaultRiskMetricsQuery>;
export type VaultRiskMetricsLazyQueryHookResult = ReturnType<typeof useVaultRiskMetricsLazyQuery>;
export type VaultRiskMetricsQueryResult = Apollo.QueryResult<VaultRiskMetricsQuery, VaultRiskMetricsQueryVariables>;
export const ArbitraryLoansEnabledForVaultDocument = gql`
    query ArbitraryLoansEnabledForVault($network: Deployment!, $comptroller: Address!, $ownerId: Address!) {
  arbitraryLoansEnabledForVault(
    network: $network
    comptroller: $comptroller
    ownerId: $ownerId
  ) {
    isFeatureEnabled
  }
}
    `;

/**
 * __useArbitraryLoansEnabledForVaultQuery__
 *
 * To run a query within a React component, call `useArbitraryLoansEnabledForVaultQuery` and pass it any options that fit your needs.
 * When your component renders, `useArbitraryLoansEnabledForVaultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArbitraryLoansEnabledForVaultQuery({
 *   variables: {
 *      network: // value for 'network'
 *      comptroller: // value for 'comptroller'
 *      ownerId: // value for 'ownerId'
 *   },
 * });
 */
export function useArbitraryLoansEnabledForVaultQuery(baseOptions: Apollo.QueryHookOptions<ArbitraryLoansEnabledForVaultQuery, ArbitraryLoansEnabledForVaultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArbitraryLoansEnabledForVaultQuery, ArbitraryLoansEnabledForVaultQueryVariables>(ArbitraryLoansEnabledForVaultDocument, options);
      }
export function useArbitraryLoansEnabledForVaultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArbitraryLoansEnabledForVaultQuery, ArbitraryLoansEnabledForVaultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArbitraryLoansEnabledForVaultQuery, ArbitraryLoansEnabledForVaultQueryVariables>(ArbitraryLoansEnabledForVaultDocument, options);
        }
export type ArbitraryLoansEnabledForVaultQueryHookResult = ReturnType<typeof useArbitraryLoansEnabledForVaultQuery>;
export type ArbitraryLoansEnabledForVaultLazyQueryHookResult = ReturnType<typeof useArbitraryLoansEnabledForVaultLazyQuery>;
export type ArbitraryLoansEnabledForVaultQueryResult = Apollo.QueryResult<ArbitraryLoansEnabledForVaultQuery, ArbitraryLoansEnabledForVaultQueryVariables>;
export const VaultDepositorMetricsDocument = gql`
    query VaultDepositorMetrics($currency: CurrencyOrAddress!, $network: Deployment!, $vault: Address!, $depositor: Address!) {
  vaultDepositorMetrics(
    currency: $currency
    network: $network
    vault: $vault
    depositor: $depositor
  ) {
    amountDeposited
    amountRedeemed
    costBaseFifo
    costBaseLifo
    currentShares
    currentValue
    sharesBought
    sharesRedeemed
    sharesReceivedFromFees
    sharesTransferredIn
    sharesTransferredOut
    totalReturn
  }
}
    `;

/**
 * __useVaultDepositorMetricsQuery__
 *
 * To run a query within a React component, call `useVaultDepositorMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVaultDepositorMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVaultDepositorMetricsQuery({
 *   variables: {
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *      vault: // value for 'vault'
 *      depositor: // value for 'depositor'
 *   },
 * });
 */
export function useVaultDepositorMetricsQuery(baseOptions: Apollo.QueryHookOptions<VaultDepositorMetricsQuery, VaultDepositorMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VaultDepositorMetricsQuery, VaultDepositorMetricsQueryVariables>(VaultDepositorMetricsDocument, options);
      }
export function useVaultDepositorMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VaultDepositorMetricsQuery, VaultDepositorMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VaultDepositorMetricsQuery, VaultDepositorMetricsQueryVariables>(VaultDepositorMetricsDocument, options);
        }
export type VaultDepositorMetricsQueryHookResult = ReturnType<typeof useVaultDepositorMetricsQuery>;
export type VaultDepositorMetricsLazyQueryHookResult = ReturnType<typeof useVaultDepositorMetricsLazyQuery>;
export type VaultDepositorMetricsQueryResult = Apollo.QueryResult<VaultDepositorMetricsQuery, VaultDepositorMetricsQueryVariables>;
export const VaultActiveProtocolIntegrationsDocument = gql`
    query VaultActiveProtocolIntegrations($network: Deployment!, $vault: Address!) {
  activeProtocolIntegrations(network: $network, vault: $vault) {
    activeIntegrations
  }
}
    `;

/**
 * __useVaultActiveProtocolIntegrationsQuery__
 *
 * To run a query within a React component, call `useVaultActiveProtocolIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVaultActiveProtocolIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVaultActiveProtocolIntegrationsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      vault: // value for 'vault'
 *   },
 * });
 */
export function useVaultActiveProtocolIntegrationsQuery(baseOptions: Apollo.QueryHookOptions<VaultActiveProtocolIntegrationsQuery, VaultActiveProtocolIntegrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VaultActiveProtocolIntegrationsQuery, VaultActiveProtocolIntegrationsQueryVariables>(VaultActiveProtocolIntegrationsDocument, options);
      }
export function useVaultActiveProtocolIntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VaultActiveProtocolIntegrationsQuery, VaultActiveProtocolIntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VaultActiveProtocolIntegrationsQuery, VaultActiveProtocolIntegrationsQueryVariables>(VaultActiveProtocolIntegrationsDocument, options);
        }
export type VaultActiveProtocolIntegrationsQueryHookResult = ReturnType<typeof useVaultActiveProtocolIntegrationsQuery>;
export type VaultActiveProtocolIntegrationsLazyQueryHookResult = ReturnType<typeof useVaultActiveProtocolIntegrationsLazyQuery>;
export type VaultActiveProtocolIntegrationsQueryResult = Apollo.QueryResult<VaultActiveProtocolIntegrationsQuery, VaultActiveProtocolIntegrationsQueryVariables>;
export const IsDepositorSignatureRequiredDocument = gql`
    query IsDepositorSignatureRequired($vaultAddress: Address!, $walletAddress: Address!) {
  isDepositorSignatureRequired(
    vaultAddress: $vaultAddress
    walletAddress: $walletAddress
  )
}
    `;

/**
 * __useIsDepositorSignatureRequiredQuery__
 *
 * To run a query within a React component, call `useIsDepositorSignatureRequiredQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsDepositorSignatureRequiredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsDepositorSignatureRequiredQuery({
 *   variables: {
 *      vaultAddress: // value for 'vaultAddress'
 *      walletAddress: // value for 'walletAddress'
 *   },
 * });
 */
export function useIsDepositorSignatureRequiredQuery(baseOptions: Apollo.QueryHookOptions<IsDepositorSignatureRequiredQuery, IsDepositorSignatureRequiredQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsDepositorSignatureRequiredQuery, IsDepositorSignatureRequiredQueryVariables>(IsDepositorSignatureRequiredDocument, options);
      }
export function useIsDepositorSignatureRequiredLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsDepositorSignatureRequiredQuery, IsDepositorSignatureRequiredQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsDepositorSignatureRequiredQuery, IsDepositorSignatureRequiredQueryVariables>(IsDepositorSignatureRequiredDocument, options);
        }
export type IsDepositorSignatureRequiredQueryHookResult = ReturnType<typeof useIsDepositorSignatureRequiredQuery>;
export type IsDepositorSignatureRequiredLazyQueryHookResult = ReturnType<typeof useIsDepositorSignatureRequiredLazyQuery>;
export type IsDepositorSignatureRequiredQueryResult = Apollo.QueryResult<IsDepositorSignatureRequiredQuery, IsDepositorSignatureRequiredQueryVariables>;
export const SubmitDepositorSignatureDocument = gql`
    mutation SubmitDepositorSignature($input: SubmitDepositorSignatureInput!) {
  submitDepositorSignature(input: $input) {
    vaultDepositorSignature {
      ...VaultDepositorSignatureDetail
    }
    errors {
      ...UserErrorFragment
    }
  }
}
    ${VaultDepositorSignatureDetailFragmentDoc}
${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type SubmitDepositorSignatureMutationFn = Apollo.MutationFunction<SubmitDepositorSignatureMutation, SubmitDepositorSignatureMutationVariables>;

/**
 * __useSubmitDepositorSignatureMutation__
 *
 * To run a mutation, you first call `useSubmitDepositorSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitDepositorSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitDepositorSignatureMutation, { data, loading, error }] = useSubmitDepositorSignatureMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitDepositorSignatureMutation(baseOptions?: Apollo.MutationHookOptions<SubmitDepositorSignatureMutation, SubmitDepositorSignatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitDepositorSignatureMutation, SubmitDepositorSignatureMutationVariables>(SubmitDepositorSignatureDocument, options);
      }
export type SubmitDepositorSignatureMutationHookResult = ReturnType<typeof useSubmitDepositorSignatureMutation>;
export type SubmitDepositorSignatureMutationResult = Apollo.MutationResult<SubmitDepositorSignatureMutation>;
export type SubmitDepositorSignatureMutationOptions = Apollo.BaseMutationOptions<SubmitDepositorSignatureMutation, SubmitDepositorSignatureMutationVariables>;
export const VaultStrategiesDocument = gql`
    query VaultStrategies {
  vaultStrategies {
    id
    ...VaultStrategyInfo
  }
}
    ${VaultStrategyInfoFragmentDoc}`;

/**
 * __useVaultStrategiesQuery__
 *
 * To run a query within a React component, call `useVaultStrategiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVaultStrategiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVaultStrategiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useVaultStrategiesQuery(baseOptions?: Apollo.QueryHookOptions<VaultStrategiesQuery, VaultStrategiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VaultStrategiesQuery, VaultStrategiesQueryVariables>(VaultStrategiesDocument, options);
      }
export function useVaultStrategiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VaultStrategiesQuery, VaultStrategiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VaultStrategiesQuery, VaultStrategiesQueryVariables>(VaultStrategiesDocument, options);
        }
export type VaultStrategiesQueryHookResult = ReturnType<typeof useVaultStrategiesQuery>;
export type VaultStrategiesLazyQueryHookResult = ReturnType<typeof useVaultStrategiesLazyQuery>;
export type VaultStrategiesQueryResult = Apollo.QueryResult<VaultStrategiesQuery, VaultStrategiesQueryVariables>;
export const VaultProfileDocument = gql`
    query VaultProfile($vaultAddress: Address!) {
  vaultProfile(vaultAddress: $vaultAddress) {
    id
    ...VaultProfileInfo
  }
}
    ${VaultProfileInfoFragmentDoc}
${VaultStrategyInfoFragmentDoc}`;

/**
 * __useVaultProfileQuery__
 *
 * To run a query within a React component, call `useVaultProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useVaultProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVaultProfileQuery({
 *   variables: {
 *      vaultAddress: // value for 'vaultAddress'
 *   },
 * });
 */
export function useVaultProfileQuery(baseOptions: Apollo.QueryHookOptions<VaultProfileQuery, VaultProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VaultProfileQuery, VaultProfileQueryVariables>(VaultProfileDocument, options);
      }
export function useVaultProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VaultProfileQuery, VaultProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VaultProfileQuery, VaultProfileQueryVariables>(VaultProfileDocument, options);
        }
export type VaultProfileQueryHookResult = ReturnType<typeof useVaultProfileQuery>;
export type VaultProfileLazyQueryHookResult = ReturnType<typeof useVaultProfileLazyQuery>;
export type VaultProfileQueryResult = Apollo.QueryResult<VaultProfileQuery, VaultProfileQueryVariables>;
export const UpdateVaultProfileDocument = gql`
    mutation UpdateVaultProfile($input: UpdateVaultProfileInput!) {
  updateVaultProfile(input: $input) {
    vaultProfile {
      id
      ...VaultProfileInfo
    }
    errors {
      ...UserErrorFragment
    }
  }
}
    ${VaultProfileInfoFragmentDoc}
${VaultStrategyInfoFragmentDoc}
${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type UpdateVaultProfileMutationFn = Apollo.MutationFunction<UpdateVaultProfileMutation, UpdateVaultProfileMutationVariables>;

/**
 * __useUpdateVaultProfileMutation__
 *
 * To run a mutation, you first call `useUpdateVaultProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVaultProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVaultProfileMutation, { data, loading, error }] = useUpdateVaultProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVaultProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVaultProfileMutation, UpdateVaultProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVaultProfileMutation, UpdateVaultProfileMutationVariables>(UpdateVaultProfileDocument, options);
      }
export type UpdateVaultProfileMutationHookResult = ReturnType<typeof useUpdateVaultProfileMutation>;
export type UpdateVaultProfileMutationResult = Apollo.MutationResult<UpdateVaultProfileMutation>;
export type UpdateVaultProfileMutationOptions = Apollo.BaseMutationOptions<UpdateVaultProfileMutation, UpdateVaultProfileMutationVariables>;
export const WalletClaimsDocument = gql`
    query WalletClaims {
  me {
    id
    walletClaims {
      ...WalletClaimInfo
    }
  }
}
    ${WalletClaimInfoFragmentDoc}`;

/**
 * __useWalletClaimsQuery__
 *
 * To run a query within a React component, call `useWalletClaimsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWalletClaimsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWalletClaimsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWalletClaimsQuery(baseOptions?: Apollo.QueryHookOptions<WalletClaimsQuery, WalletClaimsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WalletClaimsQuery, WalletClaimsQueryVariables>(WalletClaimsDocument, options);
      }
export function useWalletClaimsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WalletClaimsQuery, WalletClaimsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WalletClaimsQuery, WalletClaimsQueryVariables>(WalletClaimsDocument, options);
        }
export type WalletClaimsQueryHookResult = ReturnType<typeof useWalletClaimsQuery>;
export type WalletClaimsLazyQueryHookResult = ReturnType<typeof useWalletClaimsLazyQuery>;
export type WalletClaimsQueryResult = Apollo.QueryResult<WalletClaimsQuery, WalletClaimsQueryVariables>;
export const WalletClaimsAuthDocument = gql`
    query WalletClaimsAuth($network: Network!) {
  me {
    id
    walletClaims {
      auth(network: $network)
      ...WalletClaimInfo
    }
  }
}
    ${WalletClaimInfoFragmentDoc}`;

/**
 * __useWalletClaimsAuthQuery__
 *
 * To run a query within a React component, call `useWalletClaimsAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useWalletClaimsAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWalletClaimsAuthQuery({
 *   variables: {
 *      network: // value for 'network'
 *   },
 * });
 */
export function useWalletClaimsAuthQuery(baseOptions: Apollo.QueryHookOptions<WalletClaimsAuthQuery, WalletClaimsAuthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WalletClaimsAuthQuery, WalletClaimsAuthQueryVariables>(WalletClaimsAuthDocument, options);
      }
export function useWalletClaimsAuthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WalletClaimsAuthQuery, WalletClaimsAuthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WalletClaimsAuthQuery, WalletClaimsAuthQueryVariables>(WalletClaimsAuthDocument, options);
        }
export type WalletClaimsAuthQueryHookResult = ReturnType<typeof useWalletClaimsAuthQuery>;
export type WalletClaimsAuthLazyQueryHookResult = ReturnType<typeof useWalletClaimsAuthLazyQuery>;
export type WalletClaimsAuthQueryResult = Apollo.QueryResult<WalletClaimsAuthQuery, WalletClaimsAuthQueryVariables>;
export const CreateWalletClaimDocument = gql`
    mutation CreateWalletClaim($input: CreateWalletClaimInput!) {
  createWalletClaim(input: $input) {
    walletClaim {
      ...WalletClaimInfo
    }
    errors {
      ...UserErrorFragment
    }
  }
}
    ${WalletClaimInfoFragmentDoc}
${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type CreateWalletClaimMutationFn = Apollo.MutationFunction<CreateWalletClaimMutation, CreateWalletClaimMutationVariables>;

/**
 * __useCreateWalletClaimMutation__
 *
 * To run a mutation, you first call `useCreateWalletClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWalletClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWalletClaimMutation, { data, loading, error }] = useCreateWalletClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWalletClaimMutation(baseOptions?: Apollo.MutationHookOptions<CreateWalletClaimMutation, CreateWalletClaimMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWalletClaimMutation, CreateWalletClaimMutationVariables>(CreateWalletClaimDocument, options);
      }
export type CreateWalletClaimMutationHookResult = ReturnType<typeof useCreateWalletClaimMutation>;
export type CreateWalletClaimMutationResult = Apollo.MutationResult<CreateWalletClaimMutation>;
export type CreateWalletClaimMutationOptions = Apollo.BaseMutationOptions<CreateWalletClaimMutation, CreateWalletClaimMutationVariables>;
export const DeleteWalletClaimDocument = gql`
    mutation DeleteWalletClaim($input: DeleteWalletClaimInput!) {
  deleteWalletClaim(input: $input) {
    walletClaim {
      id
    }
    errors {
      ...UserErrorFragment
    }
  }
}
    ${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type DeleteWalletClaimMutationFn = Apollo.MutationFunction<DeleteWalletClaimMutation, DeleteWalletClaimMutationVariables>;

/**
 * __useDeleteWalletClaimMutation__
 *
 * To run a mutation, you first call `useDeleteWalletClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWalletClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWalletClaimMutation, { data, loading, error }] = useDeleteWalletClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteWalletClaimMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWalletClaimMutation, DeleteWalletClaimMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWalletClaimMutation, DeleteWalletClaimMutationVariables>(DeleteWalletClaimDocument, options);
      }
export type DeleteWalletClaimMutationHookResult = ReturnType<typeof useDeleteWalletClaimMutation>;
export type DeleteWalletClaimMutationResult = Apollo.MutationResult<DeleteWalletClaimMutation>;
export type DeleteWalletClaimMutationOptions = Apollo.BaseMutationOptions<DeleteWalletClaimMutation, DeleteWalletClaimMutationVariables>;
export const UpdateWalletClaimDocument = gql`
    mutation UpdateWalletClaim($input: UpdateWalletClaimInput!) {
  updateWalletClaim(input: $input) {
    walletClaim {
      ...WalletClaimInfo
    }
    errors {
      ...UserErrorFragment
    }
  }
}
    ${WalletClaimInfoFragmentDoc}
${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type UpdateWalletClaimMutationFn = Apollo.MutationFunction<UpdateWalletClaimMutation, UpdateWalletClaimMutationVariables>;

/**
 * __useUpdateWalletClaimMutation__
 *
 * To run a mutation, you first call `useUpdateWalletClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWalletClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWalletClaimMutation, { data, loading, error }] = useUpdateWalletClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWalletClaimMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWalletClaimMutation, UpdateWalletClaimMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWalletClaimMutation, UpdateWalletClaimMutationVariables>(UpdateWalletClaimDocument, options);
      }
export type UpdateWalletClaimMutationHookResult = ReturnType<typeof useUpdateWalletClaimMutation>;
export type UpdateWalletClaimMutationResult = Apollo.MutationResult<UpdateWalletClaimMutation>;
export type UpdateWalletClaimMutationOptions = Apollo.BaseMutationOptions<UpdateWalletClaimMutation, UpdateWalletClaimMutationVariables>;
export const CreateWalletClaimMessageDocument = gql`
    mutation CreateWalletClaimMessage($input: CreateWalletClaimMessageInput!) {
  createWalletClaimMessage(input: $input) {
    walletClaimMessage {
      ...WalletClaimMessageInfo
    }
    errors {
      ...UserErrorFragment
    }
  }
}
    ${WalletClaimMessageInfoFragmentDoc}
${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type CreateWalletClaimMessageMutationFn = Apollo.MutationFunction<CreateWalletClaimMessageMutation, CreateWalletClaimMessageMutationVariables>;

/**
 * __useCreateWalletClaimMessageMutation__
 *
 * To run a mutation, you first call `useCreateWalletClaimMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWalletClaimMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWalletClaimMessageMutation, { data, loading, error }] = useCreateWalletClaimMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWalletClaimMessageMutation(baseOptions?: Apollo.MutationHookOptions<CreateWalletClaimMessageMutation, CreateWalletClaimMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWalletClaimMessageMutation, CreateWalletClaimMessageMutationVariables>(CreateWalletClaimMessageDocument, options);
      }
export type CreateWalletClaimMessageMutationHookResult = ReturnType<typeof useCreateWalletClaimMessageMutation>;
export type CreateWalletClaimMessageMutationResult = Apollo.MutationResult<CreateWalletClaimMessageMutation>;
export type CreateWalletClaimMessageMutationOptions = Apollo.BaseMutationOptions<CreateWalletClaimMessageMutation, CreateWalletClaimMessageMutationVariables>;
export const WhiteLabelsDocument = gql`
    query WhiteLabels {
  me {
    id
    whiteLabels {
      ...WhiteLabelInfo
    }
  }
}
    ${WhiteLabelInfoFragmentDoc}`;

/**
 * __useWhiteLabelsQuery__
 *
 * To run a query within a React component, call `useWhiteLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhiteLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhiteLabelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhiteLabelsQuery(baseOptions?: Apollo.QueryHookOptions<WhiteLabelsQuery, WhiteLabelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WhiteLabelsQuery, WhiteLabelsQueryVariables>(WhiteLabelsDocument, options);
      }
export function useWhiteLabelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WhiteLabelsQuery, WhiteLabelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WhiteLabelsQuery, WhiteLabelsQueryVariables>(WhiteLabelsDocument, options);
        }
export type WhiteLabelsQueryHookResult = ReturnType<typeof useWhiteLabelsQuery>;
export type WhiteLabelsLazyQueryHookResult = ReturnType<typeof useWhiteLabelsLazyQuery>;
export type WhiteLabelsQueryResult = Apollo.QueryResult<WhiteLabelsQuery, WhiteLabelsQueryVariables>;
export const WhiteLabelBySubdomainDocument = gql`
    query WhiteLabelBySubdomain($subdomain: String!) {
  whiteLabelBySubdomain(subdomain: $subdomain) {
    ...WhiteLabelInfo
    isOwner
  }
}
    ${WhiteLabelInfoFragmentDoc}`;

/**
 * __useWhiteLabelBySubdomainQuery__
 *
 * To run a query within a React component, call `useWhiteLabelBySubdomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhiteLabelBySubdomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhiteLabelBySubdomainQuery({
 *   variables: {
 *      subdomain: // value for 'subdomain'
 *   },
 * });
 */
export function useWhiteLabelBySubdomainQuery(baseOptions: Apollo.QueryHookOptions<WhiteLabelBySubdomainQuery, WhiteLabelBySubdomainQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WhiteLabelBySubdomainQuery, WhiteLabelBySubdomainQueryVariables>(WhiteLabelBySubdomainDocument, options);
      }
export function useWhiteLabelBySubdomainLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WhiteLabelBySubdomainQuery, WhiteLabelBySubdomainQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WhiteLabelBySubdomainQuery, WhiteLabelBySubdomainQueryVariables>(WhiteLabelBySubdomainDocument, options);
        }
export type WhiteLabelBySubdomainQueryHookResult = ReturnType<typeof useWhiteLabelBySubdomainQuery>;
export type WhiteLabelBySubdomainLazyQueryHookResult = ReturnType<typeof useWhiteLabelBySubdomainLazyQuery>;
export type WhiteLabelBySubdomainQueryResult = Apollo.QueryResult<WhiteLabelBySubdomainQuery, WhiteLabelBySubdomainQueryVariables>;
export const WhiteLabelByIdDocument = gql`
    query WhiteLabelById($id: String!) {
  whiteLabelById(id: $id) {
    ...WhiteLabelInfo
    isOwner
  }
}
    ${WhiteLabelInfoFragmentDoc}`;

/**
 * __useWhiteLabelByIdQuery__
 *
 * To run a query within a React component, call `useWhiteLabelByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhiteLabelByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhiteLabelByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWhiteLabelByIdQuery(baseOptions: Apollo.QueryHookOptions<WhiteLabelByIdQuery, WhiteLabelByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WhiteLabelByIdQuery, WhiteLabelByIdQueryVariables>(WhiteLabelByIdDocument, options);
      }
export function useWhiteLabelByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WhiteLabelByIdQuery, WhiteLabelByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WhiteLabelByIdQuery, WhiteLabelByIdQueryVariables>(WhiteLabelByIdDocument, options);
        }
export type WhiteLabelByIdQueryHookResult = ReturnType<typeof useWhiteLabelByIdQuery>;
export type WhiteLabelByIdLazyQueryHookResult = ReturnType<typeof useWhiteLabelByIdLazyQuery>;
export type WhiteLabelByIdQueryResult = Apollo.QueryResult<WhiteLabelByIdQuery, WhiteLabelByIdQueryVariables>;
export const CreateWhiteLabelDocument = gql`
    mutation CreateWhiteLabel($input: CreateWhiteLabelInput!) {
  createWhiteLabel(input: $input) {
    whiteLabel {
      ...WhiteLabelInfo
    }
    errors {
      ...UserErrorFragment
    }
  }
}
    ${WhiteLabelInfoFragmentDoc}
${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type CreateWhiteLabelMutationFn = Apollo.MutationFunction<CreateWhiteLabelMutation, CreateWhiteLabelMutationVariables>;

/**
 * __useCreateWhiteLabelMutation__
 *
 * To run a mutation, you first call `useCreateWhiteLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWhiteLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWhiteLabelMutation, { data, loading, error }] = useCreateWhiteLabelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWhiteLabelMutation(baseOptions?: Apollo.MutationHookOptions<CreateWhiteLabelMutation, CreateWhiteLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWhiteLabelMutation, CreateWhiteLabelMutationVariables>(CreateWhiteLabelDocument, options);
      }
export type CreateWhiteLabelMutationHookResult = ReturnType<typeof useCreateWhiteLabelMutation>;
export type CreateWhiteLabelMutationResult = Apollo.MutationResult<CreateWhiteLabelMutation>;
export type CreateWhiteLabelMutationOptions = Apollo.BaseMutationOptions<CreateWhiteLabelMutation, CreateWhiteLabelMutationVariables>;
export const UpdateWhiteLabelDocument = gql`
    mutation UpdateWhiteLabel($input: UpdateWhiteLabelInput!) {
  updateWhiteLabel(input: $input) {
    whiteLabel {
      ...WhiteLabelInfo
    }
    errors {
      ...UserErrorFragment
    }
  }
}
    ${WhiteLabelInfoFragmentDoc}
${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type UpdateWhiteLabelMutationFn = Apollo.MutationFunction<UpdateWhiteLabelMutation, UpdateWhiteLabelMutationVariables>;

/**
 * __useUpdateWhiteLabelMutation__
 *
 * To run a mutation, you first call `useUpdateWhiteLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWhiteLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWhiteLabelMutation, { data, loading, error }] = useUpdateWhiteLabelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWhiteLabelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWhiteLabelMutation, UpdateWhiteLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWhiteLabelMutation, UpdateWhiteLabelMutationVariables>(UpdateWhiteLabelDocument, options);
      }
export type UpdateWhiteLabelMutationHookResult = ReturnType<typeof useUpdateWhiteLabelMutation>;
export type UpdateWhiteLabelMutationResult = Apollo.MutationResult<UpdateWhiteLabelMutation>;
export type UpdateWhiteLabelMutationOptions = Apollo.BaseMutationOptions<UpdateWhiteLabelMutation, UpdateWhiteLabelMutationVariables>;
export const UpdateWhiteLabelCoverDocument = gql`
    mutation UpdateWhiteLabelCover($input: UpdateWhiteLabelCoverInput!) {
  updateWhiteLabelCover(input: $input) {
    whiteLabel {
      ...WhiteLabelInfo
    }
    errors {
      ...UserErrorFragment
    }
  }
}
    ${WhiteLabelInfoFragmentDoc}
${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type UpdateWhiteLabelCoverMutationFn = Apollo.MutationFunction<UpdateWhiteLabelCoverMutation, UpdateWhiteLabelCoverMutationVariables>;

/**
 * __useUpdateWhiteLabelCoverMutation__
 *
 * To run a mutation, you first call `useUpdateWhiteLabelCoverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWhiteLabelCoverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWhiteLabelCoverMutation, { data, loading, error }] = useUpdateWhiteLabelCoverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWhiteLabelCoverMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWhiteLabelCoverMutation, UpdateWhiteLabelCoverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWhiteLabelCoverMutation, UpdateWhiteLabelCoverMutationVariables>(UpdateWhiteLabelCoverDocument, options);
      }
export type UpdateWhiteLabelCoverMutationHookResult = ReturnType<typeof useUpdateWhiteLabelCoverMutation>;
export type UpdateWhiteLabelCoverMutationResult = Apollo.MutationResult<UpdateWhiteLabelCoverMutation>;
export type UpdateWhiteLabelCoverMutationOptions = Apollo.BaseMutationOptions<UpdateWhiteLabelCoverMutation, UpdateWhiteLabelCoverMutationVariables>;
export const UpdateWhiteLabelLogoDocument = gql`
    mutation UpdateWhiteLabelLogo($input: UpdateWhiteLabelLogoInput!) {
  updateWhiteLabelLogo(input: $input) {
    whiteLabel {
      ...WhiteLabelInfo
    }
    errors {
      ...UserErrorFragment
    }
  }
}
    ${WhiteLabelInfoFragmentDoc}
${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type UpdateWhiteLabelLogoMutationFn = Apollo.MutationFunction<UpdateWhiteLabelLogoMutation, UpdateWhiteLabelLogoMutationVariables>;

/**
 * __useUpdateWhiteLabelLogoMutation__
 *
 * To run a mutation, you first call `useUpdateWhiteLabelLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWhiteLabelLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWhiteLabelLogoMutation, { data, loading, error }] = useUpdateWhiteLabelLogoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWhiteLabelLogoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWhiteLabelLogoMutation, UpdateWhiteLabelLogoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWhiteLabelLogoMutation, UpdateWhiteLabelLogoMutationVariables>(UpdateWhiteLabelLogoDocument, options);
      }
export type UpdateWhiteLabelLogoMutationHookResult = ReturnType<typeof useUpdateWhiteLabelLogoMutation>;
export type UpdateWhiteLabelLogoMutationResult = Apollo.MutationResult<UpdateWhiteLabelLogoMutation>;
export type UpdateWhiteLabelLogoMutationOptions = Apollo.BaseMutationOptions<UpdateWhiteLabelLogoMutation, UpdateWhiteLabelLogoMutationVariables>;
export const UpdateWhiteLabelThemeDocument = gql`
    mutation UpdateWhiteLabelTheme($input: UpdateWhiteLabelThemeInput!) {
  updateWhiteLabelTheme(input: $input) {
    whiteLabel {
      ...WhiteLabelInfo
    }
    errors {
      ...UserErrorFragment
    }
  }
}
    ${WhiteLabelInfoFragmentDoc}
${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type UpdateWhiteLabelThemeMutationFn = Apollo.MutationFunction<UpdateWhiteLabelThemeMutation, UpdateWhiteLabelThemeMutationVariables>;

/**
 * __useUpdateWhiteLabelThemeMutation__
 *
 * To run a mutation, you first call `useUpdateWhiteLabelThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWhiteLabelThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWhiteLabelThemeMutation, { data, loading, error }] = useUpdateWhiteLabelThemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWhiteLabelThemeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWhiteLabelThemeMutation, UpdateWhiteLabelThemeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWhiteLabelThemeMutation, UpdateWhiteLabelThemeMutationVariables>(UpdateWhiteLabelThemeDocument, options);
      }
export type UpdateWhiteLabelThemeMutationHookResult = ReturnType<typeof useUpdateWhiteLabelThemeMutation>;
export type UpdateWhiteLabelThemeMutationResult = Apollo.MutationResult<UpdateWhiteLabelThemeMutation>;
export type UpdateWhiteLabelThemeMutationOptions = Apollo.BaseMutationOptions<UpdateWhiteLabelThemeMutation, UpdateWhiteLabelThemeMutationVariables>;
export const DeleteWhiteLabelDocument = gql`
    mutation DeleteWhiteLabel($input: DeleteWhiteLabelInput!) {
  deleteWhiteLabel(input: $input) {
    errors {
      ...UserErrorFragment
    }
  }
}
    ${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type DeleteWhiteLabelMutationFn = Apollo.MutationFunction<DeleteWhiteLabelMutation, DeleteWhiteLabelMutationVariables>;

/**
 * __useDeleteWhiteLabelMutation__
 *
 * To run a mutation, you first call `useDeleteWhiteLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWhiteLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWhiteLabelMutation, { data, loading, error }] = useDeleteWhiteLabelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteWhiteLabelMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWhiteLabelMutation, DeleteWhiteLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWhiteLabelMutation, DeleteWhiteLabelMutationVariables>(DeleteWhiteLabelDocument, options);
      }
export type DeleteWhiteLabelMutationHookResult = ReturnType<typeof useDeleteWhiteLabelMutation>;
export type DeleteWhiteLabelMutationResult = Apollo.MutationResult<DeleteWhiteLabelMutation>;
export type DeleteWhiteLabelMutationOptions = Apollo.BaseMutationOptions<DeleteWhiteLabelMutation, DeleteWhiteLabelMutationVariables>;
export const DeleteWhiteLabelCoverDocument = gql`
    mutation DeleteWhiteLabelCover($input: DeleteWhiteLabelCoverInput!) {
  deleteWhiteLabelCover(input: $input) {
    errors {
      ...UserErrorFragment
    }
  }
}
    ${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type DeleteWhiteLabelCoverMutationFn = Apollo.MutationFunction<DeleteWhiteLabelCoverMutation, DeleteWhiteLabelCoverMutationVariables>;

/**
 * __useDeleteWhiteLabelCoverMutation__
 *
 * To run a mutation, you first call `useDeleteWhiteLabelCoverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWhiteLabelCoverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWhiteLabelCoverMutation, { data, loading, error }] = useDeleteWhiteLabelCoverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteWhiteLabelCoverMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWhiteLabelCoverMutation, DeleteWhiteLabelCoverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWhiteLabelCoverMutation, DeleteWhiteLabelCoverMutationVariables>(DeleteWhiteLabelCoverDocument, options);
      }
export type DeleteWhiteLabelCoverMutationHookResult = ReturnType<typeof useDeleteWhiteLabelCoverMutation>;
export type DeleteWhiteLabelCoverMutationResult = Apollo.MutationResult<DeleteWhiteLabelCoverMutation>;
export type DeleteWhiteLabelCoverMutationOptions = Apollo.BaseMutationOptions<DeleteWhiteLabelCoverMutation, DeleteWhiteLabelCoverMutationVariables>;
export const DeleteWhiteLabelLogoDocument = gql`
    mutation DeleteWhiteLabelLogo($input: DeleteWhiteLabelLogoInput!) {
  deleteWhiteLabelLogo(input: $input) {
    errors {
      ...UserErrorFragment
    }
  }
}
    ${UserErrorFragmentFragmentDoc}
${GenericUserErrorFragmentFragmentDoc}
${ArgValidationErrorFragmentFragmentDoc}
${RateLimitErrorFragmentFragmentDoc}`;
export type DeleteWhiteLabelLogoMutationFn = Apollo.MutationFunction<DeleteWhiteLabelLogoMutation, DeleteWhiteLabelLogoMutationVariables>;

/**
 * __useDeleteWhiteLabelLogoMutation__
 *
 * To run a mutation, you first call `useDeleteWhiteLabelLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWhiteLabelLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWhiteLabelLogoMutation, { data, loading, error }] = useDeleteWhiteLabelLogoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteWhiteLabelLogoMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWhiteLabelLogoMutation, DeleteWhiteLabelLogoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWhiteLabelLogoMutation, DeleteWhiteLabelLogoMutationVariables>(DeleteWhiteLabelLogoDocument, options);
      }
export type DeleteWhiteLabelLogoMutationHookResult = ReturnType<typeof useDeleteWhiteLabelLogoMutation>;
export type DeleteWhiteLabelLogoMutationResult = Apollo.MutationResult<DeleteWhiteLabelLogoMutation>;
export type DeleteWhiteLabelLogoMutationOptions = Apollo.BaseMutationOptions<DeleteWhiteLabelLogoMutation, DeleteWhiteLabelLogoMutationVariables>;
export const LendingDataForYearnAssetsDocument = gql`
    query LendingDataForYearnAssets($currency: Currency!, $network: Deployment!) {
  lendingDataForYearnAssets(currency: $currency, network: $network) {
    ...SingleYearnAssetLendingData
  }
}
    ${SingleYearnAssetLendingDataFragmentDoc}`;

/**
 * __useLendingDataForYearnAssetsQuery__
 *
 * To run a query within a React component, call `useLendingDataForYearnAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLendingDataForYearnAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLendingDataForYearnAssetsQuery({
 *   variables: {
 *      currency: // value for 'currency'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useLendingDataForYearnAssetsQuery(baseOptions: Apollo.QueryHookOptions<LendingDataForYearnAssetsQuery, LendingDataForYearnAssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LendingDataForYearnAssetsQuery, LendingDataForYearnAssetsQueryVariables>(LendingDataForYearnAssetsDocument, options);
      }
export function useLendingDataForYearnAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LendingDataForYearnAssetsQuery, LendingDataForYearnAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LendingDataForYearnAssetsQuery, LendingDataForYearnAssetsQueryVariables>(LendingDataForYearnAssetsDocument, options);
        }
export type LendingDataForYearnAssetsQueryHookResult = ReturnType<typeof useLendingDataForYearnAssetsQuery>;
export type LendingDataForYearnAssetsLazyQueryHookResult = ReturnType<typeof useLendingDataForYearnAssetsLazyQuery>;
export type LendingDataForYearnAssetsQueryResult = Apollo.QueryResult<LendingDataForYearnAssetsQuery, LendingDataForYearnAssetsQueryVariables>;