import { Utils } from "@enzymefinance/sdk";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { Textarea } from "@enzymefinance/ui";
import { ExternalPositionType } from "@enzymefinance/utils";
import { safeStringifyJSON } from "@enzymefinance/utils";
import { type Address, type Hex, decodeAbiParameters, parseAbiParameters } from "viem";
import type { GetIntegrationHandlerDependencies } from "../integrations/types";
import {
  aaveV2AddCollateral,
  aaveV2Borrow,
  aaveV2ClaimRewards,
  aaveV2RemoveCollateral,
  aaveV2RepayBorrow,
} from "./aaveV2";
import { aaveV3AddCollateral, aaveV3Borrow, aaveV3RemoveCollateral, aaveV3RepayBorrow, aaveV3SetEMode } from "./aaveV3";
import {
  arbitraryLoanCloseLoan,
  arbitraryLoanConfigureLoan,
  arbitraryLoanReconcile,
  arbitraryLoanUpdateBorrowableAmount,
} from "./arbitraryLoan";
import {
  compoundV2AddCollateral,
  compoundV2Borrow,
  compoundV2ClaimComp,
  compoundV2RemoveCollateral,
  compoundV2RepayBorrow,
} from "./compoundV2";
import {
  convexVotingClaimRewards,
  convexVotingDelegate,
  convexVotingLock,
  convexVotingRelock,
  convexVotingWithdraw,
} from "./convexVoting";
import { kilnClaimFees, kilnStake, kilnSweepEth, kilnUnstake } from "./kiln";
import { lidoWithdrawalsClaimWithdrawals, lidoWithdrawalsRequestWithdrawal } from "./lidoWithdrawals";
import {
  liquityAddCollateral,
  liquityBorrow,
  liquityClaimColletral,
  liquityCloseTrove,
  liquityOpenTrove,
  liquityRemoveCollateral,
  liquityRepay,
} from "./liquity";
import { mapleCancelRedeem, mapleClaimRewards, mapleLend, mapleRedeem, mapleRequestRedeem } from "./maple";
import { addLiquidity, buyPrincipalToken, claimRewards, removeLiquidity, sellPrincipalToken } from "./pendleV2";
import { stakeWiseClaimExitedAssets, stakeWiseEnterExitQueue, stakeWiseRedeem, stakeWiseStake } from "./stakeWise";
import {
  theGraphDelegationDelegate,
  theGraphDelegationUndelegate,
  theGraphDelegationWithdraw,
} from "./theGraphDelegation";
import type { ExternalPositionHandler } from "./types";
import {
  uniswapV3AddLiquidity,
  uniswapV3Collect,
  uniswapV3Mint,
  uniswapV3Purge,
  uniswapV3RemoveLiquidity,
} from "./uniswapV3";

interface CallOnExternalPositionArgs {
  externalPositionProxy: Address;
  actionId: bigint;
  actionArgs: Hex;
}

export function decodeCallOnExternalPositionArgs(callOnExternalPositionArgs: Hex): CallOnExternalPositionArgs {
  const [externalPositionProxy, actionId, actionArgs] = decodeAbiParameters(
    parseAbiParameters("address, uint256, bytes"),
    callOnExternalPositionArgs,
  );

  return {
    actionArgs,
    actionId,
    externalPositionProxy,
  };
}

export async function getExternalPositionHandler(
  { externalPositionProxy, actionId }: CallOnExternalPositionArgs,
  { getExternalPositionTypeByAddress }: GetIntegrationHandlerDependencies,
): Promise<ExternalPositionHandler> {
  const externalPosition = await getExternalPositionTypeByAddress(externalPositionProxy);

  const externalPositionType = externalPosition?.type;

  switch (externalPositionType) {
    case ExternalPositionType.COMPOUND_DEBT:
      switch (actionId as Integrations.CompoundV2.Action) {
        case Integrations.CompoundV2.Action.AddCollateral:
          return compoundV2AddCollateral;
        case Integrations.CompoundV2.Action.RemoveCollateral:
          return compoundV2RemoveCollateral;
        case Integrations.CompoundV2.Action.Borrow:
          return compoundV2Borrow;
        case Integrations.CompoundV2.Action.RepayBorrow:
          return compoundV2RepayBorrow;
        case Integrations.CompoundV2.Action.ClaimComp:
          return compoundV2ClaimComp;
        default:
          throw new Error(`Not implemented in the UI ${actionId}`);
      }

    case ExternalPositionType.UNISWAP_V3_LIQUIDITY:
      switch (actionId as Integrations.UniswapV3.Action) {
        case Integrations.UniswapV3.Action.Mint:
          return uniswapV3Mint;
        case Integrations.UniswapV3.Action.AddLiquidity:
          return uniswapV3AddLiquidity;
        case Integrations.UniswapV3.Action.RemoveLiquidity:
          return uniswapV3RemoveLiquidity;
        case Integrations.UniswapV3.Action.Collect:
          return uniswapV3Collect;
        case Integrations.UniswapV3.Action.Purge:
          return uniswapV3Purge;
        default:
          throw new Error(`Not implemented in the UI ${actionId}`);
      }

    case ExternalPositionType.AAVE_DEBT:
      switch (actionId as Integrations.AaveV2.Action) {
        case Integrations.AaveV2.Action.AddCollateral:
          return aaveV2AddCollateral;
        case Integrations.AaveV2.Action.RemoveCollateral:
          return aaveV2RemoveCollateral;
        case Integrations.AaveV2.Action.Borrow:
          return aaveV2Borrow;
        case Integrations.AaveV2.Action.RepayBorrow:
          return aaveV2RepayBorrow;
        case Integrations.AaveV2.Action.ClaimRewards:
          return aaveV2ClaimRewards;
        default:
          throw new Error(`Not implemented in the UI ${actionId}`);
      }

    case ExternalPositionType.AAVE_V3_DEBT:
      switch (actionId as Integrations.AaveV3.Action) {
        case Integrations.AaveV3.Action.AddCollateral:
          return aaveV3AddCollateral;
        case Integrations.AaveV3.Action.RemoveCollateral:
          return aaveV3RemoveCollateral;
        case Integrations.AaveV3.Action.Borrow:
          return aaveV3Borrow;
        case Integrations.AaveV3.Action.RepayBorrow:
          return aaveV3RepayBorrow;
        case Integrations.AaveV3.Action.SetEMode:
          return aaveV3SetEMode;
        case Integrations.AaveV3.Action.SetUseReserveAsCollateral:
          throw new Error(`Not implemented in the UI ${actionId} for Aave V3 Debt.`);
        default:
          throw new Error(`Not implemented in the UI ${actionId}`);
      }

    case ExternalPositionType.ARBITRARY_LOAN:
      switch (actionId as Integrations.ArbitraryLoan.Action) {
        case Integrations.ArbitraryLoan.Action.ConfigureLoan:
          return arbitraryLoanConfigureLoan;
        case Integrations.ArbitraryLoan.Action.UpdateBorrowableAmount:
          return arbitraryLoanUpdateBorrowableAmount;
        case Integrations.ArbitraryLoan.Action.Reconcile:
          return arbitraryLoanReconcile;
        case Integrations.ArbitraryLoan.Action.CloseLoan:
          return arbitraryLoanCloseLoan;
        case Integrations.ArbitraryLoan.Action.CallOnAccountingModule:
          throw new Error(`Not implemented in the UI ${actionId} for Arbitrary Loan.`);
        default:
          throw new Error(`Not implemented in the UI ${actionId}`);
      }

    case ExternalPositionType.KILN_STAKING:
      switch (actionId as Integrations.Kiln.Action) {
        case Integrations.Kiln.Action.Stake:
          return kilnStake;
        case Integrations.Kiln.Action.ClaimFees:
          return kilnClaimFees;
        case Integrations.Kiln.Action.SweepEth:
          return kilnSweepEth;
        case Integrations.Kiln.Action.Unstake:
          return kilnUnstake;
        case Integrations.Kiln.Action.PausePosition:
        case Integrations.Kiln.Action.UnpausePosition:
          throw new Error(`Not implemented in the UI ${actionId} for Kiln staking.`);
        default: {
          throw new Error(`Not implemented in the UI ${actionId}`);
        }
      }

    case ExternalPositionType.LIDO_WITHDRAWALS:
      switch (actionId as Integrations.Lido.Action) {
        case Integrations.Lido.Action.RequestWithdrawals:
          return lidoWithdrawalsRequestWithdrawal;
        case Integrations.Lido.Action.ClaimWithdrawals:
          return lidoWithdrawalsClaimWithdrawals;
        default:
          throw new Error(`Not implemented in the UI ${actionId}`);
      }

    case ExternalPositionType.LIQUITY_DEBT:
      switch (actionId as Integrations.Liquity.Action) {
        case Integrations.Liquity.Action.OpenTrove:
          return liquityOpenTrove;
        case Integrations.Liquity.Action.AddCollateral:
          return liquityAddCollateral;
        case Integrations.Liquity.Action.RemoveCollateral:
          return liquityRemoveCollateral;
        case Integrations.Liquity.Action.Borrow:
          return liquityBorrow;
        case Integrations.Liquity.Action.RepayBorrow:
          return liquityRepay;
        case Integrations.Liquity.Action.CloseTrove:
          return liquityCloseTrove;
        case Integrations.Liquity.Action.ClaimCollateral:
          return liquityClaimColletral;
        default:
          throw new Error(`Not implemented in the UI ${actionId}`);
      }

    case ExternalPositionType.MAPLE_LIQUIDITY:
      switch (actionId as Integrations.Maple.Action) {
        case Integrations.Maple.Action.LendV2:
          return mapleLend;
        case Integrations.Maple.Action.RequestRedeemV2:
          return mapleRequestRedeem;
        case Integrations.Maple.Action.RedeemV2:
          return mapleRedeem;
        case Integrations.Maple.Action.CancelRedeemV2:
          return mapleCancelRedeem;
        case Integrations.Maple.Action.ClaimRewardsV1:
          return mapleClaimRewards;
        default:
          throw new Error(`Not implemented in the UI ${actionId}`);
      }

    case ExternalPositionType.CONVEX_VOTING:
      switch (actionId as Integrations.Convex.Action) {
        case Integrations.Convex.Action.Lock:
          return convexVotingLock;
        case Integrations.Convex.Action.Relock:
          return convexVotingRelock;
        case Integrations.Convex.Action.Withdraw:
          return convexVotingWithdraw;
        case Integrations.Convex.Action.ClaimRewards:
          return convexVotingClaimRewards;
        case Integrations.Convex.Action.Delegate:
          return convexVotingDelegate;
        default:
          throw new Error(`Not implemented in the UI ${actionId}`);
      }

    case ExternalPositionType.THEGRAPH_DELEGATION:
      switch (actionId as Integrations.TheGraph.Action) {
        case Integrations.TheGraph.Action.Delegate:
          return theGraphDelegationDelegate;
        case Integrations.TheGraph.Action.Undelegate:
          return theGraphDelegationUndelegate;
        case Integrations.TheGraph.Action.Withdraw:
          return theGraphDelegationWithdraw;
        default:
          throw new Error(`Not implemented in the UI ${actionId}`);
      }

    case ExternalPositionType.STAKEWISE_V3:
      switch (actionId as Integrations.StakeWiseV3.Action) {
        case Integrations.StakeWiseV3.Action.Stake:
          return stakeWiseStake;
        case Integrations.StakeWiseV3.Action.Redeem:
          return stakeWiseRedeem;
        case Integrations.StakeWiseV3.Action.EnterExitQueue:
          return stakeWiseEnterExitQueue;
        case Integrations.StakeWiseV3.Action.ClaimExitedAssets:
          return stakeWiseClaimExitedAssets;
        default:
          throw new Error(`Not implemented in the UI ${actionId}`);
      }

    case ExternalPositionType.PENDLE_V2:
      switch (actionId as Integrations.PendleV2.Action) {
        case Integrations.PendleV2.Action.BuyPrincipalToken:
          return buyPrincipalToken;
        case Integrations.PendleV2.Action.SellPrincipalToken:
          return sellPrincipalToken;
        case Integrations.PendleV2.Action.AddLiquidity:
          return addLiquidity;
        case Integrations.PendleV2.Action.RemoveLiquidity:
          return removeLiquidity;
        case Integrations.PendleV2.Action.ClaimRewards:
          return claimRewards;
        default:
          throw new Error(`Not implemented in the UI ${actionId}`);
      }

    case ExternalPositionType.UNKNOWN:
    case ExternalPositionType.NOTIONAL_V2:
    case undefined: // External positions are sometimes temporary undefined, when external position types are not loaded yet. There is some race condition in the UI.
      return getDefaultExternalPositionHandler(externalPositionProxy, actionId);
    default:
      Utils.Assertion.never(
        externalPositionType,
        `getExternalPosition: Couldn't find an integration for external type ${externalPositionType}`,
      );
  }
}

function getDefaultExternalPositionHandler(
  externalPositionProxy: Address,
  actionId: bigint,
): ExternalPositionHandler<CallOnExternalPositionArgs> {
  return {
    Description({ args }) {
      return (
        <Textarea
          label="Raw args"
          id="default-integration-handler"
          readOnly={true}
          rows={8}
          value={safeStringifyJSON(args, 2)}
        />
      );
    },
    Label() {
      return <>Interact with Integrated Protocol</>;
    },
    decodeExternalPositionArgs: (actionArgs) => ({ actionArgs, actionId, externalPositionProxy }),
  };
}
