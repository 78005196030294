import { Utils } from "@enzymefinance/sdk";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useVaultDetailsQuery } from "queries/core";
import type { Inputs } from "../functionsTypes";

interface IsAllowedGasRelayerCallProps {
  args?: Inputs;
  contractAddress?: string;
  functionName?: string;
  vaultProxy?: string;
}

// Checks if a contract call is allowed to be relayed using the gas paymaster
// See https://github.com/avantgardefinance/protocol/blob/f4ef4c21d150b22414759f10b03b108c3046928b/contracts/release/infrastructure/gas-relayer/GasRelayPaymasterLib.sol#L205-L246

export function useIsRelayableCall({
  args,
  contractAddress,
  functionName,
  vaultProxy,
}: IsAllowedGasRelayerCallProps): boolean {
  const { contracts } = useGlobals();
  const { data: vaultDetails } = useVaultDetailsQuery({
    skip: !vaultProxy,
    variables: { id: vaultProxy?.toLowerCase() ?? "" },
  });
  const comptrollerProxy = vaultDetails?.vault?.comptroller.id;
  const fundDeployer = vaultDetails?.vault?.release.id;

  const policyManager = contracts.PolicyManager;

  if (Utils.Address.safeSameAddress(vaultProxy, contractAddress)) {
    // All calls to the VaultProxy are allowed
    return true;
  }

  if (Utils.Address.safeSameAddress(contractAddress, comptrollerProxy)) {
    if (
      functionName === "callOnExtension" ||
      functionName === "vaultCallOnContract" ||
      functionName === "buyBackProtocolFeeShares" ||
      functionName === "depositToGasRelayPaymaster" ||
      functionName === "setAutoProtocolFeeSharesBuyback"
    ) {
      return true;
    }
  } else if (Utils.Address.safeSameAddress(contractAddress, policyManager)) {
    if (
      functionName === "updatePolicySettingsForFund" ||
      functionName === "enablePolicyForFund" ||
      functionName === "disablePolicyForFund"
    ) {
      return Utils.Address.safeSameAddress(args?.[0], comptrollerProxy);
    }
  } else if (Utils.Address.safeSameAddress(contractAddress, fundDeployer)) {
    if (
      functionName === "createReconfigurationRequest" ||
      functionName === "executeReconfiguration" ||
      functionName === "cancelReconfiguration"
    ) {
      return Utils.Address.safeSameAddress(args?.[0], vaultProxy);
    }
  }

  return false;
}
