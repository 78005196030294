import type { InlineLinkProps as InlineLinkPropsBase } from "@enzymefinance/ui";
import { InlineLink as InlineLinkBase } from "@enzymefinance/ui";
import { useErrorBoundary } from "components/error/ErrorBoundary";
import { useCallback } from "react";
import type { LinkProps as RouterLinkProps } from "react-router-dom";
import { Link as RouterLink, useLocation } from "react-router-dom";

function useResetError(onClick: LinkProps["onClick"]) {
  const { resetError } = useErrorBoundary();

  const handleClick = useCallback<NonNullable<LinkProps["onClick"]>>(
    (event) => {
      resetError?.();
      onClick?.(event);
    },
    [onClick, resetError],
  );

  return handleClick;
}

function useLinkProps({
  onClick: onClickBase,
  to: toBase,
  ...props
}: LinkProps): Omit<LinkProps, "to"> & { to: { pathname?: string; search?: string } } {
  const onClick = useResetError(onClickBase);
  const location = useLocation();

  if (typeof toBase === "string") {
    const [pathname, search] = toBase.split("?");
    const isExternal = toBase.startsWith("http");
    const isNonHttpProtocol = toBase.startsWith("tel") || toBase.startsWith("mailto");

    if (isExternal || isNonHttpProtocol) {
      return { onClick, to: { pathname, search: search === undefined ? location.search : `?${search}` }, ...props };
    }

    return {
      onClick,
      to: {
        pathname,
        search: search === undefined ? location.search : `?${search}`,
      },
      ...props,
    };
  }

  return {
    onClick,
    to: { pathname: toBase.pathname, search: toBase.search === undefined ? location.search : `?${toBase.search}` },
    ...props,
  };
}

export type LinkProps = RouterLinkProps;

export function Link(propsBase: LinkProps) {
  const { children, replace, state, to, ...props } = useLinkProps(propsBase);

  if (to.pathname?.startsWith("http")) {
    return (
      <a href={`${to.pathname}${to.search ?? ""}`} rel="noreferrer" target="_blank" {...props}>
        {children}
      </a>
    );
  }

  if (to.pathname?.startsWith("mailto") || to.pathname?.startsWith("tel")) {
    return (
      <a href={`${to.pathname}${to.search ?? ""}`} {...props}>
        {children}
      </a>
    );
  }

  return (
    <RouterLink to={to} replace={replace} state={state} {...props}>
      {children}
    </RouterLink>
  );
}

export type InlineLinkProps = Omit<InlineLinkPropsBase<RouterLinkProps>, "as">;

export function InlineLink(propsBase: InlineLinkProps) {
  const { to, ...props } = useLinkProps(propsBase);

  return <InlineLinkBase as={Link} to={`${to.pathname}${to.search ?? ""}`} {...props} />;
}
