import { Container } from "@enzymefinance/ui";
import classNames from "classnames";
import { useMobileMenu } from "components/menu/MobileMenuProvider";
import { AnimatePresence } from "framer-motion";
import type { ReactNode } from "react";
import { useHeaderBanner } from "utils/hooks/useHeaderBanner";

interface NavigationProps {
  children?: ReactNode;
  className?: string;
}

export function Navbar({ children, className }: NavigationProps) {
  const headerBanner = useHeaderBanner();
  const { isOpen } = useMobileMenu();

  const wrapperClasses = classNames("z-20 flex-none", { "shadow-lg": isOpen }, className);
  const navbarClasses = classNames(
    "bg-white/50 dark:bg-gray-800/75 shadow-sm overflow-hidden lg:overflow-visible backdrop-filter backdrop-blur transition",
  );

  return (
    <div className={wrapperClasses}>
      {/* Banners */}
      {headerBanner === null ? null : <AnimatePresence>{headerBanner}</AnimatePresence>}

      {/* Navbar */}
      <header className={navbarClasses}>
        <Container padded={true}>{children}</Container>
      </header>
    </div>
  );
}
