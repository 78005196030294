import { BigIntDisplay, parseUnits } from "@enzymefinance/ethereum-ui";
import { SectionHeading, Toggle, Tooltip } from "@enzymefinance/ui";
import { useGlobals } from "components/providers/GlobalsProvider";
import { InlineLink } from "components/routing/Link";

import { gasRelayerMaxAmount } from "../../utils/constants.js";
import { useNetwork } from "../providers/NetworkProvider.js";

interface GasRelayerOptionsProps {
  gasRelayerBalance: bigint;
  topUpGasRelayer: boolean;
  vaultProxy?: string;
  onToggleClick: () => void;
  useGasRelayer: boolean;
}

export function GasRelayerOptions({
  gasRelayerBalance,
  vaultProxy,
  onToggleClick,
  useGasRelayer,
}: GasRelayerOptionsProps) {
  const { environment } = useGlobals();
  const { network } = useNetwork();

  const { nativeTokenWrapper } = environment.namedTokens;

  return (
    <div className="space-y-4">
      <div className="flex justify-between">
        <div>
          <Tooltip label={<SectionHeading.Subtitle>Use Gas Relayer</SectionHeading.Subtitle>}>
            The gas relayer allows you to pay gas from the vault.
          </Tooltip>
          <p>
            Gas Relayer Balance:{" "}
            <BigIntDisplay
              numberFormat={{ currency: environment.network.currency.nativeToken.symbol }}
              value={gasRelayerBalance}
            />{" "}
          </p>
          {vaultProxy &&
            gasRelayerBalance < parseUnits(gasRelayerMaxAmount[network], nativeTokenWrapper.decimals) / 5n && (
              <p>
                <InlineLink to={`/vault/${vaultProxy}/settings/fees`}>Top up</InlineLink>
              </p>
            )}
        </div>
        <Toggle altText="Use Gas Relayer" on={useGasRelayer} onClick={onToggleClick} />
      </div>
    </div>
  );
}
