import classNames from "classnames";
import type { ComponentPropsWithoutRef, ReactElement } from "react";

import { ScreenReaderText } from "../typography/ScreenReaderText.js";

// Table
export interface TableProps extends ComponentPropsWithoutRef<"table"> {
  fallback?: ReactElement | null;
}

export function Table({ className, fallback, ...props }: TableProps) {
  const classes = classNames("w-full", className);
  const wrapperClasses = classNames(
    "shadow border-t border-b sm:border-l sm:border-r border-transparent sm:rounded-t-2xl overflow-hidden",
    { "sm:rounded-b-2xl": !fallback },
  );

  return (
    <div className="flex flex-col lg:relative">
      <div className="scrollbar-hide -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className={wrapperClasses}>
            <table className={classes} {...props} />
          </div>
          {fallback}
        </div>
      </div>
    </div>
  );
}

// Table Head
export type TableHeadProps = ComponentPropsWithoutRef<"thead">;

function tableHead({ className, ...props }: TableHeadProps) {
  const classes = classNames("bg-base-200", className);

  return <thead className={classes} {...props} />;
}

Table.Head = tableHead;

// Table Header Cell
export interface TableHeaderCellProps extends NonNullable<ComponentPropsWithoutRef<"th">> {
  action?: "default" | "hidden";
  align?: "center" | "left" | "right";
}

function tableHeaderCell({ className, align = "left", action, scope = "col", ...props }: TableHeaderCellProps) {
  const classes = classNames(
    "flex items-stretch relative p-0",
    {
      "justify-center text-center": align === "center",
      "justify-end text-right": align === "right",
      "justify-start text-left": align === "left",
      "sr-only": action === "hidden",
      "text-base-content text-xs font-medium uppercase tracking-wider": action !== "hidden",
    },
    className,
  );

  return <th scope={scope} className={classes} {...props} />;
}

Table.HeaderCell = tableHeaderCell;

// Table Body
export type TableBodyProps = ComponentPropsWithoutRef<"tbody">;

function tableBody({ className, ...props }: TableBodyProps) {
  const classes = classNames("bg-base-300 divide-y divide-base-200 border-t border-base-200", className);

  return <tbody className={classes} {...props} />;
}

Table.Body = tableBody;

// Table Data Cell
interface TableDataCellProps extends NonNullable<ComponentPropsWithoutRef<"td">> {
  action?: "default" | "hidden";
  align?: "center" | "left" | "right";
}

function tableDataCell({ action, align = "left", className, ...props }: TableDataCellProps) {
  const classes = classNames(
    "flex items-center text-sm font-medium",
    {
      "justify-center text-center": align === "center",
      "justify-end text-right": align === "right",
      "justify-start text-left": align === "left",
      "pr-6 py-4": action === "hidden",
      "px-6 py-4": action !== "hidden",
    },
    className,
  );

  return <td className={classes} {...props} />;
}

Table.DataCell = tableDataCell;

// Table Row
interface TableRowProps extends NonNullable<ComponentPropsWithoutRef<"tr">> {
  /**
   * Set to "default" if the row is not supposed to be clickable but has some actions on the right hand side.
   *
   * Set to "hidden" if the row is supposed to be clickable
   */
  action?: "default" | "hidden";
  disabled?: boolean;
}

function tableRow({ action, children, className, disabled = false, ...props }: TableRowProps) {
  const classes = classNames(
    "group",
    {
      "hover:bg-base-200 transition": !disabled,
      "focus-within:bg-base-200 dark:focus-within:bg-base-200": !disabled && action !== "hidden",
      "focus-within:bg-primary dark:focus-within:bg-primary": !disabled && action === "hidden",
      relative: (!disabled && action === "hidden") || disabled,
    },
    className,
  );

  return (
    <tr className={classes} {...props}>
      {disabled ? <td className="bg-base-100 absolute inset-0 z-10 opacity-75" /> : null}
      {children}
    </tr>
  );
}

Table.Row = tableRow;

// Table Caption
type TableCaptionProps = ComponentPropsWithoutRef<"caption">;

function tableCaption({ children, ...props }: TableCaptionProps) {
  return (
    <ScreenReaderText as="caption" {...props}>
      {children}
    </ScreenReaderText>
  );
}

Table.Caption = tableCaption;
