import { Policy } from "@enzymefinance/sdk/Configuration";
import { address, bigint } from "@enzymefinance/validation";
import { z } from "zod";
import { createUseValidatedClientQuery } from "./useValidatedQuery";

export const usePricelessAssetBypassTimeLimit = createUseValidatedClientQuery(
  ["cumulative-slippage-policy-asset-bypass-time-limit"],
  {
    paramsSchema: z.object({
      policy: address({ allowZeroAddress: false }),
    }),
    responseSchema: bigint(),
    queryFn: (params, client) =>
      Policy.getPricelessAssetBypassTimeLimit(client, {
        policy: params.policy,
      }),
  },
);
