import { Utils } from "@enzymefinance/sdk";
import { useTransactionManagerTransactions } from "components/transactions/manager/TransactionManager";
import { useMemo } from "react";
import { decodeTransactionData } from "utils/functions";
import type { Address } from "viem";
import { TransactionSigHashes } from "../../components/transactions/TransactionFunctionTypes";

export function useApproveTransactions(spender: string) {
  const managerTransactions = useTransactionManagerTransactions();

  const transactions = useMemo(
    () =>
      managerTransactions.filter((transaction) => {
        const { data } = transaction.state.context;
        const metadata = decodeTransactionData(Utils.Hex.asHex(data));

        if (!metadata) {
          return false;
        }

        const isApprove = metadata.sigHash === TransactionSigHashes.APPROVE;
        const approveSpender: Address | undefined = isApprove ? metadata.inputs.spender ?? undefined : undefined;
        const isSpender = Utils.Address.safeSameAddress(spender, approveSpender?.toString());

        return isApprove && isSpender;
      }),
    [managerTransactions, spender],
  );

  const isSubmitting = transactions.some((transaction) => transaction.state.matches("submitting"));
  const isPending = transactions.some((transaction) => transaction.state.matches("pending"));

  return {
    isPending,
    isSubmitting,
    transactions,
  };
}
