import type { Address } from "@enzymefinance/environment";
import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { useGlobals } from "components/providers/GlobalsProvider";
import { decodeAbiParameters, parseAbiParameters } from "viem";
import type { IntegrationHandler } from "./types";

interface SynthetixTakeOrderArgs {
  incomingAsset: Address;
  minIncomingAssetAmount: bigint;
  outgoingAsset: Address;
  outgoingAssetAmount: bigint;
}

export const synthetix: IntegrationHandler<SynthetixTakeOrderArgs> = {
  Description({ args: { incomingAsset, minIncomingAssetAmount, outgoingAsset, outgoingAssetAmount } }) {
    const { environment } = useGlobals();

    const sellAsset = environment.getAsset(outgoingAsset);
    const buyAsset = environment.getAsset(incomingAsset);

    return (
      <>
        Swap tokens on Synthetix (selling{" "}
        <BigIntDisplay
          value={outgoingAssetAmount}
          decimals={sellAsset.decimals}
          numberFormat={{ currency: sellAsset.symbol }}
        />
        , buying at least{" "}
        <BigIntDisplay
          value={minIncomingAssetAmount}
          decimals={buyAsset.decimals}
          numberFormat={{ currency: buyAsset.symbol }}
        />
        )
      </>
    );
  },
  Label() {
    return <>Swap on Synthetix</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => {
    const [incomingAsset, minIncomingAssetAmount, outgoingAsset, outgoingAssetAmount] = decodeAbiParameters(
      parseAbiParameters("address, uint256, address, uint256"),
      encodedCallArgs,
    );

    return { incomingAsset, minIncomingAssetAmount, outgoingAsset, outgoingAssetAmount };
  },
};
