import { Tools } from "@enzymefinance/sdk";
import { address, bigint } from "@enzymefinance/validation";
import { z } from "zod";
import { createUseValidatedClientQuery } from "./useValidatedQuery";

export const useDepositQueueUserRequest = createUseValidatedClientQuery(
  ["address", "depositAssetAddress", "signerAddress"],
  {
    paramsSchema: z.object({
      address: address({ allowZeroAddress: false }).optional(),
      depositAssetAddress: address({ allowZeroAddress: false }),
      signerAddress: address({ allowZeroAddress: false }),
    }),
    responseSchema: bigint(),
    queryFn: async (params, client) => {
      if (!params.address) {
        return 0n;
      }

      const result = await Tools.GatedRedemptionQueueSharesWrapper.getDepositQueueUser(client, {
        sharesWrapper: params.address,
        depositAsset: params.depositAssetAddress,
        user: params.signerAddress,
      });

      return result.assetAmount;
    },
  },
);
