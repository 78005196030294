import type { Asset, Currency } from "@enzymefinance/environment";
import type { BaseSelectProps, MultiValueProps } from "@enzymefinance/select";
import { BaseSelect, components, sharedComponents } from "@enzymefinance/select";
import type { FieldGroupProps, Option } from "@enzymefinance/ui";
import { FieldGroup, useFieldGroup } from "@enzymefinance/ui";
import classNames from "classnames";

import { TokenLabel } from "../token-label/TokenLabel.js";

export type TokenSelectOption = Asset & { balance?: number; currency?: Currency; value?: number };

const customComponents: NonNullable<TokenSelectProps["components"]> = {
  MultiValue(props) {
    return (
      <sharedComponents.MultiValue {...(props as unknown as MultiValueProps<Option>)}>
        <TokenLabel asset={props.data} size={5} hideName={true} />
      </sharedComponents.MultiValue>
    );
  },
  SingleValue(props) {
    const { error } = useFieldGroup();
    const classes = classNames("absolute inset-0 flex items-center truncate", {
      "text-red-900 dark:text-red-300": error,
    });

    return (
      <components.SingleValue {...props} className={classes}>
        <TokenLabel asset={props.data} size={5} hideName={true} />
      </components.SingleValue>
    );
  },
};

export type BaseTokenSelectProps<TMulti extends boolean = boolean> = BaseSelectProps<TokenSelectOption, TMulti>;

export function BaseTokenSelect<TMulti extends boolean = boolean>({
  itemSize = 56,
  placeholder,
  ...props
}: TokenSelectProps<TMulti>) {
  return (
    <BaseSelect<TokenSelectOption, TMulti>
      filterOption={(option, rawInput) => {
        const input = rawInput.toLowerCase();

        if (input.length === 0) {
          return true;
        }

        // Filterable by symbol, name or id
        return (
          option.data.id.toLowerCase() === input ||
          option.data.symbol.toLowerCase().includes(input) ||
          option.data.name.toLowerCase().includes(input)
        );
      }}
      formatOptionLabel={(option) => <TokenLabel asset={option} size={9} kind="option" />}
      getOptionLabel={(option) => option.symbol}
      getOptionValue={(option) => option.id}
      itemSize={itemSize}
      noOptionsMessage={() => "No tokens found"}
      placeholder={placeholder ?? (props.isSearchable ? "Find a token by name or address" : undefined)}
      {...props}
      components={{ ...customComponents, ...props.components } as TokenSelectProps<TMulti>["components"]}
    />
  );
}

export type TokenSelectProps<TMulti extends boolean = boolean> = BaseSelectProps<TokenSelectOption, TMulti> &
  Omit<FieldGroupProps, "kind">;

export function TokenSelect<TMulti extends boolean = boolean>({
  cornerHint,
  error,
  description,
  itemSize = 56,
  isSearchable = true,
  label,
  labelHidden = false,
  ...props
}: TokenSelectProps<TMulti>) {
  return (
    <FieldGroup
      cornerHint={cornerHint}
      error={error}
      description={description}
      label={label}
      labelHidden={labelHidden}
      id={isSearchable ? `react-select-${props.id}-input` : props.id}
    >
      <BaseTokenSelect<TMulti> isSearchable={isSearchable} itemSize={itemSize} label={label} {...props} />
    </FieldGroup>
  );
}
