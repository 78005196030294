import { ProtocolSelect, useFormContext } from "@enzymefinance/hook-form";
import { Policies } from "@enzymefinance/sdk/Configuration";
import { Badge, ErrorMessage, RadioGroup } from "@enzymefinance/ui";
import { externalPosition } from "@enzymefinance/validation";
import type { ReactNode } from "react";
import { useCallback, useMemo } from "react";
import { useExternalPositionOptions } from "utils/hooks/useExternalPositionOptions";
import { z } from "zod";
import { InlineLink } from "../../../routing/Link";
import { VaultConfigFieldName } from "../VaultConfig";
import type { AllowedExternalPositionTypesPolicySettings } from "../VaultConfigSettingsTypes";
import type { VaultConfig, VaultConfigDisplayProps, VaultConfigDisplaySubgraphProps } from "../VaultConfigTypes";
import { VaultConfigPolicyListOption, VaultConfigType } from "../VaultConfigTypes";
import { PolicyExternalPositionList } from "./PolicyExternalPositionList";

export const allowedExternalPositionTypesPolicySchema = z
  .object({
    items: z.array(externalPosition()),
    isDisallowAll: z.boolean().optional(),
  })
  .refine(
    (value) => {
      return value.isDisallowAll || value.items.length > 0;
    },
    {
      message: 'Please specify some items or choose "Disallow All"',
      path: ["items"],
    },
  )
  .optional();

interface AllowedExternalPositionTypesPolicyFormFieldsValues {
  [VaultConfigFieldName.ALLOWED_EXTERNAL_POSITION_TYPES_POLICY]?: Partial<AllowedExternalPositionTypesPolicySettings>;
}

function allowedExternalPositionTypesPolicyFormFields() {
  const { getFieldState, setValue, watch } = useFormContext<AllowedExternalPositionTypesPolicyFormFieldsValues>();
  const options = useExternalPositionOptions();
  const { error } = getFieldState(VaultConfigFieldName.ALLOWED_EXTERNAL_POSITION_TYPES_POLICY);
  const [value] = watch([VaultConfigFieldName.ALLOWED_EXTERNAL_POSITION_TYPES_POLICY]) as [
    AllowedExternalPositionTypesPolicyFormFieldsValues[VaultConfigFieldName.ALLOWED_EXTERNAL_POSITION_TYPES_POLICY],
  ];
  const isDisallowAll = !!value?.isDisallowAll;

  const handleSelectListOption = useCallback(
    ({ value: newValue }: ListSelectOption) => {
      const newFieldValue: AllowedExternalPositionTypesPolicySettings = {
        isDisallowAll: newValue === VaultConfigPolicyListOption.DISALLOW_ALL,
        items: newValue === VaultConfigPolicyListOption.DISALLOW_ALL ? [] : value?.items ?? [],
      };

      setValue(VaultConfigFieldName.ALLOWED_EXTERNAL_POSITION_TYPES_POLICY, newFieldValue, { shouldValidate: true });
    },
    [setValue, value?.items],
  );

  const listSelectOptions: ListSelectOption[] = useMemo(
    () => [
      {
        value: VaultConfigPolicyListOption.CUSTOM_LIST,
        label: allowedExternalPositionTypesPolicy.label,
        description: isDisallowAll ? (
          <></>
        ) : (
          <ProtocolSelect
            isExpandable={true}
            isMulti={true}
            label={allowedExternalPositionTypesPolicy.label}
            labelHidden={true}
            name={`${VaultConfigFieldName.ALLOWED_EXTERNAL_POSITION_TYPES_POLICY}.items`}
            options={options}
          />
        ),
      },
      {
        value: VaultConfigPolicyListOption.DISALLOW_ALL,
        label: "Disallow all External Positions",
        description: <p className="text-sm">This setting cannot be changed later.</p>,
      },
    ],
    [options, isDisallowAll],
  );

  const selectedListOption = useMemo(
    () =>
      isDisallowAll
        ? listSelectOptions.find((option) => option.value === VaultConfigPolicyListOption.DISALLOW_ALL)
        : listSelectOptions.find((option) => option.value === VaultConfigPolicyListOption.CUSTOM_LIST),
    [isDisallowAll, listSelectOptions],
  );

  return (
    <div className="space-y-4">
      <RadioGroup<ListSelectOption>
        id={VaultConfigFieldName.ALLOWED_EXTERNAL_POSITION_TYPES_POLICY}
        value={selectedListOption}
        onChange={handleSelectListOption}
        label={allowedExternalPositionTypesPolicy.label}
        labelHidden={true}
        optionRenderer={(option) => (
          <div className="space-y-2">
            <p className="text-heading-content text-sm font-medium">{option.label}</p>
            {option.description}
          </div>
        )}
        options={listSelectOptions}
      />
      {typeof error?.message === "string" ? <ErrorMessage>{error.message}</ErrorMessage> : null}
    </div>
  );
}

function allowedExternalPositionTypesPolicyDisplay({
  settings,
}: VaultConfigDisplayProps<VaultConfigType.ALLOWED_EXTERNAL_POSITION_TYPES_POLICY>) {
  return <PolicyExternalPositionList ids={settings.items.map((item) => item.externalPositionIdentifier.toString())} />;
}

function allowedExternalPositionTypesPolicyDisplaySubgraph({
  settings,
}: VaultConfigDisplaySubgraphProps<VaultConfigType.ALLOWED_EXTERNAL_POSITION_TYPES_POLICY>) {
  return <PolicyExternalPositionList ids={settings.externalPositionTypes} />;
}

export const allowedExternalPositionTypesPolicy: VaultConfig<VaultConfigType.ALLOWED_EXTERNAL_POSITION_TYPES_POLICY> = {
  address: (contracts) => contracts.AllowedExternalPositionTypesPolicy,
  disableable: false,
  display: allowedExternalPositionTypesPolicyDisplay,
  displaySubgraph: allowedExternalPositionTypesPolicyDisplaySubgraph,
  editable: false,
  encode: (settings) =>
    Policies.AllowedExternalPositionTypes.encodeSettings({
      externalPositionTypeIds: settings.items.map((item) => BigInt(item.externalPositionIdentifier)),
    }),
  fetch: async () => undefined,
  formFields: allowedExternalPositionTypesPolicyFormFields,
  formInitialValues: { isDisallowAll: false, items: [] },
  label: "Limit External Positions To A Specified List",
  managerDescription: (
    <div className="space-y-4">
      <p>
        Restricts the protocols with which a vault can interact using Enzyme&apos;s external position contract
        architecture. External positions are similar to adapters, and allow an Enzyme vault to interact with a new
        subset of DeFi protocols with different considerations. For more details on external positions, please see the{" "}
        <InlineLink to="https://specs.enzyme.finance/topics/external-positions" appearance="link">
          Protocol Documentation
        </InlineLink>
        . Currently borrowing on Compound is enabled by an external position.
      </p>
      <ol>
        <li>Toggle this setting off to freely interact with any external position type</li>
        <li>
          Toggle this setting on and select &apos;Disallow all External Positions&apos; to prohibit any interaction with
          external positions
        </li>
        <li>
          Toggle this setting on and add external position types to the list to allow your vault to interact with them
        </li>
      </ol>
      <Badge appearance="warning">Semi-permanent Setting</Badge>
    </div>
  ),
  type: VaultConfigType.ALLOWED_EXTERNAL_POSITION_TYPES_POLICY,
  validationSchema: allowedExternalPositionTypesPolicySchema,
};

interface ListSelectOption {
  description: ReactNode;
  label: string;
  value: string;
}
