import { ExclamationCircleIcon } from "@enzymefinance/icons/solid";
import type { NumberDisplayProps, NumberInputMaxButtonProps } from "@enzymefinance/ui";
import {
  FieldGroup,
  Icon,
  NumberDisplay,
  NumberInputMaxButton,
  Skeleton,
  Tooltip,
  useFieldGroup,
  useMotionPresets,
} from "@enzymefinance/ui";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import type { ReactElement } from "react";
import { createContext, useCallback, useContext, useState } from "react";
import { useLatest } from "react-use";
import { parseUnits } from "viem";
import { useFormatBigInt } from "../../hooks/useFormatBigInt.js";
import type { TokenBigIntInputValue } from "../../types.js";
import type { BigIntDisplayProps } from "../big-int-display/BigIntDisplay.js";
import { BigIntDisplay } from "../big-int-display/BigIntDisplay.js";
import type { BigIntInputProps } from "../big-int-input/BigIntInput.js";
import { BigIntInput } from "../big-int-input/BigIntInput.js";
import { TokenLabel } from "../token-label/TokenLabel.js";
import type { TokenPickerProps } from "../token-picker/TokenPicker.js";
import { BaseTokenPicker as TokenPicker } from "../token-picker/TokenPicker.js";

interface TokenBigIntInputContextValues {
  error?: string[] | boolean | string;
  isError: boolean;
  onChange?: (value: TokenBigIntInputValue) => void;
  readOnly?: boolean;
  value?: TokenBigIntInputValue;
}

const TokenBigIntInputContext = createContext<TokenBigIntInputContextValues | undefined>(undefined);

function useTokenInput() {
  const context = useContext(TokenBigIntInputContext);

  if (!context) {
    throw new Error("Missing token input context");
  }

  return context;
}

export interface BaseTokenBigIntInputProps
  extends Pick<
      BigIntInputProps,
      | "disabled"
      | "error"
      | "id"
      | "label"
      | "labelHidden"
      | "maxLength"
      | "numberFormat"
      | "onBlur"
      | "onFocus"
      | "readOnly"
    >,
    Pick<TokenPickerProps, "kind"> {
  isClearable?: TokenPickerProps["isClearable"];
  loading?: boolean;
  tokens?: TokenPickerProps["options"];
}

export function BaseTokenBigIntInput({
  id,
  isClearable = false,
  kind,
  loading = false,
  onBlur: onBlurBase,
  onFocus: onFocusBase,
  tokens,
  ...props
}: BaseTokenBigIntInputProps) {
  const { ariaProps } = useFieldGroup();
  const motionPresets = useMotionPresets();
  const [focus, setFocus] = useState(false);
  const { error, isError, onChange: onChangeBase, value } = useTokenInput();
  const onBlur = useCallback<NonNullable<TokenBigIntInputProps["onBlur"]>>(
    (event) => {
      setFocus(false);
      onBlurBase?.(event);
    },
    [onBlurBase],
  );

  const onFocus = useCallback<NonNullable<TokenBigIntInputProps["onFocus"]>>(
    (event) => {
      setFocus(true);
      onFocusBase?.(event);
    },
    [onFocusBase],
  );

  const valueRef = useLatest(value);
  const onTokenChange = useCallback<NonNullable<TokenPickerProps["onChange"]>>(
    (token) => {
      if (valueRef.current?.token === token) {
        return;
      }

      onChangeBase?.({
        token: token ?? undefined,
        value:
          !props.readOnly && valueRef.current?.value && typeof token?.decimals === "number"
            ? (valueRef.current.value * parseUnits("1", token.decimals)) /
              parseUnits("1", valueRef.current.token?.decimals ?? 18)
            : valueRef.current?.value,
      });
    },
    [valueRef, onChangeBase, props.readOnly],
  );

  const onValueChange = useCallback<NonNullable<BigIntInputProps["onValueChange"]>>(
    (values) => {
      if (valueRef.current?.value === (values.bigIntValue ?? 0)) {
        return;
      }

      onChangeBase?.({ token: valueRef.current?.token, value: values.bigIntValue });
    },
    [onChangeBase, valueRef],
  );

  const classes = classNames("flex items-center space-x-2 bg-base-300 border rounded-lg p-2 transition", {
    "border-red-300 dark:border-red-700": !focus && isError,
    "border-red-500 dark:border-red-500 ring-red-500 dark:ring-red-500": focus && isError,
    "border-transparent dark:border-transparent": !(focus || isError),
    "border-primary dark:border-primary ring-primary dark:ring-primary": focus && !isError,
    "ring-1": focus,
  });

  const inputClasses = classNames(
    "block w-full bg-transparent border-none focus:ring-0 text-xl font-semibold py-0 px-1",
    {
      "text-heading-content placeholder-gray-400 dark:placeholder-gray-500": !isError,
      "text-red-900 dark:text-red-300 placeholder-red-500 dark:placeholder-red-500": isError,
    },
  );

  return (
    <div className={classes}>
      {loading ? (
        <div className="flex min-w-0 flex-1 items-center">
          <Skeleton className="h-7 w-32" />
        </div>
      ) : (
        <>
          <BigIntInput
            className={inputClasses}
            customInput={null}
            decimals={value?.token?.decimals}
            id={id}
            onBlur={onBlur}
            onFocus={onFocus}
            onValueChange={onValueChange}
            value={value?.value}
            {...ariaProps}
            {...props}
            label={null}
          />
          <AnimatePresence>
            {isError ? (
              <motion.div {...motionPresets.scale}>
                <Tooltip.Provider>
                  <Tooltip.Item>
                    <Icon icon={ExclamationCircleIcon} className="text-error" aria-hidden={true} />
                  </Tooltip.Item>
                  <Tooltip.Panel>{error}</Tooltip.Panel>
                </Tooltip.Provider>
              </motion.div>
            ) : null}
          </AnimatePresence>
        </>
      )}
      {(tokens?.length ?? 0) > 0 ? (
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        tokens?.length === 1 && "id" in tokens[0]! ? (
          <div className="bg-base-400 text-heading-content inline-flex shrink-0 items-center justify-center rounded-lg border border-transparent px-4 py-2 font-medium">
            <TokenLabel size={5} asset={tokens[0]} hideName={true} />
          </div>
        ) : (
          <TokenPicker
            id={`${id}-picker`}
            kind={kind}
            isClearable={isClearable}
            onChange={onTokenChange}
            onBlur={onBlur}
            options={tokens}
            value={value?.token}
          >
            <TokenPicker.Button aria-labelledby={id} className="bg-base-400 group" appearance="quaternary">
              <span className="text-base-content group-hover:text-heading-content text-sm font-medium transition">
                Select a token
              </span>
            </TokenPicker.Button>
          </TokenPicker>
        )
      ) : null}
    </div>
  );
}

export interface TokenBigIntInputProps extends BaseTokenBigIntInputProps {
  balance?: bigint;
  localValue?: ReactElement | string;
  onChange?: (value: TokenBigIntInputValue) => void;
  value?: TokenBigIntInputValue;
  displayErrorLabel?: boolean;
}

export function TokenBigIntInput({
  balance,
  error,
  labelHidden = false,
  localValue,
  onChange,
  value,
  displayErrorLabel = true,
  ...props
}: TokenBigIntInputProps) {
  const isError = Array.isArray(error) ? error.length > 0 : error !== undefined && error !== false;

  return (
    <TokenBigIntInputContext.Provider value={{ error, isError, onChange, readOnly: props.readOnly, value }}>
      <FieldGroup
        cornerHint={
          typeof balance === "bigint" ? (
            <TokenBigIntInput.Balance
              decimals={value?.token?.decimals}
              numberFormat={{ currency: value?.token?.symbol }}
              value={balance}
            />
          ) : undefined
        }
        error={isError}
        description={localValue}
        label={props.label}
        labelHidden={labelHidden}
        id={props.id}
      >
        <BaseTokenBigIntInput {...props} />
      </FieldGroup>
      {displayErrorLabel && isError && typeof error !== "boolean" ? (
        <div className="pt-2">
          <p className="text-error text-sm">{error}</p>
        </div>
      ) : null}
    </TokenBigIntInputContext.Provider>
  );
}

export interface TokenBigIntInputBalanceProps extends BigIntDisplayProps {
  showMaxButton?: boolean;
}

function tokenBigIntInputBalance({ showMaxButton = true, ...props }: TokenBigIntInputBalanceProps) {
  const { onChange, readOnly, value } = useTokenInput();
  const input = useFormatBigInt(value?.value, value?.token?.decimals);
  const balance = useFormatBigInt(props.value, props.decimals);
  const handleMaxClick = useCallback<NonNullable<NumberInputMaxButtonProps["onClick"]>>(() => {
    if (props.value !== undefined) {
      onChange?.({ token: value?.token, value: props.value });
    }
  }, [onChange, props.value, value]);

  return (
    <span className="inline-flex max-w-full items-center space-x-2">
      <BigIntDisplay appearance="simple" numberFormat={{ currency: value?.token?.symbol }} {...props} />
      {/* No maxButton if field is readOnly */}
      {showMaxButton && !readOnly ? (
        <NumberInputMaxButton balance={balance.value} onClick={handleMaxClick} value={input.floatValue} />
      ) : null}
    </span>
  );
}

TokenBigIntInput.Balance = tokenBigIntInputBalance;

export type TokenBigIntInputLocalValueProps = Pick<
  NumberDisplayProps,
  "className" | "loading" | "numberFormat" | "value"
>;

function tokenBigIntInputLocalValue({ loading, ...props }: TokenBigIntInputLocalValueProps) {
  const { isError } = useTokenInput();
  const wrapperClasses = classNames("flex w-full", {
    "text-base-content": !isError,
    "text-red-900 dark:text-red-300": isError,
  });

  return (
    <span className={wrapperClasses}>
      <NumberDisplay
        appearance="simple"
        loading={loading === true ? <Skeleton className="h-5 w-16" /> : loading}
        {...props}
      />
    </span>
  );
}

TokenBigIntInput.LocalValue = tokenBigIntInputLocalValue;
