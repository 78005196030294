import classNames from "classnames";
import type { ComponentPropsWithoutRef, ComponentType, ReactNode } from "react";

import { Icon } from "./Icon.js";

export type BadgeAppearance = keyof typeof appearances;
export type BadgeSize = "lg" | "sm";

const appearances = {
  error: "bg-error text-error border border-error",
  info: "bg-info text-info border border-info",
  default: "bg-high-emphasis text-high-emphasis border border-high-emphasis",
  secondary: "bg-secondary text-secondary border border-secondary",
  success: "bg-success text-success border border-success",
  primary: "bg-primary text-primary border border-primary",
  warning: "bg-warning text-warning border border-warning",
};

export interface BadgeProps {
  "aria-hidden"?: boolean;
  children: ReactNode;
  className?: string;
  appearance?: BadgeAppearance;
  leadingIcon?: ComponentType<ComponentPropsWithoutRef<"svg">>;
  size?: BadgeSize;
  trailingIcon?: ComponentType<ComponentPropsWithoutRef<"svg">>;
}

export function Badge({
  children,
  className,
  appearance = "default",
  leadingIcon,
  trailingIcon,
  size = "sm",
  ...props
}: BadgeProps) {
  const classes = classNames(
    "bg-opacity-[16%] dark:bg-opacity-[16%] inline-flex items-center font-medium whitespace-nowrap rounded-full py-0.5",
    appearances[appearance],
    {
      "px-2.5 text-xs": size === "sm",
      "px-3 text-sm": size === "lg",
      "space-x-1": leadingIcon || trailingIcon,
    },
    className,
  );

  return (
    <span className={classes} {...props}>
      {leadingIcon ? <Icon icon={leadingIcon} size={3} /> : null}
      {children}
      {trailingIcon ? <Icon icon={trailingIcon} size={3} /> : null}
    </span>
  );
}
