import type { Address, PrimitiveAsset } from "@enzymefinance/environment";
import type { ReleaseContracts } from "@enzymefinance/utils";
import type {
  AllowedAdapterIncomingAssetsPolicyDetailsFragment,
  AllowedAdaptersPerManagerPolicyDetailsFragment,
  AllowedAdaptersPolicyDetailsFragment,
  AllowedAssetsForRedemptionPolicyDetailsFragment,
  AllowedDepositRecipientsPolicyDetailsFragment,
  AllowedExternalPositionTypesPerManagerPolicyDetailsFragment,
  AllowedExternalPositionTypesPolicyDetailsFragment,
  AllowedRedeemersForSpecificAssetsPolicyDetailsFragment,
  AllowedSharesTransferRecipientsPolicyDetailsFragment,
  CumulativeSlippageTolerancePolicyDetailsFragment,
  EntranceRateBurnFeeFragment,
  EntranceRateDirectFeeFragment,
  ExitRateBurnFeeFragment,
  ExitRateDirectFeeFragment,
  ManagementFeeFragment,
  MinAssetBalancesPostRedemptionPolicyDetailsFragment,
  MinMaxDepositPolicyDetailsFragment,
  MinSharesSupplyFeeFragment,
  NoDepegOnRedeemSharesForSpecificAssetsPolicyDetailsFragment,
  PerformanceFeeFragment,
  PolicyDetailsFragment,
  UnknownFeeFragment,
} from "queries/core";
import type { ElementType, ReactElement } from "react";
import type { SubsetOf } from "types/graphql";
import type { z } from "zod";

import type { Hex, PublicClient } from "viem";
import type {
  AllowedAdapterIncomingAssetsPolicySettings,
  AllowedAdaptersPerManagerPolicySettings,
  AllowedAdaptersPolicyAlertProps,
  AllowedAdaptersPolicySettings,
  AllowedAssetsForRedemptionPolicySettings,
  AllowedDepositRecipientsPolicySettings,
  AllowedExternalPositionTypesPerManagerPolicySettings,
  AllowedExternalPositionTypesPolicySettings,
  AllowedRedeemersForSpecificAssetsPolicySettings,
  AllowedSharesTransferRecipientsPolicySettings,
  CumulativeSlippageTolerancePolicySettings,
  EntranceBurnFeeSettings,
  EntranceDirectFeeSettings,
  ExitBurnFeeSettings,
  ExitDirectFeeSettings,
  ManagementFeeSettings,
  MinAssetBalancesPostRedemptionPolicySettings,
  MinMaxDepositPolicySettings,
  NoDepegOnRedeemSharesForSpecificAssetsPolicySettings,
  PerformanceFeeSettings,
} from "./VaultConfigSettingsTypes";

export enum VaultConfigType {
  // Fees
  MANAGEMENT_FEE = "MANAGEMENT_FEE",
  PERFORMANCE_FEE = "PERFORMANCE_FEE",
  ENTRANCE_BURN_FEE = "ENTRANCE_BURN_FEE",
  ENTRANCE_DIRECT_FEE = "ENTRANCE_DIRECT_FEE",
  EXIT_BURN_FEE = "EXIT_BURN_FEE",
  EXIT_DIRECT_FEE = "EXIT_DIRECT_FEE",
  MIN_SHARES_SUPPLY_FEE = "MIN_SHARES_SUPPLY_FEE",

  // Deposit policies
  ALLOWED_DEPOSIT_RECIPIENTS_POLICY = "ALLOWED_DEPOSIT_RECIPIENTS_POLICY",
  MIN_MAX_DEPOSIT_POLICY = "MIN_MAX_DEPOSIT_POLICY",

  // Shares transfer
  ALLOWED_SHARES_TRANSFER_RECIPIENTS_POLICY = "ALLOWED_SHARES_TRANSFER_RECIPIENTS_POLICY",

  // Redemption policies
  ALLOWED_ASSETS_FOR_REDEMPTION_POLICY = "ALLOWED_ASSETS_FOR_REDEMPTION_POLICY",
  ALLOWED_REDEEMERS_FOR_SPECIFIC_ASSETS_POLICY = "ALLOWED_REDEEMERS_FOR_SPECIFIC_ASSETS_POLICY",
  MIN_ASSET_BALANCES_POST_REDEMPTION_POLICY = "MIN_ASSET_BALANCES_POST_REDEMPTION_POLICY",
  NO_DEPEG_ON_REDEEM_SHARES_FOR_SPECIFIC_ASSETS_POLICY = "NO_DEPEG_ON_REDEEM_SHARES_FOR_SPECIFIC_ASSETS_POLICY",

  // Asset management policies
  ALLOWED_ADAPTER_INCOMING_ASSETS_POLICY = "ALLOWED_ADAPTER_INCOMING_ASSETS_POLICY",
  ALLOWED_ADAPTERS_PER_MANAGER_POLICY = "ALLOWED_ADAPTERS_PER_MANAGER_POLICY",
  ALLOWED_ADAPTERS_POLICY = "ALLOWED_ADAPTERS_POLICY",
  ALLOWED_EXTERNAL_POSITION_TYPES_PER_MANAGER_POLICY = "ALLOWED_EXTERNAL_POSITION_TYPES_PER_MANAGER_POLICY",
  ALLOWED_EXTERNAL_POSITION_TYPES_POLICY = "ALLOWED_EXTERNAL_POSITION_TYPES_POLICY",
  CUMULATIVE_SLIPPAGE_TOLERANCE_POLICY = "CUMULATIVE_SLIPPAGE_TOLERANCE_POLICY",
  ONLY_REMOVE_DUST_EXTERNAL_POSITION_POLICY = "ONLY_REMOVE_DUST_EXTERNAL_POSITION_POLICY",
  ONLY_UNTRACK_DUST_OR_PRICELESS_ASSETS_POLICY = "ONLY_UNTRACK_DUST_OR_PRICELESS_ASSETS_POLICY",

  // Other policies
  SHARES_ACTION_TIMELOCK = "SHARES_ACTION_TIMELOCK",

  // Unknown
  UNKNOWN_FEE = "UNKNOWN_FEE",
  UNKNOWN_POLICY = "UNKNOWN_POLICY",
}

export interface VaultConfigFormFieldsProps<TVaultConfigType extends VaultConfigType> {
  listOptions?: { action: "add" | "remove"; options: VaultConfigSettings[TVaultConfigType] };
}

export enum VaultConfigPolicyListOption {
  CUSTOM_LIST = "CUSTOM_LIST", // User provides a list of addresses to create a new list
  CUSTOM_LIST_ID = "CUSTOM_LIST_ID", // User provides a custom existing list ID
  DELEGATED = "DELEGATED", // User chooses an existing council-maintained list
  DISALLOW_ALL = "DISALLOW_ALL", // User disallows all addresses
}

export enum VaultConfigContext {
  SETUP = "SETUP",
  RECONFIGURATION = "RECONFIGURATION",
  MIGRATION = "MIGRATION",
}

type VaultConfigEncodeArgs<TVaultConfigType extends VaultConfigType> =
  | {
      context: VaultConfigContext.MIGRATION;
      // It is not guaranteed that previous versions will have the same policies, so this has to be optional
      previousSettings?: VaultConfigSettings[TVaultConfigType];
    }
  | {
      context: VaultConfigContext.RECONFIGURATION;
      previousSettings: VaultConfigSettings[TVaultConfigType];
    }
  | {
      context: VaultConfigContext.SETUP;
    };

export interface VaultConfig<
  TVaultConfigType extends VaultConfigType,
  TEditable extends boolean = boolean,
  TDisableable extends boolean = boolean,
> {
  address: (contracts: ReleaseContracts) => Address;
  disableable: TDisableable;
  display: ElementType<VaultConfigDisplayProps<TVaultConfigType>>;
  displaySubgraph: ElementType<VaultConfigDisplaySubgraphProps<TVaultConfigType>>;
  editable: TEditable;
  encode: (
    settings: VaultConfigSettings[TVaultConfigType],
    encodeArgs?: VaultConfigEncodeArgs<TVaultConfigType>,
  ) => Hex;
  fetch: ({
    vaultConfigAddress,
    comptroller,
    client,
  }: {
    vaultConfigAddress: Address;
    comptroller: Address;
    client: PublicClient;
  }) => Promise<VaultConfigSettings[TVaultConfigType] | undefined>;
  formFields: ElementType<VaultConfigFormFieldsProps<TVaultConfigType>>;
  formInitialValues?: VaultConfigSettings[TVaultConfigType];
  label: string;
  publicDescription?: ReactElement;
  managerDescription?: ReactElement;
  type: TVaultConfigType;
  validationSchema: z.ZodSchema;
  alert?: ElementType<AllowedAdaptersPolicyAlertProps>;
  usePricelessAssetBypass?: boolean;
}

type OnlyRemoveDustExternalPositionPolicy = SubsetOf<PolicyDetailsFragment, "OnlyRemoveDustExternalPositionPolicy">;
type OnlyUntrackDustOrPricelessAssetsPolicy = SubsetOf<PolicyDetailsFragment, "OnlyUntrackDustOrPricelessAssetsPolicy">;

interface VaultConfigFragments {
  [VaultConfigType.MANAGEMENT_FEE]: ManagementFeeFragment;
  [VaultConfigType.PERFORMANCE_FEE]: PerformanceFeeFragment;
  [VaultConfigType.ENTRANCE_BURN_FEE]: EntranceRateBurnFeeFragment;
  [VaultConfigType.ENTRANCE_DIRECT_FEE]: EntranceRateDirectFeeFragment;
  [VaultConfigType.EXIT_BURN_FEE]: ExitRateBurnFeeFragment;
  [VaultConfigType.EXIT_DIRECT_FEE]: ExitRateDirectFeeFragment;
  [VaultConfigType.MIN_SHARES_SUPPLY_FEE]: MinSharesSupplyFeeFragment;
  [VaultConfigType.ALLOWED_DEPOSIT_RECIPIENTS_POLICY]: AllowedDepositRecipientsPolicyDetailsFragment;
  [VaultConfigType.MIN_MAX_DEPOSIT_POLICY]: MinMaxDepositPolicyDetailsFragment;
  [VaultConfigType.ALLOWED_SHARES_TRANSFER_RECIPIENTS_POLICY]: AllowedSharesTransferRecipientsPolicyDetailsFragment;
  [VaultConfigType.ALLOWED_ASSETS_FOR_REDEMPTION_POLICY]: AllowedAssetsForRedemptionPolicyDetailsFragment;
  [VaultConfigType.ALLOWED_REDEEMERS_FOR_SPECIFIC_ASSETS_POLICY]: AllowedRedeemersForSpecificAssetsPolicyDetailsFragment;
  [VaultConfigType.MIN_ASSET_BALANCES_POST_REDEMPTION_POLICY]: MinAssetBalancesPostRedemptionPolicyDetailsFragment;
  [VaultConfigType.ALLOWED_ADAPTER_INCOMING_ASSETS_POLICY]: AllowedAdapterIncomingAssetsPolicyDetailsFragment;
  [VaultConfigType.ALLOWED_ADAPTERS_PER_MANAGER_POLICY]: AllowedAdaptersPerManagerPolicyDetailsFragment;
  [VaultConfigType.ALLOWED_ADAPTERS_POLICY]: AllowedAdaptersPolicyDetailsFragment;
  [VaultConfigType.ALLOWED_EXTERNAL_POSITION_TYPES_PER_MANAGER_POLICY]: AllowedExternalPositionTypesPerManagerPolicyDetailsFragment;
  [VaultConfigType.ALLOWED_EXTERNAL_POSITION_TYPES_POLICY]: AllowedExternalPositionTypesPolicyDetailsFragment;
  [VaultConfigType.CUMULATIVE_SLIPPAGE_TOLERANCE_POLICY]: CumulativeSlippageTolerancePolicyDetailsFragment;
  [VaultConfigType.NO_DEPEG_ON_REDEEM_SHARES_FOR_SPECIFIC_ASSETS_POLICY]: NoDepegOnRedeemSharesForSpecificAssetsPolicyDetailsFragment;
  [VaultConfigType.ONLY_REMOVE_DUST_EXTERNAL_POSITION_POLICY]: OnlyRemoveDustExternalPositionPolicy;
  [VaultConfigType.ONLY_UNTRACK_DUST_OR_PRICELESS_ASSETS_POLICY]: OnlyUntrackDustOrPricelessAssetsPolicy;
  [VaultConfigType.SHARES_ACTION_TIMELOCK]: bigint;
  [VaultConfigType.UNKNOWN_FEE]: UnknownFeeFragment;
  [VaultConfigType.UNKNOWN_POLICY]: never;
}

export interface VaultConfigSettings {
  [VaultConfigType.MANAGEMENT_FEE]: ManagementFeeSettings;
  [VaultConfigType.PERFORMANCE_FEE]: PerformanceFeeSettings;
  [VaultConfigType.ENTRANCE_BURN_FEE]: EntranceBurnFeeSettings;
  [VaultConfigType.ENTRANCE_DIRECT_FEE]: EntranceDirectFeeSettings;
  [VaultConfigType.EXIT_BURN_FEE]: ExitBurnFeeSettings;
  [VaultConfigType.EXIT_DIRECT_FEE]: ExitDirectFeeSettings;
  [VaultConfigType.MIN_SHARES_SUPPLY_FEE]: boolean;
  [VaultConfigType.ALLOWED_DEPOSIT_RECIPIENTS_POLICY]: AllowedDepositRecipientsPolicySettings;
  [VaultConfigType.MIN_MAX_DEPOSIT_POLICY]: MinMaxDepositPolicySettings;
  [VaultConfigType.ALLOWED_SHARES_TRANSFER_RECIPIENTS_POLICY]: AllowedSharesTransferRecipientsPolicySettings;
  [VaultConfigType.ALLOWED_ASSETS_FOR_REDEMPTION_POLICY]: AllowedAssetsForRedemptionPolicySettings;
  [VaultConfigType.ALLOWED_REDEEMERS_FOR_SPECIFIC_ASSETS_POLICY]: AllowedRedeemersForSpecificAssetsPolicySettings;
  [VaultConfigType.MIN_ASSET_BALANCES_POST_REDEMPTION_POLICY]: MinAssetBalancesPostRedemptionPolicySettings;
  [VaultConfigType.ALLOWED_ADAPTER_INCOMING_ASSETS_POLICY]: AllowedAdapterIncomingAssetsPolicySettings;
  [VaultConfigType.ALLOWED_ADAPTERS_PER_MANAGER_POLICY]: AllowedAdaptersPerManagerPolicySettings;
  [VaultConfigType.ALLOWED_ADAPTERS_POLICY]: AllowedAdaptersPolicySettings;
  [VaultConfigType.ALLOWED_EXTERNAL_POSITION_TYPES_PER_MANAGER_POLICY]: AllowedExternalPositionTypesPerManagerPolicySettings;
  [VaultConfigType.ALLOWED_EXTERNAL_POSITION_TYPES_POLICY]: AllowedExternalPositionTypesPolicySettings;
  [VaultConfigType.CUMULATIVE_SLIPPAGE_TOLERANCE_POLICY]: CumulativeSlippageTolerancePolicySettings;
  [VaultConfigType.NO_DEPEG_ON_REDEEM_SHARES_FOR_SPECIFIC_ASSETS_POLICY]: NoDepegOnRedeemSharesForSpecificAssetsPolicySettings;
  [VaultConfigType.ONLY_REMOVE_DUST_EXTERNAL_POSITION_POLICY]: boolean;
  [VaultConfigType.ONLY_UNTRACK_DUST_OR_PRICELESS_ASSETS_POLICY]: boolean;

  // Other policies
  [VaultConfigType.SHARES_ACTION_TIMELOCK]: bigint;
  [VaultConfigType.UNKNOWN_FEE]: never;
  [VaultConfigType.UNKNOWN_POLICY]: never;
}

export interface VaultConfigDisplayProps<TVaultConfigType extends VaultConfigType> {
  denominationAsset?: PrimitiveAsset;
  settings: VaultConfigSettings[TVaultConfigType];
}
export interface VaultConfigDisplaySubgraphProps<TVaultConfigType extends VaultConfigType> {
  denominationAsset?: PrimitiveAsset;
  settings: VaultConfigFragments[TVaultConfigType];
}
