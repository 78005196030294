import { type Address, type Asset, Deployment, toAddress } from "@enzymefinance/environment";
import { TokenLabel, TokenPicker } from "@enzymefinance/ethereum-ui";
import { useGlobals } from "components/providers/GlobalsProvider";
import type { VaultDetailsFragment } from "queries/core";

const allowedAdditionalDepositAssetsForSpecificVaults: {
  [key in Deployment]: {
    readonly [key: Address]: readonly Address[];
  };
} = {
  [Deployment.ARBITRUM]: {},
  [Deployment.ETHEREUM]: {
    "0xbb05d8bd8c8aa18e1aa05d695129dbe95190c2a7": ["0x6b175474e89094c44da98b954eedeac495271d0f"],
  },
  [Deployment.POLYGON]: {
    "0xe7178a995867d86b0504730dc4cefb409fb0a004": [
      "0x3c499c542cef5e3811e1192ce70d8cc03d5c3359",
      "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
    ],
  },
  [Deployment.TESTNET]: {
    "0x40447544eaa91b4817ecf2d77b0daf5c8bb279db": [
      "0x3c499c542cef5e3811e1192ce70d8cc03d5c3359",
      "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
    ],
  },
} as const;

export function DepositTokenPicker({
  vault,
  depositAsset,
  setDepositAsset,
}: {
  vault: VaultDetailsFragment;
  depositAsset: Asset;
  setDepositAsset: (asset: Asset) => void;
}) {
  const { environment } = useGlobals();
  const id = toAddress(vault.id);
  const denominationAsset = environment.getAsset(toAddress(vault.comptroller.denomination.id));
  const nativeToken = environment.network.currency.nativeToken;

  const otherAssetsToDeposit =
    allowedAdditionalDepositAssetsForSpecificVaults[environment.deployment.slug][id]?.map((asset) =>
      environment.getAsset(asset),
    ) ?? [];

  return (
    <div>
      <TokenPicker
        id="deposit-token-picker"
        label=""
        isClearable={false}
        onChange={(value) => {
          if (value === null) {
            return;
          }
          setDepositAsset(value);
        }}
        options={[denominationAsset, nativeToken, ...otherAssetsToDeposit]}
      >
        <TokenPicker.Button className="w-48 rounded-l-none border-0 bg-base-400">
          <TokenLabel size={5} asset={depositAsset} hideName={true} />
        </TokenPicker.Button>
      </TokenPicker>
    </div>
  );
}
