import "@rainbow-me/rainbowkit/styles.css";

import { toAddress } from "@enzymefinance/environment";
import { Jazzicon } from "@enzymefinance/ui";
import type { Wallet } from "@rainbow-me/rainbowkit";
import { RainbowKitProvider, darkTheme, getDefaultConfig } from "@rainbow-me/rainbowkit";
import {
  braveWallet,
  coinbaseWallet,
  frameWallet,
  injectedWallet,
  ledgerWallet,
  metaMaskWallet,
  okxWallet,
  rainbowWallet,
  safeWallet,
  walletConnectWallet,
  xdefiWallet,
} from "@rainbow-me/rainbowkit/wallets";
import type { ReactNode } from "react";
import { zeroAddress } from "viem";
import { http, WagmiProvider as WagmiProviderBase } from "wagmi";
import { arbitrum, mainnet, polygon } from "wagmi/chains";

import type { CreateWalletFn } from "@rainbow-me/rainbowkit/dist/wallets/Wallet.js";
import { debug } from "../../utils/config.js";
import { mockConnector } from "../connection/mockConnector.js";

const projectId = "3f1198cd2598cba7254e9be87f95cebe";
const appName = "Enzyme";

const storedAccountKey = "enzyme.impersonator.account";
const storedChainIdKey = "enzyme.impersonator.chain";
const storedConnectedKey = "enzyme.impersonator.connected";

const mockWallet: CreateWalletFn = (): Wallet => {
  return {
    id: "mock",
    name: "Impersonator",
    iconUrl: "/impersonator.png",
    iconBackground: "#000000",
    hidden: () => !debug,
    createConnector: (walletDetails) => {
      const storedAccount = window.localStorage.getItem(storedAccountKey) ?? undefined;
      const storedChainId = window.localStorage.getItem(storedChainIdKey) ?? undefined;
      const storedConnected = Boolean(JSON.parse(window.localStorage.getItem(storedConnectedKey) ?? "false"));

      const account = storedAccount !== undefined ? toAddress(storedAccount) : zeroAddress;

      const connector = mockConnector({
        accounts: [account],
        chainId: storedChainId === undefined ? undefined : Number(storedChainId),
        walletDetails,
        connected: storedConnected,
        onChainChanged: (chainId) => {
          window.localStorage.setItem(storedChainIdKey, chainId.toString());
        },
        onAccountsChanged: (accounts) => {
          const [firstAccount] = accounts;
          if (firstAccount === undefined) {
            window.localStorage.removeItem(storedAccountKey);
          } else {
            window.localStorage.setItem(storedAccountKey, firstAccount);
          }
        },
        onConnectedChanged: (connected) => {
          window.localStorage.setItem(storedConnectedKey, connected.toString());
        },
      });

      return connector;
    },
  };
};

export const wagmiConfig = getDefaultConfig({
  appName,
  projectId,
  chains: [mainnet, polygon, arbitrum],
  transports: {
    [arbitrum.id]: http(`${window.location.origin}/rpc/arbitrum`),
    [mainnet.id]: http(`${window.location.origin}/rpc/ethereum`),
    [polygon.id]: http(`${window.location.origin}/rpc/polygon`),
  },
  wallets: [
    {
      groupName: "Popular",
      wallets: [
        injectedWallet,
        safeWallet,
        rainbowWallet,
        coinbaseWallet,
        metaMaskWallet,
        walletConnectWallet,
        xdefiWallet,
        okxWallet,
        braveWallet,
        frameWallet,
        ledgerWallet,
      ],
    },
    {
      groupName: "Debugging",
      wallets: [mockWallet],
    },
  ],
});

export function WagmiProvider({ children }: { children?: ReactNode }) {
  return (
    <WagmiProviderBase config={wagmiConfig}>
      <RainbowKitProvider
        theme={darkTheme()}
        avatar={({ address }) => <Jazzicon id={address.toLowerCase()} size="full" />}
      >
        {children}
      </RainbowKitProvider>
    </WagmiProviderBase>
  );
}
