import { Utils } from "@enzymefinance/sdk";
import type { ExternalPosition } from "@enzymefinance/utils";
import { ExternalPositionType } from "@enzymefinance/utils";
import { useExternalPositions } from "components/providers/ExternalPositionProvider";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useMemo } from "react";
import { externalPositions } from "utils/externalPositions";
import { zeroAddress } from "viem";

import { debug } from "../config";
import { useArbitraryLoanAvailable } from "./useArbitraryLoanAvailable";

export interface ExternalPositionOption extends ExternalPosition {
  externalPositionIdentifier: number;
  type: ExternalPositionType;
}

export function useExternalPositionOptions(): ExternalPositionOption[] {
  const { contracts } = useGlobals();
  const { externalPositionTypes } = useExternalPositions();

  const isArbitraryLoanAvailable = useArbitraryLoanAvailable();

  return useMemo<ExternalPositionOption[]>(() => {
    if (externalPositionTypes.length === 0) {
      return [];
    }

    return Object.values(externalPositions)
      .filter(
        (item) =>
          (item.type !== ExternalPositionType.ARBITRARY_LOAN || isArbitraryLoanAvailable) &&
          !Utils.Address.safeSameAddress(item.address(contracts), zeroAddress) &&
          (item.type !== ExternalPositionType.THEGRAPH_DELEGATION || debug),
      )
      .map((item) => {
        const externalPositionIdentifier = externalPositionTypes.find((e) => e.label === item.type)?.id;

        if (externalPositionIdentifier === undefined) {
          throw new Error("externalPositionIdentifier is undefined");
        }

        const externalPositionOption: ExternalPositionOption = {
          externalPositionIdentifier: Number(externalPositionIdentifier),
          id: item.address(contracts),
          name: item.label,
          type: item.type,
          url: item.icon,
        };

        return externalPositionOption;
      });
  }, [contracts, externalPositionTypes, isArbitraryLoanAvailable]);
}
