import type { BaseSelectProps, MultiValueProps } from "@enzymefinance/select";
import { BaseSelect, components, sharedComponents } from "@enzymefinance/select";
import type { FieldGroupProps, Option } from "@enzymefinance/ui";
import { FieldGroup, useFieldGroup } from "@enzymefinance/ui";
import type { Adapter, ExternalPosition } from "@enzymefinance/utils";
import classNames from "classnames";

import { ProtocolLabel } from "../protocol-label/ProtocolLabel.js";

export type ProtocolSelectOption = Adapter | ExternalPosition;

const customComponents: NonNullable<ProtocolSelectProps["components"]> = {
  MultiValue(props) {
    return (
      <sharedComponents.MultiValue {...(props as unknown as MultiValueProps<Option>)}>
        <ProtocolLabel id={props.data.id} name={props.data.name} size={5} url={props.data.url} />
      </sharedComponents.MultiValue>
    );
  },
  SingleValue(props) {
    const { error } = useFieldGroup();
    const classes = classNames("absolute inset-0 flex items-center truncate", {
      "text-red-900 dark:text-red-300": error,
    });

    return (
      <components.SingleValue {...props} className={classes}>
        <ProtocolLabel id={props.data.id} name={props.data.name} size={5} url={props.data.url} />
      </components.SingleValue>
    );
  },
};

export type BaseProtocolSelectProps<TMulti extends boolean = boolean> = BaseSelectProps<ProtocolSelectOption, TMulti>;

export function BaseProtocolSelect<TMulti extends boolean = boolean>(props: ProtocolSelectProps<TMulti>) {
  return (
    <BaseSelect<ProtocolSelectOption, TMulti>
      filterOption={(option, rawInput) => {
        const input = rawInput.toLowerCase();

        if (input.length === 0) {
          return true;
        }

        // Filterable by name or id
        return option.data.id.toLowerCase().includes(input) || option.data.name.toLowerCase().includes(input);
      }}
      formatOptionLabel={(option) => <ProtocolLabel id={option.id} kind="option" name={option.name} url={option.url} />}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      noOptionsMessage={() => "No protocols found"}
      {...props}
      placeholder={props.placeholder ?? (props.isSearchable ? "Find a protocol by name or address" : undefined)}
      components={{ ...customComponents, ...props.components } as ProtocolSelectProps<TMulti>["components"]}
    />
  );
}

export type ProtocolSelectProps<TMulti extends boolean = boolean> = BaseSelectProps<ProtocolSelectOption, TMulti> &
  Omit<FieldGroupProps, "kind">;

export function ProtocolSelect<TMulti extends boolean = boolean>({
  cornerHint,
  error,
  description,
  isSearchable = true,
  label,
  labelHidden = false,
  ...props
}: ProtocolSelectProps<TMulti>) {
  return (
    <FieldGroup
      cornerHint={cornerHint}
      error={error}
      description={description}
      label={label}
      labelHidden={labelHidden}
      id={isSearchable ? `react-select-${props.id}-input` : props.id}
    >
      <BaseProtocolSelect<TMulti> isSearchable={isSearchable} label={label} {...props} />
    </FieldGroup>
  );
}
