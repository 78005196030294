import { useKey } from "react-use";
import { debug } from "utils/config";

const keyFilter = debug
  ? (event: KeyboardEvent) => event.ctrlKey && event.shiftKey && ["c"].includes(event.key.toLowerCase())
  : null;

export function useColorSchemeSwitch() {
  useKey(keyFilter, () => {
    document.documentElement.classList.toggle("dark");
  });
}
